import { Box, Button, styled } from "@mui/material";


export const MuiButtonStyle = styled(Box)(({ theme }) => ({
  "& .MuiButton-root": {
    fontSize: "1.5rem",
    border: "none",
    height: "50px"
  },
  "&& .MuiButton-outlined:hover": {
    border: "none",
    backgroundColor: `${theme.palette.primary.light}`,
  },
  "& .Mui-disabled": {
    color: `${theme.palette.common.white}`,
    border: "none",
    backgroundColor: `${theme.palette.grey[100]}`,
    backgroundImage: "none",
  },

  [theme.breakpoints.down("xl")]: {
    "& .MuiButton-root": {
      fontSize: "1.3rem",
      border: "none",
      height: "40px"
    },
  },
}));
