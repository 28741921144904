import { Box, styled } from "@mui/material";
import { height } from "@mui/system";

export const OnboardingQuestionStyleWrapper = styled(Box)(({ theme }) => ({
  height: "100%",
  ".question-header": {
    fontSize: "1.6rem",
    color: `#8867ff`,
    fontWeight: "500",
    fontFamily: "Urbanist-Bold"
  },
  ".back-to-question-list-label": {
    fontSize: " 1.7rem",
    fontFamily: "Urbanist-Regular",
    fontWeight: "600",
    color: "#434245"
  },

  [theme.breakpoints.down("xl")]: {
    ".back-to-question-list-label": {
      fontSize: " 1.2rem",
    },
     ".question-header": {
      fontSize: "1.2rem",
    },
  }

}));
