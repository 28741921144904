import { SagaIterator } from "@redux-saga/types";
import { call, put } from "redux-saga/effects";
import { HttpResponseTypeEnum } from "../../../enumerations/HttpResponseTypeEnum";
import { ProgramThemeQuestionRedux } from "../../../redux/program-theme/programtheme-question/ProgramThemQuestionRedux";
// import { QuestionRedux } from "../../redux/-question/QuestionRedux";

export function* addThemeProgramQuestionSaga(
  api: any,
  action: any
): SagaIterator {
  try {
    const response = yield call(api.addProgramThemeQuestion, action.payload);
    if (response.data.code === HttpResponseTypeEnum.Success) {
      yield put(
        ProgramThemeQuestionRedux.actions.addProgramThemeQuestionSuccess(
          response.data
        )
      );
    } else {
      yield put(
        ProgramThemeQuestionRedux.actions.addProgramThemeQuestionFailure(
          response.data
        )
      );
    }
  } catch (error: any) {
    yield put(
      ProgramThemeQuestionRedux.actions.addProgramThemeQuestionFailure(error)
    );
  }
}
//getAllquestion
export function* getThemeProgramQuestionSaga(
  api: any,
  action: any
): SagaIterator {
  try {
    const response = yield call(
      api.getAllProgramThemeQuestions,
      action.payload
    );
    if (response?.data?.code === HttpResponseTypeEnum.Success) {
      yield put(
        ProgramThemeQuestionRedux.actions.getProgramThemeQuestionSuccess(
          response.data
        )
      );
    } else {
      yield put(
        ProgramThemeQuestionRedux.actions.getProgramThemeQuestionFailure(
          response.data
        )
      );
    }
  } catch (error: any) {
    yield put(
      ProgramThemeQuestionRedux.actions.getProgramThemeQuestionFailure(error)
    );
  }
}
// getEachProgramTheme

export function* getEachProgramThemeQuestionSaga(
  api: any,
  action: any
): SagaIterator {
  try {
    const response = yield call(api.getEachOnBoardingQuestions, action.payload);
    if (response?.data?.code === HttpResponseTypeEnum.Success) {
      yield put(
        ProgramThemeQuestionRedux.actions.getEachThemeQuestionSuccess(
          response.data
        )
      );
    } else {
      yield put(
        ProgramThemeQuestionRedux.actions.getEachThemeQuestionFailure(
          response.data
        )
      );
    }
  } catch (error: any) {
    yield put(
      ProgramThemeQuestionRedux.actions.getEachThemeQuestionFailure(error)
    );
  }
}

// delete question for

export function* deleteThemeProgramQuestionSaga(
  api: any,
  action: any
): SagaIterator {
  try {
    const response = yield call(
      api.deleteProgramThemeQuestions,
      action.payload
    );
    if (response.data.code === HttpResponseTypeEnum.Success) {
      yield put(
        ProgramThemeQuestionRedux.actions.deleteProgramThemeQuestionSuccess(
          response.data
        )
      );
    } else {
      yield put(
        ProgramThemeQuestionRedux.actions.deleteProgramThemeQuestionFailure(
          response.data
        )
      );
    }
  } catch (error: any) {
    yield put(
      ProgramThemeQuestionRedux.actions.deleteProgramThemeQuestionFailure(error)
    );
  }
}

export function* updateThemeProgramQuestionSaga(
  api: any,
  action: any
): SagaIterator {
  try {
    const response = yield call(
      api.updateProgramThemeQuestions,
      action.payload
    );
    if (response.data.code === HttpResponseTypeEnum.Success) {
      yield put(
        ProgramThemeQuestionRedux.actions.updateProgramThemeQuestionSuccess(
          response.data
        )
      );
    } else {
      yield put(
        ProgramThemeQuestionRedux.actions.updateProgramThemeQuestionFailure(
          response.data
        )
      );
    }
  } catch (error: any) {
    yield put(
      ProgramThemeQuestionRedux.actions.updateProgramThemeQuestionFailure(error)
    );
  }
}
