import { createSlice } from "@reduxjs/toolkit";
import { LoginRedux } from "../login/LoginRedux";

export interface ItoasterError {
  error?: string;
  success?: string;
  data?: any;
  toasterDetails?: any;
}

const initialState: ItoasterError = {
  error: "",
  success: "",
  data: null,
  toasterDetails: null,
};

export const ToasterRedux = createSlice({
  name: "toasterRedux",
  initialState,
  reducers: {
    setToaster: (state: any, action: any) => {
      const newState = {
        ...state,
        [action.payload.key]: action.payload.value,
      };
      return newState;
    },
    setToasterNull: (state: any) => {
      const newState = {
        ...state,
        toasterDetails: null,
      };
      return newState;
    },
  },
});

const { actions, reducer } = ToasterRedux;

export const { setToaster, setToasterNull } = actions;

export default reducer;
