import { Block } from "@mui/icons-material";
import { Box, styled } from "@mui/material";
import { NONE } from "apisauce";

interface IImageUploaderProps {
  error?: any;
  value?: string;
  isErrorThere?: boolean;
}

export const ImageUploderWrapper = styled(Box)<IImageUploaderProps>(
  ({ theme, error }) => ({
    border: error
      ? `dashed 1px ${theme.palette.error.main} `
      : "dashed 1px #a9a9a9;",
    backgroundColor: "#fbfbfb",
    borderRadius: "4px",
    width: "200px",
    maxWidth: "100%",
    height: "109px",
    maxHeight: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
    position: "relative",
    cursor: "pointer",
    "& .innerButton": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    ".image-uploader": {
      position: "absolute",
      height: "100%",
      width: "100%",
      top: 0,
      left: 0,
      opacity: 0,
      cursor: "pointer",
    },
    ".upload-image-text": {
      textDecoration: "underline",
      textAlign: "center",
      fontWeight: "600",
      color: error
        ? `${theme.palette.error.main}`
        : `${theme.palette.primary.contrastText}`,
    },
    ".cross-img": {
      position: "relative",
      width: "10px",
      height: "10px",
    },

    ".upload-logo": {
      width: "30px",
      height: "30px",
    },
    " .delete-icon-background": {
      position: "relative",
      zIndex: 0,
      width: "20px",
      height: "20.5px",
      padding: "5px",
      display: "none",
      left: "43%",
      top: "-4px",
      backgroundColor: "#c6c4ce",
    },
    "&:hover": {
      ".delete-icon-background": {
        position: "absolute",
        display: "block",
        cursor: "pointer",
        borderRadius: "50%",
        left: "87%",
        top: "2%",
        zIndex: 2,
        backgroundColor: "#c6c4ce",
      },
    },
  })
);

export const UploadedImageWrapper = styled(Box)({
  border: "solid 1px #a9a9a9;",
  backgroundColor: "#fbfbfb",
  borderRadius: "4px 4px 0px 0px",
  width: "200px",
  maxWidth: "100%",
  height: "109px",
  maxHeight: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-evenly",
  alignItems: "center",
  position: "relative",
  textDecoration: "underline",
  cursor: "pointer",

  ".replace-and-delete-grid": {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    position: "absolute",
    zIndex: "-1",
    width: "100%",
  },

  "&:hover": {
    ".replace-and-delete-grid": {
      zIndex: "4",
    },
    ".preview-image-height": {
      opacity: 0.5,
    },
  },

  ".preview-image-height": {
    height: "100%",
  },

  ".cursor-as-pointer": {
    cursor: "pointer",
  },

  ".upload-logo": {
    width: "30px",
    height: "30px",
  },

  ".input-display-none": {
    display: "none",
    cursor: "pointer",
  },

  ".replace-and-delete-icon-background": {
    cursor: "pointer",
    borderRadius: "6px",
    backgroundImage: "linear-gradient(132deg, #2a2a3b 3%, #33334a 89%)",
  },
});

export const AddCaptionInput = styled("div")({
  ".add-caption": {
    width: "200px",
    maxWidth: "100%",
    textAlign: "center",
    padding: "10px",
    fontSize: "15px",
    fontFamily: "Urbanist-Regular",
    fontStyle: "italic",
    border: "solid 1px #c4c5ce",
    borderRadius: "0px 0px 4px 4px",
    outline: "none",
    "::placeholder": {
      fontSize: "1.4rem",
    },
  },
});
