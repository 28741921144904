import { useState, useEffect, Fragment } from "react";
import { Box, FormControlLabel, Radio, Stack } from "@mui/material";
import styles from "./CreateThemeForm.module.css";
import { Form, Formik, getIn } from "formik";

import MuiGroupRadioButton from "../../../components/mui-group-radio-button/MuiGroupRadioButton";
import FileUploadDragDrop from "../../../components/file-upload-drag-drop/FileUploadDragDrop";
import {
  InitialProgramThemeState,
  ProgramThemeValidation,
  ThemeGuidelineValidation,
} from "../ProgramThemeInitialState";
import { StringConstant } from "../../../resources/strings";
import MuiTextField from "../../../components/ui-elements/mui-text-field/MuiTextField";
import { Constants } from "../../../resources/constants";
import { extractFileType } from "../../../utils/FileOperationUtils";
import FormBottomButtons from "./../../../components/form-bottom-btns/FormBottomButtons";
import { addProgramThemeGuidelineRequest, getEachProgramThemeRequest, setCreateProgramTheme, setCreateProgramThemeNull, updateProgramThemeGuidelineRequest } from "../../../redux/program-theme/create-program-theme/CreateProgramThemeRedux";
import { fileUploadS3Request, fileUploadS3UpdateRequest, setFileUploadS3Update } from "../../../redux/s3-upload/FileUploadS3Redux";
import { useDispatch, useSelector } from "react-redux";
import { InitialProgramGuideline } from "./../ProgramThemeInitialState";
import { AttachmentTypeEnum, FileUploadMIMETypeEnum } from "../../../enumerations/FileUploadMIMETypeEnum";
import { setToaster } from "../../../redux/toaster/ToasterRedux";

interface IThemeGuideLines {
  tab?: number;
  handleTabPrev?: (event: any) => void;
  handleTabNext: (event: any) => void;
  handleValidMove?: (validMove: boolean) => void;
}

const ThemeGuidelines = (props: IThemeGuideLines) => {
  const actionDispatch = (dispatch: any) => ({
    addProgramThemeGuideline: (data: any) =>
      dispatch(addProgramThemeGuidelineRequest(data)),
    updateFileS3: (data: any) => dispatch(fileUploadS3UpdateRequest(data)),
    uploadfileS3: (data: any) => dispatch(fileUploadS3Request(data)),
    setFileUpload: (data: any) => dispatch(setFileUploadS3Update(data)),
    getEachProgramTheme: (data: any) =>
      dispatch(getEachProgramThemeRequest(data)),
    setCreateThemeNull: () => dispatch(setCreateProgramThemeNull()),
    setToasterInfo: (data: any) => dispatch(setToaster(data)),
    setReduxProgramTheme: (data: any) => dispatch(setCreateProgramTheme(data)),
    // setProgramTheme: (data: any) => dispatch(setCreateProgramTheme(data)),
    updateGuilde: (data: any) => dispatch(updateProgramThemeGuidelineRequest(data))
    // updateOnboardingQuestions: (data: any) => (dispatch(updateOnboardingQuestionRequest(data))),
    // setOnboardingNull: () => dispatch(setOnboardingToasterNull()),

    // setToasterInfo: (data: any) => dispatch(setToaster(data)),
  });
  const { addProgramThemeGuideline, uploadfileS3, setFileUpload, updateFileS3, getEachProgramTheme, setToasterInfo, setReduxProgramTheme, setCreateThemeNull, updateGuilde } = actionDispatch(useDispatch());
  const { themeQuestion, createProgramTheme, fileUploader } = useSelector(
    (state: any) => state
  );

  const { tab, handleTabPrev, handleTabNext, handleValidMove } = props;
  const [value, setValue] = useState<number>(1);
  const [fileType, setFileType] = useState("")
  const [postMediaData, setPostMediaData] = useState<any[]>([]);
  const [previewFile, setpreviewFile] = useState<any>("");
  const [fileName, setFileName] = useState<string>("");
  const [objectURL, setObjectURL] = useState<string>("");
  const [fileError, setFileError] = useState<string | false>(false);
  const [guideLines, setGuideline] = useState<any>(null)
  const [isFormSubmit, setIsFormSubmit] = useState<boolean>(false);


  useEffect(() => {
    if (fileUploader?.singleImageUpload) {
      addProgramThemeGuideline({
        programThemeId: createProgramTheme.programThemeId,
        attachments: [
          {
            attachmentType: AttachmentTypeEnum.SessionTemplate,
            contentType: FileUploadMIMETypeEnum?.Document,
            publicUrl: fileUploader?.singleImageUpload,
            fileName: fileName,
          },
        ],
      });
      setFileUpload({ key: "toasterDetails", value: null });
      setFileUpload({ key: "singleImageUpload", value: "" });
      setIsFormSubmit(true);

    }
  }, [fileUploader.singleImageUpload])




  useEffect(() => {
    if (createProgramTheme?.guideLineData) {
      setpreviewFile(
        createProgramTheme?.guideLineData?.themeGuidelines?.guidelineFileValue
      );
      setFileName(
        createProgramTheme?.guideLineData?.themeGuidelines
          ?.guideLineFileName
      );
      setObjectURL(createProgramTheme?.guideLineData?.themeGuidelines?.guidelineFileValue)
    }
    setReduxProgramTheme({ key: "toasterDetails", value: null })
    setFileUpload({ key: "toasterDetails", value: null });
    setFileUpload({ key: "singleImageUpload", value: "" });

  }, [])

  useEffect(() => {
    if (isFormSubmit) {
      createProgramTheme.guideLineData
        ? handleTabNext(tab)
        : setIsFormSubmit(false);
    }
  }, [createProgramTheme.guideLineData]);

  useEffect(() => {
    if (fileUploader?.toasterDetails) {
      setToasterInfo({
        key: "toasterDetails",
        value: { ...fileUploader?.toasterDetails, toasterOpen: true },
      });
      getEachProgramTheme(createProgramTheme?.programThemeId);
      setFileUpload({ key: "fileUpadtedInS3", value: false })
      if (fileUploader?.toasterDetails?.code === 200) {
        setFileUpload({ key: "toasterDetails", value: null });
        setFileUpload({ key: "fileUpadtedInS3", value: false })
        handleTabNext(tab)
      }
    }

  }, [fileUploader?.toasterDetails])

  useEffect(() => {
    if (createProgramTheme?.toasterDetails) {
      setToasterInfo({
        key: "toasterDetails",
        value: { ...createProgramTheme?.toasterDetails, toasterOpen: true },
      });
      setCreateThemeNull()
    }
  }, [createProgramTheme.toasterDetails])


  useEffect(() => {
    if (createProgramTheme?.isLinkedUpadate) {
      getEachProgramTheme(createProgramTheme?.programThemeId);
      setIsFormSubmit(true)
      setReduxProgramTheme({ key: "isLinkedUpadate", value: false })
    }

  }, [createProgramTheme?.isLinkedUpadate])

  const handleRadioChange = (event: any, setFieldValue: any) => {
    setValue(Number(event.target.value));
    // handleClear();
    if (event.target.value == 1) {
      setFieldValue("themeGuidelines.guidelineTextValue", "")
    } else {
      setFieldValue("themeGuidelines.guideLineFileName", "")
      setFieldValue("themeGuidelines.guidelineFileValue", "")
      setFileName("")
      setObjectURL("")
      setpreviewFile(false)

    }

  };

  const handleDrop = (e: any, setFieldValue: any) => {
    e.preventDefault();
    e.stopPropagation();

    handleClear(e, setFieldValue)
    const accept = ["doc", "docx"];
    if (
      e.dataTransfer.files[0].size / Constants.one_mega_byte <
      Constants.max_file_size
    ) {
      if (accept.includes(extractFileType(e.dataTransfer.files[0].name))) {
        handleMediaUpload(e.dataTransfer.files[0]);
        setFileType(e.dataTransfer.files[0].type)
        setFieldValue(
          "themeGuidelines.guidelineFileValue",
          URL.createObjectURL(e.dataTransfer.files[0])
        );
        setFieldValue(
          "themeGuidelines.guideLineFileName",
          e.dataTransfer.files[0].name
        );
        setFileError(false);
      } else {
        setFileError('Please choose "docx,doc" file type.');
      }
    } else {
      // handleClear();
      setFileError("File is too large. Change to below 10 MB and upload again.");
    }
  };

  const handleFileChange = (e: any, setFieldValue: any) => {
    e.preventDefault();
    const accept = ["doc", "docx"];

    if (
      e.target.files[0].size / Constants.one_mega_byte <
      Constants.max_file_size
    ) {
      if (accept.includes(extractFileType(e.target.files[0].name))) {
        handleMediaUpload(e.target.files[0]);
        setFileType(e.target.files[0].type)
        setFieldValue(
          "themeGuidelines.guidelineFileValue",
          URL.createObjectURL(e.target.files[0])
        );
        setFieldValue(
          "themeGuidelines.guideLineFileName",
          e.target.files[0].name
        );
        setFileError(false);
      } else {
        setFileError('Please choose "docx,doc" file type.');
      }
    } else {
      // handleClear();
      setFileError(StringConstant.fileisTooLarge)
    }
  };

  const handleDrag = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleMediaUpload = (data: any) => {
    const uploadedMedia: any[] = [...postMediaData];
    uploadedMedia.push({ data });

    let fileURL;
    if (fileURL) {
      URL.revokeObjectURL(fileURL);
    }
    fileURL = URL.createObjectURL(data);
    setObjectURL(fileURL);
    setPostMediaData([...uploadedMedia]);
    setFileName(data.name);
    setpreviewFile(true);
  };

  const handleClear = (e: any, setFieldValue: any) => {
    setPostMediaData([]);
    setFileError(false);
    setpreviewFile(false);
    setFieldValue("themeGuidelines.guideLineFileName", "")
    setFieldValue("themeGuidelines.guidelineFileValue", "")
    setObjectURL("")

    if (createProgramTheme?.guideLineData?.themeGuidelines?.guidelineFileValue) {
      setReduxProgramTheme({
        key: "guideLineData",
        value: {
          themeGuidelines: {
            ...createProgramTheme?.guideLineData?.themeGuidelines,
            guidelineFileValue: "",
            guideLineFileName: ""
          }
        },
      });
    }

  };

  const handleSubmit = async (values: any) => {
    // updating the guideline
    if (createProgramTheme?.guideLineData?.themeGuidelines?.guideLineFileId) {

      if (values.themeGuidelines.guidelineType == "1") {
        const data: any = {
          name: values?.themeGuidelines?.guideLineFileName,
          oldFileName: createProgramTheme?.guideLineData?.themeGuidelines?.guideLineFileName,
          attachmentId: createProgramTheme?.guideLineData?.themeGuidelines?.guideLineFileId,
          programThemeId: createProgramTheme?.programThemeId,
          attachmentType: AttachmentTypeEnum.SessionTemplate,
          contentType: FileUploadMIMETypeEnum.Document,
          urlType: "upload",
          binary: "",
          type: fileType,
        };
        const reader = new FileReader();
        reader.onload = async (e: any) => {
          data.binary = e.target.result;
        };
        reader.readAsArrayBuffer(postMediaData[0].data);
        await updateFileS3(data)

        setGuideline(values)
      }
      if (values.themeGuidelines.guidelineType == "2") {
        const reqBodyForLink: any = [
          {
            attachmentId: createProgramTheme?.guideLineData?.themeGuidelines?.guideLineFileId,
            link: values?.themeGuidelines?.guidelineTextValue,
          },
        ]

        updateGuilde(reqBodyForLink);
        setIsFormSubmit(true);
      }
    } else {
      if (values.themeGuidelines.guidelineType == "1") {
        const data: any = {
          name: values?.themeGuidelines?.guideLineFileName,
          urlType: "upload",
          binary: "",
          type: fileType,
        };
        const reader = new FileReader();
        reader.onload = async (e: any) => {
          data.binary = e.target.result;
        };
        reader.readAsArrayBuffer(postMediaData[0].data);
        await uploadfileS3(data)
        setGuideline(values)
      }
      if (values.themeGuidelines.guidelineType == "2") {
        const reqBodyForLink = {
          programThemeId: createProgramTheme?.programThemeId,
          attachments: [
            {
              link: values?.themeGuidelines?.guidelineTextValue,
            },
          ],
        };
        addProgramThemeGuideline(reqBodyForLink);
        setIsFormSubmit(true);
      }

    }
    // adding guideline

  };

  return (
    <Formik
      initialValues={createProgramTheme?.guideLineData || InitialProgramGuideline}
      validationSchema={ThemeGuidelineValidation}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({
        values,
        setFieldValue,
        handleChange,
        handleBlur,
        errors,
        touched,
        isValid,
        dirty,
      }) => {
        return (
          <Form style={{ height: "100%" }}>
            <Box
              pl={3}
              pt={2}
              className={styles["program-theme-inner-container"]}
            >

              <p className={styles["form-sub-heading"]}>
                {StringConstant.themeGuidelineformHeading}
              </p>
              <MuiGroupRadioButton
                value={values?.themeGuidelines?.guidelineType}
                name="themeGuidelines.guidelineType"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  handleRadioChange(event, setFieldValue);
                  setFieldValue(
                    "themeGuidelines.guidelineType",
                    Number(event.target.value)
                  );
                }}
              >
                <Stack spacing={2} width={"100%"}>
                  <div>
                    <FormControlLabel
                      labelPlacement="end"
                      control={<Radio size="small" color="primary" />}
                      value={1}
                      label={"Upload File"}
                    />
                    <FileUploadDragDrop
                      onDrop={(e: any) => {
                        handleDrop(e, setFieldValue);
                      }}
                      name={fileName}
                      onChange={(e) => {
                        handleFileChange(e, setFieldValue);
                      }}
                      previewFile={previewFile}
                      onDeleteImage={(e) => {
                        handleClear(e, setFieldValue)


                      }}
                      isImage={false}
                      handleDrag={handleDrag}
                      downloadUrl={objectURL}
                      disabled={values?.themeGuidelines?.guidelineType !== 1 ? true : false}
                      error={fileError ? fileError : false}
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      labelPlacement="end"
                      value={2}
                      control={<Radio size="small" color="primary" />}
                      label="Paste Link"
                    />
                    <MuiTextField
                      sx={{ marginLeft: 2.75, width: "90%" }}
                      disabled={values?.themeGuidelines?.guidelineType !== 2 ? true : false}
                      onChange={handleChange}
                      value={values?.themeGuidelines?.guidelineTextValue}
                      name={"themeGuidelines.guidelineTextValue"}
                      onBlur={handleBlur}
                      error={
                        getIn(touched, "themeGuidelines.guidelineTextValue") &&
                        getIn(errors, `themeGuidelines.guidelineTextValue`)
                      }
                      helperText={
                        getIn(touched, `themeGuidelines.guidelineTextValue`)
                          ? getIn(errors, `themeGuidelines.guidelineTextValue`)
                          : ""
                      }
                    />
                  </div>
                </Stack>
              </MuiGroupRadioButton>
            </Box>
            {/* <FormBottomButtons
              tab={tab}
              handleTabNext={handleTabNext}
              handleTabPrev={handleTabPrev}

              isDisabled={!isValid || !dirty}
              loading={values?.themeGuidelines?.guidelineType == 1 ? createProgramTheme?.isLoading || fileUploader?.isLoading : createProgramTheme?.isLoading}
            /> */}
            <FormBottomButtons
              tab={tab}
              loading={values?.themeGuidelines?.guidelineType == 1 ? createProgramTheme?.isLoading || fileUploader?.isLoading : createProgramTheme?.isLoading}
              handleTabNext={handleTabNext}
              handleTabPrev={handleTabPrev}
              // handleSubmit={handleSubmit}
              isDisabled={!isValid || !dirty}
              next={!createProgramTheme?.guideLineData || (isValid && dirty) ? false : true}
            // if "next" is "true" mean it will not make any api call, it simply navigates to next page
            // if "next" is "false" it will make Api calls
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default ThemeGuidelines;
