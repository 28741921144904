import { SagaIterator } from "@redux-saga/types";
import { call, put } from "redux-saga/effects";
import { HttpResponseTypeEnum } from "../../enumerations/HttpResponseTypeEnum";
import { programThemesRedux } from "../../redux/program-themes/ProgramThemesRedux";

export function* fetchProgramThemesSaga(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.fetchProgramThemes, action.payload);    
    if (response.data.code === HttpResponseTypeEnum.Success) {
      yield put(programThemesRedux.actions.programThemesSuccess(response?.data));
    } else {
      yield put(programThemesRedux.actions.programThemesFailure(response.data));
    }
  } catch (error) {
    yield put(programThemesRedux.actions.programThemesFailure(error));
  }
}
