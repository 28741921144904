import {
  Box,
  Chip,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import MediaAssets from "../../../../assets";
import {
  ActionTypeEnumResponseType,
  ActionTypeEnumUtilsTextResponse,
} from "../../../../enumerations/ResponseTypeEnum";
import { StringConstant } from "../../../../resources/strings";
import { RouteConfigurations } from "../../../../routes/RouteConfigurations";
import { questionList } from "./ThemeQuestionStaticData";
import styles from "./ThemeQuestions.module.css";
import ConfirmationDialog from "../../../../components/confirmation-dialog/ConfirmationDialog";
import MuiButton from "../../../../components/ui-elements/mui-button/MuiButton";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteProgramThemeQuestionRequest,
  getProgramThemeQuestionRequest,
  setProgramThemeState,
} from "../../../../redux/program-theme/programtheme-question/ProgramThemQuestionRedux";
import { setToaster } from "../../../../redux/toaster/ToasterRedux";
import { OnboardingQuestionListsStyleWrapper } from "../../../onboarding-question/onboarding-question-list/OnBoardQuestionItem.style";
import PageLoader from "./../../../../components/page-loader/PageLoader";
import FormBottomButtons from "../../../../components/form-bottom-btns/FormBottomButtons";
import { setProgramThemeToasterNull } from "../../../../redux/program-theme/programtheme-question/ProgramThemQuestionRedux";
import { Height } from "@mui/icons-material";
import { groupBy } from "../../../../utils/LambdaUtils";

const ProgramThemeListOfQuestion = (props: any) => {
  const { tab, handleTabPrev, handleTabNext } = props;
  const actionDispatch = (dispatch: any) => ({
    getAllThemeQuestion: (data: any) =>
      dispatch(getProgramThemeQuestionRequest(data)),
    setThemeQuestion: (data: any) => dispatch(setProgramThemeState(data)),
    deleteProgramThemeQuestion: (data: any) =>
      dispatch(deleteProgramThemeQuestionRequest(data)),
    setProgramThemeQuestionNull: () => dispatch(setProgramThemeToasterNull()),

    // deleteOnboardingQuestion: (data: any) => dispatch(deleteOnboardingQuestionRequest(data)),
    setToasterInfo: (data: any) => dispatch(setToaster(data)),
  });
  const {
    getAllThemeQuestion,
    setThemeQuestion,
    deleteProgramThemeQuestion,
    setToasterInfo,
    setProgramThemeQuestionNull,
  } = actionDispatch(useDispatch());
  const navigate = useNavigate();

  const [shouldOpenConfirmatonDialog, setShouldOpenConfirmatonDialog] =
    useState(false);
  const [questionId, setQuestionId] = useState("");
  const { themeQuestion, createProgramTheme, programThemeQuestion } =
    useSelector((state: any) => state);

  useEffect(() => {
    if (programThemeQuestion?.isProgramThemeQuestionDeleted) {
      const reqbody = {
        id: createProgramTheme.programThemeId,
      };
      setShouldOpenConfirmatonDialog(false);
      setToasterInfo({
        key: "toasterDetails",
        value: { ...programThemeQuestion?.toasterDetails, toasterOpen: true },
      });
      setThemeQuestion({ key: "isProgramThemeQuestionDeleted", value: false });
      setProgramThemeQuestionNull();
      getAllThemeQuestion({
        reqbody,
      });
    }
  }, [
    shouldOpenConfirmatonDialog,
    programThemeQuestion.isProgramThemeQuestionDeleted,
  ]);
  useEffect(() => {
    if (createProgramTheme?.programThemeId) {
      const reqbody = {
        id: createProgramTheme?.programThemeId,
      };
      getAllThemeQuestion({
        reqbody,
      });
    }
  }, []);

  const handleNextpage = () => {
    handleTabNext(tab);
  };

  // useEffect(()=>{
  //   if (programThemeQuestion?.toasterDetails) {
  //     const reqbody = {
  //       id: createProgramTheme.programThemeId,
  //     };
  //     setToasterInfo({
  //       key: "toasterDetails",
  //       value: { ...programThemeQuestion?.toasterDetails, toasterOpen: true },
  //     });
  //     setProgramThemeQuestionNull()
  //     setShouldOpenConfirmatonDialog(false)
  //     getAllThemeQuestion({
  //       reqbody,
  //     });
  //   }

  // },[programThemeQuestion.toasterDetails])

  const handleConfirmDelete = () => {
    deleteProgramThemeQuestion({ questionId });
  };
  const navigateToCreateQuestions = (question: any) => {
    navigate(RouteConfigurations.themeQuestionCreation, { state: question });
  };

  const LoadingComponent = () => {
    return <PageLoader />;
  };

  const renderRetry = () => {
    return <div>Try again</div>;
  };

  const renderNoData = () => {
    return (
      <div>
        <div className={styles["create-questions-specific-to-theme-label"]}>
          {StringConstant.themeCreateQuestionsHeader}
        </div>
        <Box className={styles["positioned-create-new-question-button"]}>
          <MuiButton
            variant="contained"
            onClick={() => navigate(RouteConfigurations.themeQuestionCreation)}
          >
            Create New Question
          </MuiButton>
        </Box>
      </div>
    );
  };

  const renderTable = () => {
    // for(let obj of programThemeQuestion?.listsProgramThemeQuestion)
    // {
    //   let topicCatArr:any = [];
    //   if(obj?.responseFormat == 1005)
    //   {
    //     console.log("sdsdsd");
    //     let responseArr = groupBy(obj.programthemequestionsresponses, "questionTopic");
    //     console.log("responseArr", responseArr);
    //     (Object.keys(responseArr) as (keyof typeof obj)[]).forEach((key, index) => {
    //       // 👇️ name Bobby Hadz 0, country Chile 1
    //       console.log(key, responseArr[key], index);
    //       let tempCatObj :any = {};
    //       tempCatObj.topic = key;
    //       tempCatObj.responseOptions = responseArr[key];
    //       topicCatArr.push(tempCatObj);
    //     });
    //     obj["topicCategories"] = topicCatArr;
    //     console.log("sdsdsd");
    //   }
    // }
    return (
      <>
        <ConfirmationDialog
          isConfirmModelOpen={shouldOpenConfirmatonDialog}
          handleClose={() => setShouldOpenConfirmatonDialog(false)}
          handleConfirm={handleConfirmDelete}
          title={"Are you sure you want to delete this question ?"}
          leftButtonText={"No"}
          rightButtonText={"Yes"}
        />
        <Grid
          container
          xs={11.5}
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          mt={"20px"}
        >
          <Grid item className={styles["back-to-question-list-label"]}>
            <Typography className="page-header">
              {StringConstant.themeCreateQuestionsHeader}
            </Typography>
          </Grid>
          <Grid item>
            <MuiButton
              variant="contained"
              onClick={() =>
                navigate(RouteConfigurations.themeQuestionCreation)
              }
            >
              Create New Question
            </MuiButton>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{ height: "calc(100% - 70px)", overflowY: "auto" }}
          gap={"20px"}
          pt={"20px"}
        >
          {programThemeQuestion?.listsProgramThemeQuestion?.map(
            (item: any, index: any) => (
              <Grid item xs={11}>
                <OnboardingQuestionListsStyleWrapper key={item?.id}>
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    pt={2}
                    pl={2}
                    alignItems={"center"}
                  >
                    <Typography className={"question-list-header"}>
                      {`Question ${index + 1}`}
                    </Typography>
                    <Box>
                      <IconButton
                        onClick={(e) => navigateToCreateQuestions(item)}
                      >
                        <img
                          src={MediaAssets.ic_edit}
                          alt={"edit_icon"}
                          className={"question-list-icon"}
                        />
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          setShouldOpenConfirmatonDialog(true);
                          setQuestionId(item?.id);
                        }}
                      >
                        <img
                          src={MediaAssets.ic_delete}
                          alt={"delete_icon"}
                          className={"question-list-icon"}
                        />
                      </IconButton>
                    </Box>
                  </Stack>
                  <Box pt={1} pl={3}>
                    {item?.isVisibleForMentor && (
                      <Stack direction={"row"} gap={1} my={1}>
                        <Typography className={"question-version-style"}>
                          Frientor's version:
                        </Typography>

                        <span className={"question-version-res"}>
                          {item?.mentorQuestion}
                        </span>
                      </Stack>
                    )}
                    {item?.isVisibleForMentee && (
                      <Stack direction={"row"} gap={1} my={1}>
                        <Typography className={"question-version-style"}>
                          Frientee's version:
                        </Typography>
                        <span className={"question-version-res"}>
                          {item?.menteeQuestion}
                        </span>
                      </Stack>
                    )}
                  </Box>
                  {/* <Stack
                    direction={"row"}
                    spacing={2}
                    pl={3}
                    pt={1}
                    pb={1}
                    gap={2}
                    mb={1}
                    sx={{ flexWrap: "wrap" }}
                  >
                    {item?.responseOptions?.length? item?.responseOptions?.map((res: any, index: any) =>
                    (
                      <Chip label={res?.optionName} />
                    )
                    ):null}
                    <div>
                      {item?.topicCategories? item.topicCategories.map((topic: any) => (
                        topic?.responseOptions?.map((res: any) => (
                          <Chip key={res.id} label={res?.optionName
                          } />
                        ))
                      )):null}
                    </div>
                  </Stack> */}
                  <Grid
                    container
                    direction={"row"}
                    spacing={2}
                    p={2}
                    pl={2}
                  >
                    {/* <div>{JSON.stringify(item)}</div> */}
                    {item?.programthemequestionsresponses?.length ? item?.programthemequestionsresponses?.map((res: any, index: any) => 
                      {
                        if(res?.publicUrl?.length > 0)
                        {
                          return <Grid item xs={2}  >
                            <Chip
                              avatar={<img src={res?.publicUrl} />}
                              label={res?.caption ? res?.caption : "-"}
                            />
                          </Grid>
                        }
                        else if(res?.questionTopic?.length > 0)
                        {
                          return <Grid item xs={2}  >
                            <Chip
                              avatar={<img src={res?.publicUrl} />}
                              label={res?.fileName ? res?.fileName : "-"}
                            />
                          </Grid>
                        }
                        else
                        {
                          return <Grid item xs={2}  >
                            <Chip key={res?.id} label={res?.optionName} />
                          </Grid>
                        }
                        
                      }
                    ) : null}
                  </Grid>
                  {item?.topicCategories ? item?.topicCategories?.map((topic: any) => {
                    return (
                      <>
                        <Typography pl={3} className={"question-version-style"}> Topic: {topic?.topic}</Typography>
                        <Grid
                          container
                          direction={"row"}
                          spacing={2}
                          p={2}
                          pl={3}

                        >
                          {topic?.responseOptions?.map((res: any) => (
                            <Grid item xs={1.5}  >
                              <Chip key={res?.id} label={res?.optionName} />
                            </Grid>
                          ))}
                        </Grid>
                      </>
                    )
                  }) : null}
                  {item?.attachments?.length ? <Grid
                    container
                    pl={3}
                    pt={1}
                    pb={1}
                    gap={2}
                    mb={1}
                  >
                    {item?.attachments?.map((imgOption: any) => (
                      <Grid item xs={2}  >
                        <Chip
                          avatar={<img src={imgOption?.publicUrl} />}
                          label={imgOption?.caption ? imgOption?.caption : "-"}
                        />
                      </Grid>
                    ))}
                  </Grid> : null}




                  <hr className="horizontal-border" />
                  <Stack direction={"row"} p={2} gap={1}>
                    <Typography className={"question-response-style"}>
                      Type:
                    </Typography>
                    <Stack
                      sx={{ flexWrap: "wrap" }}
                      direction="row"
                      spacing={1}
                      alignItems={"center"}
                      divider={<Divider orientation="vertical" flexItem />}
                    >
                      <Typography className={"question-response-style"}>
                        {ActionTypeEnumResponseType?.getResponseType(
                          item?.responseType
                        )}
                        -{" "}
                        {ActionTypeEnumUtilsTextResponse?.getEnumText(
                          item?.responseFormat
                        )}
                      </Typography>
                      {item?.isImpactful && (
                        <Typography className={"question-response-style"}>
                          Impactful
                        </Typography>
                      )}
                      {item?.isTopicBased && (
                        <Typography className={"question-response-style"}>
                          Topic Based
                        </Typography>
                      )}
                      {item?.isCustomisable && (
                        <Typography className={"question-response-style"}>
                          Customisable
                        </Typography>
                      )}
                      {item?.isQuestionMandatory && (
                        <Typography className={"question-response-style"}>
                          Mandatory question
                        </Typography>
                      )}
                      {item?.isAnswerMandatory && (
                        <Typography className={"question-response-style"}>
                          Response required
                        </Typography>
                      )}
                      {item?.isVisibleForMentor && item?.isVisibleForMentee ? (
                        <Typography className={"question-response-style"}>
                          Both Frientor & Frientee
                        </Typography>
                      ) : item?.isVisibleForMentor ? (
                        <Typography className={"question-response-style"}>
                          Frientor
                        </Typography>
                      ) : (
                        <Typography className={"question-response-style"}>
                          Frientee
                        </Typography>
                      )}
                    </Stack>
                  </Stack>
                </OnboardingQuestionListsStyleWrapper>
              </Grid>
            )
          )}
        </Grid>
        <FormBottomButtons
          isDisabled={false}
          tab={tab}
          handleClick={handleNextpage}
          handleTabPrev={handleTabPrev}
        />
      </>
    );
  };

  const render = useMemo(() => {
    if (programThemeQuestion?.listsProgramThemeQuestion?.length > 0) {
      return renderTable();
    } else if (programThemeQuestion?.isLoading) {
      return LoadingComponent();
    } else if (!programThemeQuestion?.listsProgramThemeQuestion?.length) {
      return renderNoData();
    } else {
      return renderRetry();
    }
  }, [
    programThemeQuestion.error,
    programThemeQuestion.isLoading,
    programThemeQuestion?.listsProgramThemeQuestion,
    shouldOpenConfirmatonDialog,
    programThemeQuestion?.isProgramThemeQuestionDeleted,
  ]);

  return (
    <Box sx={{ height: "100%" }} ml={"30px"}>
      {render}
    </Box>
  );
};

export default ProgramThemeListOfQuestion;
