import { Fragment, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { StringConstant } from "../../../resources/strings";
import styles from "./CreateEntityForm.module.css";
import EntityTheme from "../entity-themes/EntityTheme";
import FormBottomButtons from "../../../components/form-bottom-btns/FormBottomButtons";
import { useDispatch, useSelector } from "react-redux";
import {
  contactDetailsRedux,
  fetchContactDetailsRequest,
} from "../../../redux/entity-management/ContactDetailsRedux";
import { basicInfoRedux } from "../../../redux/entity-management/BasicInfoRedux";
import {
  fetchProgramAdminRequest,
  programAdminRedux,
} from "../../../redux/entity-management/ProgramAdminRedux";
import ErrorAlert from "../../../components/ui-elements/error-alert/ErrorAlert";
import {
  makeEntityAsOnboardedRequest,
  makeToasterNull,
  onBoardedEntitiesRedux,
  setOnBoardedEntitiesState,
} from "../../../redux/entity-management/OnBoardedEntitiesRedux";
import { useNavigate } from "react-router-dom";
import { RouteConfigurations } from "../../../routes/RouteConfigurations";
import { setToaster } from "../../../redux/toaster/ToasterRedux";

interface Props {
  tab: number;
  handleTabPrev: (value: number) => void;
  handleTabNext?: (value: number) => void;
}
const ManageEntittyThemes = (props: Props) => {
  const { tab, handleTabPrev } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isEntityHasThemes, setIsEntityHasThemes] = useState<boolean>(false);

  const { basicInfo, onBoardedEntities, contactDetails, programAdmin, associatedThemeEntities } =
    useSelector((state: any) => state);

  const [entityId, setEntityId] = useState<any>(() => {
    dispatch(onBoardedEntitiesRedux.actions.reset());
    return basicInfo?.data?.id;
  });

  const contactDetailsOfEntity = contactDetails?.data;
  const programAdminsOfEntity = {
    programAdmin:
      programAdmin?.data && programAdmin?.data.length !== 0
        ? programAdmin?.data
        : null,
  };
  const actionDispatch = (dispatch: any) => ({
    setToasterNull: () => dispatch(makeToasterNull()),
    setToasterInfo: (data: any) => dispatch(setToaster(data)),
    getContactDetails: (entityId: any) =>
      dispatch(fetchContactDetailsRequest({ entityId })),
    getProgramAdmins: (entityId: any) =>
      dispatch(fetchProgramAdminRequest({ entityId })),
    setOnBoardedEntitiesReduxState: (data: any) =>
      dispatch(setOnBoardedEntitiesState(data)),
  });

  const {
    setToasterNull,
    setToasterInfo,
    getContactDetails,
    getProgramAdmins,
    setOnBoardedEntitiesReduxState,
  } = actionDispatch(useDispatch());

  useEffect(() => {
    if (entityId) {
      getContactDetails(entityId);
      getProgramAdmins(entityId);
    }
  }, []);

  useEffect(() => {
    if (onBoardedEntities?.toasterDetails) {
      setToasterInfo({
        key: "toasterDetails",
        value: { ...onBoardedEntities?.toasterDetails, toasterOpen: true },
      });
    }
  }, [onBoardedEntities?.toasterDetails]);

  useEffect(() => {
    return () => setToasterNull();
  }, []);

  const handleSubmit = () => {
    const reqObject = {
      isEntityOnboardingCompleted: true,
      entityId,
    };
    dispatch(makeEntityAsOnboardedRequest(reqObject));
  };

  useEffect(() => {
    if (onBoardedEntities?.success) {
      dispatch(basicInfoRedux.actions.reset());
      dispatch(contactDetailsRedux.actions.reset());
      dispatch(programAdminRedux.actions.reset());
      navigate(RouteConfigurations.entityManagement);

      setOnBoardedEntitiesReduxState({ key: "success", value: false });
    }
  }, [onBoardedEntities?.success]);

  let contactDetailshasAdded = false;
  if (contactDetailsOfEntity) {
    contactDetailshasAdded = contactDetailsOfEntity.length > 0 && true;
  }

  let errorArray = [];

  !entityId && errorArray.push(StringConstant.pleaseCompleteProgramInfo);
  !contactDetailshasAdded &&
    errorArray.push(StringConstant.pleaseFillContactDetails);
  !programAdminsOfEntity?.programAdmin &&
    errorArray.push(StringConstant.addAtleastOneProgramAdmin);

  return (
    <Fragment>
      {errorArray.length > 0 && !associatedThemeEntities?.isLoading ? <ErrorAlert errorArray={errorArray} />: null}
      <p className={styles["entity-form-sub-heading"]}>
        {StringConstant.programThemesEnabledForEntity}
      </p>
      <Box px={3} className={styles["inner-content-height-program-admin"]}>
        <EntityTheme
          entityId={entityId}
          setIsEntityHasThemes={setIsEntityHasThemes}
        />
        <FormBottomButtons
          tab={tab}
          handleClick={handleSubmit}
          handleTabPrev={handleTabPrev}
          onBoarded={basicInfo?.data?.isEntityOnboardingCompleted}
          isDisabled={
            !entityId ||
            !contactDetailshasAdded ||
            !programAdminsOfEntity?.programAdmin ||
            !isEntityHasThemes
          }
          next={false}
          // if "next" is "true" mean it will not make any api call, it simply navigates to next page
          // if "next" is "false" it will make Api calls
        />
      </Box>
    </Fragment>
  );
};

export default ManageEntittyThemes;
