import { createSlice } from "@reduxjs/toolkit";

interface IAssociatedThemeEntities {
  isLoading?: boolean;
  error?: string;
  success?: string;
  data?: any;
  toasterDetails?: any;
  mappingDone?: boolean;
}

const initialState: IAssociatedThemeEntities = {
  isLoading: false,
  error: "",
  success: "",
  data: null,
  toasterDetails: null,
  mappingDone: false,
};

export const associatedThemeEntitiesRedux = createSlice({
  name: "associatedThemeEntities",
  initialState,
  reducers: {
    associateThemeEntitiesRequest(state, action) {
      return { ...state, isLoading: true };
    },
    associateThemeEntitiesSuccess(state, action) {
      return {
        ...state,
        data: action?.payload?.data?.response,
        isLoading: false,
        toasterDetails: action?.payload,
        mappingDone: action?.payload?.mapping,
      };
    },
    associateThemeEntitiesFailure(state, action) {
      return {
        ...state,
        isLoading: false,
        error: action?.payload?.response,
        toasterDetails: action?.payload,
      };
    },
    fetchAssociatedThemeEntitiesRequest(state, payload) {
      return { ...state, isLoading: true };
    },
    makeToasterNull(state) {
      return {
        ...state,
        toasterDetails: null,
      };
    },
    reset(state) {
      return {
        ...state,
        data: null,
        error: "",
        success: "",
      };
    },
  },
});

const { reducer, actions } = associatedThemeEntitiesRedux;
export const {
  associateThemeEntitiesRequest,
  associateThemeEntitiesSuccess,
  associateThemeEntitiesFailure,
  fetchAssociatedThemeEntitiesRequest,
  makeToasterNull,
  reset,
} = actions;
export default reducer;
