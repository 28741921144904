import apisauce from "apisauce";
import { Constants } from "../constants/Constants";
import { HttpResponseTypeEnum } from "../enumerations/HttpResponseTypeEnum";
import { RouteConfigurations } from "../routes/RouteConfigurations";
import { getItemFromLocalStorage, setItemToLocalStorage } from "../utils/LocalStorageUtils";
import { IApiServices } from "./ApiServicesInterface";

const create = (
  baseURL = process.env.REACT_APP_USER_MGNT_URL,
  baseURLAdmin = process.env.REACT_APP_SUPER_ADMIN_URL
): IApiServices => {
  const token = () => localStorage.getItem("token");
  const api = apisauce.create({
    baseURL: baseURL,
    headers: {
      "Cache-Control": "no-cache",
      "Content-Type": "application/json",
      Authorization: getItemFromLocalStorage(Constants.authorizationToken),
    },
    timeout: 10000,
  });


  const superAdminApi = apisauce.create({
    baseURL: baseURLAdmin,
    headers: {
      "Cache-Control": "no-cache",
      "Content-Type": "application/json",
      Authorization: getItemFromLocalStorage(Constants.authorizationToken),
    },
    timeout: 10000,
  });


  api.addMonitor((response: any) => {
    if (
      response.status === HttpResponseTypeEnum.Unauthorized ||
      response.status === HttpResponseTypeEnum.Forbidden
    ) {
      localStorage.clear();
      window.location.href = RouteConfigurations.login;
    }
  });

  const setAuthorization = () => {
    const token = getItemFromLocalStorage(Constants.authorizationToken);
    api.headers["Authorization"] = token;
  };

  //Authentication APIs4p
  const login = async () => {
    return api.get("/user-management/api/authorize-database");
  };
  const onBoardingQuestion = async (data: any) => {
    setAuthorization();
    return api.post("admin/api/question", data);
  };

  // Entity Management APIs
  const createEntity = async (data: any) => {
    setAuthorization();
    const response = api.post(`/admin/api/entity/add`, data);
    return response;
  };

  const updateEntity = async (data: any) => {
    setAuthorization();
    const response = api.put(`/admin/api/entity/update`, data);
    return response;
  };

  const addContactDetails = async (data: any) => {
    setAuthorization();
    const response = api.post("/admin/api/entity/contacts", data);
    return response;
  };

  const updateContactDetails = async (data: any) => {
    setAuthorization();
    const response = api.put("/admin/api/entity/contacts", data);
    return response;
  };

  const addProgramAdmins = async (data: any) => {
    setAuthorization();
    const response = api.post("/program-admin/api/invite", data);
    return response;
  };

  const fetchEntityTypes = async () => {
    setAuthorization();
    const response = api.get("/admin/api/entity-types");
    return response;
  };

  const fetchOnboardedEntities = async (data: any) => {
    setAuthorization();
    const response = api.get(
      `/admin/api/entities?size=${data?.size}&index=${data?.index}`
    );
    return response;
  };

  const patchEntityOnboarded = async (data: any) => {
    setAuthorization();
    const response = api.patch("/admin/api/entity/onboarding", data);
    return response;
  };
  const fetchContactDetails = async (data: any) => {
    setAuthorization();
    const response = api.get(`/admin/api/entity/contacts/${data.entityId}`);
    return response;
  };

  const fetchProgramAdmins = async (data: any) => {
    setAuthorization();
    const response = api.get(
      `/admin/api/entities?entityId=${data.entityId}`
    );
    return response;
  };

  const deleteProgramAdmins = async (data: any) => {
    setAuthorization();
    const response = api.delete(`/program-admin/api/user`, {}, { data });
    return response;
  };

  const getAllUsersByRoleId = async (params: any) => {
    setAuthorization();
    return api.get(`/user-management/api/users`);
  };

  const addInviteSuperAdmin = async (reqBody: any) => {

    setAuthorization()
    return api.post(`/user-management/api/invite`, reqBody);
  };

  const updateMyProfile = async (reqBody: any) => {
    setAuthorization();
    return api.patch(`/program-admin/api/update-profile?entityId=${reqBody.entityId}`, reqBody.data);
  };

  // onboarding question
  const addOnBoardingQuestion = async (data: any) => {
    setAuthorization();

    return api.post(`admin/api/question`, data);
  };
  const getAllOnBoardingQuestions = async () => {
    setAuthorization();

    return api.get("admin/api/question");
  };
  const deleteOnBoardingQuestions = async (data: any) => {
    setAuthorization();

    return api.delete(`admin/api/question/${data}`);
  };
  const updateOnBoardingQuestions = async (data: any) => {
    setAuthorization();

    return api.put(`admin/api/question`, data);
  };

  const getEachOnBoardingQuestions = async (data: any) => {
    setAuthorization();
    return api.get(`/admin/api/question?questionId=${data}`)
  }

  //create program theme
  const addCreateProgramTheme = async (data: any) => {
    setAuthorization();
    return api.post("/admin/api/program-theme", data);
  };

  const updateCreateProgramTheme = async (data: any) => {
    setAuthorization();

    return api.put("/admin/api/program-theme", data);
  };
  const submitProgramTheme = async (data: any) => {
    setAuthorization();

    return api.put("/admin/api/program-theme", data);
  };

  const addProgramThemeQuestion = async (data: any) => {
    setAuthorization();

    return api.post(`/admin/api/question`, data);
  };
  const getAllProgramThemeQuestions = async (data: any) => {
    setAuthorization();
    return api.get(`/admin/api/question?programThemeId=${data.reqbody.id}`);
  };
  const deleteProgramThemeQuestions = async (data: any) => {
    setAuthorization();
    return api.delete(`/admin/api/question/${data?.questionId}`);
  };

  const updateProgramThemeQuestions = async (data: any) => {
    setAuthorization();
    return api.put(`/admin/api/question`, data);
  };

  // programtheme guidelines

  const addProgramThemeGuideline = async (data: any) => {
    setAuthorization();
    return api.post(`/admin/api/attachment`, data);
  };
  const getProgramThemeGuidelines = async (data: any) => {
    setAuthorization();
    return api.get(`/admin/api/attachment?programThemeId=${data.reqbody.id}`);
  };
  const deleteProgramThemeGuidelines = async (data: any) => {
    setAuthorization();
    return api.delete(`/admin/api/attachment/${data}`);
  };

  const updateProgramThemeGuidelines = async (data: any) => {
    setAuthorization();
    return api.put(`/admin/api/attachment`, data);
  };

  //get all program theme
  const getAllProgramTheme = async () => {

    setAuthorization();
    return superAdminApi.get(`/admin/api/program-themes`);
  };

  const getEachProgramTheme = async (id: any) => {
    setAuthorization();
    return api.get(`/admin/api/program-theme/${id}`);
  };

  //topic category
  const getAllTopicCategory = async (data: any) => {
    setAuthorization();
    return api.get(`/admin/api/topic-categories?programThemeId=${data}`);
  };

  //file upload to s3
  const getSignedUrlFileUploadToS3 = (data: any) => {
    setAuthorization();
    const response = api.get(
      `/user-management/api/presignedUrl?type=${data.urlType
      }&key=${encodeURIComponent(data.name)}`
    );
    return response;
  };

  const uploadeFileToS3 = async (data: any) => {
    const res = await fetch(data.url, {
      method: "PUT",
      headers: {
        "Cache-Control": "no-cache",
        "Content-Type": data.contentType,
      },
      body: data.blob,
    });

    return res;
  };

  //single file update
  const getUpdatedPresignedUrl = (data: any) => {
    setAuthorization();
    const res = api.put(
      `/user-management/api/s3/update?deleteDocKey=${data.oldFileName
      }&uploadDocKey=${encodeURIComponent(data.name)}`
    );
    return res;
  };
  // multi file update
  const getUpdateSignedMultiFileUpload = (data: any) => {
    setAuthorization();
    const promise: any = [];

    try {
      for (let item of data) {
        let url = api.put(
          `/user-management/api/s3/update?deleteDocKey=${item?.oldFileName
          }&uploadDocKey=${encodeURIComponent(item?.name)}`
        );
        promise.push(url);
      }
    } catch (error) {
      console.log(error);
    }

    const response = Promise.allSettled(promise);
    return response;
  };

  const multipleUpdateUrlInS3 = async (data: any) => {
    const promise = [];
    for (let item of data) {
      let api = await fetch(item.url, {
        method: "PUT",
        headers: {
          "Cache-Control": "no-cache",
          "Content-Type": item.contentType,
        },
        body: item.blob,
      });
      promise.push(api);
    }
    const response = Promise.allSettled(promise)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
    return response;
  };

  const updateAttchment = (data: any) => {
    return api.put(`/admin/api/attachment`, data);
  };

  const addAttachmentQuestion = (data: any) => {
    setAuthorization();
    return api.post(`/admin/api/attachment`, data);
  };
  const multipleUpdateAttachment = async (data: any, payload: any) => {
    const requestBody = [];

    for (let i = 0; i < data.length; i++) {
      let update = {
        attachmentId: payload[i]?.attachmentId,
        attachmentType: payload[i]?.attachmentType,
        contentType: payload[i].contentType,
        publicUrl: data[i]?.value?.url.split("?")[0],
        fileName: payload[i]?.name,
        questionId: payload[i]?.questionId,
        caption: payload[i]?.caption
      };

      requestBody.push(update);
    }
    return api.put(`/admin/api/attachment`, requestBody);
  };

  const multiDeleteAttachment = async (data: any) => {
    setAuthorization();
    return api.delete(`/admin/api/attachment`, {}, { data });
  };

  const updateFileS3 = async (data: any) => {
    setAuthorization();
    const res = await fetch(data.url, {
      method: "PUT",
      headers: {
        "Cache-Control": "no-cache",
        "Content-Type": data.contentType,
      },
      body: data.blob,
    });
    return res;
  };

  const getSignedURLmultipleFileUploadToS3 = (data: any) => {
    setAuthorization();
    const promise = [];
    try {
      for (const item of data) {
        let url = api.get(
          `/user-management/api/presignedUrl?type=${item.urlType
          }&key=${encodeURIComponent(item.name)}`
        );
        promise.push(url);
      }
    } catch (error) {
      console.log(error);
    }

    const response = Promise.allSettled(promise)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
    return response;
  };

  //single file update

  const multipleFileUpload = async (data: any) => {
    let promises = [];
    for (let item of data) {
      let api = await fetch(item.url, {
        method: "PUT",
        headers: {
          "Cache-Control": "no-cache",
          "Content-Type": item.contentType,
        },
        body: item.blob,
      });
      promises.push(api);
    }

    const responses = Promise.allSettled(promises)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });

    return responses;
  };

  const fetchProgramThemes = async () => {
    setAuthorization()
    const response = api.get(`/admin/api/program-themes`);
    return response;
  };

  const associateThemesAndEntities = async (data: any) => {
    setAuthorization()
    const response = api.post(
      `/admin/api/entity/programTheme/association`,
      data
    );
    return response;
  };

  const fetchAssociatedProgramThemesAndEntites = async (data: any) => {
    setAuthorization()
    const response = api.get(
      `/admin/api/entity/programTheme/association?entityId=${data}`
    );
    return response;
  };
  const getUserDetails = async (email: any) => {
    console.log("getUserDetails*************");
    const response: any = await api.get(`/user-management/api/user?email=${email}`);
    setItemToLocalStorage(Constants.loggedInUser, response?.data);
    setItemToLocalStorage(Constants.authorizationToken, response?.data?.loginToken);

    return response
  };

  const getProfileInfo = async () => {
    setAuthorization();
    return api.get(`/user-management/api/profile`)
  }

  const deleteAttachmentById = async (data: any) => {
    setAuthorization();
    return api.delete(`/user-management/api/attachment`, {}, { data })
  }

  return {
    login,
    onBoardingQuestion,
    getAllUsersByRoleId,
    addInviteSuperAdmin,
    updateMyProfile,
    createEntity,
    updateEntity,
    fetchEntityTypes,
    addContactDetails,
    updateContactDetails,
    addProgramAdmins,
    fetchOnboardedEntities,
    patchEntityOnboarded,
    fetchContactDetails,
    fetchProgramAdmins,
    deleteProgramAdmins,
    fetchProgramThemes,
    fetchAssociatedProgramThemesAndEntites,
    associateThemesAndEntities,
    getSignedUrlFileUploadToS3,
    uploadeFileToS3,
    getUpdatedPresignedUrl,
    getSignedURLmultipleFileUploadToS3,
    multipleFileUpload,
    getUserDetails,
    addOnBoardingQuestion,
    deleteOnBoardingQuestions,
    getAllOnBoardingQuestions,
    updateOnBoardingQuestions,
    addCreateProgramTheme,
    updateProgramThemeQuestions,
    deleteProgramThemeQuestions,
    getAllProgramThemeQuestions,
    addProgramThemeQuestion,
    getProgramThemeGuidelines,
    updateProgramThemeGuidelines,
    deleteProgramThemeGuidelines,
    addProgramThemeGuideline,
    getAllProgramTheme,
    getEachProgramTheme,
    updateCreateProgramTheme,
    getAllTopicCategory,
    submitProgramTheme,
    updateFileS3,
    updateAttchment,
    getUpdateSignedMultiFileUpload,
    multipleUpdateUrlInS3,
    multipleUpdateAttachment,
    addAttachmentQuestion,
    multiDeleteAttachment,
    getProfileInfo,
    deleteAttachmentById,
    getEachOnBoardingQuestions
  };
};

export default {
  create,
};
