import { Box } from "@mui/material";
import { Form, Formik, FieldArray, getIn } from "formik";
import {
  programAdminSchema,
  ProgramAdminInitialState,
} from "../EntityManagementInitialState";
import { StringConstant } from "../../../resources/strings";
import styles from "./CreateEntityForm.module.css";
import { handlePhoneNumberFieldChange } from "../../../utils/ValidationUtils";
import MuiButton from "../../../components/ui-elements/mui-button/MuiButton";
import FormBottomButtons from "../../../components/form-bottom-btns/FormBottomButtons";
import { useEffect, useState } from "react";
import {
  setProgramAdmin,
  addProgramAdminRequest,
  fetchProgramAdminRequest,
  makeToasterNull,
} from "../../../redux/entity-management/ProgramAdminRedux";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
// import { setToaster } from "../../../redux/toaster/ToasterRedux";
import ProgramAdminRows from "./ProgramAdminRows";
import ErrorAlert from "../../../components/ui-elements/error-alert/ErrorAlert";
import { fetchContactDetailsRequest } from "../../../redux/entity-management/ContactDetailsRedux";
import MuiDialog from "../../../components/mui-dialog/MuiDialog";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import PageLoader from "../../../components/page-loader/PageLoader";
import AlertCard from "../../../components/alert/AlertCard";
interface Props {
  tab: number;
  handleTabPrev: (value: number) => void;
  handleTabNext: (value: number) => void;
}

const AddProgramAdminForm = (props: Props) => {
  const { tab, handleTabPrev, handleTabNext } = props;
  const location = useLocation();

  const [openAlert, setOpenAlert] = useState<boolean>(false);

  const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false);
  const [hasChildUpdated, setHasChildUpdated] = useState<boolean>(false);

  const [isAdminProgramAdd, setIsAdminProgramAdd] = useState<boolean>(false)

  const [isInvitaionSuccess, setIsInvitaionSuccess] = useState(false);
  const [isInvitaionFailed, setIsInvitaionFailed] = useState(false);

  const { basicInfo, contactDetails, programAdmin } = useSelector(
    (state: any) => state
  );
  
  const basicInfoOfEntity = basicInfo?.data;
  const contactDetailsOfEntity = contactDetails?.data;

  const programAdminsOfEntity = {
    programAdmin:
      programAdmin?.data && programAdmin?.data.length !== 0
        ? programAdmin?.data
        : null,
  };

  const [programAdminCounts, setProgramAdminCounts] = useState<number>(
    programAdminsOfEntity?.programAdmin
      ? programAdminsOfEntity?.programAdmin?.length
      : 1
  );

  let entityId = location?.state?.row?.id;

  const actionDispatch = (dispatch: any) => ({
    // setToasterNull: () => dispatch(makeToasterNull()),
    // setToasterInfo: (data: any) => dispatch(setToaster(data)),
    getContactDetails: (entityId: any) =>
      dispatch(fetchContactDetailsRequest({ entityId })),
    getProgramAdmins: (entityId: any) =>
      dispatch(fetchProgramAdminRequest({ entityId })),
    postProgramAdmins: (requestObject: any) =>
      dispatch(addProgramAdminRequest(requestObject)),
    setProgramAdminState: (data: any) => dispatch(setProgramAdmin(data)),
  });

  const {
    // setToasterNull,
    // setToasterInfo,
    getContactDetails,
    getProgramAdmins,
    postProgramAdmins,
    setProgramAdminState,
  } = actionDispatch(useDispatch());

  useEffect(() => {
    if (entityId) {
      getContactDetails(entityId);
      getProgramAdmins(entityId);
    }
  }, []);

  useEffect(() => {
    if (programAdmin?.isSuccess) {
      setIsInvitaionSuccess(true);
      setProgramAdminState({ key: "isSuccess", value: false });
    }

    if (programAdmin?.isError) {
      setIsInvitaionFailed(true);
      setProgramAdminState({ key: "isError", value: false });
    }
  }, [programAdmin?.isSuccess, programAdmin?.isError]);

  useEffect(() => {
    // fetching data once child-rows either updated or deleted
    if (hasChildUpdated && programAdmin?.isModified) {
      getProgramAdmins(entityId);
      setHasChildUpdated(false);
    }
  }, [programAdmin?.isModified]);

  useEffect(() => {
    if (isFormSubmitted) {
      programAdminsOfEntity.programAdmin
        ? handleTabNext(tab)
        : setIsFormSubmitted(false);
    }
  }, [isFormSubmitted]);

  if (basicInfoOfEntity) {
    entityId = basicInfoOfEntity.id;
  }
  const filterNewProgramAdmin = (id: any, savedProgramAdmins: any) => {
    return !savedProgramAdmins.some((programAdmin: any) => {
      return programAdmin.id === id;
    });
  };

  useEffect(() => {
    if (programAdmin?.isProgramAdminDeleted) {
      setProgramAdminCounts((value) => (value === 1 ? value : value - 1));
      setProgramAdminState({ key: "isProgramAdminDeleted", value: false });
    }
  }, [programAdmin?.isProgramAdminDeleted]);

  const handleSubmit = (programAdminFromSubmit: any) => {
    let requestObject = programAdminFromSubmit.programAdmin.map(
      (element: any) => {
        let object = { ...element };
        object.entityId = entityId;
        object.roleId = 1001;
        return object;
      }
    );
    if (programAdminsOfEntity?.programAdmin) {
      // filtering new values
      requestObject = requestObject.filter((programAdmin: any) => {
        return filterNewProgramAdmin(
          programAdmin.id,
          programAdminsOfEntity?.programAdmin
        );
      });
    }
    postProgramAdmins(requestObject);
    // setIsFormSubmitted(true);
  };
  let contactDetailshasAdded = false;
  if (contactDetailsOfEntity) {
    contactDetailshasAdded = contactDetailsOfEntity.length > 0 && true;
  }

  let errorArray = [];

  !entityId && errorArray.push(StringConstant.pleaseCompleteProgramInfo);
  !contactDetailshasAdded &&
    errorArray.push(StringConstant.pleaseFillContactDetails);

  const onCloseOfSuccessDialog = () => {
    programAdmin.data.every((element: any) => element.isSuccess === true)
      ? setIsFormSubmitted(true)
      : getProgramAdmins(entityId);
    setIsInvitaionSuccess(false);
    setIsInvitaionFailed(false);
  };

  const formikInitialState = () => {
    let formikInitialarray =
      programAdminsOfEntity?.programAdmin &&
        programAdminsOfEntity?.programAdmin.length > 0
        ? programAdminsOfEntity?.programAdmin
        : ProgramAdminInitialState?.programAdmin;

    let newFormikInitialArray = formikInitialarray.map((element: any) => {
      return {
        ...element,
        domain: basicInfoOfEntity?.domain ? basicInfoOfEntity?.domain : "",
      };
    });

    return { programAdmin: newFormikInitialArray };
  };

  return (
    <>
      {openAlert && (
        <AlertCard
          setOpenAlert={setOpenAlert}
          alertText={"Program admin limit exceeds"}
        />
      )}
      {programAdmin.isLoading ? (
        <div className={"loading-container"}>
          <PageLoader />
        </div>
      ) : null}
      {errorArray.length > 0 && !programAdmin?.isLoading ? <ErrorAlert errorArray={errorArray} /> : null}
      <Formik
        initialValues={formikInitialState()}
        validationSchema={programAdminSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          isValid,
          dirty,
          setFieldValue,
        }) => {
          return (
            <Form style={{ height: "calc(100% - 10px)" }}>
              <p className={styles["entity-form-sub-heading"]}>
                {StringConstant.addProgramAdmins}
              </p>
              <FieldArray name="programAdmin">
                {({ insert, remove, push }) => (
                  <div className={styles["inner-content-height-program-admin"]}>
                    {values?.programAdmin?.length > 0 &&
                      values?.programAdmin?.map((admin: any, index: any) => (
                        <ProgramAdminRows
                          key={index}
                          index={index}
                          values={values}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          getIn={getIn}
                          touched={touched}
                          errors={errors}
                          basicInfoOfEntity={basicInfoOfEntity}
                          handlePhoneNumberFieldChange={
                            handlePhoneNumberFieldChange
                          }
                          remove={remove}
                          entityId={entityId}
                          setProgramAdminCounts={setProgramAdminCounts}
                          programAdminsOfEntity={programAdminsOfEntity}
                          setFieldValue={setFieldValue}
                          setHasChildUpdated={setHasChildUpdated}
                          isDisabled={
                            programAdminsOfEntity?.programAdmin ? true : false
                          }
                        />
                      ))}
                    <Box mt={4}>
                      <MuiButton
                        variant="contained"
                        className={styles["entity-form-btn"]}
                        onClick={() => {
                          if (
                            programAdminCounts >= basicInfo?.data?.maximumAdmins
                          ) {
                            setOpenAlert(true);
                          } else {
                            setProgramAdminCounts((value) => value + 1);
                            push({
                              firstName: "",
                              lastName: "",
                              email: "",
                              phoneNumber: "",
                              domain: basicInfoOfEntity?.domain,
                              countryCode: "+91",
                              id: "",
                            });
                          }
                        }}
                      >
                        {StringConstant.addAnother}
                      </MuiButton>
                    </Box>
                  </div>
                )}
              </FieldArray>
              <FormBottomButtons
                tab={tab}
                loading={basicInfo.isLoading ? true : false}
                handleTabNext={handleTabNext}
                handleTabPrev={handleTabPrev}
                // handleSubmit={handleSubmit}
                isDisabled={
                  !entityId || !contactDetailshasAdded || !isValid || !dirty
                }
                next={
                  programAdminsOfEntity?.programAdmin?.length === 0 || (isValid && dirty)
                    ? false
                    : true
                }
              // if "next" is "true" mean it will not make any api call, it simply navigates to next page
              // if "next" is "false" it will make Api calls
              />

            </Form>
          );
        }}
      </Formik>
      <MuiDialog
        isDialogOpen={isInvitaionSuccess}
        handleClose={onCloseOfSuccessDialog}
        dialogTitle={<div> </div>}
      >
        <>
          <div
            style={{
              fontSize: "2rem",
              fontFamily: "Urbanist-Bold",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              color: "black",
              width: "500px",
              marginBottom: "40px",
            }}
          >
            {programAdmin?.data?.map((element: any, elementIndex: any) => {
              return (
                <div key={elementIndex}>
                  {element?.isSuccess ? (
                    <div
                      style={{
                        alignItems: "center",
                        flexDirection: "row",
                        display: "flex",
                      }}
                    >
                      <CheckCircleIcon
                        style={{ color: "#27bc48", marginRight: "20px" }}
                      />
                      <div> {element?.value?.message}</div>
                    </div>
                  ) : (
                    <div
                      style={{
                        alignItems: "center",
                        flexDirection: "row",
                        display: "flex",
                      }}
                    >
                      <ErrorOutlineIcon
                        style={{ color: "red", marginRight: "20px" }}
                      />
                      <div> {element?.value}</div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <MuiButton variant="contained" onClick={onCloseOfSuccessDialog}>
              {StringConstant.ok}
            </MuiButton>
          </div>
        </>
      </MuiDialog>
      <MuiDialog
        isDialogOpen={isInvitaionFailed}
        handleClose={onCloseOfSuccessDialog}
        dialogTitle={<div> </div>}
      >
        <>
          <div
            style={{
              fontSize: "2rem",
              fontFamily: "Urbanist-Bold",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              color: "black",
              width: "500px",
              marginBottom: "40px",
            }}
          >
            {programAdmin?.inviteAdminErrorArray?.map(
              (item: any, index: any) => {
                return (
                  <div
                    key={index}
                    style={{
                      alignItems: "center",
                      flexDirection: "row",
                      display: "flex",
                    }}
                  >
                    {
                      <ErrorOutlineIcon
                        style={{ color: "red", marginRight: "10px" }}
                      />
                    }
                    <div> {item?.value}</div>
                  </div>
                );
              }
            )}
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <MuiButton variant="contained" onClick={onCloseOfSuccessDialog}>
              {StringConstant.ok}
            </MuiButton>
          </div>
        </>
      </MuiDialog>
    </>
  );
};

export default AddProgramAdminForm;
