import * as yup from "yup";
import { isValidURL } from "../../utils/ValidationUtils";

interface InitialProgramThemeState {
  themeBasicInfo: {
    themeName: string;
    themeDescription: string;
    themeBannerImage: string;
    themeBannerFileName:string
    themeBannerImageId:string;
  }
}
interface InitialProgramTheme {
  name: string,
  description: string,
}

export const InitialProgramThemeState: InitialProgramThemeState = {
  themeBasicInfo: {
    themeName: "",
    themeDescription: "",
    themeBannerImage:"" ,
    themeBannerFileName:"",
    themeBannerImageId:"",
  },
};

export const ProgramThemeValidation = yup.object().shape({
  themeBasicInfo: yup.object().shape({
    themeName: yup.string().required("Please enter theme name"),
    themeDescription: yup.string().required("Please enter theme description"),
    themeBannerImage: yup.string().required("Please upload theme banner"),
  }),
 
});

//programtheme guideline

interface IProgramGuidelinesState {
  themeGuidelines: {
    guidelineType: any,
    guideLineFileId:any
    guidelineFileValue: string,
    guidelineTextValue: string,
    guideLineFileName:string
  },
}


export const InitialProgramGuideline: IProgramGuidelinesState = {
  themeGuidelines: {
    guidelineType: 1,
    guidelineFileValue: "",
    guideLineFileId:"",
    guideLineFileName:"",
    guidelineTextValue: "",
  },
};

export const ThemeGuidelineValidation = yup.object().shape({
  themeGuidelines: yup.object().shape({
    guidelineType: yup
      .number()
      .required("please Upload File or Link the resource"),
    guideLineFileName: yup.string().when("guidelineType",{
      is:1,
      then:yup.string().required("Please Upload Image")
    }),
    guidelineTextValue: yup.string().when("guidelineType", {
      is: 2,
      then: yup
        .string()
        .required("Please paste guideline link")
        .test("test_url", "Invalid URL", (value) => isValidURL(value)),
    }),
  }),
});




interface Column {
  id:
  | "ThemeName"
  | "QuestionsAdded"
  | "CreatedBy"
  | "Createdon"
  | "Status"
  | "";
  label: string | number;
  minWidth?: number;
  align?: "center" | "left" | "right";
  format?: (value: number) => string;
}

export const programThemesColumns: readonly any[] = [
  {
    id: "ThemeName",
    label: " Theme Name",
    minWidth: 180,
    align: "left",
    format: (value: number) => value.toFixed(2),
  },

  {
    id: "QuestionsAdded",
    label: "Questions Added",
    minWidth: 180,
    align: "left",
    format: (value: number) => value.toFixed(2),
  },
  {
    id: "CreatedBy",
    label: "Created By",
    minWidth: 170,
    align: "left",
    format: (value: number) => value.toLocaleString("en-US"),
  },
  {
    id: "Createdon",
    label: "Created on",
    minWidth: 170,
    align: "left",
    format: (value: number) => value.toFixed(2),
  },
  {
    id: "Status",
    label: "Status",
    minWidth: 170,
    align: "left",
    format: (value: number) => value.toFixed(2),
  },
  {
    id: "",
    label: "",
    minWidth: 80,
    align: "left",
    format: (value: number) => value.toFixed(2),
  },
];

export const programThemesRows = [
  {
    themeName: "DEI",
    questionsAdded: 1,
    createdBy: "Arun",
    createdOn: "09-11-2022",
    status: "pending",
  },
  {
    themeName: "Classic",
    questionsAdded: 23,
    createdBy: "Arun",
    createdOn: "09-11-2022",
    status: "Published",
  },
  {
    ID: "F#003",
    themeName: "IT",
    questionsAdded: 12,
    createdBy: "Arun",
    createdOn: "09-11-2022",
    status: "Published",
  },
  {
    themeName: "US",
    questionsAdded: 12,
    createdBy: "Arun",
    createdOn: "09-11-2022",
    status: "Published",
  },
  {
    themeName: "CA",
    questionsAdded: 45,
    createdBy: "Arun",
    createdOn: "09-11-2022",
    status: "pending",
  },
  {
    themeName: "AU",
    questionsAdded: 56,
    createdBy: "Arun",
    createdOn: "09-11-2022",
    status: "pending",
  },
  {
    themeName: "DE",
    questionsAdded: 56,
    createdBy: "Arun",
    createdOn: "09-11-2022",
    status: "Published",
  },
  {
    themeName: "IE",
    questionsAdded: 67,
    createdBy: "Arun",
    createdOn: "09-11-2022",
    status: "pending",
  },
  {
    themeName: "MX",
    questionsAdded: 78,
    createdBy: "Arun",
    createdOn: "09-11-2022",
    status: "pending",
  },
  {
    themeName: "JP",
    questionsAdded: 78,
    createdBy: "Arun",
    createdOn: "09-11-2022",
    status: "Published",
  },
  {
    themeName: "IN",
    questionsAdded: 68,
    createdBy: "Arun",
    createdOn: "09-11-2022",
    status: "pending",
  },
  {
    themeName: "CN",
    questionsAdded: 67,
    createdBy: "Arun",
    createdOn: "09-11-2022",
    status: "pending",
  },
  {
    themeName: "IT",
    questionsAdded: 67,
    createdBy: "Arun",
    createdOn: "09-11-2022",
    status: "pending",
  },
  {
    themeName: "US",
    questionsAdded: 6,
    createdBy: "Arun",
    createdOn: "09-11-2022",
    status: "Published",
  },
  {
    themeName: "CA",
    questionsAdded: 6,
    createdBy: "Arun",
    createdOn: "09-11-2022",
    status: "pending",
  },
  {
    themeName: "AU",
    questionsAdded: 6,
    createdBy: "Arun",
    createdOn: "09-11-2022",
    status: "Published",
  },
  {
    ID: "F#017",
    themeName: "DE",
    questionsAdded: 8,
    createdBy: "Arun",
    createdOn: "09-11-2022",
    status: "pending",
  },
];
