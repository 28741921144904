import { SagaIterator } from "@redux-saga/types";
import { call, put } from "redux-saga/effects";
import { HttpResponseTypeEnum } from "../../enumerations/HttpResponseTypeEnum";
import { associatedThemeEntitiesRedux } from "../../redux/entity-management/AssociatedThemeEntitiesRedux";

export function* associateProgramThemesEntitiesSaga(
  api: any,
  action: any
): SagaIterator {
  try {
    const response = yield call(api.associateThemesAndEntities, action.payload);

    if (response.data.code === HttpResponseTypeEnum.Success) {
      yield put(
        associatedThemeEntitiesRedux.actions.associateThemeEntitiesSuccess({
          data: response?.data,
          mapping: true,
        })
      );
    } else {
      yield put(
        associatedThemeEntitiesRedux.actions.associateThemeEntitiesFailure(
          response.data
        )
      );
    }
  } catch (error) {
    yield put(
      associatedThemeEntitiesRedux.actions.associateThemeEntitiesFailure(error)
    );
  }
}
