import React from 'react';
import { Outlet } from 'react-router-dom';
import Layout from '../components/layout/Layout';


const ProtectedRoutes = () => {
    return (
        <Layout>
            <Outlet />
        </Layout>
    );
};

export default ProtectedRoutes;