import * as Yup from "yup";
import { StringConstant } from "../../resources/strings";
import {
  checkPhoneNumberWithCountrycode,
  isValidURL,
} from "../../utils/ValidationUtils";

interface IBasicInfo {
  entityName: string;
  entityType: string;
  entityTypeReferenceId: string;
  line1: string;
  line2: string;
  city: string;
  state: string;
  country: string;
  zipCode: string;
  gstIn: string;
  domain: string;
  totalEmployeeCount: string | number;
  maximumAdmins: string | number;
  maximumUsers: string | number;
}

interface IAddProgramAdmin {
  programAdmin: {
    firstName: string;
    lastName: string;
    email: string;
    domain: string;
    phoneNumber: string | number;
    countryCode: string;
    id: string;
  }[];
}

interface IContactDetails {
  contactDetails: {
    firstName: string;
    lastName: string;
    workEmail: string;
    domain: string;
    phoneNumber: string;
    countryCode: string;
    entityContactType: string;
  }[];
}

export const BasicInfo: IBasicInfo = {
  entityName: "",
  entityType: "1001",
  entityTypeReferenceId: "",
  line1: "",
  line2: "",
  city: "",
  state: "",
  country: "",
  zipCode: "",
  gstIn: "",
  domain: "",
  totalEmployeeCount: "",
  maximumAdmins: "",
  maximumUsers: "",
};
export const ProgramAdminInitialState: IAddProgramAdmin = {
  programAdmin: [
    {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      domain: "",
      countryCode: "+91",
      id: "",
    },
  ],
};

export const ContactDetailsInitialState: IContactDetails = {
  contactDetails: [
    {
      firstName: "",
      lastName: "",
      workEmail: "",
      domain: "",
      phoneNumber: "",
      countryCode: "+91",
      entityContactType: "1000",
    },
    {
      firstName: "",
      lastName: "",
      workEmail: "",
      domain: "",
      phoneNumber: "",
      countryCode: "+91",
      entityContactType: "1001",
    },
  ],
};

export const BasicInfoValidationSchema = Yup.object().shape({
  entityName: Yup.string().required(StringConstant.pleaseEnterName),
  entityType: Yup.string().required(
    StringConstant.pleaseEnterEntityType
  ),
  line1: Yup.string().required(StringConstant.pleaseEnterAddress),
  line2: Yup.string(),
  city: Yup.string().required(StringConstant.pleaseEnterCity),
  state: Yup.string().required(StringConstant.pleaseEnterState),
  country: Yup.string().required(StringConstant.pleaseEnterCountry),
  zipCode: Yup.string()
    .trim()
    .required(StringConstant.pleaseEnterZIPCode)
    .min(6, StringConstant.sixCharactersRequired),
  gstIn: Yup.string().trim().required(StringConstant.pleaseEnterGSTIN),
  domain: Yup.string()
    .required(StringConstant.pleaseEnterDomain)
    .test("test_url", "Invalid URL", function (value) {
      return isValidURL(value);
    }),
  totalEmployeeCount: Yup.string().required(
    StringConstant.pleaseEnterEmployeeCount
  ),
  maximumUsers: Yup.number().moreThan(0, StringConstant.greaterThenZero).required(StringConstant.pleaseEnterMaxNoOfUsers),
  maximumAdmins: Yup.number().moreThan(0, StringConstant.greaterThenZero).required(StringConstant.pleaseEnterMaxNoOfAdmins)
});

export const ContactDetailsValidationSchema = Yup.object().shape({
  contactDetails: Yup.array().of(
    Yup.object().shape({
      firstName: Yup.string().required(StringConstant.pleaseEnterFirstName),
      lastName: Yup.string().required(StringConstant.pleaseEnterLastName),
      domain: Yup.string(),
      workEmail: Yup.string()
        .email(StringConstant.invalidEmail)
        .required(StringConstant.pleaseEnterEmail)
        .test({
          name: "checking email by domain",
          exclusive: false,
          params: {},
          message: `${StringConstant.emailDoesNotBelongToEntity}`,
          test: function (value) {
            let domainaddress = this.parent.domain
            let domain = domainaddress
            if (domainaddress?.startsWith('http')) {
              domain = this.parent.domain.split('/')[2]
              if (domain?.startsWith('www')) {
                domain = domain?.slice(4)
              }
            } else if (domain?.startsWith('www')) {
              domain = domain?.slice(4)
            }
            return value?.split("@")[1] === domain;
          },
        }),
      countryCode: Yup.string().required(),
      phoneNumber: Yup.string()
        .required(StringConstant.pleaseEnterPhoneNumber)
        // .max(handleRestrictPhoneNumberLength(this.parent.countryCode), StringConstant.sixCharactersRequired)
        .test({
          name: "check country code",
          exclusive: false,
          params: {},
          message: `${StringConstant.invalidPhoneNumerforCountryCode}`,
          test: function (value) {
            let length = value ? value.length : 0;
            return checkPhoneNumberWithCountrycode(
              this.parent.countryCode,
              length
            );
          },
        }),
    })
  ),
});

export const programAdminSchema = Yup.object().shape({
  programAdmin: Yup.array().of(
    Yup.object().shape({
      firstName: Yup.string().required(StringConstant.pleaseEnterFirstName),
      lastName: Yup.string().required(StringConstant.pleaseEnterLastName),
      domain: Yup.string(),
      email: Yup.string()
        .email(StringConstant.invalidEmail)
        .required(StringConstant.pleaseEnterEmail)
        .test({
          name: "checking email by domain",
          exclusive: false,
          params: {},
          message: StringConstant.emailDoesNotBelongToEntity,
          test: function (value) {
            let domainaddress = this.parent.domain
            let domain = domainaddress
            // console.log(domainaddress)
            if (domainaddress?.startsWith('http')) {
              domain = this.parent.domain.split('/')[2]
              if (domain.startsWith('www')) {
                domain = domain.slice(4)
              }
            } else if (domain?.startsWith('www')) {
              domain = domain?.slice(4)
            }
            return value?.split("@")[1] === domain;
          },
        }),
      countryCode: Yup.string().required(),
      phoneNumber: Yup.string()
        .required(StringConstant.pleaseEnterPhoneNumber)
        .test({
          name: "check country code",
          exclusive: false,
          params: {},
          message: StringConstant.invalidPhoneNumerforCountryCode,
          test: function (value) {
            let length = value ? value.length : 0;
            return checkPhoneNumberWithCountrycode(
              this.parent.countryCode,
              length
            );
          },
        }),
    })
  ),
});
