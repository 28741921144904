import { createSlice } from "@reduxjs/toolkit";

export interface ISuperAdminUserManagementRedux {
  isLoading?: boolean;
  error?: string;
  isError?:boolean;
  isSuccess?: boolean;
  allUsersData?: any;
  inviteAdminErrorArray?:any;
  inviteAdminSuccessArray?:any;
}

const initialState: ISuperAdminUserManagementRedux = {
  isLoading: false,
  error: "",
  isSuccess: false,
  isError:false,
  allUsersData: [],
  inviteAdminErrorArray:[],
  inviteAdminSuccessArray:[],
};

export const SuperAdminUserManagementRedux = createSlice({
  name: "superAdminUserManagementRedux",
  initialState,
  reducers: {
    setSuperAdminUserManagement: (state: any, action: any) => {
      const newState = {
        ...state,
      };
      newState[action.payload.key] = action.payload.value;
      return newState;
    },
    getAllUsersByRoleIdRequest: (state:any) => {
      const newState = {
        ...state,
        isLoading: true,
      };
      return newState;
    },
    getAllUsersByRoleIdSuccess: (state: any, action: any) => {
      return {
        ...state,
        error: null,
        isLoading: false,
        allUsersData: action?.payload?.response,
      };
    },
    getAllUsersByRoleIdFailure: (state: any, action: any) => {
      return {
        ...state,
        isLoading: false,
        error: action?.payload?.message,
      };
    },
    addInviteSuperAdminRequest: (state: any, action: any) => {
      const newState = {
        ...state,
        isLoading: true,
      };
      return newState;
    },
    addInviteSuperAdminSuccess: (state: any, action: any) => {
      return {
        ...state,
        isLoading:false,
        isSuccess: true,
        inviteAdminSuccessArray: action?.payload?.response,
        error: null,
      };
    },
    addInviteSuperAdminFailure: (state: any, action: any) => {
        return{
            ...state,
            isLoading:false,
            isError:true,
            inviteAdminErrorArray: action?.payload?.response?.filter((item:any) => item?.isSuccess === false),
        }
    },
  },
});

const { actions, reducer } = SuperAdminUserManagementRedux;
export const {
  getAllUsersByRoleIdRequest,
  getAllUsersByRoleIdSuccess,
  getAllUsersByRoleIdFailure,
  setSuperAdminUserManagement,
  addInviteSuperAdminRequest,
  addInviteSuperAdminSuccess,
  addInviteSuperAdminFailure,
} = actions;

export default reducer;
