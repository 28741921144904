import React, { Fragment, lazy, Suspense, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import EntityManagement from "../pages/entity-management/EntityManagement";
import { Login } from "../pages/login/Login";
import OnboardingQuestion from "../pages/onboarding-question/onboarding-question-create-edit/OnboardingQuestion";
import ProgramThemes from "../pages/program-themes/ProgramThemes";
import SuperAdminDashBoard from "../pages/dashboard/SuperAdminDashBoard";
import SuperAdminUserManagement from "../pages/super-admin-user-management/SuperAdminUserManagement";
import ProtectedRoutes from "./ProtectedRoutes";
import { RouteConfigurations } from "./RouteConfigurations";
import MyProfile from "../pages/my-profile/MyProfile";
import OnboardingListOfQuestion from "../pages/onboarding-question/onboarding-question-list/OnboardingQuestionList";
import CreateEntity from "../pages/entity-management/create-entity/CreateEntity";
import { getItemFromLocalStorage } from "../utils/LocalStorageUtils";
import { Constants } from "../constants/Constants";
import LoginSuccess from "../pages/login/LoginSuccess";
import { useSelector } from "react-redux";
import CreateThemes from "../pages/program-themes/create-themes/CreateThemes";

export const AppRoutes = () => {
  const loginState = useSelector((state: any) => state.login);

  let routes = (
    <Fragment>
      <Route path="/" element={<Login />} />
      <Route path={RouteConfigurations.login} element={<Login />} />
      <Route
        path={RouteConfigurations.loginSuccess}
        element={<LoginSuccess />}
      />
      <Route path="*" element={<Navigate to="/" />} />
    </Fragment>
  );

  const token = getItemFromLocalStorage(Constants.authorizationToken);

  if (token) {
    routes = (
      <Route element={<ProtectedRoutes />}>
        <Route
          path={RouteConfigurations.dashboard}
          element={<SuperAdminDashBoard />}
        />
        <Route
          path={RouteConfigurations.adminUserManagement}
          element={<SuperAdminUserManagement />}
        />
        <Route
          path={RouteConfigurations.programThemes}
          element={<ProgramThemes />}
        />
        <Route
          path={RouteConfigurations.entityManagement}
          element={<EntityManagement />}
        />
        <Route
          path={RouteConfigurations.onboardingQuestion}
          element={<OnboardingQuestion />}
        />
        {/* <Route
          path={RouteConfigurations.onBoardingQuestionList}
          element={<OnboardingListOfQuestion />}
        /> */}
        <Route
          path={RouteConfigurations.createEntity}
          element={<CreateEntity />}
        />

        <Route path={RouteConfigurations.myProfile} element={<MyProfile />} />

        <Route path={"/"} element={<SuperAdminDashBoard />} />
        <Route path="*" element={<SuperAdminDashBoard />} />
        <Route
          path={RouteConfigurations.onBoardingQuestionList}
          element={<OnboardingListOfQuestion />}
        />
        <Route
          path={`${RouteConfigurations.createTheme}/*`}
          element={<CreateThemes />}
        />
        <Route
          path={RouteConfigurations.createEntity}
          element={<CreateEntity />}
        />
      </Route>
    );
  }

  return <Routes>{routes}</Routes>;
};
