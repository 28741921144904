import { SagaIterator } from "@redux-saga/types";
import { call, put } from "redux-saga/effects";
import { HttpResponseTypeEnum } from "../../enumerations/HttpResponseTypeEnum";
import { OnboardingQuestionRedux } from "../../redux/onboarding-question/OnboardingQuestionRedux";

export function* addOnboardingQuestionSaga(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.addOnBoardingQuestion, action.payload);
    if (response.data.code === HttpResponseTypeEnum.Success) {
      yield put(
        OnboardingQuestionRedux.actions.addOnboardingQuestionSuccess(
          response.data
        )
      );
    } else {
      yield put(
        OnboardingQuestionRedux.actions.addOnboardingQuestionFailure(
          response.data
        )
      );
    }
  } catch (error: any) {
    yield put(
      OnboardingQuestionRedux.actions.addOnboardingQuestionFailure(error)
    );
  }
}
//getAllonboardingquestion
export function* getOnboardingQuestionSaga(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.getAllOnBoardingQuestions, action.payload);
    if (response?.data?.code === HttpResponseTypeEnum.Success) {
      yield put(
        OnboardingQuestionRedux.actions.getOnboardingQuestionSuccess(
          response.data
        )
      );
    } else {
      yield put(
        OnboardingQuestionRedux.actions.getOnboardingQuestionFailure(
          response.data
        )
      );
    }
  } catch (error: any) {
    yield put(
      OnboardingQuestionRedux.actions.addOnboardingQuestionFailure(error)
    );
  }
}


// get Each Onboarding question
export function* getEachOnboardingQuestionSaga(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.getEachOnBoardingQuestions, action.payload);
    if (response?.data?.code === HttpResponseTypeEnum.Success) {
      yield put(
        OnboardingQuestionRedux.actions.getEachOnboardingQuestionSuccess(
          response.data
        )
      );
    } else {
      yield put(
        OnboardingQuestionRedux.actions.getEachOnboardingQuestionSuccess(
          response.data
        )
      );
    }
  } catch (error: any) {
    yield put(
      OnboardingQuestionRedux.actions.getEachOnboardingQuestionFailure(error)
    );
  }
}



// delete question for onboarding

export function* deleteOnboardingQuestionSaga(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.deleteOnBoardingQuestions, action.payload);
    if (response.data.code === HttpResponseTypeEnum.Success) {
      yield put(
        OnboardingQuestionRedux.actions.deleteOnboardingQuestionSuccess(
          response.data
        )
      );
    } else {
      yield put(
        OnboardingQuestionRedux.actions.deleteOnboardingQuestionFailure(
          response.data
        )
      );
    }
  } catch (error: any) {
    yield put(
      OnboardingQuestionRedux.actions.deleteOnboardingQuestionFailure(error)
    );
  }
}

export function* updateOnboardingQuestionSaga(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.updateOnBoardingQuestions, action.payload);
    if (response.data.code === HttpResponseTypeEnum.Success) {
      yield put(
        OnboardingQuestionRedux.actions.updateOnboardingQuestionSuccess(
          response.data
        )
      );
    } else {
      yield put(
        OnboardingQuestionRedux.actions.updateOnboardingQuestionFailure(
          response.data
        )
      );
    }
  } catch (error: any) {
    yield put(
      OnboardingQuestionRedux.actions.updateOnboardingQuestionFailure(error)
    );
  }
}

