import {
  Box,
  Chip,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import MediaAssets from "../../../assets";
import PageHeading from "../../../components/page-heading/PageHeading";
import {
  ActionTypeEnumResponseType,
  ActionTypeEnumTextResponse,
  ActionTypeEnumUtilsTextResponse,
  ResponseTypeEnum,
} from "../../../enumerations/ResponseTypeEnum";
import { RouteConfigurations } from "../../../routes/RouteConfigurations";
import { OnboardingQuestionListsStyleWrapper } from "./OnBoardQuestionItem.style";
import { useEffect } from "react";
import {
  deleteOnboardingQuestionRequest,
  getOnboardingQuestionRequest,
  setOnboardingState,
  setOnboardingToasterNull,
} from "../../../redux/onboarding-question/OnboardingQuestionRedux";
import { setToaster } from "../../../redux/toaster/ToasterRedux";
import { useDispatch, useSelector } from "react-redux";
import NoItemFound from "./../../../components/no-data-record/NoDataFound";
import PageLoader from "./../../../components/page-loader/PageLoader";
import ConfirmationDialog from "../../../components/confirmation-dialog/ConfirmationDialog";
import FrientorTheme from './../../../mui-themes/FrientorTheme';

const OnboardingListOfQuestion = () => {
  const actionDispatch = (dispatch: any) => ({
    getAllOnboardingQuestion: () => dispatch(getOnboardingQuestionRequest()),
    setOnboardingNull: () => dispatch(setOnboardingToasterNull()),
    deleteOnboardingQuestion: (data: any) =>
      dispatch(deleteOnboardingQuestionRequest(data)),
    setToasterInfo: (data: any) => dispatch(setToaster(data)),
    setOnboardingQuestion: (data: any) => dispatch(setOnboardingState(data))
  });

  const {
    getAllOnboardingQuestion,
    deleteOnboardingQuestion,
    setOnboardingQuestion,
    setToasterInfo,
    setOnboardingNull,
  } = actionDispatch(useDispatch());

  const navigate = useNavigate();
  const { onboardingQuestion } = useSelector((state: any) => state);
  const { isLoading, error, listsOnboardingQuestion, toasterDetails } =
    onboardingQuestion;
  const [shouldOpenConfirmatonDialog, setShouldOpenConfirmatonDialog] =
    useState(false);
  const [questionId, setQuestionId] = useState("")
  useEffect(() => {
    getAllOnboardingQuestion();
    return () => {
      renderTable();
      setOnboardingNull();
    };
  }, []);

  useEffect(() => {
    if (toasterDetails) {
      getAllOnboardingQuestion();
      setOnboardingNull();
    }
    setOnboardingNull();
  }, [toasterDetails]);

  const navigateToCreateQuestions = () => {
    navigate(RouteConfigurations.onboardingQuestion);
  };
  useEffect(() => {
    if (onboardingQuestion?.toasterDetails) {
      setToasterInfo({
        key: "toasterDetails",
        value: { ...onboardingQuestion?.toasterDetails, toasterOpen: true },
      });
      setOnboardingNull();
    }
    return () => {
      setOnboardingNull();
    };
  }, [onboardingQuestion?.toasterDetails]);

  const handleEditQuestion = (question: any) => {
    navigate(RouteConfigurations.onboardingQuestion, { state: question });
  };

  useEffect(() => {
    if (onboardingQuestion?.isOnboradingQuestionDeleted) {
      setShouldOpenConfirmatonDialog(false)
      setOnboardingQuestion({ key: "isOnboradingQuestionDeleted", value: "false" })

    }
  }, [onboardingQuestion?.isOnboradingQuestionDeleted])
  // const handleDeleteQuestion = (questionId: any) => {
  //   deleteOnboardingQuestion(questionId);
  // };
  const handleConfirmDelete = () => {
    deleteOnboardingQuestion(questionId)
  }
  const renderNoData = () => {
    return (
      <NoItemFound
        navImage={MediaAssets.ic_onboarding_question}
        textMesaage={
          "You haven’ t created any onboarding questions yet, create your first onboarding questioin."
        }
        buttonText={"Create Onboard Question"}
        handleClick={navigateToCreateQuestions}
      />
    );
  };

  const renderTable = () => {
    return (
      <>
        <ConfirmationDialog
          isConfirmModelOpen={shouldOpenConfirmatonDialog}
          handleClose={() => setShouldOpenConfirmatonDialog(false)}
          handleConfirm={handleConfirmDelete}
          title={"Are you sure you want to delete this question ?"}
          leftButtonText={"No"}
          rightButtonText={"Yes"}
        />
        <>
          <PageHeading
            pageHeading="Onboarding Questions"
            pageHeaderSubtitle={
              "These questions will be asked to the participants during their onboarding journey."
            }
            buttonText="Create onboarding question"
            isInviteButton={true}
            handleClick={navigateToCreateQuestions}
          />
          <Grid container className="tab-innner-content-height" gap={"20px"} alignContent={"flex-start"}>
            {listsOnboardingQuestion?.length > 0 &&
              listsOnboardingQuestion?.map((item: any, index: any) => (
                <Grid item lg={11} xl={11} direction={"column"}>
                  <OnboardingQuestionListsStyleWrapper>
                    <Stack
                      direction={"row"}
                      justifyContent={"space-between"}
                      pt={2}
                      pl={2}
                      alignItems={"center"}
                    >
                      <Typography
                        className={"question-list-header"}
                      >
                        {`Question ${index + 1}`}
                      </Typography>
                      <Box>
                        <IconButton onClick={(e) => handleEditQuestion(item)}>
                          <img
                            src={MediaAssets.ic_edit}
                            alt={"edit_icon"}
                            className={"question-list-icon"}
                          />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            setShouldOpenConfirmatonDialog(true)
                            setQuestionId(item?.id)

                          }}
                        >
                          <img
                            src={MediaAssets.ic_delete}
                            alt={"delete_icon"}
                            className={"question-list-icon"}
                          />
                        </IconButton>
                      </Box>
                    </Stack>
                    <Box pt={1} pl={2}>
                      {item?.isVisibleForMentor && <Stack direction={"row"} gap={1}>
                        <Typography className={"question-version-style"}>
                          Frientor's version:
                        </Typography>

                        <span className={"question-version-res"}>
                          {item?.mentorQuestion}
                        </span>
                      </Stack>}
                      {item?.isVisibleForMentee && <Stack direction={"row"} gap={1}>
                        <Typography className={"question-version-style"}>
                          Frientee's version:
                        </Typography>
                        <span className={"question-version-res"}>
                          {item?.menteeQuestion}
                        </span>
                      </Stack>}
                    </Box>
                    <Grid
                      container
                      direction={"row"}
                      spacing={2}
                      p={2}
                      pl={2}
                    >
                      {item?.responseOptions?.length ? item?.responseOptions?.map((res: any, index: any) => (
                        <Grid item xs={1.5}  >
                          <Chip key={res?.id} label={res?.optionName} />
                        </Grid>
                      )) : null}
                    </Grid>
                    {item?.topicCategories ? item?.topicCategories?.map((topic: any) => {
                      return (
                        <>
                          <Typography pl={2} className={"question-version-style"}> Topic: {topic?.topic}</Typography>
                          <Grid
                            container
                            direction={"row"}
                            spacing={2}
                            p={2}
                            pl={2}
                          >
                            {topic?.responseOptions?.map((res: any) => (
                              <Grid item xs={1.5}  >
                                <Chip key={res?.id} label={res?.optionName} />
                              </Grid>
                            ))}
                          </Grid>
                        </>
                      )
                    }) : null}
                    {item?.attachments?.length ? <Grid
                      container
                      pl={3}
                      pt={1}
                      pb={1}
                      gap={2}
                      mb={1}
                    >
                      {item?.attachments?.map((imgOption: any) => (
                        <Grid item xs={1.5}  >
                          <Chip
                            avatar={<img src={imgOption?.publicUrl} />}
                            label={imgOption?.caption ? imgOption?.caption : "-"}
                          />
                        </Grid>
                      ))}
                    </Grid> : null}
                    <hr className="horizontal-border" />
                    <Stack direction={"row"} p={2} gap={1}>
                      <Typography className={"question-response-style"}
                      >
                        Type:
                      </Typography>
                      <Stack
                        sx={{ flexWrap: "wrap" }}
                        direction="row"
                        spacing={1}
                        alignItems={"center"}
                        divider={<Divider orientation="vertical" flexItem />}
                      >
                        <Typography
                          className={"question-response-style"}
                        >
                          {ActionTypeEnumResponseType?.getResponseType(
                            item?.responseType
                          )}
                          -{" "}
                          {ActionTypeEnumUtilsTextResponse?.getEnumText(
                            item?.responseFormat
                          )}
                        </Typography>
                        {item?.isImpactful && (
                          <Typography className={"question-response-style"}
                          >
                            Impactful
                          </Typography>
                        )}
                        {item?.isTopicBased && (
                          <Typography className={"question-response-style"}
                          >
                            Topic Based
                          </Typography>
                        )}
                        {item?.isCustomisable && (
                          <Typography className={"question-response-style"}
                          >
                            Customisable
                          </Typography>
                        )}
                        {item?.isQuestionMandatory && (
                          <Typography className={"question-response-style"}
                          >
                            Mandatory question
                          </Typography>
                        )}
                        {item?.isAnswerMandatory && (
                          <Typography className={"question-response-style"}
                          >
                            Response required
                          </Typography>
                        )}
                        {item?.isVisibleForMentor && item?.isVisibleForMentee ? (
                          <Typography className={"question-response-style"}
                          >
                            Both Frientor & Frientee
                          </Typography>
                        ) : item?.isVisibleForMentee ? (
                          <Typography className={"question-response-style"}
                          >
                            Frientee
                          </Typography>
                        ) : (
                          <Typography className={"question-response-style"}
                          >
                            Frientor
                          </Typography>)}

                      </Stack>
                    </Stack>
                  </OnboardingQuestionListsStyleWrapper>
                </Grid>
              ))}
          </Grid>
        </>
      </>
    );
  };

  const LoadingComponent = () => {
    return <PageLoader />;
  };

  const renderRetry = () => {
    return <div>Try again</div>;
  };

  const render = useMemo(() => {
    if (isLoading) {
      return LoadingComponent();
    } else if (listsOnboardingQuestion?.length > 0) {
      return renderTable();
    } else if (!listsOnboardingQuestion?.length) {
      return renderNoData();
    } else if (error) {
      return renderRetry();
    }
  }, [error, isLoading, listsOnboardingQuestion, shouldOpenConfirmatonDialog]);

  return <>{render}</>;
};

export default OnboardingListOfQuestion;
