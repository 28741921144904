import { Fragment, useEffect, useState } from "react";
import { Grid, MenuItem } from "@mui/material";
import { Form, Formik, getIn } from "formik";
import {
  BasicInfoValidationSchema,
  BasicInfo,
} from "../EntityManagementInitialState";
import { useSelector, useDispatch } from "react-redux";
import { StringConstant } from "../../../resources/strings";
import styles from "./CreateEntityForm.module.css";
import MuiTextField from "../../../components/ui-elements/mui-text-field/MuiTextField";
import {
  handleNumberFieldChange,
  handleRestrictLength,
} from "../../../utils/ValidationUtils";
import FormBottomButtons from "../../../components/form-bottom-btns/FormBottomButtons";
import { entityTypeRedux } from "../../../redux/entity-management/EntityTypesRedux";
import { useLocation } from "react-router-dom";
import { setToaster } from "../../../redux/toaster/ToasterRedux";

import {
  makeToasterNull,
  addBasicDetailsSuccess,
  addBasicDetailsRequest,
  updateBasicDetailsRequest,
} from "../../../redux/entity-management/BasicInfoRedux";
import PageLoader from "../../../components/page-loader/PageLoader";

interface Props {
  tab: number;
  handleTabPrev: (value: number) => void;
  handleTabNext: (value: number) => void;
}

const BasicInfoForm = (props: Props) => {
  const { tab, handleTabPrev, handleTabNext } = props;
  const location = useLocation();

  const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false);

  const { basicInfo, entityTypes } = useSelector((state: any) => state); // it will has value after entity has created before that it will has null
  const basicInfoOfEntity = basicInfo?.data;

  const actionDispatch = (dispatch: any) => ({
    setToasterNull: () => dispatch(makeToasterNull()),
    setToasterInfo: (data: any) => dispatch(setToaster(data)),
    getEntityTypeRequest: () =>
      dispatch(entityTypeRedux.actions.fetchEntityTypeRequest()),
    addBasicInfoDetailsToReduxStore: (entityDetail: any) =>
      dispatch(
        addBasicDetailsSuccess({
          response: entityDetail,
        })
      ),
    addBasicInfoDetails: (requestObject: any) =>
      dispatch(addBasicDetailsRequest(requestObject)),
    updateBasicInfoDetails: (requestObject: any) =>
      dispatch(updateBasicDetailsRequest(requestObject)),
  });

  const {
    setToasterNull,
    setToasterInfo,
    getEntityTypeRequest,
    addBasicInfoDetailsToReduxStore,
    addBasicInfoDetails,
    updateBasicInfoDetails,
  } = actionDispatch(useDispatch());

  // >>>>>>>>>>>>>>>>> TOASTER FUNCTIONALITIES STARTS HERE >>>>>>>>>>>>>>>>>>>>>>>>>>>>

  useEffect(() => {
    if (basicInfo?.toasterDetails) {
      setToasterInfo({
        key: "toasterDetails",
        value: { ...basicInfo?.toasterDetails, toasterOpen: true },
      });
    }
  }, [basicInfo?.toasterDetails]);

  useEffect(() => {
    return () => setToasterNull();
  }, []);

  // >>>>>>>>>>>>>>>>> TOASTER FUNCTIONALITIES ENDS HERE >>>>>>>>>>>>>>>>>>>>>>>>>>>>
  useEffect(() => {
    getEntityTypeRequest();
    // storing entity data in BasicInfoRedux for update
    if (location?.state?.row && !basicInfoOfEntity) {
      let entityDetail = {
        ...location?.state?.row,
      };
      addBasicInfoDetailsToReduxStore(entityDetail);
    }
  }, []);

  useEffect(() => {
    if (isFormSubmitted)
      basicInfoOfEntity ? handleTabNext(tab) : setIsFormSubmitted(false); // Handles redirecting to next page after form submission
  }, [basicInfoOfEntity]);

  const handleSubmit = (basicInfoFromSubmit: any) => {
    let requestObject = {
      ...basicInfoFromSubmit,
      totalEmployeeCount: Number(basicInfoFromSubmit.totalEmployeeCount),
      maximumUsers: Number(basicInfoFromSubmit.maximumUsers),
      maximumAdmins: Number(basicInfoFromSubmit.maximumAdmins),
    };
    if (basicInfoOfEntity) {
      delete requestObject["createdBy"];
    }
    basicInfoOfEntity
      ? updateBasicInfoDetails(requestObject)
      : addBasicInfoDetails(requestObject);
    setIsFormSubmitted(true);
  };
  return (
    <Fragment>
      {basicInfo?.isLoading ? (
        <div className={"loading-container"}>
        <PageLoader />
        </div>
      ) : null}
      <Formik
        initialValues={basicInfoOfEntity || BasicInfo}
        validationSchema={BasicInfoValidationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          isValid,
          dirty,
          setFieldValue,
        }) => {
          return (
            <Form className={styles["tab-inner-content-height"]}>
              <Grid
                container
                className={styles["form-sub-container"]}
                gap={3}
                pt={3}
              >
                <Grid item xs={3} ml={3}>
                  <MuiTextField
                    className={styles["entity-form-text-field"]}
                    textfieldlabel={`Enter Name`}
                    name={`entityName`}
                    value={values?.entityName}
                    placeholder={"Enter here"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                    helperText={
                      getIn(touched, `entityName`)
                        ? getIn(errors, `entityName`)
                        : ""
                    }
                    error={
                      getIn(touched, `entityName`) &&
                        getIn(errors, `entityName`)
                        ? true
                        : false
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  <MuiTextField
                    className={styles["entity-form-text-field"]}
                    textfieldlabel={`Entity Type`}
                    placeholder="Select"
                    select
                    fullWidth
                    name={`entityType`}
                    value={values?.entityType}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                    helperText={
                      getIn(touched, `entityType`)
                        ? getIn(errors, `entityType`)
                        : ""
                    }
                    error={
                      getIn(touched, `entityType`) &&
                        getIn(errors, `entityType`)
                        ? true
                        : false
                    }
                  >
                    {entityTypes.data.map((entityType: any, index: any) => {
                      return (
                        <MenuItem
                          key={index}
                          className={styles["select-drop-down"]}
                          value={entityType.id}
                        >
                          {entityType.entityType}
                        </MenuItem>
                      );
                    })}
                  </MuiTextField>
                </Grid>
              </Grid>
              <div>
                <p className={styles["entity-form-sub-heading"]}>
                  {StringConstant.entityAddress}
                </p>
              </div>
              <Grid
                container
                className={styles["form-sub-container"]}
                columnGap={3}
                rowGap={2}
                pl={3}
                mt={1}
              >
                <Grid item xs={5}>
                  <MuiTextField
                    className={styles["entity-form-text-field"]}
                    textfieldlabel={`Address Line 1`}
                    name={`line1`}
                    value={values?.line1}
                    placeholder={"Enter here"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                    helperText={
                      getIn(touched, `line1`)
                        ? getIn(errors, `line1`)
                        : ""
                    }
                    error={
                      getIn(touched, `line1`) &&
                        getIn(errors, `line1`)
                        ? true
                        : false
                    }
                  />
                </Grid>
                <Grid item xs={5}>
                  <MuiTextField
                    className={styles["entity-form-text-field"]}
                    textfieldlabel={`Address Line 2`}
                    name={`line2`}
                    value={values?.line2}
                    placeholder={"Enter here"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>
                <Grid item xs={2.39}>
                  <MuiTextField
                    className={styles["entity-form-text-field"]}
                    textfieldlabel={`City`}
                    fullWidth
                    name={`city`}
                    value={values?.city}
                    placeholder={"Enter here"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                    helperText={
                      getIn(touched, `city`)
                        ? getIn(errors, `city`)
                        : ""
                    }
                    error={
                      getIn(touched, `city`) &&
                        getIn(errors, `city`)
                        ? true
                        : false
                    }
                  />
                </Grid>
                <Grid item xs={2.39}>
                  <MuiTextField
                    className={styles["entity-form-text-field"]}
                    textfieldlabel={`State`}
                    placeholder={"Enter here"}
                    fullWidth
                    name={`state`}
                    value={values?.state}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                    helperText={
                      getIn(touched, `state`)
                        ? getIn(errors, `state`)
                        : ""
                    }
                    error={
                      getIn(touched, `state`) &&
                        getIn(errors, `state`)
                        ? true
                        : false
                    }
                  />
                </Grid>
                <Grid item xs={2.39}>
                  <MuiTextField
                    className={styles["entity-form-text-field"]}
                    textfieldlabel={`Country`}
                    placeholder={"Enter here"}
                    fullWidth
                    name={`country`}
                    value={values?.country}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                    helperText={
                      getIn(touched, `country`)
                        ? getIn(errors, `country`)
                        : ""
                    }
                    error={
                      getIn(touched, `country`) &&
                        getIn(errors, `country`)
                        ? true
                        : false
                    }
                  />
                </Grid>
                <Grid item xs={2.39}>
                  <MuiTextField
                    className={styles["entity-form-text-field"]}
                    textfieldlabel={`ZIP Code`}
                    name={`zipCode`}
                    value={values?.zipCode?.trim()}
                    placeholder={"Enter here"}
                    onChange={handleRestrictLength(setFieldValue, 6)}
                    onBlur={handleBlur}
                    required
                    helperText={
                      getIn(touched, `zipCode`)
                        ? getIn(errors, `zipCode`)
                        : ""
                    }
                    error={
                      getIn(touched, `zipCode`) &&
                        getIn(errors, `zipCode`)
                        ? true
                        : false
                    }
                  />
                </Grid>
              </Grid>
              <Grid
                container
                className={styles["form-sub-container"]}
                columnGap={3}
                rowGap={2}
                pl={3}
                mt={2}
              >
                <Grid item xs={3}>
                  <MuiTextField
                    className={styles["entity-form-text-field"]}
                    textfieldlabel={`GSTIN/CIN`}
                    name={`gstIn`}
                    value={values?.gstIn.trim()}
                    placeholder={"Enter here"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                    helperText={
                      getIn(touched, `gstIn`) ? getIn(errors, `gstIn`) : ""
                    }
                    error={
                      getIn(touched, `gstIn`) && getIn(errors, `gstIn`)
                        ? true
                        : false
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  <MuiTextField
                    className={styles["entity-form-text-field"]}
                    textfieldlabel={`Domain`}
                    name={`domain`}
                    value={values?.domain?.trim()}
                    placeholder={"Enter here"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                    helperText={
                      getIn(touched, `domain`) ? getIn(errors, `domain`) : ""
                    }
                    error={
                      getIn(touched, `domain`) && getIn(errors, `domain`)
                        ? true
                        : false
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  <MuiTextField
                    className={styles["entity-form-text-field"]}
                    textfieldlabel={`Entity URL`}
                    name={`entityUrl`}
                    value={values?.entityUrl?.trim()}
                    placeholder={"Enter here"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                    helperText={
                      getIn(touched, `entityUrl`) ? getIn(errors, `entityUrl`) : ""
                    }
                    error={
                      getIn(touched, `entityUrl`) && getIn(errors, `entityUrl`)
                        ? true
                        : false
                    }
                  />
                </Grid>
                <Grid item lg={3} xl={2.39}>
                  <MuiTextField
                    className={styles["entity-form-text-field"]}
                    textfieldlabel={`Total Employee Count`}
                    name={`totalEmployeeCount`}
                    value={values?.totalEmployeeCount}
                    placeholder={"Enter here"}
                    onChange={handleNumberFieldChange(setFieldValue)}
                    onBlur={handleBlur}
                    required
                    helperText={
                      getIn(touched, `totalEmployeeCount`)
                        ? getIn(errors, `totalEmployeeCount`)
                        : ""
                    }
                    error={
                      getIn(touched, `totalEmployeeCount`) &&
                        getIn(errors, `totalEmployeeCount`)
                        ? true
                        : false
                    }
                  />
                </Grid>
                <Grid item lg={3} xl={2.39}>
                  <MuiTextField
                    className={styles["entity-form-text-field"]}
                    textfieldlabel={`Max No of Users`}
                    name={`maximumUsers`}
                    value={values?.maximumUsers}
                    placeholder={"Enter here"}
                    onChange={handleNumberFieldChange(setFieldValue)}
                    onBlur={handleBlur}
                    required
                    helperText={
                      getIn(touched, `maximumUsers`)
                        ? getIn(errors, `maximumUsers`)
                        : ""
                    }
                    error={
                      getIn(touched, `maximumUsers`) &&
                        getIn(errors, `maximumUsers`)
                        ? true
                        : false
                    }
                  />
                </Grid>
                <Grid item lg={3} xl={2.39}>
                  <MuiTextField
                    className={styles["entity-form-text-field"]}
                    textfieldlabel={`Max No of Admins`}
                    name={`maximumAdmins`}
                    value={values?.maximumAdmins}
                    placeholder={"Enter here"}
                    onChange={handleNumberFieldChange(setFieldValue)}
                    onBlur={handleBlur}
                    required
                    helperText={
                      getIn(touched, `maximumAdmins`)
                        ? getIn(errors, `maximumAdmins`)
                        : ""
                    }
                    error={
                      getIn(touched, `maximumAdmins`) &&
                        getIn(errors, `maximumAdmins`)
                        ? true
                        : false
                    }
                  />
                </Grid>
              </Grid>
              <FormBottomButtons
                tab={tab}
                loading={basicInfo.isLoading ? true : false}
                handleTabNext={handleTabNext}
                handleTabPrev={handleTabPrev}
                handleSubmit={handleSubmit}
                isDisabled={!isValid || !dirty}
                next={!basicInfoOfEntity || (isValid && dirty) ? false : true}
                // if "next" is "true" mean it will not make any api call, it simply navigates to next page
                // if "next" is "false" it will make Api calls
              />
            </Form>
          );
        }}
      </Formik>
    </Fragment>
  );
};

export default BasicInfoForm;
