import {
  BaseTextFieldProps,
  Box,
  InputLabel,
  styled,
  TextField,
} from "@mui/material";
import React, { ChangeEvent, ReactNode } from "react";

export interface MuiTextFieldProps extends BaseTextFieldProps {
  textfieldlabel?: any;
  InputProps?: { [key: string]: ReactNode };
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  value?: any;
  defaultValue?: any;
  disabled?:boolean;
  required?:boolean
}
const MuiTextFieldWrapper = styled(Box)(({ theme }) => ({
  "&&& .MuiInputBase-root": {
    backgroundColor: "#fbfbfb",
  
  },
  "&& .MuiInputBase-root.Mui-error": {
    backgroundColor: "#fdfafa"
  },
  " && .MuiFormHelperText-root.Mui-error": {
    fontSize: "14px",
    fontFamily: "Urbanist-SemiBold",
    fontWeight: 600
  },

  [theme.breakpoints.down("xl")]:{
    " && .MuiFormHelperText-root.Mui-error": {
      fontSize: "1.2rem",
      fontFamily: "Urbanist-SemiBold",
      fontWeight: 600
    },
    "&&& .MuiInputBase-root": {
   
      fontSize: "1.2rem"
    },
  }
}))

const MuiTextField = (props: MuiTextFieldProps) => {
  const { textfieldlabel,required } = props;
  return (
    <MuiTextFieldWrapper>
      <InputLabel error={props.error} required={props.required}>{textfieldlabel}</InputLabel>
      <TextField {...props} />
    </MuiTextFieldWrapper>
  );
};

export default MuiTextField;
