import * as Yup from "yup";
import { StringConstant } from "../../resources/strings";
import { isEmailValid } from "../../utils/ValidationUtils";
// import { isEmailValid } from "../../utils/ValidationUtils";
import {
  checkPhoneNumberWithCountrycode,
  isValidURL,
} from "../../utils/ValidationUtils";

export interface ICreateAdminInitialState {
  createAdmin: {
    superAdminFirstName: string;
    superAdminLastName: string;
    superAdminEmail: string;
    phoneNumber: string | number;
    countryCode: string | number;
  }[];
}

export const createAdminInitialState: ICreateAdminInitialState = {
  createAdmin: [
    {
      superAdminFirstName: "",
      superAdminLastName: "",
      superAdminEmail: "",
      phoneNumber: "",
      countryCode: "+91",
    },
  ],
};

export const createAdminSchema = Yup.object().shape({
  createAdmin: Yup.array().of(
    Yup.object().shape({
      superAdminFirstName: Yup.string().required(
        StringConstant.pleaseEnterFirstName
      ),
      superAdminLastName: Yup.string().required(
        StringConstant.pleaseEnterLastName
      ),
      superAdminEmail: Yup.string()
        .email(StringConstant.pleaseEnterEmail)
        .required(StringConstant.pleaseEnterEmail)
        .matches(isEmailValid(), StringConstant.pleaseEnterFrientorEmail),
      countryCode: Yup.string().required(),
      phoneNumber: Yup.string()
        .required(StringConstant.pleaseEnterPhoneNumber)
        // .test({
        //   name: "check country code",
        //   exclusive: false,
        //   params: {},
        //   message: StringConstant.invalidPhoneNumerforCountryCode,
        //   test: function (value) {
        //     let length = value ? value.length : 0;
        //     return checkPhoneNumberWithCountrycode(
        //       this.parent.countryCode,
        //       length
        //     );
        //   },
        // }),
    })
  ),
});

interface Column {
  id: "First Name" | "Last Name" | "Email ID" | "phoneNumber" | "";
  label: string;
  minWidth?: number;
  align?: "center" | "left" | "right";
  format?: (value: number) => string;
}

export const columns: readonly Column[] = [
  { id: "First Name", label: "First Name", minWidth: 170, align: "left" },
  { id: "Last Name", label: "Last Name", minWidth: 170, align: "left" },
  { id: "Email ID", label: "Email ID", minWidth: 170, align: "left" },
  {
    id: "phoneNumber",
    label: "Phone Number",
    minWidth: 180,
    align: "left",
    format: (value: number) => value.toLocaleString("en-US"),
  },
  {
    id: "",
    label: "",
    minWidth: 180,
    align: "left",
    format: (value: number) => value.toFixed(2),
  },
];

