import { Fragment, useEffect, useState } from "react";
import MediaAssets from "../../assets";
import NoItemFound from "../../components/no-data-record/NoDataFound";
import PageHeader from "../../components/page-heading/PageHeading";
import { StringConstant } from "../../resources/strings";
import OnBoardedEntities from "./onboarded-entity/OnBoardedEntities";
import { columns } from "../../utils/json/OnBoardedEntitiesJson";
import { RouteConfigurations } from "../../routes/RouteConfigurations";
import { useNavigate } from "react-router";
import { setItemToLocalStorage } from "../../utils/LocalStorageUtils";
import { Constants } from "../../constants/Constants";
import { onBoardedEntitiesRedux } from "../../redux/entity-management/OnBoardedEntitiesRedux";
import { useDispatch, useSelector } from "react-redux";

const EntityManagement = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(() => {
    dispatch(
      onBoardedEntitiesRedux.actions.addOnBoardedEntitiesRequest({
        size: 50,
        index: 1,
      })
    );
    return true;
  });

  const handlePageHeadingButton = () => {
    navigate(RouteConfigurations.createEntity);
  };

  const onBoardedEntities = useSelector(
    (state: any) => state?.onBoardedEntities
  );

  useEffect(() => {
    setLoading(onBoardedEntities.isLoading);
  }, [onBoardedEntities.isLoading]);

  return (
    <Fragment>
      {!onBoardedEntities?.data?.data ||
      onBoardedEntities?.data?.data.length === 0 ? (
        <Fragment>
          <PageHeader pageHeading={StringConstant.EntityManagement} />
          <NoItemFound
            textMesaage={StringConstant.noDataOnBoardingQuestion}
            buttonText={StringConstant.CreateEntity}
            navImage={MediaAssets.ic_entity}
            handleClick={handlePageHeadingButton}
            loading={loading}
          />
        </Fragment>
      ) : (
        <OnBoardedEntities
          onBoardedEntities={onBoardedEntities.data}
          columns={columns}
          loading={loading}
        />
      )}
    </Fragment>
  );
};

export default EntityManagement;
