import { Height } from "@mui/icons-material";
import { Box, styled } from "@mui/material";

export const NoItemFoundWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100%",
  overflowY: "auto",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  ".menu-image-background": {
    backgroundColor: "#f7f6ff",
    borderRadius: "50%",
    width: "172px",
    height: "172px",
    padding:"43px"
  },
  ".menu-image": {
    width: "86px",
    height: "86px",
    margin: "0 0 40px 0",
  },
  " &&& .MuiTypography-root": {
    fontSize: "2rem",
  },

  [theme.breakpoints.down("xl")]: {
    ".menu-image": {
      width: "70px",
      height: "70px",
    },

    ".menu-image-background": {
      borderRadius: "50%",
      width: "150px",
      height: "150px",
    },
    " &&& .MuiTypography-root": {
      fontSize: "1.7rem",
    },

  },

  


}));
