import { SagaIterator } from "@redux-saga/types";
import { call, put } from "redux-saga/effects";
import { HttpResponseTypeEnum } from "../../../enumerations/HttpResponseTypeEnum";
import { CreateProgramThemeRedux } from "../../../redux/program-theme/create-program-theme/CreateProgramThemeRedux";


export function* addCreateProgramThemeSaga(api: any, action: any): SagaIterator {
    try {
        const response = yield call(api.addCreateProgramTheme, action.payload);
        console.log(response.data, "response.data.code");
        if (response.data.code == HttpResponseTypeEnum.Success) {
            yield put(
                CreateProgramThemeRedux.actions.addCreateProgramThemeSuccess(
                    response.data
                )
            );
        } else {
            yield put(
                CreateProgramThemeRedux.actions.addCreateProgramThemeFailure(
                    response.data
                )
            );
        }
    } catch (error: any) {
        console.log(error);
        yield put(
            CreateProgramThemeRedux.actions.addCreateProgramThemeFailure(error)
        );
    }
}
//getAllCreateProgramTheme
export function* getProgramThemeSaga(api: any, action: any): SagaIterator {
    try {
        const response = yield call(api.getAllProgramTheme, action.payload);
        if (response?.data?.code === HttpResponseTypeEnum.Success) {
            yield put(
                CreateProgramThemeRedux.actions.getProgramThemeSuccess(
                    response.data
                )
            );
        } else {
            yield put(
                CreateProgramThemeRedux.actions.getProgramThemeFailure(
                    response.data
                )
            );
        }
    } catch (error: any) {
        yield put(
            CreateProgramThemeRedux.actions.getProgramThemeFailure(error)
        );
    }
}

//getEachProgramTheme 
export function* getEachProgramThemeSaga(api: any, action: any): SagaIterator {
    try {
        const response = yield call(api.getEachProgramTheme, action.payload);
        if (response?.data?.code === HttpResponseTypeEnum.Success) {
            yield put(
                CreateProgramThemeRedux.actions.getEachProgramThemeSuccess(
                    response.data
                )
            );
        } else {
            yield put(
                CreateProgramThemeRedux.actions.getEachProgramThemeFailure(
                    response.data
                )
            );
        }
    } catch (error: any) {
        yield put(
            CreateProgramThemeRedux.actions.getEachProgramThemeFailure(error)
        );
    }
}


export function* deleteCreateProgramThemeSaga(api: any, action: any): SagaIterator {
    try {
        const response = yield call(api.deleteCreateProgramThemes, action.payload);
        if (response.data.code === HttpResponseTypeEnum.Success) {
            yield put(
                CreateProgramThemeRedux.actions.deleteCreateProgramThemeSuccess(
                    response.data
                )
            );
        } else {
            yield put(
                CreateProgramThemeRedux.actions.deleteCreateProgramThemeFailure(
                    response.data
                )
            );
        }
    } catch (error: any) {
        yield put(
            CreateProgramThemeRedux.actions.deleteCreateProgramThemeFailure(error)
        );
    }
}

export function* updateCreateProgramThemeSaga(api: any, action: any): SagaIterator {
    try {
        const response = yield call(api.updateCreateProgramTheme, action.payload);
        if (response.data.code === HttpResponseTypeEnum.Success) {
            yield put(
                CreateProgramThemeRedux.actions.updateCreateProgramThemeSuccess(
                    response.data
                )
            );
        } else {
            yield put(
                CreateProgramThemeRedux.actions.updateCreateProgramThemeFailure(
                    response.data
                )
            );
        }
    } catch (error: any) {
        yield put(
            CreateProgramThemeRedux.actions.updateCreateProgramThemeFailure(error)
        );
    }
}

//programtheme guide


export function* addProgramThemeGuidelineSaga(api: any, action: any): SagaIterator {
    try {
        const response = yield call(api.addProgramThemeGuideline, action.payload);
        if (response.data.code == HttpResponseTypeEnum.Success) {
            yield put(
                CreateProgramThemeRedux.actions.addProgramThemeGuidelineSuccess(
                    response.data
                )
            );
        } else {
            yield put(
                CreateProgramThemeRedux.actions.addProgramThemeGuidelineFailure(
                    response.data
                )
            );
        }
    } catch (error: any) {
        yield put(
            CreateProgramThemeRedux.actions.addProgramThemeGuidelineFailure(error)
        );
    }
}
export function* updateProgramThemeGuidelineSaga(api: any, action: any): SagaIterator {
    try {
        const response = yield call(api.updateAttchment, action.payload);
        if (response.data.code == HttpResponseTypeEnum.Success) {
            yield put(
                CreateProgramThemeRedux.actions.updateProgramThemeGuidelineSuccess(
                    response.data
                )
            );
        } else {
            yield put(
                CreateProgramThemeRedux.actions.updateProgramThemeGuidelineFailure(
                    response.data
                )
            );
        }
    } catch (error: any) {
        yield put(
            CreateProgramThemeRedux.actions.updateProgramThemeGuidelineFailure(error)
        );
    }
}
