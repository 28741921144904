import React from "react";
import styles from "./SecondarySidemenu.module.css";

interface Props {
  tab: number;
  handleTab: (event: React.MouseEvent<HTMLElement>) => void;
  sidebarData: { id: number; name: string }[];
}

const SecondarySidemenu = ({ tab, handleTab, sidebarData }: Props) => {
  return (
    <div className={styles["create-entity-side-bar"]}>
      {sidebarData.map((element, index) => (
        <div
          key={element.id}
          className={`${styles["create-entity-side-bar-element-container"]} ${
            element.id === tab && styles["active"]
          }`}
          data-id={element.id}
          onClick={handleTab}
        >
          {element.id === tab && <div className={styles["dark-button"]}></div>}
          <p className={styles["create-entity-sidebar-element-text"]}>
            {`${element.id}. ${element.name}`}
          </p>
        </div>
      ))}
    </div>
  );
};

export default SecondarySidemenu;
