import React, { Fragment, useEffect, useState } from "react";
import {
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import styles from "../create-entity/CreateEntity.module.css";
import PageHeading from "../../../components/page-heading/PageHeading";
import { RouteConfigurations } from "../../../routes/RouteConfigurations";
import { useNavigate } from "react-router";
import OnBoardedEntitiesRows from "./OnBoardedEntitiesRows";
import { useDispatch } from "react-redux";
import { onBoardedEntitiesRedux } from "../../../redux/entity-management/OnBoardedEntitiesRedux";
import MuiTextField from "../../../components/ui-elements/mui-text-field/MuiTextField";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { Constants } from "../../../resources/constants";
import { basicInfoRedux } from "../../../redux/entity-management/BasicInfoRedux";
import { contactDetailsRedux } from "../../../redux/entity-management/ContactDetailsRedux";
import { programAdminRedux } from "../../../redux/entity-management/ProgramAdminRedux";
import MediaAssets from "../../../assets";
import Pagination from "../../../components/pagination/Pagination";
import { associatedThemeEntitiesRedux } from "../../../redux/entity-management/AssociatedThemeEntitiesRedux";
import PageLoader from "../../../components/page-loader/PageLoader";
import { TableBodyWrapper } from "../../../components/table-body/TableBodyWrapper.style";
import { TableHeadWrapper } from "../../../components/table-head/TableHead.style";
interface Props {
  onBoardedEntities: any;

  columns:
    | { id: string; label: string; minWidth: number; align: string }[]
    | [];

  loading?: boolean;
}

const OnBoardedEntities = ({ onBoardedEntities, columns, loading }: Props) => {
  const dispatch = useDispatch();
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [page, setPage] = useState(1);
  const [arrayLenth, setArrayLength] = useState<number>(() => {
    return Math.ceil(onBoardedEntities?.totalCount / rowsPerPage);
  });
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const open = Boolean(anchorEl);

  useEffect(() => {
    dispatch(basicInfoRedux.actions.reset());
    dispatch(contactDetailsRedux.actions.reset());
    dispatch(programAdminRedux.actions.reset());
    dispatch(associatedThemeEntitiesRedux.actions.reset());
  }, []);
  // This blocks cleans entity form details from redux after user edits basic-info ,contact-details ,program-admins
  //  particular entity and come back to this page

  useEffect(() => {
    dispatch(
      onBoardedEntitiesRedux.actions.addOnBoardedEntitiesRequest({
        size: rowsPerPage,
        index: page,
      })
    );
    // making pagination api call if rowsperPage or page has changed
  }, [rowsPerPage, page]);

  const navigate = useNavigate();
  const handleRowPerPage = (e: any) => {
    let value = Number((e.target as HTMLElement).dataset?.value);
    setRowsPerPage(value);
    setPage(1);
    setAnchorEl(null);
  };

  const handleRowsperPageModal = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePrev = (page: number) => {
    page > Constants.number_min_create_entity_tab &&
      setPage((page) => page - 1);
  };
  const handleNext = (page: number) => {
    page < onBoardedEntities?.totalPages && setPage((page) => page + 1);
  };

  const handlePageHeadingButton = () => {
    navigate(RouteConfigurations.createEntity);
  };
  return (
    <Fragment>
      <PageHeading
        pageHeading="Onboard Entities"
        isInviteButton={true}
        buttonText={"Onboard New Entity"}
        headingSuffix={`${onBoardedEntities?.totalCount} ${
          onBoardedEntities?.totalCount > 1 ? "Entities" : "Entity"
        }`}
        btnroute={RouteConfigurations.createEntity}
        handleClick={handlePageHeadingButton}
      />
      {!loading ? (
        <div className={styles["table-wrapper"]}>
          <TableContainer
            sx={{ height: "calc(100% - 90px)", overflowY: "scroll" }}
          >
            <Table stickyHeader aria-label="sticky table">
              <TableHeadWrapper>
                <TableRow>
                  {columns.map((column, index) => (
                    <TableCell
                      key={index}
                      align={"left"}
                      style={{
                        minWidth: column.minWidth,
                        fontFamily: "Urbanist-SemiBold",
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHeadWrapper>
              <TableBodyWrapper>
                {onBoardedEntities?.data.map((row: any, index: any) => {
                  return <OnBoardedEntitiesRows key={index} row={row} />;
                })}
                {/* {loading && (
                <tr style={{ position: "relative" }}>
                  <td>
                    <PageLoader />
                  </td>
                </tr>
              )} */}
              </TableBodyWrapper>
            </Table>
          </TableContainer>

          {/* <Pagination
          rowsPerPage={rowsPerPage}
          handleRowsperPageModal={handleRowsperPageModal}
          open={open}
          anchorEl={anchorEl}
          handleClose={handleClose}
          arrayLenth={arrayLenth}
          handleRowPerPage={handleRowPerPage}
          page={page}
          handlePrev={handlePrev}
          handleNext={handleNext}
          totalPages={onBoardedEntities?.totalPages}
        /> */}
        </div>
      ) : (
        <PageLoader />
      )}
    </Fragment>
  );
};

export default OnBoardedEntities;
