export const StringConstant: any = {
  //auth

  mandatory: "This field is mandatory.",
  authorizationToken: "AuthorizationToken",

  applicationName: "Frient Super Admin",
  headerBarNotification: "Notification",
  headerBarProfile: "Profile",

  //dashboard constants
  welcome: "Welcome",
  overView: "Overview",

  //dashborad card titles
  superAdminUser: "Super Admin User",
  programThemes: "Program Themes",
  entityOnBoarded: "Entity Onboard",
  onBoardingQuestion: "Onboarding Questions",

  //dashboard buttontext
  inviteSuperAdmin: "Invite Admin User>",
  createProgramTheme: "Create Program Theme >",
  onBoardEntity: "Onboard Entity>",
  createOnboardingQuestion: "Create Onboarding Question>",

  // admin use Management
  adminSuperManagementHeader: "Super Admin Management",

  //admin user Management table
  adminTableHeaderName: "Admin Name",
  adminTableHeaderEmail: "Email ID",
  adminTableHeaderPhone: "Phone Number",

  //invite super admin pop up
  inviteHeader: "Invite Super Admin",
  inviteAnotherUserButtonText: "Add another",

  //inviteuser successs

  invitationSentSuccessfully: "Invitation Sent Successfully ",

  //program theme
  noThemeExists: "You haven't created any themes yet,create your first theme",
  createProgramHeaderTitle: "Create Program Theme",
  createProgramHeaderSubtitle:
    "Let's get started on your frientor program theme",

  //program theme creation tab
  tabBasicInfo: "Base Info",
  tabThemeQuestion: "Theme Question",
  tabThemeGuideLine: "Theme Guideline",
  tabTopicPreview: " Topics Preview",
  programThemeQuestions: "Program Theme Questions",

  //theme basic info tab
  themeHasAfixedDurationTitle: "This program theme has a fixed duration?",

  //themequestion tab
  themeCreateQuestionsHeader:
    "Create questions specific to this program theme.",
  questionVisibleTo: "Question visible to",
  markThisAsTopicQuestion: "Mark as a topic question",
  markThisAsImpactfulQuestion: "Mark as an impactful question",
  mustMatchQuestion:"Mark as must match question",
  imapctQuestionWillbeUsedForMatchingLogic:
    "( Impactful questions will be used for matching logic)",
  markThisAsMandatoryQuestion:
    "Mark as mandatory question (i.e, program admin cannot hide)",
  markResponsesMandatory: "Mark response as mandatory",
  allowProgramAdminToAddMoreResponse:
    "Allow program admin to add more responses",
  createYourFirstTheme:
    "You haven’t created any themes yet, create your first theme.",

  //theme guideliness tab
  themeGuideLineWillaReferenceFor:
    "Theme guidelines will a reference for the end users.",

  //programthemes table
  themeName: "Theme Name",
  themeQuestionAdded: "Questions Added",
  themeCreatedBy: "Created By",
  themeCreatedOn: "Created on",
  themeStatus: "Status",

  //entity management
  entityManagementHeader: "Entity Management",
  noEntityExists:
    "You haven't onboared any Entities yet,create your first Entity",
  addMoreProgarmAdmins: "Add more Program Admins",

  //Onboarding question
  onBoardingQuestionSubtitle:
    "These questions will be asked to the participants during their onboarding journey.",
  createNewQuestion: "Create new Question",

  //onBoarding confirmation pop up
  onboardingPopTitle: "Onboarding Confirmation",
  onboardingPopText:
    "This will send onboarding Invites to all the added Program Admin(s).",

  // enterValidEmail: "Please enter a valid email",
  pleaseEnterEmail: "Please enter an email",
  pleaseEnterValidEmail: "Please Enter Valid email",
  pleaseEnterFrientorEmail: `Please enter an email address ending in "@frientor.io."`,
  enterValidFullname: "Full name shouldn't contain a number",
  pleaseEnterName: "Please enter a name",
  pleaseEnterFirstName: "Please enter a First Name",
  pleaseEnterLastName: "Please enter a last Name",
  pleaseSelectGener: "Please select a gender",
  enterValidPhoneNumber: "Please enter a valid mobile number",
  pleaseEnterPhoneNumber: "Please enter a phone number",
  pleaseEnterUsername: "Please enter a username",
  userNameAlreadyExist: "This user name already exist",
  pleaseSelectRole: "Please select an role",
  numberOfRolesError: "Number of roles must be less then 7",
  numberOfCharactersError: "Minimum 3 characters should be there",
  emailSpacingError: "Email cannot include leading and trailing spaces",
  dateSelectionError: "Please select the date",
  invalidPhoneNumerforCountryCode:
    "Invalid phone number for selected country code",

  //onboarding question options

  noDataOnBoardingQuestion:
    "You haven’t created any onboarding questions yet, create your first question.",

  //    Login page
  text_super_admin: "Super Admin",
  text_login_with_your: "Login with your",
  text_frientor_io: "@frientor.io",
  text_account: "account.",
  text_sign_in_with_google: "Login with Google",
  text_profile_updated_successfully: "Profile Updated Successfully",

  //   File Uploader common component
  text_drag_and_drop: " Drag & Drop ",
  text_or: "or",
  text_image: "Image",
  text_document: "document",
  text_here: " here",
  text_browse: "Browse file",
  text_max_file_size: "Max file size : ",
  text_ten_MB: "10 MB, ",
  text_file_type: " File Type : ",
  text_word: "MS Word",
  text_dimension: "Dimension(W*H) : ",
  text_to_be_finalised: "To be finalized after EU VD",
  text_MIME_type: ".JPEG,.PNG",

  // button texts

  prev: "prev",
  next: "next",
  block: "block",
  unblock: "unblock",
  remove: "remove",
  add: "add",
  back: "Back",
  onBoard: "Onboard",
  onBoarded: 'Onboarded',
  ok:"OK",

  //Entity Create management

  EntityManagement: "Entity Management",
  CreateEntity: "Create Entity",
  onBoardEntityCreate: "Onboard Entity",
  letsGetStarted: `Let's get started`,
  entityAddress: "Entity Address",
  pleaseEnterEntityType: "Please choose entity type",
  pleaseEnterAddress: "Please enter address",
  pleaseEnterCity: "Please enter city",
  pleaseEnterState: "Please enter state",
  pleaseEnterCountry: "Please enter country",
  pleaseEnterZIPCode: "Please enter Zip code",
  pleaseEnterGSTIN: "Please enter GSTIN/CIN",
  pleaseEnterDomain: "Please enter website address",
  pleaseEnterEmployeeCount: "Please enter employee count",
  pleaseEnterMaxNoOfAdmins: "Please enter max no of admins",
  pleaseEnterMaxNoOfUsers: "Please enter max no of users",
  adminsShouldLessThanEmployees: "Admins should be less than Employees",
  adminsShouldLessThanUsers: "Admins should be less than Users",
  usersShouldLessThanEmployees: "Users should be less than Employees",

  primaryContact: "Primary Contact",
  escalationContact: "Escalation Contact",
  addAnother: "Add another",
  addProgramAdmins: "Add Program Admin(s)",
  programThemesEnabledForEntity: "Program Theme(s) enabled for Entity",
  invalidEmail: "Please enter valid Email",
  emailDoesNotBelongToEntity: "Email doesn't belong to this entity's domain",
  sixCharactersRequired: "Minimum six characters required",

  // Dialog texts

  onBoardingConfirmation: "Onboarding Confirmation",
  onBoardingContent:
    "This will send onboarding Invites to all the added Program Admin(s)",
  leftButtonText: "Cancel",
  rightButtonText: "Confirm",

  AddConfirmation: "Add?",
  AddEntityThemeContent: "Are you sure to link this theme with the entity?",

  RemoveConfirmation: "Remove?",
  RemoveEntityThemeContent: "Are you sure to unlink this theme and the entity?",

  Block: "Block?",
  BlockContent: "Are you sure to Block this entity",

  UnBlock: "UnBlock?",
  UnBlockContent: "Are you sure to Unlock this entity",

  renewTheme: "Renew Theme",
  pauseTheme: "Pause Theme",
  manage: "Manage",
  blocked: "Blocked",

  onboarded: "Onboarded",
  pending: "Pending",

  editProgramAdminContent: "Are you sure to Edit?",
  deleteProgramAdminContent: "Are you sure to remove this program admin?",

  // Create Program themes

  CreateProgramTheme: "Create Program Theme",
  createProgramThemeSubHeading: `Let's get started on your frientor program theme`,
  themeGuidelineformHeading:
    "Theme guidelines will be a reference for participants.",
  themepreviewHeading:
    "This preview is for you to decide better on topics for this program theme only.",
  topicInthisTheme: "Topic in this theme",
  fetchedFromTheme: "Fetched from all the topic questions",
  TopicfromOnboardingQuestions: "Topics from onboarding questions",
  fetchedFromOnboardQuestions:
    "Fetched from all the topic questions in onboarding questions",
  publishProgramTheme: "Publish Program Theme",

  pleaseCompleteProgramInfo: "Please complete Basic Info",
  pleaseFillContactDetails: "Please Fill Contact Details",
  addAtleastOneProgramAdmin: "Please add atleast one program admin for this entity",

  // file error
  fileisTooLarge:"File is too large. Change to 10 MB and upload again",
  greaterThenZero:"Must be greater then Zero"
};
