import { Box, styled, TableHead, TableBody } from "@mui/material";

export const TableBodyWrapper = styled(TableBody)(({ theme }) => ({
    marginTop: "20px",
    "&&& .MuiTableCell-root": {
        padding: "10px 15px",
        fontSize: "1.8rem"
    },
    [theme.breakpoints.down("xl")]: {
        "&&& .MuiTableCell-root": {
            padding: "10px 15px",
            fontSize: "1.3rem",
        },

    },

}));
