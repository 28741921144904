import React from "react";
import { StringConstant } from "../../resources/strings";
import MuiButton from "../ui-elements/mui-button/MuiButton";
import MuiLoadingButton from "../ui-elements/mui-loading-button/MuiLoadingButton";
import styles from "./FormBottomButtons.module.css";

interface ReactNodeProps {
  tab?: any;
  pageId?: number;
  handleTabPrev?: any;
  handleTabNext?: any;
  handleSubmit?: any;
  handleClick?: any;
  isDisabled?: boolean;
  loading?: boolean;
  onBoarded?: boolean;
  next?: boolean;
}

//*****next*****
//
// if "next" is "true" mean it will not make any api call, it simply navigates to next page
// if "next" is "false" it will make Api calls

const FormBottomButtons = (props: ReactNodeProps) => {
  const {
    tab,
    handleTabPrev,
    handleClick,
    handleTabNext,
    handleSubmit,
    isDisabled,
    pageId,
    loading,
    onBoarded,
    next,
  } = props;

  return (
    <div
      className={`${styles["entity-form-btn-container"]} ${
        tab === 1 && styles["reverse"]
      }`}
    >
      {tab !== 1 && (
        <MuiButton
          variant="outlined"
          className={`${styles["entity-form-btn-prev"]} }`}
          value={tab}
          type="button"
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            handleTabPrev(tab);
          }}
        >
          {StringConstant.back}
        </MuiButton>
      )}
      {next ? (
        <MuiButton
          variant="contained"
          className={`${styles["entity-form-btn"]}`}
          value={tab}
          onClick={(e: any) => {
            handleTabNext(tab);
          }}
        >
          Next
        </MuiButton>
      ) : (
        <MuiButton
          variant="contained"
          className={`${styles["entity-form-btn"]}`}
          value={tab}
          type="submit"
          onSubmit={handleSubmit}
          // loading={loading}
          onClick={handleClick}
          disabled={isDisabled || onBoarded ? true : false}
        >
          {pageId && pageId === 1 && tab === 4
            ? StringConstant.publishProgramTheme
            : !pageId && tab !== 4
            ? StringConstant.next
            : !pageId && tab === 4 && !onBoarded
            ? StringConstant.onBoard
            : StringConstant.onBoarded}
        </MuiButton>
      )}
    </div>
  );
};

export default FormBottomButtons;
