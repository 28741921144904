import * as yup from "yup";
import { ToWords } from "to-words";
import {
  ActionTypeEnumTextResponse,
  ResponseTypeEnum,
} from "../../../../enumerations/ResponseTypeEnum";
import { StringConstant } from "../../../../resources/strings";
import { UsersRoleTypeEnum } from "../../../../enumerations/UsersRoleTypeEnum";

export interface IThemeQuestionProps {
  mentorQuestion?: string;
  menteeQuestion?: string;
  description?: string;
  responseType?: string; //response option enum
  responseFormat?: string; //response option enum
  questionType: string;
  responseOptionImage?:
  | {
    fileType: any;
    name?: any;
    imageUrl?: any;
    id?: string;
    caption?: string;
  }[]
  | any;
  options?: {
    id?: string;
    optionName?: string;
  }[];
  characterLimitForResponse?: string | number;
  topicCategory?:
  | {
    topicCategoryId: string;
    categoryName: string;
    options: {
      id?: string;
      optionName?: string;
    }[];
    deletedOptionId?: string[];
  }[]
  | any;

  questionVisibleTo?: string; //response options enum//response options enum
  isVisibleForMentor?: boolean;
  isVisibleForMentee?: boolean;
  isIdentityQuestion?: boolean;
  isTopicBased?: boolean;
  isImpactful?: boolean;
  isQuestionMandatory?: boolean;
  isAnswerMandatory?: boolean;
  isCustomisable?: boolean;
  isMustMatchQuestion?: boolean;
  maximumResponseSelection?: string | number;
}

export const initialThemeQuestionState: IThemeQuestionProps = {
  mentorQuestion: "",
  menteeQuestion: "",
  description: " ",
  responseType: "", //response option enum
  responseFormat: "", //response option enum
  questionType: "1002", // for themeQuestion
  responseOptionImage: [
    {
      fileType: "",
      name: "",
      imageUrl: "",
      id: "",
      caption: "",
    },
    {
      fileType: "",
      name: "",
      imageUrl: "",
      id: "",
      caption: "",
    },
  ],
  options: [],
  topicCategory: [
    {
      topicCategoryId: "",
      categoryName: "",
      options: [],
      deletedOptionId: [],
    },
  ],
  characterLimitForResponse: 3,

  questionVisibleTo: UsersRoleTypeEnum.Mentor, //response options enum
  isQuestionMandatory: true,
  isImpactful: true,
  isTopicBased: true,
  isAnswerMandatory: true,
  isCustomisable: true,
  isIdentityQuestion: true,
  isMustMatchQuestion: false,
  maximumResponseSelection: 1,
  isVisibleForMentor: true,
  isVisibleForMentee: false,
};

export const themeQuestionsScheme = yup.object().shape({
  questionVisibleTo: yup.string().required(StringConstant.mandatory),
  mentorQuestion: yup.string().when("questionVisibleTo", {
    is: (questionVisibleTo: any) =>
      questionVisibleTo == UsersRoleTypeEnum.Mentor ||
      questionVisibleTo == UsersRoleTypeEnum.MentorAndMentee,
    then: yup.string().required(StringConstant.mandatory),
  }),
  menteeQuestion: yup.string().when("questionVisibleTo", {
    is: (questionVisibleTo: any) =>
      questionVisibleTo == UsersRoleTypeEnum.Mentee ||
      questionVisibleTo == UsersRoleTypeEnum.MentorAndMentee,
    then: yup.string().required(StringConstant.mandatory),
  }),
  responseType: yup.string().required(StringConstant.mandatory),
  responseFormat: yup.string().required("selected Reponse format"),

  // validing based on selected response
  maximumResponseSelection: yup.number().when("responseFormat", {
    is: "1002",
    then: yup.number().moreThan(0, "Maximum response selection should be more then one"),
  }),
  options: yup.array().when(["responseFormat", "responseType"], {
    is: (responseFormat: any, responseType: any) =>
      (responseFormat == ActionTypeEnumTextResponse.SingleChoice &&
        responseType == ResponseTypeEnum.Text) ||
      (responseFormat == ActionTypeEnumTextResponse.MultipleChoice &&
        responseType == ResponseTypeEnum.Text),
    then: yup
      .array()
      .of(
        yup.object().shape({
          optionName: yup.string().required("Please Enter value"),
        })
      ).min(2, `Minimum two response options are mandatory`)
      // .when("maximumResponseSelection", (value: any, schema: any) => {
      //   if (value ? value : 1) {
      //     const toWords = new ToWords();
      //     let words = toWords.convert(value ? value : 1);
      //     return schema.min(
      //       value,
      //       `Minimum ${words} response options are mandatory`
      //     ); 
      //   }
      // })
      .required("Please Enter options")
      .nullable(),
  }),

  responseOptionImage: yup.array().when(["responseFormat", "responseType"], {
    is: (responseFormat: any, responseType: any) =>
      (responseFormat == ActionTypeEnumTextResponse.SingleChoice &&
        responseType == ResponseTypeEnum.Image) ||
      (responseFormat === ActionTypeEnumTextResponse.MultipleChoice &&
        responseType == ResponseTypeEnum.Image),
    then: yup
      .array()
      .of(
        yup.object().shape({
          imageUrl: yup.string().required("Please Enter value"),
        })
      )
      .when("maximumResponseSelection", (value: any, schema: any) => {
        if (value ? value : 1) {
          const toWords = new ToWords();
          let words = toWords.convert(value ? value : 1);
          return schema.min(
            value,
            `Minimum ${words} response options are mandatory`
          );
        }
      }),
  }),
  characterLimitForResponse: yup.number().when("responseFormat", {
    is: "1003",
    then: yup.number().moreThan(2, "Character Limit must be greater than 3"),
  }),

  topicCategory: yup.array().when("responseFormat", {
    is: "1005",
    then: yup.array(
      yup.object().shape({
        categoryName: yup.string().required("Please Enter Category"),
        options: yup
          .array()
          .min(1, "Minimum one response is mandatory")
          .required("Provide at least one option")
          .nullable(),
      })
    ),
  }),
});

const filedValues: any = [
  {
    allowProgramAdminToAddMoreResponse: true,
    characterLimitForResponse: "",
    markResponseMandatory: true,
    markThisAsImpactFulQuestion: true,
    markThisAsMandatoryQuestion: true,
    markThisAstopicQuestion: true,
    themeQuestion: "chetan",
    questionVisibleTo: "Mentor",
    responseType: "text",
    responsesOption: [],
    selectedResponseFormat: 1,
  },
  {
    allowProgramAdminToAddMoreResponse: true,
    characterLimitForResponse: "",
    markResponseMandatory: false,
    markThisAsImpactFulQuestion: false,
    markThisAsMandatoryQuestion: true,
    markThisAstopicQuestion: true,
    themeQuestion: "chetan",
    questionVisibleTo: "Mentor",
    responseType: "text",
    responsesOption: [],
    selectedResponseFormat: 1,
  },
];

export const questionList: any = [
  {
    createdAt: "2023-01-06T13:03:26.462Z",
    updatedAt: "2023-01-06T13:03:26.464Z",
    id: "a0223a8e-6c82-42e5-9386-84d5fddcdb73",
    question:
      "A positive reaction to a mediated communication is described as ?",
    description: "theme related question",
    responseType: "1001",
    responseFormat: "1001",
    questionType: "1002",
    isImpactful: true,
    isTopicBased: true,
    isCustomisable: true,
    isQuestionMandatory: true,
    isAnswerMandatory: true,
    isVisibleForMentor: true,
    isVisibleForMentee: true,
    createdBy: null,
    updatedBy: null,
    reponseOptions: {
      createdAt: "2023-01-06T13:03:27.431Z",
      updatedAt: "2023-01-06T13:03:27.431Z",
      id: "ecdca6ab-53eb-42d3-b4b9-b3ae49e855ec",
      questionId: "a0223a8e-6c82-42e5-9386-84d5fddcdb73",
      options: [
        "Fragmented Feedback",
        "Flak",
        "Passive Response",
        "non conformity",
      ],
      createdBy: null,
      updatedBy: null,
    },
    attachments: [
      {
        createdAt: "2023-01-06T13:03:30.329Z",
        updatedAt: "2023-01-06T13:03:30.329Z",
        id: "b13a8d05-bf31-4103-9a18-1b602e1d3227",
        questionId: "a0223a8e-6c82-42e5-9386-84d5fddcdb73",
        attachmentType: "1001",
        contentType: "1001",
        publicUrl: "sample-red-grunge-stamp",
        fileName:
          "https://www.shutterstock.com/image-vector/sample-red-grunge-stamp-260nw-338250266.jpg",
        userId: null,
        programThemeId: null,
        createdBy: null,
        updatedBy: null,
      },
    ],
  },
  {
    createdAt: "2023-01-06T13:03:26.462Z",
    updatedAt: "2023-01-06T13:03:26.464Z",
    id: "a0223a8e-6c82-42e5-9386-84d5fddcdb73",
    question:
      "A positive reaction to a mediated communication is described as ?",
    description: "theme related question",
    responseType: "1001",
    responseFormat: "1001",
    questionType: "1002",
    isImpactful: true,
    isTopicBased: true,
    isCustomisable: true,
    isQuestionMandatory: true,
    isAnswerMandatory: true,
    isVisibleForMentor: true,
    isVisibleForMentee: true,
    isImpactfuls: true,
    isTopicBaseds: true,
    createdBy: null,
    updatedBy: null,
    reponseOptions: {
      createdAt: "2023-01-06T13:03:27.431Z",
      updatedAt: "2023-01-06T13:03:27.431Z",
      id: "ecdca6ab-53eb-42d3-b4b9-b3ae49e855ec",
      questionId: "a0223a8e-6c82-42e5-9386-84d5fddcdb73",
      options: [
        "Fragmented Feedback",
        "Flak",
        "Passive Response",
        "non conformity",
      ],
      createdBy: null,
      updatedBy: null,
    },
    attachments: [
      {
        createdAt: "2023-01-06T13:03:30.329Z",
        updatedAt: "2023-01-06T13:03:30.329Z",
        id: "b13a8d05-bf31-4103-9a18-1b602e1d3227",
        questionId: "a0223a8e-6c82-42e5-9386-84d5fddcdb73",
        attachmentType: "1001",
        contentType: "1001",
        publicUrl: "sample-red-grunge-stamp",
        fileName:
          "https://www.shutterstock.com/image-vector/sample-red-grunge-stamp-260nw-338250266.jpg",
        userId: null,
        programThemeId: null,
        createdBy: null,
        updatedBy: null,
      },
    ],
  },
];
