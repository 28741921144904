import { Button, ButtonProps } from "@mui/material";
import React from "react";
import { MuiButtonStyle } from "./MuiButton.style";

const MuiButton = (props: ButtonProps) => {
    const { children } = props;
    return (
        <MuiButtonStyle>
            <Button {...props}>{children}</Button>
        </MuiButtonStyle>
    );
};

export default MuiButton;
