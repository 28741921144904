import { Preview } from "@mui/icons-material";
import { Box, FormHelperText, Grid, Typography } from "@mui/material";
import MediaAssets from "../../assets";
import AlertHelperText from "../ui-elements/mui-alert-helper-text/AlertHelperText";
import {
  AddCaptionInput,
  ImageUploderWrapper,
  UploadedImageWrapper,
} from "./ImageUploader.style";

interface IImageUploaderProps {
  index?: any;
  fieldhelpertext?: any;
  onDrop?: (event: any) => void;
  handleImpageUpload?: (event: any) => void;
  previewImage?: any;
  name?: string;
  onDeleteImage?: (event?: any) => void;
  handleImageUpload?: (event?: any, file?: any) => void;
  onBlur?: (event: any) => void;
  value?: any;
  captionValue?: string;
  captionName?: string;
  handleChangeCaption?: (event?: any) => void;
  handleDeleteImageField?: any;
}

const ImageUploader = (props: IImageUploaderProps) => {
  const {
    index,
    handleImpageUpload,
    name,
    value,
    previewImage,
    onDeleteImage,
    captionName,
    captionValue,
    handleImageUpload,
    handleChangeCaption,
    handleDeleteImageField,
    fieldhelpertext,
  } = props;
  // const imageUrl = URL?.createObjectURL(previewImage)
  return (
    <div>
      {previewImage ? (
        <div>
          <UploadedImageWrapper>
            <img
              className="preview-image-height"
              src={`${previewImage}`}
              alt={name}
            />
            <Grid className="replace-and-delete-grid">
              <div className="replace-and-delete-icon-background">
                <label className="cursor-as-pointer" htmlFor={`${name}`}>
                  <input
                    accept=".jpg,.png,.jpeg"
                    id={`${name}`}
                    name={name}
                    type="file"
                    value={value}
                    className="input-display-none"
                    onChange={handleImageUpload}
                  />
                  <img
                    className="upload-logo"
                    src={MediaAssets.ic_replace_white}
                  ></img>
                </label>
              </div>

              <div
                className="replace-and-delete-icon-background"
                onClick={onDeleteImage}
              >
                <img
                  className="upload-logo"
                  src={MediaAssets.ic_delete_white}
                ></img>
              </div>
            </Grid>
          </UploadedImageWrapper>
          <AddCaptionInput>
            <input
              placeholder="Add Caption(Optional)"
              className="add-caption"
              type="text"
              value={captionValue}
              name={captionName}
              onChange={handleChangeCaption}
            />
          </AddCaptionInput>
        </div>
      ) : (
        <ImageUploderWrapper error={fieldhelpertext}>
          <input
            name={name}
            accept=".jpg,.png,.jpeg,"
            type="file"
            className="image-uploader"
            onChange={handleImageUpload}
            value={value}
          />
          {index > 1 && (
            <div className="delete-icon-background">
              <img
                onClick={handleDeleteImageField}
                className="cross-img"
                src={MediaAssets.ic_cross}
              ></img>
            </div>
          )}
          {props.fieldhelpertext ? (
            <img src={MediaAssets.ic_uploader_error} className="upload-logo" />
          ) : (
            <img src={MediaAssets.ic_upload} className="upload-logo" />
          )}
          <Box>
            <Typography className="upload-image-text">{`Upload Image`}</Typography>
          </Box>
        </ImageUploderWrapper>
      )}
    </div>
  );
};

export default ImageUploader;
