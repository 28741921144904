import { FormControl, RadioGroup, RadioGroupProps } from "@mui/material";

const MuiGroupRadioButton = (props: RadioGroupProps) => {
    const { children } = props;
    return (
        <FormControl fullWidth component="fieldset">
            <RadioGroup row {...props}>
                {children}
            </RadioGroup>
        </FormControl>
    );
};

export default MuiGroupRadioButton;