import { Grid } from "@mui/material";
import styles from "./CreateEntityForm.module.css";
import MuiTextField from "../../../components/ui-elements/mui-text-field/MuiTextField";
import InputAdornment from "@mui/material/InputAdornment";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { countryCodeJson } from "../../../utils/json/countryCode";
import IconButton from "@mui/material/IconButton";
import MediaAssets from "../../../assets";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Popover from "@mui/material/Popover";
import { useEffect, useState } from "react";
import ConfirmationDialog from "../../../components/confirmation-dialog/ConfirmationDialog";
import { StringConstant } from "../../../resources/strings";
import { useDispatch } from "react-redux";
import {
  deleteProgramAdminRequest,
  editProgramAdminRequest,
  programAdminRedux,
} from "../../../redux/entity-management/ProgramAdminRedux";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import TaskAltIcon from "@mui/icons-material/TaskAlt";

interface Props {
  index: number;
  values: any;
  getIn: any;
  touched: any;
  handleChange: any;
  handleBlur: any;
  errors: any;
  handlePhoneNumberFieldChange: any;
  remove: any;
  programAdminsOfEntity: any;
  setFieldValue: any;
  setHasChildUpdated: any;
  isDisabled: boolean;
  basicInfoOfEntity: any;
  setProgramAdminCounts: any;
  entityId: any
}
const ProgramAdminRows = (props: Props) => {
  const {
    index,
    values,
    handleBlur,
    getIn,
    touched,
    errors,
    handlePhoneNumberFieldChange,
    remove,
    programAdminsOfEntity,
    setFieldValue,
    isDisabled,
    setHasChildUpdated,
    basicInfoOfEntity,
    setProgramAdminCounts,
    entityId
  } = props;

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [disabled, setDisabled] = useState<boolean>(() => {
    return isDisabled;
  });
  const [programAdminID, setProgramAdminId] = useState<string>("");
  const [isEditOn, setIsEditOn] = useState<boolean>(false);
  const [isConfirmModelOpen, setisConfirmModelOpen] = useState<boolean>(false);
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [countryCode, setCountryCode] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");

  const actionDispatch = (dispatch: any) => ({
    deleteProgramAdmin: (reqArray: any) =>
      dispatch(deleteProgramAdminRequest(reqArray)),
    editProgramAdmin: (reqObject: any) =>
      dispatch(editProgramAdminRequest(reqObject)),
  });

  const { deleteProgramAdmin, editProgramAdmin } = actionDispatch(
    useDispatch()
  );

  const handleClose = () => setisConfirmModelOpen(false);
  const handleDeleteSubmit = () => {
    handleClose();
    setAnchorEl(null);
    const data = {
      "userId": programAdminID,
      "entityId": entityId
    }
    deleteProgramAdmin(data);
    setHasChildUpdated(true);
  };

  const handleEditSubmit = () => {
    const reqObj = {
      entityId: entityId,
      data: {
        id: programAdminID,
        firstName: firstName || values.programAdmin[index].firstName,
        lastName: lastName || values.programAdmin[index].lastName,
        email: email || values.programAdmin[index].email,
        countryCode: countryCode || values.programAdmin[index].countryCode,
        phoneNumber: phoneNumber || values.programAdmin[index].phoneNumber,
      }
    };
    setHasChildUpdated(true);
    setIsEditOn(false);
    editProgramAdmin(reqObj);
  };

  useEffect(() => {
    setDisabled(
      programAdminsOfEntity?.programAdmin &&
        programAdminsOfEntity?.programAdmin[index]
        ? true
        : false
    ); // while editing making input fields disabled
    programAdminsOfEntity?.programAdmin &&
      setProgramAdminId(programAdminsOfEntity?.programAdmin[index]?.id); //setting program admin id for this row only
  }, [programAdminsOfEntity?.programAdmin]);

  const handlePopoverClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <div>
      <Grid container gap={3} mt={4} pl={3} position={"relative"}>
        <Grid item xs={2.5}>
          <input
            type="hidden"
            name={`programAdmin.${index}.domain`}
            value={values.programAdmin[index].domain}
          />
          <MuiTextField
            className={styles["entity-form-text-field"]}
            textfieldlabel={index == 0 ? "First Name" : ""}
            placeholder={"Enter first name"}
            required={index == 0 && true}
            name={`programAdmin.${index}.firstName`}
            value={values.programAdmin[index].firstName}
            onChange={(event: any) => {
              setFieldValue(
                `programAdmin.${index}.firstName`,
                event.target.value
              );
              setFirstName(event.target.value);
            }}
            helperText={
              getIn(touched, `programAdmin.${index}.firstName`)
                ? getIn(errors, `programAdmin.${index}.firstName`)
                : ""
            }
            error={
              getIn(touched, `programAdmin.${index}.firstName`) &&
                getIn(errors, `programAdmin.${index}.firstName`)
                ? true
                : false
            }
            onBlur={handleBlur}
            disabled={
              disabled && values.programAdmin[index].firstName !== ""
                ? true
                : false
            }
          />
        </Grid>
        <Grid item xs={2.5}>
          <MuiTextField
            className={styles["entity-form-text-field"]}
            textfieldlabel={index == 0 ? "Last Name" : ""}
            name={`programAdmin.${index}.lastName`}
            placeholder={"Enter last name"}
            value={values?.programAdmin[index].lastName}
            onChange={(event: any) => {
              setFieldValue(
                `programAdmin.${index}.lastName`,
                event.target.value
              );
              setLastName(event.target.value);
            }}
            required={index == 0 && true}
            helperText={
              getIn(touched, `programAdmin.${index}.lastName`)
                ? getIn(errors, `programAdmin.${index}.lastName`)
                : ""
            }
            error={
              getIn(touched, `programAdmin.${index}.lastName`) &&
                getIn(errors, `programAdmin.${index}.lastName`)
                ? true
                : false
            }
            onBlur={handleBlur}
            disabled={
              disabled && values.programAdmin[index].lastName !== ""
                ? true
                : false
            }
          />
        </Grid>
        <Grid item xs={2.5}>
          <MuiTextField
            textfieldlabel={index == 0 ? "Email Address" : ""}
            className={styles["entity-form-text-field"]}
            type="email"
            name={`programAdmin.${index}.email`}
            value={values.programAdmin[index].email}
            placeholder={"Enter here"}
            onChange={(event: any) => {
              setFieldValue(`programAdmin.${index}.email`, event.target.value);
              setEmail(event.target.value);
            }}
            required={index == 0 && true}
            helperText={
              getIn(touched, `programAdmin.${index}.email`)
                ? getIn(errors, `programAdmin.${index}.email`)
                : ""
            }
            error={
              getIn(touched, `programAdmin.${index}.email`) &&
                getIn(errors, `programAdmin.${index}.email`)
                ? true
                : false
            }
            onBlur={handleBlur}
            disabled={
              disabled && values.programAdmin[index].email !== "" ? true : false
            }
          />
        </Grid>
        <Grid item xs={2.5}>
          <MuiTextField
            className={styles["entity-form-text-field"]}
            textfieldlabel={index == 0 ? "Phone Number" : ""}
            name={`programAdmin.${index}.phoneNumber`}
            value={
              values.programAdmin[index].phoneNumber
                ? values.programAdmin[index].phoneNumber
                : ""
            }
            onChange={(event: any) => {
              handlePhoneNumberFieldChange(
                setFieldValue,
                values.programAdmin[index].countryCode
              )(event);
              setPhoneNumber(event.target.value);
            }}
            required={index == 0 && true}
            helperText={
              getIn(touched, `programAdmin.${index}.phoneNumber`)
                ? getIn(errors, `programAdmin.${index}.phoneNumber`)
                : ""
            }
            error={Boolean(
              getIn(touched, `programAdmin.${index}.phoneNumber`) &&
                getIn(errors, `programAdmin.${index}.phoneNumber`)
                ? true
                : false
            )}
            disabled={
              disabled && values.programAdmin[index].phoneNumber !== ""
                ? true
                : false
            }
            onBlur={handleBlur}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Select
                    variant="standard"
                    disableUnderline
                    name={`programAdmin.${index}.countryCode`}
                    value={
                      values.programAdmin[index].countryCode
                        ? values.programAdmin[index].countryCode
                        : "+91"
                    }
                    onChange={(event: any) => {
                      setFieldValue(
                        `programAdmin.${index}.countryCode`,
                        event.target.value
                      );
                      setCountryCode(event.target.value);
                    }}
                    onBlur={handleBlur}
                    defaultValue={"+91"}
                  >
                    {countryCodeJson.map((element: any, index: any) => (
                      <MenuItem value={element.dial_code} key={index}>
                        {element.dial_code}
                      </MenuItem>
                    ))}
                  </Select>
                </InputAdornment>
              ),
            }}
            sx={{
              "& input": {
                borderLeft: `1px solid ${Boolean(
                  getIn(touched, `programAdmin.${index}.phoneNumber`) &&
                  getIn(errors, `programAdmin.${index}.phoneNumber`)
                )
                  ? "#f97973"
                  : "#c4c5ce"
                  }`,
                paddingLeft: "10px",
                borderRadius: "0px !important",
              },
              "&& .MuiSelect-select.MuiSelect-standard": {
                paddingTop: "15%",
              },
            }}
          />
          <input
            type="hidden"
            name={`programAdmin.${index}.id`}
            value={values.programAdmin[index].id}
          />
        </Grid>
        <Grid
          item
          xs={1}
          mt={3}
          className={`${styles["delete-btn-container"]} ${index === 0 && styles["first-row"]
            }  ${isEditOn && styles["row-edit-btn-container"]}`}
        >
          {index > 0 &&
            (!programAdminsOfEntity?.programAdmin ||
              !programAdminsOfEntity?.programAdmin[index]) && (
              <IconButton
                onClick={() => {
                  setProgramAdminCounts((value: any) => value - 1);
                  remove(index);
                }}
              >
                <img src={MediaAssets.ic_delete} width={30} />
              </IconButton>
            )}
          {programAdminsOfEntity?.programAdmin &&
            programAdminsOfEntity.programAdmin[index]?.id &&
            !isEditOn && (
              <IconButton onClick={handlePopoverClick}>
                <MoreVertIcon />
              </IconButton>
            )}
          {programAdminsOfEntity?.programAdmin &&
            programAdminsOfEntity.programAdmin[index]?.id &&
            isEditOn && (
              <div className={styles["row-btns"]}>
                <IconButton
                  onClick={(e: any) => {
                    handleEditSubmit();
                  }}
                >
                  <TaskAltIcon />
                </IconButton>
                <IconButton
                  onClick={() => {
                    setIsEditOn(false);
                    setDisabled(true);
                  }}
                >
                  <HighlightOffIcon />
                </IconButton>
              </div>
            )}
        </Grid>
      </Grid>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <p
          className={styles["dialog-edit-delete-button"]}
          onClick={(e: any) => {
            setDisabled((prev) => !prev);
            setIsEditOn(true);
            handlePopoverClose();
          }}
        >
          Edit
        </p>
        <p
          className={styles["dialog-edit-delete-button"]}
          onClick={() => {
            setisConfirmModelOpen(true);
          }}
        >
          Delete
        </p>
      </Popover>
      <ConfirmationDialog
        isConfirmModelOpen={isConfirmModelOpen}
        handleClose={handleClose}
        handleConfirm={handleDeleteSubmit}
        title={
          false
            ? StringConstant.AddConfirmation
            : StringConstant.RemoveConfirmation
        }
        contentText={
          false
            ? StringConstant.editProgramAdminContent
            : StringConstant.deleteProgramAdminContent
        }
        leftButtonText={StringConstant.leftButtonText}
        rightButtonText={StringConstant.rightButtonText}
      />
    </div>
  );
};

export default ProgramAdminRows;
