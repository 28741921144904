import { SagaIterator } from "@redux-saga/types";
import { put, call } from "redux-saga/effects";
import { HttpResponseTypeEnum } from "../../enumerations/HttpResponseTypeEnum";
import { contactDetailsRedux } from "../../redux/entity-management/ContactDetailsRedux";

export default function* contactDetailsSaga(
  api: any,
  action: any
): SagaIterator {
  try {
    const response = yield call(api.addContactDetails, action.payload);
    if (response.data.code === HttpResponseTypeEnum.Success) {
      yield put(
        contactDetailsRedux.actions.addContactDetailsSuccess({
          data: response.data,
          fetched: false,
        })
      );
    } else {
      yield put(
        contactDetailsRedux.actions.addContactDetailsFailure({
          data: response.data,
          fetched: false,
        })
      );
    }
  } catch (e) {
    yield put(contactDetailsRedux.actions.addContactDetailsFailure(e));
  }
}
