import { Box, Radio, styled, RadioProps } from '@mui/material';
import React from 'react';


export interface IMuiRadioButtonProps extends RadioProps{
    error?:any
}

const MuiRadioWrapper=styled(Box)(({theme})=>({


    
}))

const MuiRadioButton = (props: IMuiRadioButtonProps) => {
    return (
        <MuiRadioWrapper>
            <Radio {...props} sx={{
                '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)':
                {
                    color: props.error ? '#e0514b' : "",
                },
                "&.Mui-checked": {
                    color:"#8867ff"
                },

            }} />
        </MuiRadioWrapper>
    );
};

export default MuiRadioButton;