import { createSlice } from "@reduxjs/toolkit";

interface IProgramThemes {
  isLoading?: boolean;
  error?: string;
  success?: string;
  data?: any;
  toasterDetails?: any;
}

const initialState: IProgramThemes = {
  isLoading: false,
  error: "",
  success: "",
  data: null,
  toasterDetails: null,
};

export const programThemesRedux = createSlice({
  name: "programThemes",
  initialState,
  reducers: {
    fetchprogramThemesRequest(state) {
      return { ...state, isLoading: true };
    },
    programThemesSuccess(state, action) {
      return {
        ...state,
        data: action?.payload?.response,
        isLoading: false,
        toasterDetails: action?.payload?.response,
      };
    },
    programThemesFailure(state, action) {
      return {
        ...state,
        isLoading: false,
        error: action?.payload?.response,
        toasterDetails: action?.payload?.response,
      };
    },
    makeToasterNull(state) {
      return {
        ...state,
        toasterDetails: null,
      };
    },
    reset(state) {
      return {
        ...state,
        data: null,
        error: "",
        success: "",
      };
    },
  },
});

const { reducer } = programThemesRedux;
export default reducer;
