import { ChangeEvent } from "react";
import { countryCodeJson } from "./json/countryCode";

const isEmailValid = () => {
  const regXFrientor = new RegExp(/^[a-zA-Z0-9._%+-]+@frientor\.io/);
  return regXFrientor;
};

const isEmpty = (str: string) => {
  return str.length === 0 ? true : false;
};

const isOnlyNumberAllowed = (number: string) => {
  const regX = new RegExp(/^\d*[.]?\d*$/);
  return regX.test(number.toString());
};

// export const handlePhoneNumberFieldChange =
//   (setFieldValue: any, dialCode?: any) =>
//   (event: ChangeEvent<HTMLInputElement>) => {
//     let maxLength = countryCodeJson.filter(
//       (element) => element.dial_code === dialCode
//     )[0].pLength;
//     if (event.target.value.split("").length <= maxLength) {
//       if (isOnlyNumberAllowed(event.target.value)) {
//         setFieldValue(`${event.target.name}`, event.target.value);
//       }
//     }
//   };

  export const handlePhoneNumberFieldChange =
  (setFieldValue: any, dialCode?: any) => (event: any) => {
    // let keyPressed = event?.nativeEvent.inputType;
    // let maxLength = countryCodeJson.filter(
    //   (element) => element.dial_code === dialCode
    // )[0].pLength;
    // if (
    //   // event.target.value.split("").length <= maxLength ||
    //   keyPressed === "deleteContentBackward" ||
    //   keyPressed === "deleteContentForward"
    // ) {
      if (isOnlyNumberAllowed(event.target.value)) {
        setFieldValue(`${event.target.name}`, event.target.value);
      }
    // }
  };

export const handleNumberFieldChange =
  (setFieldValue: any) => (event: ChangeEvent<HTMLInputElement>) => {
    if (isOnlyNumberAllowed(event.target.value)) {
      setFieldValue(`${event.target.name}`,Number(event.target.value));
    }
  };

export const handleRestrictLength =
  (setFieldValue: any, length: number) =>
  (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length <= length) {
      setFieldValue(`${event.target.name}`, event.target.value);
    }
  };

export const isValidURL = (url: string | undefined) => {
  if (!url) return false;

  const regX = new RegExp(
    /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/
  );
  return regX.test(url);
};

export const checkPhoneNumberWithCountrycode = (
  dialCode: string,
  phNumberLength: number
) => {
  let countrycodeDetails = countryCodeJson.filter(
    (element) => element.dial_code === dialCode
  );
  if (countrycodeDetails.length === 0) return false;
  if (countrycodeDetails[0].pLength !== phNumberLength) return false;

  return true;
};

export { isEmpty, isOnlyNumberAllowed, isEmailValid };
