import { createSlice } from "@reduxjs/toolkit";
import { AttachmentTypeEnum } from "../../enumerations/FileUploadMIMETypeEnum";

export interface IFileUploadS3Redux {
  isLoading?: boolean;
  error?: string;
  success?: string;
  data?: any;
  multiAttachment?: any;
  singleImageUpload?: any;
  fileUpadtedInS3?: false;
  editedAttachment?: any;
  isDeleted?: boolean;
  profileFileUpadtedInS3?: boolean
  updatedProfileUrl?: any
}

const initialState: IFileUploadS3Redux = {
  isLoading: false,
  error: "",
  success: "",
  data: null,
  fileUpadtedInS3: false,
  multiAttachment: null,
  singleImageUpload: "",
  editedAttachment: null,
  isDeleted: false,
  profileFileUpadtedInS3: false,
  updatedProfileUrl: "",
};

export const FileUploadS3Redux = createSlice({
  name: "fileUploadS3Redux",
  initialState,
  reducers: {
    // add new create theme question
    fileUploadS3Request: (state: any, action: any) => {
      const newState = {
        ...state,
        isLoading: true,
      };
      // dispatchEvent({type:"",payload:{}})
      return newState;
    },
    fileUploadS3Success(state: any, action: any) {
      const newState = {
        ...state,
        isLoading: false,
        singleImageUpload: action.payload.url.split("?")[0],
      };
      return newState;
    },
    fileUploadS3Failure: (state: any, action: any) => {
      const newState = {
        ...state,
        isLoading: false,
        toasterDetails: action?.payload,
      };
      return newState;
    },
    //delete program theme question
    fileUploadS3DeleteRequest: (state: any) => {
      const newState = {
        ...state,
        isLoading: true,
      };
      return newState;
    },
    fileUploadS3DeleteSuccess: (state: any, action: any) => {
      const newState = {
        ...state,
        isLoading: false,
        toasterDetails: action?.payload,
      };
      return newState;
    },
    fileUploadS3DeleteFailure: (state: any, action: any) => {
      const newState = {
        ...state,
        isLoading: false,
        error: action?.payload,
        toasterDetails: action?.payload,
      };
      return newState;
    },

    //upaloading multiple files
    multiFileUploadRequest: (state: any, action: any) => {
      const newState = {
        ...state,
        isLoading: true,
      };
      return newState;
    },
    multiFileUploadSuccess: (state: any, action: any) => {
      const questionUploadResBody = action?.payload?.map((item: any) => {
        return {
          attachmentType: AttachmentTypeEnum.Other,
          contentType: "1001",
          publicUrl: item?.url,
          fileName: item?.name,
          caption: item?.caption,
        };
      });

      const newState = {
        ...state,
        isLoading: false,
        multiAttachment: questionUploadResBody,
      };
      return newState;
    },

    multiFileUploadFailure: (state: any, action: any) => {
      const newState = {
        ...state,
        isLoading: true,

      };
    },

    multiFileUpdateRequest: (state: any, action: any) => {
      const newState = {
        ...state,
        isLoading: true,
      };
      return newState;
    },

    multiFileUpdateSuccess: (state: any, action: any) => {
      const newState = {
        ...state,
        isLoading: false,
        editedAttachment: action?.payload
      };
      return newState;
    },

    multiFileUpdateFailure: (state: any, action: any) => {
      const newState = {
        ...state,
        isLoading: false,
        editedAttachment: action?.payload
      };
      return newState;
    },

    //updated the question
    fileUploadS3UpdateRequest: (state: any, action: any) => {
      const newState = {
        ...state,
        isLoading: true,
      };
      return newState;
    },
    fileUploadS3UpdateSuccess: (state: any, action: any) => {
      const newState = {
        ...state,
        isLoading: false,
        fileUpadtedInS3: true,
        toasterDetails: action?.payload,
      };
      return newState;
    },
    fileUploadS3UpdateFailure: (state: any, action: any) => {
      const newState = {
        ...state,
        isLoading: false,
        error: action?.payload,
        toasterDetails: action?.payload,
      };
      return newState;
    },

    setFileUploadS3Update: (state: any, action: any) => {
      const newState = {
        ...state,
        [action.payload.key]: action.payload.value,
      };
      return newState;
    },
    setFileUploadS3UpdateNull: (state: any) => {
      const newState = {
        ...state,
        toasterDetails: null,
      };
      return newState;
    },

    deleteAttachmentByIdRequest: (state: any, action: any) => {
      const newState = {
        ...state,
        isLoading: true,
      };
      return newState;
    },

    deleteAttachmentByIdSuccess: (state: any, action: any) => {
      const newState = {
        ...state,
        isLoading: false,
        isDeleted: true,
        toasterDetails: action?.payload,
      };
      return newState;
    },

    deleteAttachmentByIdFailure: (state: any, action: any) => {
      const newState = {
        ...state,
        isLoading: false,
        error: action?.payload,
        toasterDetails: action?.payload,
      };
      return newState;
    },

    // profileFileUpdateS3Saga

    profileFileUpdateS3Request: (state: any, action: any) => {
      const newState = {
        ...state,
        isLoading: true,
      };
      return newState;
    },
    profileFileUpdateS3Success: (state: any, action: any) => {
      const newState = {
        ...state,
        isLoading: false,
        profileFileUpadtedInS3: true,
        updatedProfileUrl: action.payload.url.split("?")[0],
        // toasterDetails: action?.payload,
      };
      return newState;
    },
    profileFileUpdateS3Failure: (state: any, action: any) => {
      const newState = {
        ...state,
        isLoading: false,
        error: action?.payload,
        // toasterDetails: action?.payload,
      };
      return newState;
    },


  },
});

const { actions, reducer } = FileUploadS3Redux;

export const {
  fileUploadS3Request,
  fileUploadS3Success,
  fileUploadS3Failure,

  //on delete
  fileUploadS3DeleteRequest,
  fileUploadS3DeleteSuccess,
  fileUploadS3DeleteFailure,

  //on update
  fileUploadS3UpdateRequest,
  fileUploadS3UpdateSuccess,
  fileUploadS3UpdateFailure,

  //multiple

  multiFileUploadRequest,
  multiFileUploadSuccess,
  multiFileUploadFailure,

  multiFileUpdateRequest,
  multiFileUpdateSuccess,
  multiFileUpdateFailure,

  // Delete attachment by ID

  deleteAttachmentByIdRequest,
  deleteAttachmentByIdSuccess,
  deleteAttachmentByIdFailure,

  profileFileUpdateS3Request,
  profileFileUpdateS3Success,
  profileFileUpdateS3Failure,

  setFileUploadS3Update,
  setFileUploadS3UpdateNull,
} = actions;

export default reducer;
