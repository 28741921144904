import { Alert } from "@mui/material";
import styles from "./ErrorAlert.module.css";

interface Props {
  alertText?: string;
  errorArray?: string[];
}
const ErrorAlert = ({ alertText, errorArray }: Props) => {
  return (
    <Alert
      variant="outlined"
      severity="error"
      className={styles["alert-container"]}
      sx={{
        backgroundColor:'var(--light-red-color) !important',
        "& .MuiAlert-message": {
          fontSize: "var(--font-180-rem)",
        },
      }}
    >
      {errorArray?.length === 0
        ? null
        : errorArray?.map((error, index) => <p key={index}>{index+1}.{" "}{error}</p>)}
    </Alert>
  );
};

export default ErrorAlert;
