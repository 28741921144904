import { styled, TablePagination } from "@mui/material";

interface PaginationProps {
  varient: string;
}

const MuiTablePaginationWrapper = styled(TablePagination)<PaginationProps>(
  ({ theme, varient }) => ({
    marginRight: "80px",
    "&& .MuiToolbar-root": { display: "flex" },
    "&& .MuiInputBase-root": {
      width: "95px",
      border: "1px solid #b6b8ce",
      flex: "7%",
      height: "40px",
      backgroundColor: "#fff",
    },
    "&& .MuiSelect-select": {
      paddingRight: "5.3rem !important",
      [theme.breakpoints.down("lg")]: {
        paddingRight: "4.3rem !important",
      },
    },
    "&& .MuiTablePagination-selectLabel": {
      order: 1,
      marginRight: "7%",
      fontFamily: "Urbanist-Regular",
      fontSize: "var(--font-160-rem)",
      color: "#383838",

      [theme.breakpoints.down("xl")]: {
        marginRight: "10%",
      },
    },
    "&& .MuiTablePagination-displayedRows": {
      order: 1,
      marginRight: "4.5%",
      [theme.breakpoints.down("xl")]: {
        marginRight: "6%",
      },
    },
    "&& .MuiTablePagination-actions": {
      order: 1,
      position: "absolute",
      right: "0.5%",
      width: "190px",
      padding: "0",
      margin: "0",
      display: "flex",
      justifyContent: "space-between",
      [theme.breakpoints.down("xl")]: {
        right: "0%",
      },
    },
    "&& .MuiButtonBase-root": {
      cursor: "pointer",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "var(--tertiary-light-gray-color)",
      borderRadius: "6px",
    },
    "&& .MuiButtonBase-root.Mui-disabled": {
      backgroundColor: "#faf9f9",
    },
    "&& .MuiButtonBase-root:hover": {
      opacity: "0.8",
    },
  })
);

export const MuiTablePagination = (props: any) => {
  return <MuiTablePaginationWrapper {...props} />;
};
