import { SagaIterator } from "redux-saga";
import { HttpResponseTypeEnum } from "../../enumerations/HttpResponseTypeEnum";
import { call, put } from "redux-saga/effects";
import { SuperAdminUserManagementRedux } from "../../redux/super-admin-user-management-redux/SuperAdminUserManagementRedux";

export function* getAllUsersByRoleId(api: any, action: any): SagaIterator {
    try {
      const response = yield call(api.getAllUsersByRoleId, action.payload);
      if (response.data.code === HttpResponseTypeEnum.Success) {
        yield put(SuperAdminUserManagementRedux.actions.getAllUsersByRoleIdSuccess(response.data));
      } else {
        yield put(SuperAdminUserManagementRedux.actions.getAllUsersByRoleIdFailure(response.data));
      }
    } catch (error: any) {
      yield put(SuperAdminUserManagementRedux.actions.getAllUsersByRoleIdFailure(error));
    }
  }

  export function* addInviteSuperAdmin(api: any, action: any): SagaIterator {
    try {
      const response = yield call(api.addInviteSuperAdmin, action.payload);
      if (response.status === HttpResponseTypeEnum.Success) {
      
        yield put(SuperAdminUserManagementRedux.actions.addInviteSuperAdminSuccess(response.data));
      } else {
        yield put(SuperAdminUserManagementRedux.actions.addInviteSuperAdminFailure(response.data));
      }
    } catch (error: any) {
      yield put(SuperAdminUserManagementRedux.actions.addInviteSuperAdminFailure(error));
    }
  }