import { SagaIterator } from "redux-saga";
import { call, put } from "redux-saga/effects";
import { HttpResponseTypeEnum } from "../../enumerations/HttpResponseTypeEnum";
import { programAdminRedux } from "../../redux/entity-management/ProgramAdminRedux";

export default function* deleteProgramAdminSaga(
  api: any,
  action: any
): SagaIterator {
  try {
    const response = yield call(api.deleteProgramAdmins, action?.payload);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(
        programAdminRedux.actions.deleteProgramAdminSuccess({
          data: response.data,
          fetched: false,
        })
      );
    } else {
      yield put(
        programAdminRedux.actions.deleteProgramAdminFailure({
          data: response.data,
          fetched: false,
        })
      );
    }
  } catch (e) {
    yield put(programAdminRedux.actions.deleteProgramAdminFailure(e));
  }
}
