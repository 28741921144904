import { SagaIterator } from "redux-saga";
import { call, put } from "redux-saga/effects";
import { HttpResponseTypeEnum } from "../../enumerations/HttpResponseTypeEnum";
import { FileUploadS3Redux } from "../../redux/s3-upload/FileUploadS3Redux";

export function* fileUploadS3Saga(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.getSignedUrlFileUploadToS3, action.payload);
    if (response.data.code == HttpResponseTypeEnum.Success) {
      const uploadResponse = yield call(api.uploadeFileToS3, {
        url: response.data.url,
        blob: action.payload.binary,
        contentType: action.payload.type,
        ...action.payload,
      });
      if (uploadResponse.status === HttpResponseTypeEnum.Success) {
        yield put(
          FileUploadS3Redux.actions.fileUploadS3Success(uploadResponse)
        );
      } else {
        yield put(
          FileUploadS3Redux.actions.fileUploadS3Failure(uploadResponse)
        );
      }
    } else {
      yield put(FileUploadS3Redux.actions.fileUploadS3Failure(response.data));
    }
  } catch (error: any) {
    yield put(FileUploadS3Redux.actions.fileUploadS3Failure(error));
  }
}

export function* multipleFileUploadS3Saga(api: any, action: any): SagaIterator {
  try {
    const response = yield call(
      api.getSignedURLmultipleFileUploadToS3,
      action.payload
    );
    if (response) {
      const reqBody = [];
      for (let i = 0; i < action?.payload?.length; i++) {
        let item = action?.payload[i];
        const presignedUrl = response[i]?.value?.data?.url;
        if (presignedUrl) {
          reqBody.push({
            url: presignedUrl,
            blob: item?.binary,
            contentType: item.type,
          });
        }
      }
      const uploadResponse = yield call(api.multipleFileUpload, reqBody);
      if (uploadResponse.length > 0) {
        const urlResponse: any = [];
        for (let i = 0; i < action?.payload?.length; i++) {
          let item = action.payload[i];
          const presignedUrl = response[i]?.value?.data?.url;
          if (presignedUrl) {
            urlResponse.push({
              url: presignedUrl?.split("?")[0],
              blob: item.binary,
              contentType: item?.file?.type,
              ...item,
            });
          }
        }
        yield put(
          FileUploadS3Redux.actions.multiFileUploadSuccess(urlResponse)
        );
      } else {
        yield put(
          FileUploadS3Redux.actions.multiFileUploadFailure(uploadResponse?.data)
        );
      }
    } else {
      yield put(
        FileUploadS3Redux.actions.multiFileUploadFailure(response?.data)
      );
    }
  } catch (error: any) {
    yield put(FileUploadS3Redux.actions.multiFileUploadFailure(error));
  }
}

export function* multiFileUpdateSaga(api: any, action: any): SagaIterator {
  try {
    const response = yield call(
      api.getUpdateSignedMultiFileUpload,
      action.payload
    );
    if (response.length > 0) {
      let reqBody = [];
      for (let i = 0; i < action?.payload?.length; i++) {
        let item = action.payload[i];
        const preSignedUrl = response[i]?.value?.data?.url;
        if (preSignedUrl) {
          reqBody.push({
            ...item,
            url: preSignedUrl,
            blob: item?.binary,
            contentType: item.type,
          });
        }
      }
      const updateImageResponse = yield call(
        api.multipleUpdateUrlInS3,
        reqBody
      );
      if(updateImageResponse){
      const updateMultiAttachment=  yield call(api.multipleUpdateAttachment,updateImageResponse,action?.payload)
      if(updateMultiAttachment){
      yield put(FileUploadS3Redux.actions.multiFileUpdateSuccess(updateMultiAttachment))
      }
      }else{
        yield put(FileUploadS3Redux.actions.multiFileUpdateFailure(updateImageResponse))
      }
    }
  } catch (error:any) {
    yield put(FileUploadS3Redux.actions.multiFileUpdateFailure(error))

  }
}

export function* fileUpdateS3Saga(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.getUpdatedPresignedUrl, action.payload);
    if (response.status === HttpResponseTypeEnum.Success) {
      const updateSingleFile = yield call(api.updateFileS3, {
        url: response?.data?.url,
        blob: action.payload.binary,
        contentType: action.payload.type,
        ...action.payload,
      });
      if (updateSingleFile.status == HttpResponseTypeEnum.Success) {
        const reqBody = [{
          attachmentId: action?.payload?.attachmentId,
          attachmentType: action?.payload?.attachmentType,
          contentType: action?.payload?.contentType,
          publicUrl:  response.data.url.split("?")[0],
          fileName: action.payload.name,
          programThemeId: action?.payload?.programThemeId,
        }];
        const updateAttchments = yield call(api.updateAttchment, reqBody);
        if (updateAttchments.data.code === HttpResponseTypeEnum.Success) {
          yield put(
            FileUploadS3Redux.actions.fileUploadS3UpdateSuccess(
              updateAttchments.data
            )
          );
        } else {
          yield put(
            FileUploadS3Redux.actions.fileUploadS3UpdateFailure(
              updateAttchments?.data
            )
          );
        }
      } else {
        yield put(
          FileUploadS3Redux.actions.fileUploadS3UpdateFailure(response?.data)
        );
      }
    } else {
      yield put(
        FileUploadS3Redux.actions.fileUploadS3UpdateFailure(response?.data)
      );
    }
  } catch (error: any) {
    yield put(FileUploadS3Redux.actions.fileUploadS3UpdateFailure(error));
  }
}

export function* deleteAttachmentById(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.deleteAttachmentById, action.payload);    
    if (response.data.code === HttpResponseTypeEnum.Success) {
      yield put(FileUploadS3Redux.actions.deleteAttachmentByIdSuccess(response?.data));
    } else {
      yield put(FileUploadS3Redux.actions.deleteAttachmentByIdFailure(response.data));
    }
  } catch (error:any) {
    yield put(FileUploadS3Redux.actions.deleteAttachmentByIdFailure(error));
  }
}


export function* profileFileUpdateS3Saga(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.getUpdatedPresignedUrl, action.payload);
    if (response.data.code == HttpResponseTypeEnum.Success) {
      const uploadResponse = yield call(api.updateFileS3, {
        url: response.data.url,
        blob: action.payload.binary,
        contentType: action.payload.type,
        
        ...action.payload,
      });
      if (uploadResponse.status === HttpResponseTypeEnum.Success) {
        yield put(
          FileUploadS3Redux.actions.profileFileUpdateS3Success(uploadResponse)
        );
      } else {
        yield put(
          FileUploadS3Redux.actions.profileFileUpdateS3Failure(uploadResponse)
        );
      }
    } else {
      yield put(FileUploadS3Redux.actions.profileFileUpdateS3Failure(response.data));
    }
  } catch (error: any) {
    yield put(FileUploadS3Redux.actions.profileFileUpdateS3Failure(error));
  }
}
