import { createContext, useCallback, useEffect, useState } from "react";
import Snackbar, { SnackbarOrigin } from "@mui/material/Snackbar";
import { Alert, AlertTitle, Box, styled, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setToasterNull } from "../redux/toaster/ToasterRedux";

interface IErrorHandling {
  error?: boolean | string | any;
  message?: string;
  status?: number;
  addToToast?: (() => void) | undefined;
}

const defaultState = {
  error: false,
};

const AlertWrapper = styled(Box)(({ theme }) => ({
  "&&& .MuiAlertTitle-root ": {
    fontSize: "1.8rem",
    fontWeight: 700,
  },
  "&& .MuiPaper-root": {
    borderRadius: "12px",
  },
  ".toaster-message": {
    fontWeight: 600,
    fontSize: "1.5rem",
    textTransform: "capitalize",
  },
}));

export const ToastProvider = (props: any) => {
  const dispatch = useDispatch();
  const { toaster } = useSelector((state: any) => state);

  const { toasterDetails } = toaster;

  useEffect(() => {
    let timer: any;
    if (toasterDetails) {
      timer = setTimeout(() => dispatch(setToasterNull()), 4000);
    }
    return () => clearTimeout(timer);
  }, [toasterDetails]);

  return (
    <Box id="toasterroot">
      {props.children}
      {toasterDetails?.code && (
        <Snackbar
          autoHideDuration={5000}
          open={toasterDetails?.toasterOpen ? true : false}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <AlertWrapper>
            <Alert
              severity={
                toasterDetails?.code === 200
                  ? "success"
                  : toasterDetails?.code == 400
                  ? "error"
                  : undefined
              }
              sx={{ width: "100%" }}
              variant="filled"
            >
              <AlertTitle>
                {toasterDetails?.code === 200
                  ? "Success"
                  : toasterDetails?.code === 400
                  ? "Error"
                  : null}
              </AlertTitle>
              <div className="toaster-message"> {toasterDetails?.message}</div>
            </Alert>
          </AlertWrapper>
        </Snackbar>
      )}
    </Box>
  );
};
