import { createSlice } from "@reduxjs/toolkit";

export interface LoginState {
  isLoading?: boolean;
  error?: string;
  isSuccess?: boolean;
  data?: any;
  isLoggedIn?: boolean;
  userObject?: any;
  profileDetails?:any;
}

const initialState: LoginState = {
  isLoading: false,
  error: "",
  isSuccess: false,
  data: null,
  isLoggedIn: false,
  userObject: {},
  profileDetails:{},
};

export const LoginRedux = createSlice({
  name: "loginRedux",
  initialState,
  reducers: {
    loginRequest: (state: any, action: any) => {
      const newState = {
        ...state,
        isLoading: true,
      };
      return newState;
    },
    loginSuccess: (state: any, action: any) => {
      return {
        ...state,
        isLoggedIn: true,
        error: null,
        isLoading: false,
      };
    },
    loginFailure: (state: any, action: any) => {
      return {
        ...state,
        isLoading: false,
        error: action?.error?.data?.message
          ? action?.error?.data?.message
          : "Check Your Network!",
      };
    },
    setLoginState: (state: any, action: any) => {
      const newState = {
        ...state,
      };
      newState[action.payload.key] = action.payload.value;
      return newState;
    },

    updateMyProfileRequest: (state: any, action: any) => {
      const newState = {
        ...state,
        isLoading: true,
      };
      return newState;
    },

    updateMyProfileSuccess: (state: any, action: any) => {
      return {
        ...state,
        error: null,
        isLoading: false,
        isSuccess: true,
      };
    },

    updateMyProfileFailure: (state: any, action: any) => {
      return {
        ...state,
        isLoading: false,
        error: action?.payload?.message,
      };
    },

    getUserDetailsByEmailRequest: (state: any, action: any) => {
      const newState = {
        ...state,
        isLoading: true,
      };
      return newState;
    },

    getUserDetailsByEmailSuccess: (state: any, action: any) => {
      return {
        ...state,
        error: null,
        isLoading: false,
        userObject: action?.payload,
      };
    },

    getUserDetailsByEmailFailure: (state: any, action: any) => {
      return {
        ...state,
        isLoading: false,
        error: action?.payload?.message,
      };
    },

    getProfileInfoRequest : (state:any) => {
      const newState = {
        ...state,
        isLoading: true,
      };
      return newState;
    },

    getProfileInfoSuccess : (state:any, action:any) => {
      return {
        ...state,
        error: null,
        isLoading: false,
        profileDetails: action?.payload,
      };
    },
    getProfileInfoFailure: (state: any, action: any) => {
      return {
        ...state,
        isLoading: false,
        error: action?.payload?.message,
      };
    },
  },
});

const { actions, reducer } = LoginRedux;
export const {
  loginRequest,
  loginFailure,
  loginSuccess,
  setLoginState,
  updateMyProfileRequest,
  updateMyProfileSuccess,
  updateMyProfileFailure,
  getUserDetailsByEmailRequest,
  getUserDetailsByEmailSuccess,
  getUserDetailsByEmailFailure,
  getProfileInfoRequest,
  getProfileInfoSuccess,
  getProfileInfoFailure,
} = actions;

export default reducer;
