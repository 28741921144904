import { createSlice } from "@reduxjs/toolkit";

interface IOnBoardedEntities {
  isLoading?: boolean;
  error?: string;
  success?: boolean;
  data?: any;
  toasterDetails?: any;
}

const initialState: IOnBoardedEntities = {
  isLoading: false,
  error: "",
  success: false,
  data: null,
  toasterDetails: null,
};

export const onBoardedEntitiesRedux = createSlice({
  name: "onBoardedEntities",
  initialState,
  reducers: {
    setOnBoardedEntitiesState: (state: any, action: any) => {
      const newState = {
        ...state,
      };
      newState[action.payload.key] = action.payload.value;
      return newState;
    },
    addOnBoardedEntitiesRequest: (state, action) => {
      const newState = {
        ...state,
        isLoading: true,
      };
      return newState;
    },
    addOnBoardedEntitiesSuccess: (state, action) => {
      const newState = {
        ...state,
        isLoading: false,
        data: action?.payload?.response,
        // toasterDetails: action?.payload,
      };
      return newState;
    },
    addOnBoardedEntitiesFailure: (state, action) => {
      const newState = {
        ...state,
        isLoading: false,
        error: action?.payload.response,
        toasterDetails: action.payload?.message?.["Validation Error"]
          ? {
              code: 400,
              message: Object.values(
                action.payload?.message?.["Validation Error"][0]
              )[0],
            }
          : action?.payload,
      };
      return newState;
    },
    makeEntityAsOnboardedRequest: (state, action) => {
      const newState = {
        ...state,
        data: null,
        isLoading: true,
      };
      return newState;
    },
    makeEntityAsOnboardedSuccess: (state, action) => {
      const newState = {
        ...state,
        isLoading: false,
        data: action?.payload?.response,
        toasterDetails: action?.payload,
        success:true,
      };
      return newState;
    },
    makeEntityAsOnboardedFailure: (state, action) => {
      const newState = {
        ...state,
        isLoading: false,
        data: null,
        toasterDetails: action?.payload,
      };
      return newState;
    },
    makeToasterNull(state) {
      return {
        ...state,
        toasterDetails: null,
      };
    },
    reset(state) {
      return {
        ...state,
        data: null,
        error: "",
        success: false,
      };
    },
  },
});
const { reducer, actions } = onBoardedEntitiesRedux;
export const {
  setOnBoardedEntitiesState,
  addOnBoardedEntitiesRequest,
  addOnBoardedEntitiesSuccess,
  addOnBoardedEntitiesFailure,
  makeEntityAsOnboardedRequest,
  makeEntityAsOnboardedSuccess,
  makeEntityAsOnboardedFailure,
  makeToasterNull,
  reset,
} = actions;
export default reducer;
