import React, { useState } from "react";
import { TableCell, TableRow, Box, TextField } from "@mui/material";
import { LocalizationProvider, DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { StringConstant } from "../../../resources/strings";
import MuiButton from "../../../components/ui-elements/mui-button/MuiButton";
import styles from "../create-entity/CreateEntity.module.css";
import ConfirmationDialog from "../../../components/confirmation-dialog/ConfirmationDialog";
import {
  associatedThemeEntitiesRedux,
  associateThemeEntitiesRequest,
} from "../../../redux/entity-management/AssociatedThemeEntitiesRedux";
import { useDispatch } from "react-redux";

interface Props {
  theme: any;
  status?: number;
  handleModal?: (event: React.MouseEvent<HTMLElement>, statusId: any) => void;
  active?: boolean;
  entityId: string;
  setIsAssociated: (data: boolean) => void;
}

const ThemeRows = ({
  theme,
  active,
  entityId,
  setIsAssociated,
  status,
}: Props) => {
  const dispatch = useDispatch();
  const [isConfirmModelOpen, setisConfirmModelOpen] = useState<boolean>(false);
  const [statusOfTheme, setStatusOfTheme] = useState<number>(1);

  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const row: { statusId: number } = { statusId: 3 }; //For now row is empty object

  const handleClose = () => setisConfirmModelOpen(!isConfirmModelOpen);
  const handleModal = (
    event: React.MouseEvent<HTMLElement>,
    statusId: number
  ) => {
    setisConfirmModelOpen(true);
    setStatusOfTheme(statusId);
  };

  const handleSubmit = () => {
    const unmappinObject = {
      entityId: entityId,
      programThemeId: theme?.id || theme?.programThemeId,
      isAssociationActive: !active,
    };
    let mappingObject: any;
    if (!active) {
      mappingObject = {
        ...unmappinObject,
        startDate: startDate,
        endDate: endDate,
      };
    }
    const requestObject = !active ? mappingObject : unmappinObject;
    dispatch(associateThemeEntitiesRequest([requestObject]));
    setIsAssociated(true);
    handleClose();
  };
  return (
    <TableRow sx={{ mt: 2 }}>
      <TableCell>{theme.name}</TableCell>
      <TableCell>
        {!active && (
          <Box width="90%">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                value={startDate}
                minDate={new Date()}
                disablePast
                inputFormat={"DD-MM-YYYY"}
                onChange={(newValue) => {
                  setStartDate(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Box>
        )}
        {active && dayjs(theme.startDate).format("DD-MM-YYYY")}
      </TableCell>
      <TableCell>
        {!active && (
          <Box width="90%">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                value={endDate}
                disablePast
                minDate={startDate}
                inputFormat={"DD-MM-YYYY"}
                // maxDate={dayjs(startDate).add(30, "days")}
                onChange={(newValue) => {
                  setEndDate(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Box>
        )}
        {active && dayjs(theme.endDate).format("DD-MM-YYYY")}
      </TableCell>
      <TableCell>
        <MuiButton
          variant={`${!active ? "contained" : "outlined"}`}
          className={`${styles["entity-form-btn"]} ${styles["remove"]} `}
          onClick={(event: React.MouseEvent<HTMLElement>) =>
            handleModal(event, row.statusId)
          }
          disabled={active ? false : endDate && startDate ? false : true}
        >
          {!active ? StringConstant.add : StringConstant.remove}
        </MuiButton>
      </TableCell>

      <ConfirmationDialog
        isConfirmModelOpen={isConfirmModelOpen}
        handleClose={handleClose}
        handleConfirm={handleSubmit}
        title={
          !active
            ? StringConstant.AddConfirmation
            : StringConstant.RemoveConfirmation
        }
        contentText={
          !active
            ? StringConstant.AddEntityThemeContent
            : StringConstant.RemoveEntityThemeContent
        }
        leftButtonText={StringConstant.leftButtonText}
        rightButtonText={StringConstant.rightButtonText}
      />
    </TableRow>
  );
};

export default ThemeRows;
