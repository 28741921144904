import { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import styles from "../create-entity/CreateEntity.module.css";
import { EntityThemeColumns } from "../../../utils/json/EntityThemeJson";
import ThemeRows from "./ThemeRows";
import { useDispatch, useSelector } from "react-redux";
import { fetchAssociatedThemeEntitiesRequest } from "../../../redux/entity-management/AssociatedThemeEntitiesRedux";
import PageLoader from "../../../components/page-loader/PageLoader";
interface Props {
  entityId?: any;
  setIsEntityHasThemes?: (theme: boolean) => void;
}
const  EntityTheme = ({ entityId, setIsEntityHasThemes }: Props) => {
  const [isAssociated, setIsAssociated] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const associatedThemesForEntity = useSelector(
    (state: any) => state.associatedThemeEntities
  );

  let mappingDone = associatedThemesForEntity?.mappingDone;

  const actionDispatch = (dispatch: any) => ({
    getAssociatedThemeEntitiesRequest: (entityId: any) =>
      dispatch(fetchAssociatedThemeEntitiesRequest(entityId)),
  });

  const { getAssociatedThemeEntitiesRequest } = actionDispatch(useDispatch());

  useEffect(() => {
    entityId && getAssociatedThemeEntitiesRequest(entityId);
  }, []); // initial fetching after mounted

  useEffect(() => {
    if (isAssociated) {
      getAssociatedThemeEntitiesRequest(entityId);
    }
    setIsAssociated(false);
  }, [mappingDone]); // fetching after mapping or unmapping happened

  const associatedThemes = associatedThemesForEntity?.data
    ? associatedThemesForEntity?.data?.activeProgramTheme
    : [];

  const otherThemes = associatedThemesForEntity?.data
    ? associatedThemesForEntity?.data?.otherThemes
    : [];

  const expiredThemes = associatedThemesForEntity?.data
    ? associatedThemesForEntity?.data?.expiredProgramThemes
    : [];

  useEffect(() => {
    if (associatedThemes?.length > 0 && setIsEntityHasThemes) setIsEntityHasThemes(true);
    if (associatedThemes?.length === 0 && setIsEntityHasThemes) setIsEntityHasThemes(false)
  }, [associatedThemes?.length]);

  useEffect(() => {
    setLoading(associatedThemesForEntity?.isLoading);
  }, [associatedThemesForEntity?.isLoading]);

  return (
    <div>
      {loading ? (
        <div className={"loading-container"}>
          <PageLoader />
        </div>
      ) : null}
      <TableContainer className={styles["table-container"]}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {EntityThemeColumns.map((EntityThemeColumns, index) => (
                <TableCell
                  key={index}
                  align={"left"}
                  style={{ minWidth: EntityThemeColumns.minWidth }}
                >
                  {EntityThemeColumns.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <tr>
              <td
                className={styles["entity-theme-row-heading"]}
              >{`Expired Theme(s) (${(expiredThemes || []).length})`}</td>
            </tr>
            {(expiredThemes || []).map((theme: any, index: any) => {
              return (
                <ThemeRows
                  theme={theme}
                  key={index}
                  status={1}
                  active={false}
                  entityId={entityId}
                  setIsAssociated={setIsAssociated}
                />
              );
            })}
            <tr>
              <td
                className={styles["entity-theme-row-heading"]}
              >{`Active Theme(s) (${(associatedThemes || []).length})`}</td>
            </tr>
            {(associatedThemes || []).map((theme: any, index: any) => {
              return (
                <ThemeRows
                  theme={theme}
                  key={index}
                  status={2}
                  active={true}
                  entityId={entityId}
                  setIsAssociated={setIsAssociated}
                />
              );
            })}
            <tr>
              <td
                className={styles["entity-theme-row-heading"]}
              >{`Other Theme(s) (${(otherThemes || []).length})`}</td>
            </tr>
            {(otherThemes || []).map((theme: any, index: any) => {
              return (
                <ThemeRows
                  theme={theme}
                  key={index}
                  // row={row}
                  active={false}
                  entityId={entityId}
                  setIsAssociated={setIsAssociated}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default EntityTheme;
