import { Typography } from "@mui/material";
import React from "react";
import PageLoader from "../page-loader/PageLoader";
import MuiButton from "../ui-elements/mui-button/MuiButton";
import { NoItemFoundWrapper } from "./NoDataFound.style";

interface noItemFound {
  textMesaage?: string;
  buttonText?: string;
  handleClick?: () => void;
  navImage?: string;
  loading?: boolean;
}

const NoItemFound = (props: noItemFound) => {
  const { textMesaage, buttonText, handleClick, navImage, loading } = props;
  return loading ? (
    <PageLoader />
  ) : (
    <NoItemFoundWrapper>
      <div className="menu-image-background">
        <img src={navImage} className="menu-image" />
      </div>
      <Typography  mt={2} variant="body1">{textMesaage}</Typography>
      <MuiButton
        onClick={handleClick}
        variant={"contained"}
        sx={{ mt: "20px" }}
      >
        {buttonText}
      </MuiButton>
    </NoItemFoundWrapper>
  );
};

export default NoItemFound;
