import React, { Fragment, useEffect, useState } from "react";
import PageHeader from "../../../components/page-heading/PageHeading";
import { Grid } from "@mui/material";
import { StringConstant } from "../../../resources/strings";
import { Constants } from "../../../resources/constants";
import SideBar from "../../../components/secondary-sidemenu/SecondarySidemenu";
import CreateContainerWrapper from "../../../components/secondary-form-field-container/SecondaryFormFieldContainer";
import FormFieldWrapper from "../../../components/secondary-form-field-inner-container/SecondaryFormFieldInnerContainer";
import FormBottomButtons from "../../../components/form-bottom-btns/FormBottomButtons";
import ThemeGuidelines from "../create-theme-forms/ThemeGuidelines";
import ProgramThemesBasicInfo from "../create-theme-forms/ProgramThemesBasicInfo";
import ThemePreview from "../create-theme-forms/ThemePreview";
import ThemeQuestions from "../create-theme-forms/theme-questions/ThemeQuestions";
import ProgramThemeListOfQuestion from "../create-theme-forms/theme-questions/ThemeQuestionsList";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import { RouteConfigurations } from "../../../routes/RouteConfigurations";

const sideBarArray = [
  { id: 1, name: "Basic Info" },
  { id: 2, name: "Theme Questions" },
  { id: 3, name: "Theme Guidelines" },
  { id: 4, name: "Review & Publish" },
];
interface IProgramThemesBasicIno {
  tab?: number;
  handleTabPrev?: (event: any) => void;
  handleTabNext: (event: any) => void;
  handleValidMove?: (validMove: boolean) => void;
}

const CreateThemes = () => {
  const [tab, setTab] = useState<number>(
    Constants.number_min_create_entity_tab
  );
  const [isValidMove, setIsValidMove] = useState<boolean>(false);
  const param = useParams();
  const navigate = useNavigate();
  const lastPath = window.location.pathname.split("/");
  const handleTabSideBar = (event: React.MouseEvent<HTMLElement>) => {
    if (lastPath[lastPath.length - 1] == "create-theme-questions") {
      navigate(-1);
      let tabValue = parseInt(
        (event.currentTarget as HTMLElement).dataset?.id || "1"
      );
      setTab(tabValue);
    } else {
      let tabValue = parseInt(
        (event.currentTarget as HTMLElement).dataset?.id || "1"
      );
      setTab(tabValue);
    }
  };

  // useEffect(() => {
  //   window.addEventListener("beforeunload", handleBeforeUnload);
  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, []);

  const handleBeforeUnload = (e: any) => {
    e.preventDefault();
    const message =
      "Are you sure you want to leave? All provided data will be lost.";
    e.returnValue = message;
    return message;
  };

  const handleTabPrev = (value: any) => {
    value > Constants.number_min_create_entity_tab &&
      setTab((value) => value - 1);
  };

  const handleTabNext = (value: number) => {
    value < Constants.number_max_create_entity_tab &&
      setTab((value) => value + 1);
  };
  const handleValidMove = (validMove: boolean) => setIsValidMove(validMove);

  return (
    <Fragment>
      <div>
        <PageHeader
          pageHeading={StringConstant.CreateProgramTheme}
          pageHeaderSubtitle={StringConstant.createProgramThemeSubHeading}
        />
      </div>
      <CreateContainerWrapper>
        <Grid item lg={2} xl={2.2}>
          <SideBar
            tab={tab}
            handleTab={handleTabSideBar}
            sidebarData={sideBarArray}
          />
        </Grid>
        <FormFieldWrapper>
          {tab === 1 && (
            <ProgramThemesBasicInfo
              tab={tab}
              handleTabNext={handleTabNext}
              handleTabPrev={handleTabPrev}
              handleValidMove={handleValidMove}
            />
          )}
          {tab === 2 && (
            <Routes>
              <Route
                path={"/"}
                element={
                  <>
                    <ProgramThemeListOfQuestion
                      tab={tab}
                      handleTabNext={handleTabNext}
                      handleTabPrev={handleTabPrev}
                    />
                  </>
                }
              />
              <Route
                path={RouteConfigurations.themeQuestionCreation}
                element={<ThemeQuestions />}
              />
              {/* <Route
                path={RouteConfigurations.themeQuestionsList}
                element={<ProgramThemeListOfQuestion />}
              /> */}
            </Routes>
          )}
          {tab === 3 && (
            <ThemeGuidelines
              tab={tab}
              handleTabNext={handleTabNext}
              handleTabPrev={handleTabPrev}
              handleValidMove={handleValidMove}
            />
          )}
          {tab === 4 && (
            <ThemePreview
              tab={tab}
              handleTabNext={handleTabNext}
              handleTabPrev={handleTabPrev}
              handleValidMove={handleValidMove}
            />
          )}
          {/* {tab === 4 && (
            <FormBottomButtons
              tab={tab}
              pageId={1}
              handleTabNext={handleTabNext}
              handleTabPrev={handleTabPrev}
            />
          )} */}
        </FormFieldWrapper>
      </CreateContainerWrapper>
    </Fragment>
  );
};

export default CreateThemes;
