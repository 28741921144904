import {
  Box,
  Container,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  MenuList,
  Radio,
  Select,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import { FieldArray, Form, Formik, getIn } from "formik";

import { MuiChipsInput } from "mui-chips-input";

import {
  ActionTypeEnumTextResponse,
  ActionTypeEnumUtilsImageResponse,
  ActionTypeEnumUtilsTextResponse,
  ResponseTypeEnum,
} from "../../../../enumerations/ResponseTypeEnum";
import FormFieldContainer from "../../../../components/form-field-container/FormFieldContainer";
import { StringConstant } from "../../../../resources/strings";
import {
  initialThemeQuestionState,
  themeQuestionsScheme,
} from "./ThemeQuestionStaticData";
import MuiLoadingButton from "../../../../components/ui-elements/mui-loading-button/MuiLoadingButton";
import MuiTextField from "../../../../components/ui-elements/mui-text-field/MuiTextField";
import MediaAssets from "../../../../assets";
import MuiButton from "../../../../components/ui-elements/mui-button/MuiButton";
import ImageUploader from "../../../../components/image-uploader/ImageUploader";
import RadioButtonGroupComponent from "../../../../components/mui-group-radio-button/MuiGroupRadioButton";
import styles from "./ThemeQuestions.module.css";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useLocation, useNavigate } from "react-router-dom";
import MuiRadioButton from "../../../../components/ui-elements/mui-radio-button/MuiRadioButton";
import {
  handleNumberFieldChange,
  handleRestrictLength,
} from "../../../../utils/ValidationUtils";
import { setToaster } from "../../../../redux/toaster/ToasterRedux";
import { useDispatch, useSelector } from "react-redux";
import {
  addProgramThemeQuestionRequest,
  deleteProgramThemeQuestionRequest,
  getEachThemeQuestionRequest,
  setProgramThemeState,
  setProgramThemeToasterNull,
  updateProgramThemeQuestionRequest,
} from "../../../../redux/program-theme/programtheme-question/ProgramThemQuestionRedux";
import { RouteConfigurations } from "../../../../routes/RouteConfigurations";
import {
  multiFileUpdateRequest,
  multiFileUploadRequest,
  setFileUploadS3Update,
} from "../../../../redux/s3-upload/FileUploadS3Redux";
import {
  addAttachmentRequest,
  deleteAttachmentRequest,
  setAttachmentState,
  updateAttachmentRequest,
} from "../../../../redux/attachment/AttachmentRedux";
import {
  AttachmentTypeEnum,
  FileUploadMIMETypeEnum,
} from "../../../../enumerations/FileUploadMIMETypeEnum";
import { UsersRoleTypeEnum } from "../../../../enumerations/UsersRoleTypeEnum";
import ConfirmationDialog from "../../../../components/confirmation-dialog/ConfirmationDialog";
import {
  deleteOnboardingQuestionRequest,
  setOnboardingState,
} from "../../../../redux/onboarding-question/OnboardingQuestionRedux";
import { QuestionEditResponse } from "../../../../utils/QuestionEditResponse";
import { ScrollToError } from "../../../../components/scroll-error/ScrollError";

const ThemeQuestions = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const actionDispatch = (dispatch: any) => ({
    addThemeQuestions: (data: any) =>
      dispatch(addProgramThemeQuestionRequest(data)),
    updateThemeQuestions: (data: any) =>
      dispatch(updateProgramThemeQuestionRequest(data)),
    setThemeNull: () => dispatch(setProgramThemeToasterNull()),
    multiFileUpload: (data: any) => dispatch(multiFileUploadRequest(data)),
    setToasterInfo: (data: any) => dispatch(setToaster(data)),
    setFileUpload: (data: any) => dispatch(setFileUploadS3Update(data)),
    multiFileUpdate: (data: any) => dispatch(multiFileUpdateRequest(data)),
    addAttachment: (data: any) => dispatch(addAttachmentRequest(data)),
    setAttachment: (data: any) => dispatch(setAttachmentState(data)),
    deleteAttachment: (data: any) => dispatch(deleteAttachmentRequest(data)),
    deleteProgramThemeQuestion: (data: any) =>
      dispatch(deleteProgramThemeQuestionRequest(data)),
    updateCaption: (data: any) => dispatch(updateAttachmentRequest(data)),
    setOnboardingQuestion: (data: any) => dispatch(setOnboardingState(data)),
    getEachProgramThemeQuestion: (data: any) =>
      dispatch(getEachThemeQuestionRequest(data)),
    setThemeQuestion: (data: any) => dispatch(setProgramThemeState(data)),
  });

  const {
    addThemeQuestions,
    setThemeNull,
    setToasterInfo,
    updateThemeQuestions,
    multiFileUpload,
    setFileUpload,
    multiFileUpdate,
    addAttachment,
    setAttachment,
    deleteAttachment,
    deleteProgramThemeQuestion,
    updateCaption,
    setOnboardingQuestion,
    getEachProgramThemeQuestion,
    setThemeQuestion,
  } = actionDispatch(useDispatch());

  const {
    programThemeQuestion,
    onboardingQuestion,
    themeQuestion,
    createProgramTheme,
    fileUploader,
    attachment,
  } = useSelector((state: any) => state);
  const actionText =
    ActionTypeEnumUtilsTextResponse.getActionTypeEnumsTextResponse();
  const actionImage =
    ActionTypeEnumUtilsImageResponse.getActionTypeEnumsImageResponse();
  const [chips, setChip] = useState<any>([]);
  // const [value, setValue] = React.useState('');
  const [trailALLData, setTrailAllData] = useState<any>([]);
  const [actionDropDown, setActionDropDown] = useState<any>([]);
  const [captionUpdate, setCaptionUpdate] = useState<boolean>(false);
  const [editQuestion, seteditQuestion] = useState<any>(null);
  const [responseFormat, setResponseFormat] = useState<any>();
  const [responseType, setResponseType] = useState<string>("");
  const [shouldOpenConfirmOnImg, setShouldOpenConfirmOnImg] =
    useState<boolean>(false);
  const [deletedOption, setDeleteOption] = useState<any>([]);
  const [themeQuestionRequestBody, setThemeQuestionRequestBody] =
    useState<any>(null);
  const [imageDeleteIds, setImageDeleteIds] = useState<any>([]);
  const [shouldOpenConfirmatonDialog, setShouldOpenConfirmatonDialog] =
    useState(false);
  const [isNewImageUploaded, setNewImageUploaded] = useState<boolean>(false);
  const [isImageUpdate, setImageUpdate] = useState<boolean>(false);
  const [questionId, setQuestionId] = useState("");
  const [deleteCategoryID, setDeleteCategoryID] = useState<any>([]);
  const [imageResponseDelete, setImageResponseDelete] = useState<any>("");

  useEffect(() => {
    getEachProgramThemeQuestion(location?.state?.id);
    if (location.state) {
      if (location?.state.responseType === +ResponseTypeEnum.Text) {
        setActionDropDown(actionText);
      } else {
        setActionDropDown(actionImage);
      }
    }
    return () => {
      setThemeQuestion({ key: "eachThemeQuestion", value: null });
    };
  }, []);

  useEffect(() => {
    if (programThemeQuestion?.toasterDetails) {
      setToasterInfo({
        key: "toasterDetails",
        value: { ...programThemeQuestion?.toasterDetails, toasterOpen: true },
      });
      setThemeNull();
      if (programThemeQuestion.toasterDetails.code === 200) {
        navigate("/program-themes/create");
      }
    }
    return () => {
      setThemeNull();
    };
  }, [programThemeQuestion?.toasterDetails]);

  // new theme question
  useEffect(() => {
    if (fileUploader?.multiAttachment && !location?.state) {
      addThemeQuestions({
        programThemeId: createProgramTheme?.programThemeId,
        questions: [
          {
            ...themeQuestionRequestBody,
            options: themeQuestionRequestBody.options.map(
              (item: any) => item?.optionName
            ),
            attachments: [...fileUploader?.multiAttachment],
          },
        ],
      });
      setFileUpload({ key: "multiAttachment", value: null });
    }
  }, [fileUploader?.multiAttachment]);

  //update theme question
  useEffect(() => {
    if (fileUploader?.multiAttachment && location?.state) {
      addAttachment({
        questionId: location?.state.id,
        attachments: [...fileUploader?.multiAttachment],
      });
      setFileUpload({ key: "multiAttachment", value: null });
    }
  }, [fileUploader?.multiAttachment]);

  //after editting attachment in question
  // useEffect(() => {
  //   if (
  //     location?.state &&
  //     (fileUploader?.editedAttachment || attachment?.isAttachmentSuccessful)
  //   ) {
  //     updateThemeQuestions({
  //       questionId: location.state.id,
  //       ...editQuestion,
  //     });

  //     setFileUpload({ key: "editedAttachment", value: null });
  //     setAttachment({ key: "isAttachmentSuccessful", value: false });
  //   }
  // }, [editQuestion, attachment.isAttachmentSuccessful,fileUploader?.editedAttachment]);

  useEffect(() => {
    //both upload and editted
    if (isImageUpdate && isNewImageUploaded) {
      if (
        location?.state &&
        fileUploader?.editedAttachment &&
        attachment?.isAttachmentSuccessful
      ) {
        const updateRequestBody = QuestionEditResponse(
          editQuestion,
          deletedOption,
          deleteCategoryID
        );
        updateThemeQuestions({
          ...editQuestion,
          questionId: location.state.id,
          mentorQuestion: editQuestion?.mentorQuestion,
          menteeQuestion: editQuestion?.menteeQuestion,
          options: updateRequestBody?.options,
          topicCategory: updateRequestBody?.topicCategory,
        });

        setFileUpload({ key: "editedAttachment", value: null });
        setAttachment({ key: "isAttachmentSuccessful", value: false });
      }
    }
    // ONLY UPLOAD
    else if (!isImageUpdate && (isNewImageUploaded || captionUpdate)) {
      if (location?.state && attachment?.isAttachmentSuccessful) {
        const updateRequestBody = QuestionEditResponse(
          editQuestion,
          deletedOption,
          deleteCategoryID
        );

        updateThemeQuestions({
          ...editQuestion,
          questionId: location.state.id,
          mentorQuestion: editQuestion?.mentorQuestion,
          menteeQuestion: editQuestion?.menteeQuestion,
          options: updateRequestBody?.options,
          topicCategory: updateRequestBody?.topicCategory,
        });

        setAttachment({ key: "isAttachmentSuccessful", value: false });
        setAttachment({ key: "isAttachmentUpdateSuccess", value: false });
        setCaptionUpdate(false);
      }
    }
    // ONLY UPDATE
    else if (isImageUpdate && !isNewImageUploaded) {
      if (location?.state && fileUploader?.editedAttachment) {
        const updateRequestBody = QuestionEditResponse(
          editQuestion,
          deletedOption,
          deleteCategoryID
        );
        updateThemeQuestions({
          ...editQuestion,
          questionId: location.state.id,
          mentorQuestion: editQuestion?.mentorQuestion,
          menteeQuestion: editQuestion?.menteeQuestion,
          options: updateRequestBody?.options,
          topicCategory: updateRequestBody?.topicCategory,
        });

        setFileUpload({ key: "editedAttachment", value: null });
      }
    }
  }, [
    editQuestion,
    attachment.isAttachmentSuccessful,
    fileUploader?.editedAttachment,
  ]);

  //it is for switching for image type question to text
  useEffect(() => {
    if (
      responseType === ResponseTypeEnum.Text &&
      attachment?.isAttachmentDeleteSuccessful
    ) {
      const updateRequestBody = QuestionEditResponse(
        editQuestion,
        deletedOption,
        deleteCategoryID
      );
      updateThemeQuestions({
        ...editQuestion,
        questionId: location.state.id,
        mentorQuestion: editQuestion?.mentorQuestion,
        menteeQuestion: editQuestion?.menteeQuestion,
        options: updateRequestBody?.options,
        topicCategory: updateRequestBody?.topicCategory,
      });

      setAttachment({ key: "isAttachmentDeleteSuccessful", value: false });
    }
  }, [attachment?.isAttachmentDeleteSuccessful]);
  useEffect(() => {
    if (
      !(responseType === ResponseTypeEnum.Text) &&
      attachment?.isAttachmentDeleteSuccessful
    ) {
      // const updateRequestBody = QuestionEditResponse(editQuestion, deletedOption, deleteCategoryID)
      // updateThemeQuestions({
      //   ...editQuestion,
      //   questionId: location.state.id,
      //   mentorQuestion: editQuestion?.mentorQuestion,
      //   menteeQuestion: editQuestion?.menteeQuestion,
      //   options: updateRequestBody?.options,
      //   topicCategory: updateRequestBody?.topicCategory

      // });
      getEachProgramThemeQuestion(location?.state?.id);
      setShouldOpenConfirmOnImg(false);
      setAttachment({ key: "isAttachmentDeleteSuccessful", value: false });
    }
  }, [attachment?.isAttachmentDeleteSuccessful]);

  useEffect(() => {
    if (onboardingQuestion?.isOnboradingQuestionDeleted) {
      setShouldOpenConfirmatonDialog(false);
      setOnboardingQuestion({
        key: "isOnboradingQuestionDeleted",
        value: "false",
      });
    }
  }, [onboardingQuestion?.isOnboradingQuestionDeleted]);

  // only caption Updated
  useEffect(() => {
    if (attachment?.isAttachmentUpdateSuccess && !captionUpdate) {
      const updateRequestBody = QuestionEditResponse(
        editQuestion,
        deletedOption,
        deleteCategoryID
      );
      updateThemeQuestions({
        ...editQuestion,
        questionId: location.state.id,
        mentorQuestion: editQuestion?.mentorQuestion,
        menteeQuestion: editQuestion?.menteeQuestion,
        options: updateRequestBody?.options,
        topicCategory: updateRequestBody?.topicCategory,
      });
      setAttachment({ key: "isAttachmentUpdateSuccess", value: false });
    }
  }, [attachment?.isAttachmentUpdateSuccess]);

  const editingThemeQuestion = () => {
    const isAnswerMandatory: any =
      programThemeQuestion?.eachThemeQuestion?.isAnswerMandatory;
    const isCustomisable: any =
      programThemeQuestion?.eachThemeQuestion?.isCustomisable;
    const isIdentityQuestion: any =
      programThemeQuestion?.eachThemeQuestion?.isIdentityQuestion;
    const isImpactful: any =
      programThemeQuestion?.eachThemeQuestion?.isImpactful;
    const isQuestionMandatory: any =
      programThemeQuestion?.eachThemeQuestion?.isQuestionMandatory;
    const isTopicBased: any =
      programThemeQuestion?.eachThemeQuestion?.isTopicBased;
    const isVisibleForMentee: any =
      programThemeQuestion?.eachThemeQuestion?.isVisibleForMentee;
    const isVisibleForMentor: any =
      programThemeQuestion?.eachThemeQuestion?.isVisibleForMentor;
    const menteeQuestion: any =
      programThemeQuestion?.eachThemeQuestion?.menteeQuestion;
    const mentorQuestion: any =
      programThemeQuestion?.eachThemeQuestion?.mentorQuestion;
    const questionType: any =
      programThemeQuestion?.eachThemeQuestion?.questionType + "";
    const responseFormat: any =
      programThemeQuestion?.eachThemeQuestion?.responseFormat + "";
    const responseType: any =
      programThemeQuestion?.eachThemeQuestion?.responseType + "";
    const responseOptions: any =
      programThemeQuestion?.eachThemeQuestion?.programthemequestionsresponses;
    const characterLimit: any =
      programThemeQuestion?.eachThemeQuestion?.characterLimit;
    const attachments: any =
      programThemeQuestion?.eachThemeQuestion?.programthemequestionsresponses;
    const maximumResponseSelection: any =
      programThemeQuestion?.eachThemeQuestion?.maximumResponseSelection;

    const optionsArray =
      responseOptions?.length > 0 &&
      responseOptions?.map((resOption: any) => ({
        id: resOption?.id,
        optionName: resOption?.optionName,
      }));
    // const topicRespones = location?.state?.topicCategories?.map(
    const topicRespones =
      programThemeQuestion?.eachThemeQuestion?.topicCategories?.map(
        (item: any) => ({
          categoryName: item?.topic,
          topicCategoryId: item?.id,
          options: item?.responseOptions?.map((resOption: any) => ({
            id: resOption?.id,
            optionName: resOption?.optionName,
          })),
          deletedOptionId: [],
        })
      );
    const attachmentImage = attachments?.map((img: any, index: any) => ({
      fileType: img?.contentType,
      name: img?.fileName,
      oldFileName: img?.fileName,
      imageUrl: img?.publicUrl,
      id: img?.id,
      caption: img?.caption,
    }));
    return {
      mentorQuestion: mentorQuestion,
      menteeQuestion: menteeQuestion,
      responseType: responseType, //response option enum
      description: "new",
      responseFormat: responseFormat, //response option enum
      questionType: questionType, // for only onboarding question
      responseOptionImage: attachmentImage?.length
        ? attachmentImage
        : [
            {
              fileType: "",
              name: "",
              imageUrl: "",
              id: "",
              caption: "",
            },
            {
              fileType: "",
              name: "",
              imageUrl: "",
              id: "",
              caption: "",
            },
          ],
      // options: responseOptions?.length>0 && responseOptions?.map((resOption:any)=>(resOption?.options.map((option:any)=>option.optionName))),
      options: optionsArray || [],
      topicCategory: topicRespones?.length
        ? topicRespones
        : [
            {
              topicCategoryId: "",
              categoryName: "",
              options: [],
              deletedOptionId: [],
            },
          ],
      characterLimitForResponse: characterLimit,
      questionVisibleTo:
        isVisibleForMentee && isVisibleForMentor
          ? UsersRoleTypeEnum.MentorAndMentee
          : isVisibleForMentor
          ? UsersRoleTypeEnum.Mentor
          : UsersRoleTypeEnum.Mentee, //response options enum
      isQuestionMandatory: isQuestionMandatory,
      isImpactful: isImpactful,
      isTopicBased: isTopicBased,
      isAnswerMandatory: isAnswerMandatory,
      isCustomisable: isCustomisable,
      isIdentityQuestion: isIdentityQuestion,
      maximumResponseSelection: maximumResponseSelection
        ? maximumResponseSelection
        : 1,
      isVisibleForMentor: isVisibleForMentor,
      isVisibleForMentee: isVisibleForMentee,
      isMustMatchQuestion: false,
      createdBy: location.state.createdBy,
    };
  };

  const deleteImageResponse = (setFieldValue: any, index: any) => {
    setFieldValue(`responseOptionImage.${index}.name`, "");
    setFieldValue(`responseOptionImage.${index}.fileType`, "");
    setFieldValue(`responseOptionImage.${index}.imageUrl`, "");
    setFieldValue(`responseOptionImage.${index}.oldFileName`, "");
    setFieldValue(`responseOptionImage.${index}.id`, "");
    setFieldValue(`responseOptionImage.${index}.caption`, "");
  };
  const handleSubmitThemeQuestion = async (values: any) => {
    // if(location.state){
    //   updateThemeQuestions(
    //     {
    //       questionId :location.state.id,
    //       isTopicBased :true,
    //       ...values,
    //     }
    //   )
    // }else{
    //   addThemeQuestions({
    //     programThemeId: createProgramTheme.programThemeId,
    //     questions: [
    //       values
    //     ]
    //   })
    // }
    if (location?.state?.id) {
      seteditQuestion(values);
      // if (imageDeleteIds.length > 0) {
      //   deleteAttachment(imageDeleteIds);
      // }
      if (values?.responseType !== ResponseTypeEnum.Image) {
        // text response
        const imageIDs = values?.responseOptionImage.map(
          (item: any) => item.id
        );

        // if (imageIDs[0]?.length && imageIDs[1]?.length) {
        //   deleteAttachment(imageIDs);
        // } else {
        const updateRequestBody = QuestionEditResponse(
          values,
          deletedOption,
          deleteCategoryID
        );
        updateThemeQuestions({
          ...values,
          questionId: location.state.id,
          options: updateRequestBody?.options,
          topicCategory: updateRequestBody?.topicCategory,
        });
        // }
      } else if (values?.responseType == ResponseTypeEnum.Image) {
        const editedImages = values?.responseOptionImage?.filter(
          // filtering editted image
          (item: any, index: any) => {
            return item?.imageUrl?.includes("blob");
          }
        );
        const newImageUpload = editedImages?.filter((newUploadImage: any) => {
          // getting new added image
          return newUploadImage.id === "";
        });
        /// new image upload while editing
        if (newImageUpload?.length > 0) {
          const changeCaption = values?.responseOptionImage?.filter(
            (caption: any, index: any) => {
              return (
                caption?.caption !==
                programThemeQuestion?.eachOnboardingQuestion?.attachments[index]
                  ?.caption
              );
            }
          );
          const removeId = changeCaption?.filter(
            (newImage: any, index: any) => newImage?.id?.length !== 0
          );
          if (removeId?.length) {
            const attachmentReq = removeId?.map((item: any, index: any) => {
              return {
                attachmentId: item?.id,
                attachmentType: AttachmentTypeEnum.Other,
                publicUrl: item?.imageUrl,
                fileName: item?.name,
                caption: item?.caption,
                contentType: item?.fileType,
              };
            });
            // setEditting caption while adding new image
            updateCaption(attachmentReq);
            setCaptionUpdate(true);
          }
          setNewImageUploaded(true);
          const multipleImageToGet = [];
          for (const image of newImageUpload) {
            const reader = new FileReader();
            let data: any = {
              urlType: "upload",
              name: image.name,
              binary: "",
              type: image.fileType,
              caption: image?.caption,
            };
            let blob = await fetch(image.imageUrl).then((r) => r.blob());
            reader.onload = (e: any) => {
              data.binary = e.target.result;
            };
            reader.readAsArrayBuffer(blob);
            multipleImageToGet.push(data);
          }
          multiFileUpload(multipleImageToGet);
        }
        const updateImage = editedImages?.filter((updateImage: any) => {
          return updateImage?.id !== "";
        });
        if (updateImage?.length > 0) {
          setImageUpdate(true);
          const multipleImageToGet = [];
          for (const image of updateImage) {
            const reader = new FileReader();
            let data: any = {
              questionId: location?.state?.id,
              caption: image?.caption,
              urlType: "upload",
              name: image.name,
              binary: "",
              type: image?.fileType,
              oldFileName: image?.oldFileName,
              attachmentId: image?.id,
              contentType:
                image?.name.split(".")[1].toUpperCase() ===
                FileUploadMIMETypeEnum?.PNG
                  ? FileUploadMIMETypeEnum?.PNG
                  : FileUploadMIMETypeEnum?.JPEG,
              attachmentType: AttachmentTypeEnum.Other,
            };
            let blob = await fetch(image.imageUrl).then((r) => r.blob());
            reader.onload = (e: any) => {
              data.binary = e.target.result;
            };
            reader.readAsArrayBuffer(blob);
            multipleImageToGet.push(data);
          }
          multiFileUpdate(multipleImageToGet);
          // setUpdateAttachment(true);
        }
        // only caption editting
        if (newImageUpload?.length == 0 && updateImage?.length === 0) {
          const changeCaption = values?.responseOptionImage.filter(
            (caption: any, index: any) =>
              caption.caption !== values?.responseOptionImage[index]?.caption
          );
          if (changeCaption?.length > 0) {
            const attachmentReq = changeCaption?.map(
              (item: any, index: any) => {
                return {
                  attachmentId: item?.id,
                  attachmentType: AttachmentTypeEnum.Other,
                  publicUrl: item?.imageUrl,
                  fileName: item?.name,
                  caption: item?.caption,
                  contentType: item?.fileType,
                };
              }
            );
            updateCaption(attachmentReq);
          } else {
            const updateRequestBody = QuestionEditResponse(
              values,
              deletedOption,
              deleteCategoryID
            );
            updateThemeQuestions({
              ...values,
              questionId: location.state.id,
              options: updateRequestBody?.options,
              topicCategory: updateRequestBody?.topicCategory,
            });
          }
        }
      }
    } else {
      // new question adding
      const multipleImageToGet = [];
      if (values?.responseType == ResponseTypeEnum.Image) {
        for (const image of values.responseOptionImage) {
          const reader = new FileReader();
          let data: any = {
            urlType: "upload",
            name: image.name,
            binary: "",
            caption: image.caption,
            type: image.fileType,
          };
          let blob = await fetch(image.imageUrl).then((r) => r.blob());
          reader.onload = (e: any) => {
            data.binary = e.target.result;
          };
          reader.readAsArrayBuffer(blob);
          multipleImageToGet.push(data);
        }
        await multiFileUpload(multipleImageToGet);
        setThemeQuestionRequestBody(values);
      } else {
        addThemeQuestions({
          programThemeId: createProgramTheme?.programThemeId,
          questions: [
            {
              ...values,
              options: values?.options.map((item: any) => item?.optionName),
              topicCategory: values?.topicCategory?.map((item: any) => {
                return {
                  categoryName: item?.categoryName,
                  options: item?.options.map(
                    (optionName: any) => optionName.optionName
                  ),
                };
              }),
            },
          ],
        });
      }
    }
  };

  const handleResponseType = (event: any) => {
    if (event === ResponseTypeEnum.Text) {
      setResponseType(ResponseTypeEnum.Text);
      setActionDropDown(actionText);
    } else {
      setResponseType(ResponseTypeEnum.Image);
      setActionDropDown(actionImage);
    }
  };
  const handleResponseFormat = (resFormat: any) => {
    setResponseFormat(resFormat);
  };

  const handleConfirmDelete = () => {
    deleteProgramThemeQuestion({ questionId });
  };

  const handleConfirmImageResDelete = () => {
    deleteAttachment([imageResponseDelete]);
  };
  return (
    <Formik
      initialValues={
        location.state ? editingThemeQuestion() : initialThemeQuestionState
      }
      validationSchema={themeQuestionsScheme}
      onSubmit={handleSubmitThemeQuestion}
      enableReinitialize
    >
      {({
        values,
        setFieldValue,
        handleChange,
        handleBlur,
        errors,
        touched,
        isValid,
        dirty,
      }) => (
        <>
          <ConfirmationDialog
            isConfirmModelOpen={shouldOpenConfirmatonDialog}
            handleClose={() => setShouldOpenConfirmatonDialog(false)}
            handleConfirm={handleConfirmDelete}
            title={"Are you sure you want to delete this question ?"}
            leftButtonText={"No"}
            rightButtonText={"Yes"}
          />
          <ConfirmationDialog
            isConfirmModelOpen={shouldOpenConfirmOnImg}
            handleClose={() => setShouldOpenConfirmOnImg(false)}
            handleConfirm={handleConfirmImageResDelete}
            title={"Are you sure you want to delete this Image Response ?"}
            leftButtonText={"No"}
            rightButtonText={"Yes"}
          />
          <Box
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
            mt={1}
          >
            <IconButton onClick={() => navigate(-1)}>
              <ArrowBackIosIcon style={{ color: "#434245" }} />
            </IconButton>
            <Typography className={styles["back-to-question-list-label"]}>
              Back to Question List
            </Typography>
          </Box>
          <Grid
            container
            className={styles["theme-questions-tab-inner-content-height"]}
          >
            <Grid item xs={11.5} sx={{ height: "100%" }}>
              <FormFieldContainer>
                <Form className="form-container">
                  <Stack
                    direction="row"
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    py={1}
                    px={2}
                  >
                    <div className={styles["question-header"]}>Question</div>
                    <Stack direction={"row"} spacing={1} alignItems={"center"}>
                      {location?.state && (
                        <IconButton
                          onClick={() => {
                            setShouldOpenConfirmatonDialog(true);
                            setQuestionId(location?.state?.id);
                          }}
                        >
                          <img src={MediaAssets.ic_delete} width={30} />
                        </IconButton>
                      )}
                      <MuiLoadingButton
                        variant="outlined"
                        type="submit"
                        loading={
                          programThemeQuestion?.isLoading ||
                          fileUploader?.isLoading ||
                          attachment.isLoading
                        }
                      >
                        Save
                      </MuiLoadingButton>
                    </Stack>
                  </Stack>
                  <Stack
                    sx={{
                      height: "calc(100% - 70px)",
                      overflowY: "auto",
                      overflowX: "hidden",
                    }}
                  >
                    <Box>
                      <Grid
                        container
                        direction={"row"}
                        alignItems={"center"}
                        className={"individual-padding"}
                      >
                        <Grid item xl={2.2} lg={2}>
                          <InputLabel
                            error={
                              getIn(touched, `questionVisibleTo`) &&
                              getIn(errors, `questionVisibleTo`)
                            }
                            className="inputs-label-font"
                          >
                            Question visible to
                            <span className={"astrik-color"}>*</span>
                          </InputLabel>
                        </Grid>
                        <Grid item xs={6}>
                          <RadioButtonGroupComponent
                            value={values.questionVisibleTo}
                            name="questionVisibleTo"
                            onChange={(event) => {
                              if (
                                event?.target?.value ===
                                UsersRoleTypeEnum.Mentor
                              ) {
                                setFieldValue("isVisibleForMentor", true);
                                setFieldValue("isVisibleForMentee", false);
                                setFieldValue("menteeQuestion", "");
                                setFieldValue(
                                  "questionVisibleTo",
                                  event?.target?.value
                                );
                              } else if (
                                event?.target?.value ===
                                UsersRoleTypeEnum.Mentee
                              ) {
                                setFieldValue("isVisibleForMentee", true);
                                setFieldValue("isVisibleForMentor", false);
                                setFieldValue("mentorQuestion", "");
                                setFieldValue(
                                  "questionVisibleTo",
                                  event?.target?.value
                                );
                              } else {
                                setFieldValue("isVisibleForMentee", true);
                                setFieldValue("isVisibleForMentor", true);
                                setFieldValue(
                                  "questionVisibleTo",
                                  event?.target?.value
                                );
                              }
                            }}
                          >
                            <FormControlLabel
                              value={UsersRoleTypeEnum.Mentor}
                              control={
                                <MuiRadioButton
                                  error={
                                    getIn(touched, `questionVisibleTo`) &&
                                    getIn(errors, `questionVisibleTo`)
                                  }
                                />
                              }
                              label={
                                <Box className="inputs-label-font">
                                  Frientor
                                </Box>
                              }
                              labelPlacement="end"
                            />
                            <FormControlLabel
                              value={UsersRoleTypeEnum.Mentee}
                              control={
                                <MuiRadioButton
                                  error={
                                    getIn(touched, `questionVisibleTo`) &&
                                    getIn(errors, `questionVisibleTo`)
                                  }
                                />
                              }
                              label={
                                <Box className="inputs-label-font">
                                  Frientee
                                </Box>
                              }
                              labelPlacement="end"
                            />
                            <FormControlLabel
                              value={UsersRoleTypeEnum.MentorAndMentee}
                              control={
                                <MuiRadioButton
                                  error={
                                    getIn(touched, `questionVisibleTo`) &&
                                    getIn(errors, `questionVisibleTo`)
                                  }
                                />
                              }
                              label={
                                <Box className="inputs-label-font">Both</Box>
                              }
                              labelPlacement="end"
                            />
                          </RadioButtonGroupComponent>
                        </Grid>
                        <Grid item xs={12}>
                          {" "}
                          {getIn(touched, `questionVisibleTo`) &&
                            getIn(errors, `questionVisibleTo`) && (
                              <FormHelperText
                                error={true}
                                className="helpertext-error"
                              >
                                {/* {errors?.questionVisibleTo} */}
                              </FormHelperText>
                            )}
                        </Grid>
                      </Grid>
                      {values?.isVisibleForMentor ? (
                        <Box my={1} className={"individual-padding"}>
                          {values?.isVisibleForMentor && (
                            <MuiTextField
                              textfieldlabel={
                                <span>
                                  Frientor’s version{" "}
                                  <span className={"astrik-color"}>*</span>
                                </span>
                              }
                              name="mentorQuestion"
                              placeholder="Enter"
                              // required={true}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values?.mentorQuestion}
                              helperText={
                                getIn(touched, `mentorQuestion`)
                                  ? getIn(errors, `mentorQuestion`)
                                  : ""
                              }
                              error={
                                getIn(touched, `mentorQuestion`) &&
                                getIn(errors, `mentorQuestion`)
                              }
                            />
                          )}
                        </Box>
                      ) : null}
                      {values?.isVisibleForMentee ? (
                        <Box my={1} className={"individual-padding"}>
                          {values?.isVisibleForMentee && (
                            <MuiTextField
                              textfieldlabel={
                                <span>
                                  Frientee's version
                                  <span className={"astrik-color"}>*</span>
                                </span>
                              }
                              name="menteeQuestion"
                              placeholder="Enter"
                              // required={true}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values?.menteeQuestion}
                              helperText={
                                getIn(touched, `menteeQuestion`)
                                  ? getIn(errors, `menteeQuestion`)
                                  : ""
                              }
                              error={
                                getIn(touched, `menteeQuestion`) &&
                                getIn(errors, `menteeQuestion`)
                              }
                            />
                          )}
                        </Box>
                      ) : null}
                    </Box>
                    <hr className="horizontal-border" />
                    <Grid
                      container
                      direction={"row"}
                      alignItems={"center"}
                      pt={2}
                      pb={2}
                      className={"individual-padding"}
                    >
                      <Grid item xs={2}>
                        <InputLabel className="inputs-label-font">
                          Response type{" "}
                          <span className={"astrik-color"}>*</span>
                        </InputLabel>
                      </Grid>
                      <Grid item xs={4}>
                        <FormControl>
                          <RadioButtonGroupComponent
                            value={
                              values?.responseType?.length > 0 &&
                              values?.responseType
                            }
                            name={"responseType"}
                            onChange={(event) => {
                              setFieldValue(
                                "responseType",
                                event.currentTarget.value
                              );
                              handleResponseType(event.target.value);
                            }}
                            onBlur={handleBlur}
                          >
                            <FormControlLabel
                              value={`${ResponseTypeEnum?.Text}`}
                              control={
                                <MuiRadioButton
                                  color="primary"
                                  error={
                                    getIn(touched, `responseType`) &&
                                    getIn(errors, `responseType`)
                                  }
                                />
                              }
                              label={
                                <Box className="inputs-label-font">Text</Box>
                              }
                              labelPlacement="end"
                            />
                            <FormControlLabel
                              value={`${ResponseTypeEnum.Image}`}
                              control={
                                <Radio
                                  sx={{
                                    "& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)":
                                      {
                                        color:
                                          getIn(touched, `responseType`) &&
                                          getIn(errors, `responseType`)
                                            ? "#e0514b"
                                            : "",
                                      },
                                  }}
                                />
                              }
                              label={
                                <Box className="inputs-label-font">Image</Box>
                              }
                              labelPlacement="end"
                            />
                          </RadioButtonGroupComponent>
                        </FormControl>
                      </Grid>
                      <Grid xs={12}>
                        {getIn(touched, `responseType`) &&
                          getIn(errors, `responseType`) && (
                            <FormHelperText
                              error={true}
                              className="helpertext-error"
                            >
                              {/* {errors?.responseType} */}
                            </FormHelperText>
                          )}
                      </Grid>
                    </Grid>
                    <hr className="horizontal-border" />
                    <Grid container>
                      {values?.responseType > 0 && (
                        <Grid
                          item
                          xl={4}
                          lg={5}
                          className={"individual-padding"}
                        >
                          <InputLabel
                            required
                            error={
                              getIn(touched, `responseFormat`) &&
                              getIn(errors, `responseFormat`)
                            }
                          >
                            Select response format
                            <span className={"astrik-color"}>*</span>
                          </InputLabel>
                          <MuiTextField
                            select
                            fullWidth
                            placeholder="Select"
                            name={"responseFormat"}
                            value={values?.responseFormat}
                            error={
                              getIn(touched, `responseFormat`) &&
                              getIn(errors, `responseFormat`)
                            }
                            onChange={(event) => {
                              if (
                                event.target.value ===
                                  ActionTypeEnumTextResponse.SingleChoice &&
                                values?.responseType === ResponseTypeEnum.Text
                              ) {
                                setFieldValue(
                                  "responseFormat",
                                  event.target.value
                                );
                                setFieldValue("maximumResponseSelection", 1);

                                handleResponseFormat(event.target.value);
                              } else if (
                                event.target.value ===
                                  ActionTypeEnumTextResponse.SingleChoice &&
                                values?.responseType === ResponseTypeEnum.Image
                              ) {
                                setFieldValue(
                                  "responseFormat",
                                  event.target.value
                                );
                                handleResponseFormat(event.target.value);
                                setFieldValue("maximumResponseSelection", 2);
                              } else {
                                setFieldValue(
                                  "responseFormat",
                                  event.target.value
                                );
                                handleResponseFormat(event.target.value);
                              }
                            }}
                          >
                            {actionDropDown?.map(
                              (textSelection: any, index: number) => (
                                <MenuItem value={textSelection.value}>
                                  {textSelection?.label}
                                </MenuItem>
                              )
                            )}
                          </MuiTextField>
                          {/* <div>{JSON.stringify(actionText)}</div>
                          <div>{JSON.stringify(actionDropDown)}</div> */}
                        </Grid>
                      )}
                      <Grid
                        item
                        xs={12}
                        mt={2}
                        className={"individual-padding"}
                      >
                        {(values?.responseFormat ===
                          ActionTypeEnumTextResponse.MultipleChoice ||
                          values.responseFormat ===
                            ActionTypeEnumTextResponse.SingleChoice) &&
                          values?.responseType === ResponseTypeEnum.Text && (
                            <Box mt={2}>
                              <InputLabel
                                required
                                error={
                                  getIn(touched, "options") &&
                                  getIn(errors, `options`)
                                }
                              >
                                Response options{" "}
                                <span
                                  style={{
                                    fontStyle: "italic",
                                    fontSize: "14px",
                                    color: "#807b92",
                                  }}
                                >
                                  ( Press “Enter” after each response option)
                                </span>
                              </InputLabel>
                              <MuiChipsInput
                                name={"options"}
                                value={values.options.map(
                                  (item: any) => item.optionName
                                )}
                                onAddChip={(chipValue: any, chipIndex: any) => {
                                  setFieldValue("options", [
                                    ...values.options,
                                    {
                                      id: "",
                                      optionName: chipValue,
                                    },
                                  ]);
                                }}
                                onEditChip={(
                                  chipValue: any,
                                  chipIndex: any
                                ) => {
                                  const newObj = values?.options?.map(
                                    (item: any, index: any) => {
                                      if (chipIndex == index) {
                                        return {
                                          id: item.id,
                                          optionName: chipValue,
                                        };
                                      }
                                      return {
                                        ...item,
                                      };
                                    }
                                  );
                                  setFieldValue("options", newObj);
                                }}
                                onDeleteChip={(chipValue, chipIndex) => {
                                  const deletedID = values?.options?.find(
                                    (item: any, index: any) =>
                                      index === chipIndex
                                  );
                                  const deleted = values?.options?.filter(
                                    (item: any, index: any) =>
                                      index !== chipIndex
                                  );
                                  if (deletedID?.id?.length > 0) {
                                    setDeleteOption([
                                      ...deletedOption,
                                      deletedID.id,
                                    ]);
                                  }
                                  setFieldValue("options", deleted);
                                }}
                                // renderChip={()}
                                onBlur={handleBlur}
                                error={
                                  getIn(touched, "options") &&
                                  getIn(errors, `options`)
                                }
                                helperText={
                                  getIn(touched, `options`)
                                    ? getIn(errors, `options`)
                                    : ""
                                }
                              />
                            </Box>
                          )}

                        {values?.responseFormat ===
                          ActionTypeEnumTextResponse.TopicQuestion && (
                          <FieldArray name="topicCategory">
                            {({ insert, remove, push }) => (
                              <div>
                                {values?.topicCategory?.length > 0 &&
                                  values?.topicCategory?.map(
                                    (question: any, index: any) => (
                                      <Grid container spacing={1} mb={1}>
                                        <Grid item xl={3} lg={4}>
                                          {/* <div>{JSON.stringify( values)}</div> */}
                                          <MuiTextField
                                            placeholder="Create topic category"
                                            textfieldlabel={
                                              index == 0 && (
                                                <span>
                                                  Topic Category
                                                  <span
                                                    className={"astrik-color"}
                                                  >
                                                    *
                                                  </span>
                                                </span>
                                              )
                                            }
                                            name={`topicCategory.${index}.categoryName`}
                                            value={
                                              values?.topicCategory[index]
                                                ?.categoryName
                                            }
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={
                                              getIn(
                                                touched,
                                                `topicCategory.${index}.categoryName`
                                              ) &&
                                              getIn(
                                                errors,
                                                `topicCategory.${index}.categoryName`
                                              )
                                            }
                                            helperText={
                                              getIn(
                                                touched,
                                                `topicCategory.${index}.categoryName`
                                              )
                                                ? getIn(
                                                    errors,
                                                    `topicCategory.${index}.categoryName`
                                                  )
                                                : ""
                                            }
                                          />
                                        </Grid>
                                        <Grid item xs={7}>
                                          {index == 0 && (
                                            <InputLabel
                                              error={
                                                getIn(
                                                  touched,
                                                  `topicCategory.${index}.options`
                                                ) &&
                                                getIn(
                                                  errors,
                                                  `topicCategory.${index}.options`
                                                )
                                              }
                                            >
                                              Response options{" "}
                                              <span className={"astrik-color"}>
                                                *
                                              </span>
                                              <span
                                                style={{
                                                  fontStyle: "italic",
                                                  fontSize: "14px",
                                                }}
                                              >
                                                ( Press “Enter” after each
                                                response option)
                                              </span>
                                            </InputLabel>
                                          )}
                                          <MuiChipsInput
                                            name={`topicCategory.${index}.options`}
                                            value={values?.topicCategory[
                                              index
                                            ]?.options?.map(
                                              (item: any) => item?.optionName
                                            )}
                                            // onChange={(newValue: any) => {
                                            //   setFieldValue(
                                            //     `${`topicCategory.${index}.options`}`,
                                            //     newValue
                                            //   );
                                            // }}
                                            onAddChip={(
                                              chipValue: any,
                                              chipIndex: any
                                            ) => {
                                              setFieldValue(
                                                `${`topicCategory.${index}.options`}`,
                                                [
                                                  ...values.topicCategory[index]
                                                    .options,
                                                  {
                                                    id: "",
                                                    optionName: chipValue,
                                                  },
                                                ]
                                              );
                                            }}
                                            onEditChip={(
                                              chipValue: any,
                                              chipIndex: any
                                            ) => {
                                              const editObj =
                                                values?.topicCategory[
                                                  index
                                                ]?.options?.map(
                                                  (item: any, ind: any) => {
                                                    if (ind === chipIndex) {
                                                      return {
                                                        id: item.id,
                                                        optionName: chipValue,
                                                      };
                                                    }
                                                    return {
                                                      ...item,
                                                    };
                                                  }
                                                );
                                              setFieldValue(
                                                `${`topicCategory.${index}.options`}`,
                                                editObj
                                              );
                                            }}
                                            onDeleteChip={(
                                              chipValue: any,
                                              chipIndex: any
                                            ) => {
                                              const deletedID =
                                                values?.topicCategory[
                                                  index
                                                ]?.options?.find(
                                                  (item: any, index: any) =>
                                                    index === chipIndex
                                                );
                                              const deleted =
                                                values?.topicCategory[
                                                  index
                                                ]?.options?.filter(
                                                  (item: any, index: any) =>
                                                    index !== chipIndex
                                                );
                                              setFieldValue(
                                                `${`topicCategory.${index}.options`}`,
                                                deleted
                                              );
                                              if (deletedID?.id?.length > 0) {
                                                setFieldValue(
                                                  `${`topicCategory.${index}.deletedOptionId`}`,
                                                  [
                                                    ...values?.topicCategory[
                                                      index
                                                    ].deletedOptionId,
                                                    deletedID,
                                                  ]
                                                );
                                              }
                                            }}
                                            // renderChip={()}
                                            onBlur={handleBlur}
                                            error={
                                              getIn(
                                                touched,
                                                `topicCategory.${index}.options`
                                              ) &&
                                              getIn(
                                                errors,
                                                `topicCategory.${index}.options`
                                              )
                                            }
                                            helperText={
                                              getIn(
                                                touched,
                                                `topicCategory.${index}.options`
                                              )
                                                ? getIn(
                                                    errors,
                                                    `topicCategory.${index}.options`
                                                  )
                                                : ""
                                            }
                                          />
                                        </Grid>
                                        <Grid item xs={1}>
                                          {index >= 0 && (
                                            <IconButton
                                              onClick={() => {
                                                remove(index);
                                                if (
                                                  values?.topicCategory[index]
                                                    ?.topicCategoryId?.length >
                                                  0
                                                ) {
                                                  setDeleteCategoryID([
                                                    ...deleteCategoryID,
                                                    values?.topicCategory[index]
                                                      ?.topicCategoryId,
                                                  ]);
                                                }
                                              }}
                                            >
                                              <img
                                                src={MediaAssets.ic_delete}
                                                width={30}
                                              />
                                            </IconButton>
                                          )}
                                        </Grid>
                                      </Grid>
                                    )
                                  )}
                                <Box mt={2}>
                                  <MuiButton
                                    variant="outlined"
                                    onClick={() => {
                                      push({
                                        topicCategoryId: "",
                                        categoryName: "",
                                        options: [],
                                      });
                                    }}
                                  >
                                    {" "}
                                    + Add another category
                                  </MuiButton>
                                </Box>
                              </div>
                            )}
                          </FieldArray>
                        )}
                        {(values?.responseFormat ===
                          ActionTypeEnumTextResponse.MultipleChoice ||
                          values?.responseFormat ===
                            ActionTypeEnumTextResponse.SingleChoice) &&
                          values?.responseType == ResponseTypeEnum.Image && (
                            <div>
                              <InputLabel
                                className="inputs-label-font"
                                required
                                error={
                                  getIn(touched, `responseOptionImage`) &&
                                  getIn(errors, `responseOptionImage`)
                                }
                              >
                                Response options
                              </InputLabel>
                              <FieldArray name="responseOptionImage">
                                {({ insert, remove, push }) => (
                                  <Grid
                                    container
                                    spacing={1}
                                    alignItems="flex-start"
                                  >
                                    {values?.responseOptionImage?.map(
                                      (item: any, index: any) => (
                                        <Grid item xl={2} lg={3}>
                                          {/* <div>{JSON.stringify(values)}</div> */}
                                          <ImageUploader
                                            name={`responseOptionImage.${index}.imageUrl`}
                                            index={index}
                                            // value={values.responseOptionImage[index]?.imageUrl && values?.responseOptionImage[index]?.imageUrl}
                                            handleImageUpload={(e) => {
                                              const imageBlod =
                                                URL.createObjectURL(
                                                  e.target.files[0]
                                                );
                                              setFieldValue(
                                                `responseOptionImage.${index}.name`,
                                                e.target.files[0].name
                                              );
                                              setFieldValue(
                                                `responseOptionImage.${index}.fileType`,
                                                e.target.files[0].type
                                              );
                                              setFieldValue(
                                                `responseOptionImage.${index}.imageUrl`,
                                                imageBlod
                                              );
                                            }}
                                            captionValue={
                                              values.responseOptionImage[index]
                                                ?.caption
                                            }
                                            captionName={`responseOptionImage.${index}.caption`}
                                            handleChangeCaption={handleRestrictLength(
                                              setFieldValue,
                                              20
                                            )}
                                            previewImage={
                                              values?.responseOptionImage[index]
                                                ?.imageUrl
                                                ? values?.responseOptionImage[
                                                    index
                                                  ]?.imageUrl
                                                : null
                                            }
                                            fieldhelpertext={
                                              getIn(
                                                touched,
                                                `responseOptionImage.${index}.imageUrl`
                                              )
                                                ? getIn(
                                                    errors,
                                                    `responseOptionImage.${index}.imageUrl`
                                                  )
                                                : ""
                                            }
                                            handleDeleteImageField={() =>
                                              remove(index)
                                            }
                                            onDeleteImage={(e: any) => {
                                              if (item?.id?.length > 0) {
                                                setImageResponseDelete(
                                                  item?.id
                                                );
                                                setShouldOpenConfirmOnImg(true);
                                              } else {
                                                deleteImageResponse(
                                                  setFieldValue,
                                                  index
                                                );
                                              }
                                            }}
                                          />
                                        </Grid>
                                      )
                                    )}

                                    <Grid item xl={2} lg={3} mt={4}>
                                      <MuiButton
                                        variant="outlined"
                                        onClick={() =>
                                          push({
                                            fileType: "",
                                            name: "",
                                            imageUrl: "",
                                            id: "",
                                            caption: "",
                                          })
                                        }
                                      >
                                        {`+Add more`}
                                      </MuiButton>
                                    </Grid>
                                  </Grid>
                                )}
                                {/* <Grid></Grid> */}
                              </FieldArray>
                              <div className="character-limit-text">
                                Character limit for each caption is{" "}
                                <span className={"character-limit-count"}>
                                  20 Characters
                                </span>
                              </div>
                            </div>
                          )}
                        {getIn(touched, `responseOptionImage`) &&
                          getIn(errors, `responseOptionImage`) && (
                            <InputLabel
                              className="inputs-label-font"
                              required
                              error={
                                getIn(touched, `responseOptionImage`) &&
                                getIn(errors, `responseOptionImage`)
                              }
                            >
                              {values.responseOptionImage?.length === 2 &&
                              errors?.responseOptionImage?.length === 2
                                ? "Min two response options are mandatory"
                                : "Upload images in all the options,delete the empty options"}
                            </InputLabel>
                          )}
                      </Grid>
                    </Grid>
                    <hr className="horizontal-border" />
                    {values?.responseFormat && (
                      <div>
                        {values?.responseFormat ===
                          ActionTypeEnumTextResponse.TextInputWithLimit &&
                          values?.responseType === ResponseTypeEnum.Text && (
                            <Grid
                              container
                              direction="row"
                              alignItems={"center"}
                              spacing={1}
                              ml={0.2}
                            >
                              <Grid item lg={4} xl={3}>
                                <InputLabel
                                  required
                                  error={
                                    getIn(
                                      touched,
                                      `characterLimitForResponse`
                                    ) &&
                                    getIn(errors, `characterLimitForResponse`)
                                  }
                                  className="inputs-label-font"
                                >
                                  Character limit for responses
                                </InputLabel>
                              </Grid>
                              <Grid xs={1.5} p={1}>
                                <MuiTextField
                                  name={"characterLimitForResponse"}
                                  onChange={handleNumberFieldChange(
                                    setFieldValue
                                  )}
                                  value={values.characterLimitForResponse}
                                  onBlur={handleBlur}
                                  placeholder={"Enter"}
                                  error={
                                    getIn(
                                      touched,
                                      `characterLimitForResponse`
                                    ) &&
                                    getIn(errors, `characterLimitForResponse`)
                                  }
                                />
                              </Grid>
                              <Grid item xs={12}>
                                {getIn(touched, `characterLimitForResponse`) &&
                                  getIn(
                                    errors,
                                    `characterLimitForResponse`
                                  ) && (
                                    <FormHelperText
                                      error={true}
                                      className="helpertext-error"
                                    >
                                      Required Field
                                    </FormHelperText>
                                  )}
                              </Grid>
                            </Grid>
                          )}

                        {values?.responseFormat ==
                          ActionTypeEnumTextResponse.MultipleChoice && (
                          <Grid
                            container
                            p={2}
                            direction={"row"}
                            alignItems={"center"}
                          >
                            <Grid item xl={3} lg={4}>
                              <Typography className="inputs-label-font">
                                Maximum response selection
                              </Typography>
                            </Grid>
                            <Grid xs={2}>
                              <MuiTextField
                                name="maximumResponseSelection"
                                onChange={handleNumberFieldChange(
                                  setFieldValue
                                )}
                                onBlur={handleBlur}
                                placeholder={"Enter Here"}
                                value={values?.maximumResponseSelection}
                                helperText={
                                  getIn(touched, `maximumResponseSelection`)
                                    ? getIn(errors, `maximumResponseSelection`)
                                    : ""
                                }
                                error={
                                  getIn(touched, `maximumResponseSelection`) &&
                                  getIn(errors, `maximumResponseSelection`)
                                }
                              />
                            </Grid>
                          </Grid>
                        )}
                        {/* {/ <hr /> /} */}
                        <hr className="horizontal-border" />
                        <Box p={1}>
                          <FormControlLabel
                            control={
                              <Switch
                                color="primary"
                                name={"isQuestionMandatory"}
                                value={values.isQuestionMandatory}
                                onChange={(event, checked) =>
                                  setFieldValue(
                                    "isQuestionMandatory",
                                    checked ? true : false
                                  )
                                }
                                checked={values.isQuestionMandatory}
                              />
                            }
                            label={
                              <Box className="inputs-label-font">
                                {StringConstant.markThisAsMandatoryQuestion}
                              </Box>
                            }
                            labelPlacement="start"
                          />
                        </Box>
                        <hr className="horizontal-border" />
                        <Stack p={1} direction={"row"} alignItems={"center"}>
                          <FormControlLabel
                            control={
                              <Switch
                                color="primary"
                                name={"isImpactful"}
                                value={values.isImpactful}
                                onChange={(event, checked) =>
                                  setFieldValue(
                                    "isImpactful",
                                    checked ? true : false
                                  )
                                }
                                checked={values.isImpactful}
                              />
                            }
                            label={
                              <Box className="inputs-label-font">
                                {StringConstant.markThisAsImpactfulQuestion}
                              </Box>
                            }
                            labelPlacement="start"
                          />
                          <Typography color="error.main" fontStyle={"italic"}>
                            ( Impactful questions will be used for matching
                            logic)
                          </Typography>
                        </Stack>
                        <hr className="horizontal-border" />
                        <Box p={1}>
                          <FormControlLabel
                            value={values.isAnswerMandatory}
                            control={
                              <Switch
                                color="primary"
                                name={"mustMatchQuestion"}
                                value={values.isMustMatchQuestion}
                                onChange={(event, checked) =>
                                  setFieldValue(
                                    "isMustMatchQuestion",
                                    checked ? true : false
                                  )
                                }
                                checked={values.isMustMatchQuestion}
                              />
                            }
                            label={
                              <Box className="inputs-label-font">
                                {StringConstant.mustMatchQuestion}
                              </Box>
                            }
                            labelPlacement="start"
                          />
                        </Box>
                        <hr className="horizontal-border" />
                        <Box p={1}>
                          <FormControlLabel
                            value={values.isAnswerMandatory}
                            control={
                              <Switch
                                color="primary"
                                name={"isAnswerMandatory"}
                                value={values.isAnswerMandatory}
                                onChange={(event, checked) =>
                                  setFieldValue(
                                    "isAnswerMandatory",
                                    checked ? true : false
                                  )
                                }
                                checked={values.isAnswerMandatory}
                              />
                            }
                            label={
                              <Box className="inputs-label-font">
                                {StringConstant.markResponsesMandatory}
                              </Box>
                            }
                            labelPlacement="start"
                          />
                        </Box>

                        <hr className="horizontal-border" />
                        <Box p={1}>
                          <FormControlLabel
                            control={
                              <Switch
                                color="primary"
                                name={"isCustomisable"}
                                value={values.isCustomisable}
                                onChange={(event, checked) =>
                                  setFieldValue(
                                    "isCustomisable",
                                    checked ? true : false
                                  )
                                }
                                checked={values.isCustomisable}
                              />
                            }
                            label={
                              <Box className="inputs-label-font">
                                {
                                  StringConstant.allowProgramAdminToAddMoreResponse
                                }
                              </Box>
                            }
                            labelPlacement="start"
                          />
                        </Box>
                      </div>
                    )}
                  </Stack>
                </Form>
              </FormFieldContainer>
            </Grid>
          </Grid>
          <ScrollToError />
        </>
      )}
    </Formik>
  );
};

export default ThemeQuestions;
