import React, { useEffect, useMemo } from "react";
import MediaAssets from "../../assets";
import NoItemFound from "../../components/no-data-record/NoDataFound";
import PageHeader from "../../components/page-heading/PageHeading";
import { StringConstant } from "../../resources/strings";
import { RouteConfigurations } from "../../routes/RouteConfigurations";
import { useNavigate } from "react-router";
import {
  IconButton,
  Table,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import {
  programThemesColumns,
  programThemesRows,
} from "./ProgramThemeInitialState";
import {
  getProgramThemeRequest,
  setCreateProgramTheme,
} from "../../redux/program-theme/create-program-theme/CreateProgramThemeRedux";
import { useDispatch, useSelector } from "react-redux";
import PageLoader from "../../components/page-loader/PageLoader";
import { setProgramThemeState } from "../../redux/program-theme/programtheme-question/ProgramThemQuestionRedux";
import dayjs from "dayjs";
import { TableHeadWrapper } from "./../../components/table-head/TableHead.style";
import { TableBodyWrapper } from "./../../components/table-body/TableBodyWrapper.style";

import styles from "./Styles.module.css";
import { MuiTablePagination } from "../../components/mui-pagination/MuiTablePagination";

const ProgramThemes = () => {
  const actionDispatch = (dispatch: any) => ({
    getProgramThemes: () => dispatch(getProgramThemeRequest()),
    setThemeQuestion: (data: any) => dispatch(setProgramThemeState(data)),
    setProgramThemeId: (data: any) => dispatch(setCreateProgramTheme(data)),
    setReduxProgramTheme: (data: any) => dispatch(setCreateProgramTheme(data)),
    // updateOnboardingQuestions: (data: any) => (dispatch(updateOnboardingQuestionRequest(data))),
    // setOnboardingNull: () => dispatch(setOnboardingToasterNull()),

    // setToasterInfo: (data: any) => dispatch(setToaster(data)),
  });
  const {
    getProgramThemes,
    setProgramThemeId,
    setReduxProgramTheme,
    setThemeQuestion,
  } = actionDispatch(useDispatch());

  const { createProgramTheme } = useSelector((state: any) => state);

  const { listProgramTheme, isLoading, error } = createProgramTheme;
  const navigate = useNavigate();

  useEffect(() => {
    getProgramThemes();
  }, []);

  const handlePageHeadingButton = () => {
    navigate(RouteConfigurations.createTheme);
  };

  // Pagination
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const onHandleEdit = (id: any) => {
    setProgramThemeId({ key: "programThemeId", value: id });
    navigate(RouteConfigurations.createTheme);
  };

  const onCreateNewThemeQuestion = () => {
    setReduxProgramTheme({ key: "reduxProgramTheme", value: null });
    setProgramThemeId({ key: "programThemeId", value: "" });
    setReduxProgramTheme({ key: "guideLineData", value: null });
    setThemeQuestion({ key: "listsProgramThemeQuestion", value: null });
    setReduxProgramTheme({ key: "data", value: null });

    navigate(RouteConfigurations.createTheme);
  };

  const LoadingComponent = () => {
    return <PageLoader />;
  };

  const renderRetry = () => {
    return <div>Try again</div>;
  };

  const renderTable = () => {
    return (
      <>
        {programThemesRows?.length > 0 && (
          <>
            <PageHeader
              pageHeading={"Program Themes"}
              isInviteButton={true}
              handleClick={onCreateNewThemeQuestion}
              buttonText="Create Program Theme"
              headingSuffix={`${listProgramTheme?.length} Themes`}
            />
            {/* <div>{JSON.stringify(listProgramTheme)}</div> */}
            <TableContainer className="tab-innner-content-height">
              <Table stickyHeader aria-label="sticky table">
                <TableHeadWrapper>
                  <TableRow>
                    {programThemesColumns?.map((column: any) => (
                      <TableCell
                        key={`${column.id}_${column.label}`}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHeadWrapper>
                <TableBodyWrapper>
                  {listProgramTheme
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    ?.map((row: any, index: any) => {
                      return (
                        
                        <TableRow
                          tabIndex={-1}
                          key={`${row.questionsAdded}_${index}`}
                          className={"program-theme-tableRow"}
                        >
                          <TableCell>{row.name}</TableCell>
                          <TableCell>
                            {row?.programThemeQuestions?.length}
                          </TableCell>
                          <TableCell>{row?.createdBy?.firstName}</TableCell>
                          <TableCell>
                            {dayjs(row?.createdAt).format("DD-MM-YYYY")}
                          </TableCell>
                          <TableCell>
                            {row?.isPublished ? "Published" : "Pending"}
                          </TableCell>
                          <TableCell key={index}>
                            <IconButton
                              className="program-theme-icon-button"
                              onClick={() => onHandleEdit(row?.id)}
                            >
                              <img
                                src={MediaAssets.ic_edit_icon}
                                className="program-theme-edit-icon"
                              />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBodyWrapper>
              </Table>
            </TableContainer>
            <hr className="horizontal-border" />
            <MuiTablePagination
              rowsPerPageOptions={[5, 10, 25, 30, 35, 40, 45, 50]}
              component="div"
              count={listProgramTheme?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage={"Per Page"}
              labelDisplayedRows={function defaultLabelDisplayedRows({
                count,
              }: any) {
                return (
                  <div style={{ display: "flex", gap: "1rem" }}>
                    <div className={styles["current-page"]}>{page + 1}</div>
                    <div className={styles["total-page-count"]}>
                      {" "}
                      of {Math.ceil(count / rowsPerPage)}
                    </div>
                  </div>
                );
              }}
            />
          </>
        )}
      </>
    );
  };
  const renderNoData = () => {
    return (
      <>
        <PageHeader pageHeading="Program Themes" />
        <NoItemFound
          textMesaage={StringConstant.createYourFirstTheme}
          buttonText="Create Program Theme"
          navImage={MediaAssets.ic_program_theme}
          handleClick={handlePageHeadingButton}
        />
      </>
    );
  };

  const render = useMemo(() => {
    if (listProgramTheme?.length > 0) {
      return renderTable();
    } else if (isLoading) {
      return LoadingComponent();
    } else if (listProgramTheme?.length === 0) {
      return renderNoData();
    } else if (error) {
      return renderRetry();
    }
  }, [error, isLoading, listProgramTheme, rowsPerPage, page]);

  return <>{render}</>;
};

export default ProgramThemes;
