import { createSlice } from "@reduxjs/toolkit";

interface IEntityType {
  isLoading?: boolean;
  error?: string;
  success?: string;
  data?: any;
}

const initialState: IEntityType = {
  isLoading: false,
  error: "",
  success: "",
  data: [],
};

export const entityTypeRedux = createSlice({
  name: "entityTypes",
  initialState,
  reducers: {
    fetchEntityTypeRequest: (state) => {
      const newState = { ...state, isLoading: true };
      return newState;
    },
    fetchEntityTypeSuccess: (state, action) => {
      const newState = {
        ...state,
        isLoading: false,
        data: action.payload.response,
      };
      return newState;
    },
    fetchEntityTypeFailure: (state, action) => {
      const newState = {
        ...state,
        isLoading: false,
        error: action.payload,
      };
      return newState;
    },
  },
});

const { reducer, actions } = entityTypeRedux;
export const {
  fetchEntityTypeRequest,
  fetchEntityTypeSuccess,
  fetchEntityTypeFailure,
} = actions;
export default reducer;
