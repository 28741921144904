export const adminDashBoardStaticData: any = {
  superAdmin: {
    count: 1,
    useName: "super Admins",
    buttonTitle: "Invite Super Admin",
  },
  // onBoarding: {
  //   count: 12,
  //   useName: "Onboarding Questions",
  //   buttonTitle: "Create Onboarding Question",
  // },
  createProgramThemes: {
    count: 4,
    useName: "Program Themes",
    buttonTitle: "Create Program Theme",
  },
  entityOnboard: {
    count: 5,
    useName: "Entities",
    buttonTitle: "Onboard Entity",
  },
};
