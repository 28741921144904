import { createSlice } from "@reduxjs/toolkit";

export interface IOnboardingQuestion {
  isLoading?: boolean;
  error?: string;
  success?: string;
  data?: any;
  toasterDetails?: any;
  listsOnboardingQuestion?: any;
  isAttachmentDeleteSuccessful?:boolean,
  isAttachmentSuccessful?:boolean;
  isAttachmentUpdateSuccess?:boolean;
}

const initialState: IOnboardingQuestion = {
  isLoading: false,
  error: "",
  success: "",
  isAttachmentDeleteSuccessful:false,
  data: null,
  isAttachmentSuccessful:false,
  toasterDetails: null,
  isAttachmentUpdateSuccess:false

};

export const AttachmentRedux = createSlice({
    name: "AttachmentRedux",
    initialState,
    reducers: {
  
      // add new onboarding question
      addAttachmentRequest: (state: any, action: any) => {
        const newState = {
          ...state,
          isLoading: true,
        };
        return newState;
      },
      addAttachmentSuccess: (state: any, action: any) => {
        const newState = {
          ...state,
          isLoading: false,
          isAttachmentSuccessful:true,
          data: action.payload.data,
          toasterDetails: action?.payload,
        };
        return newState;
      },
      addAttachmentFailure: (state: any, action: any) => {
        const newState = {
          ...state,
          isLoading: false,
          toasterDetails: action?.payload,
        };
        return newState;
      },
  
      // attachment get request
      getAttachmentRequest: (state: any) => {
        const newState = {
          ...state,
          isLoading: true,
        };
        return newState;
      },
      getAttachmentSuccess: (state: any, action: any) => {
        const newState = {
          ...state,
          isLoading: false,
          listsAttachment: action?.payload?.response,
        };
        return newState;
      },
      getAttachmentFailure: (state: any, action: any) => {
        const newState = {
          ...state,
          isLoading: false,
          error: action?.payload,
        };
        return newState;
      },
  
  
     
      deleteAttachmentRequest: (state: any,action:any) => {
        const newState = {
          ...state,
          isLoading: true,
        };
        return newState;
      },
      deleteAttachmentSuccess: (state: any, action: any) => {
        const newState = {
          ...state,
          isLoading: false,
          isAttachmentDeleteSuccessful:true,
          toasterDetails: action?.payload,
        };
        return newState;
      },
      deleteAttachmentFailure: (state: any, action: any) => {
        const newState = {
          ...state,
          isLoading: false,
          error: action?.payload,
          toasterDetails: action?.payload,
        };
        return newState;
      },
  
      //updated the question 
      updateAttachmentRequest: (state: any) => {
        const newState = {
          ...state,
          isLoading: true,
        };
        return newState;
      },
      updateAttachmentSuccess: (state: any, action: any) => {
        const newState = {
          ...state,
          isLoading: false,
          isAttachmentUpdateSuccess: true,
          // toasterDetails: action?.payload,
        };
        return newState;
      },
      updateAttachmentFailure: (state: any, action: any) => {
        const newState = {
          ...state,
          isLoading: false,
          error: action?.payload,
          toasterDetails: action?.payload,
        };
        return newState;
      },

      setAttachmentState: (state: any, action: any) => {
        const newState = {
          ...state,
          [action.payload.key]: action.payload.value,
        };
        return newState;
      },
      setAttachmentNull: (state: any) => {
        const newState = {
          ...state,
          toasterDetails: null,
        };
        return newState;
      },
    },
  });
  
  const { actions, reducer } = AttachmentRedux;
  
  export const {
    addAttachmentRequest,
    addAttachmentSuccess,
    addAttachmentFailure,
  
    //on get
    getAttachmentRequest,
    getAttachmentSuccess,
    getAttachmentFailure,
  
    //on delete
    deleteAttachmentRequest,
    deleteAttachmentSuccess,
    deleteAttachmentFailure,
  
    //on update
    updateAttachmentRequest,
    updateAttachmentSuccess,
    updateAttachmentFailure,
  
  
    setAttachmentState,
    setAttachmentNull,
  } = actions;
  
  export default reducer;
  