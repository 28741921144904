import { createSlice } from "@reduxjs/toolkit";

interface IProgramAdminInfo {
  isLoading?: boolean;
  error?: string;
  success?: string;
  data?: any;
  toasterDetails?: any;
  isModified?: boolean;
}

const initialState: IProgramAdminInfo = {
  isLoading: false,
  error: "",
  success: "",
  data: null,
  toasterDetails: null,
  isModified: false,
};

export const programAdminRedux = createSlice({
  name: "programAdmin",
  initialState,
  reducers: {
    setProgramAdmin: (state: any, action: any) => {
      const newState = {
        ...state,
      };
      newState[action.payload.key] = action.payload.value;
      return newState;
    },
    addProgramAdminRequest(state) {
      return { ...state, isLoading: true };
    },
    editProgramAdminRequest(state, payload) {
      return { ...state, isModified: false, isLoading: true };
    },
    deleteProgramAdminRequest(state, payload) {
      return { ...state, isModified: false, isLoading: true };
    },
    fetchProgramAdminRequest(state, payload) {
      return { ...state, isLoading: true };
    },
    addProgramAdminSuccess(state, action) {
      return {
        ...state,
        data: action?.payload?.fetched
          ? action?.payload?.data?.response?.data[0]?.programAdminAssociatedToEntity
            ? action?.payload?.data?.response?.data[0]?.programAdminAssociatedToEntity
            : []
          : action?.payload?.data?.response, // Backend response object is different for GET and POST API
        isLoading: false,
        isSuccess: action?.payload?.fetched ? false : true,
        toasterDetails: action?.payload?.fetched // while editing we are updating this state by GET API call, for that time , this logic makes toaster value null
          ? null // because toaster should visible after update or create it
          : { code: 200, message: "Successfully Added" }, //  object hard-coded here, because success response returns empty array
      };
    },
    addProgramAdminFailure(state, action) {
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action?.payload?.data?.response,
        inviteAdminErrorArray: action?.payload?.response?.filter(
          (item: any) => item?.isSuccess === false
        ),
        toasterDetails: action?.payload?.fetched
          ? null
          : {
            code: action?.payload?.data?.code,
            message: action?.payload?.data?.response[0]?.value,
          },
      };
    },
    editProgramAdminSuccess(state, action) {
      return {
        ...state,
        isLoading: false,
        data: action?.payload?.response,
        isModified: true,
        toasterDetails: action?.payload?.response,
      };
    },
    editProgramAdminFailure(state, action) {
      return {
        ...state,
        isLoading: false,
        data: action?.payload?.response,
        toasterDetails: action?.payload?.response,
      };
    },
    deleteProgramAdminSuccess(state, action) {
      return {
        ...state,
        isLoading: false,
        data: action?.payload?.response,
        isModified: true,
        toasterDetails: action?.payload?.response,
        isProgramAdminDeleted: true,
      };
    },
    deleteProgramAdminFailure(state, action) {
      return {
        ...state,
        isLoading: false,
        data: action?.payload?.response,
        toasterDetails: action?.payload?.response,
      };
    },
    makeToasterNull(state) {
      return {
        ...state,
        toasterDetails: null,
      };
    },
    reset(state) {
      return {
        ...state,
        data: null,
        error: "",
        success: "",
      };
    },
  },
});

const { reducer, actions } = programAdminRedux;
export const {
  setProgramAdmin,
  addProgramAdminRequest,
  editProgramAdminRequest,
  deleteProgramAdminRequest,
  fetchProgramAdminRequest,
  addProgramAdminSuccess,
  addProgramAdminFailure,
  editProgramAdminSuccess,
  editProgramAdminFailure,
  deleteProgramAdminSuccess,
  makeToasterNull,
  reset,
} = actions;
export default reducer;
