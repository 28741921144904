import {
  Grid,
  MenuItem,
  InputAdornment,
  Select,
  Alert,
} from "@mui/material";
import { Form, Formik, getIn } from "formik";
import {
  ContactDetailsInitialState,
  ContactDetailsValidationSchema,
} from "../EntityManagementInitialState";
import { StringConstant } from "../../../resources/strings";
import styles from "./CreateEntityForm.module.css";
import MuiTextField from "../../../components/ui-elements/mui-text-field/MuiTextField";
import { handlePhoneNumberFieldChange } from "../../../utils/ValidationUtils";
import FormBottomButtons from "../../../components/form-bottom-btns/FormBottomButtons";
import { countryCodeJson } from "../../../utils/json/countryCode";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { setToaster } from "../../../redux/toaster/ToasterRedux";
import ErrorAlert from "../../../components/ui-elements/error-alert/ErrorAlert";
import {
  contactDetailsRedux,
  addContactDetailsRequest,
  fetchContactDetailsRequest,
  makeToasterNull,
  updateContactDetailsRequest,
} from "../../../redux/entity-management/ContactDetailsRedux";
import PageLoader from "../../../components/page-loader/PageLoader";

interface Props {
  tab: number;
  handleTabPrev: (value: number) => void;
  handleTabNext: (value: number) => void;
}
const ContactDetailsForm = (props: Props) => {
  const { tab, handleTabPrev, handleTabNext } = props;
  const location = useLocation();

  const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false);

  const { basicInfo, contactDetails } = useSelector((state: any) => state);
  const basicInfoOfEntity = basicInfo?.data;

  let isEntityOnboardingCompleted: any;
  let entityId = location?.state?.row?.id;

  if (basicInfoOfEntity) {
    isEntityOnboardingCompleted =
      basicInfoOfEntity?.isEntityOnboardingCompleted;
    entityId = basicInfoOfEntity?.id;
  }
  const contactDetailsOfEntity = {
    contactDetails: contactDetails?.data ? contactDetails?.data : [],
  }; // Fetch contactDetails from redux and made it similar to initial Formik object

  const actionDispatch = (dispatch: any) => ({
    setToasterNull: () => dispatch(makeToasterNull()),
    setToasterInfo: (data: any) => dispatch(setToaster(data)),
    getContactDetails: (entityId: any) =>
      dispatch(fetchContactDetailsRequest({ entityId })),
    addContactDetails: (requestObject: any) =>
      dispatch(addContactDetailsRequest(requestObject)),
    updateContactDetails: (requestObject: any) =>
      dispatch(updateContactDetailsRequest(requestObject)),
  });

  const {
    setToasterNull,
    setToasterInfo,
    getContactDetails,
    addContactDetails,
    updateContactDetails,
  } = actionDispatch(useDispatch());

  // >>>>>>>>>>>>>>>>> TOASTER FUNCTIONALITIES STARTS HERE >>>>>>>>>>>>>>>>>>>>>>>>>>>>

  useEffect(() => {
    if (contactDetails?.toasterDetails) {
      setToasterInfo({
        key: "toasterDetails",
        value: { ...contactDetails?.toasterDetails, toasterOpen: true },
      });
    }
  }, [contactDetails?.toasterDetails]);

  useEffect(() => {
    if (entityId) getContactDetails(entityId);
  }, []);

  useEffect(() => {
    return () => setToasterNull();
  }, []);

  // >>>>>>>>>>>>>>>>> TOASTER FUNCTIONALITIES ENDS HERE >>>>>>>>>>>>>>>>>>>>>>>>>>>>

  useEffect(() => {
    if (isFormSubmitted)
      contactDetailsOfEntity.contactDetails
        ? handleTabNext(tab)
        : setIsFormSubmitted(false);
  }, [contactDetailsOfEntity.contactDetails]); // Handlenext UseEffect Function

  const handleSubmit = (contactDetailsFromSubmit: any) => {
    // Sending API call function
    let requestObject = contactDetailsFromSubmit.contactDetails.map(
      (element: any) => {
        const object = { ...element };
        object.isEntityOnboardingCompleted = isEntityOnboardingCompleted;
        object.entityId = entityId;
        return object;
      }
    );
    contactDetailsOfEntity.contactDetails.length > 1
      ? updateContactDetails(requestObject)
      : addContactDetails(requestObject);

    setIsFormSubmitted(true);
  };

  const formikInitialState = () => {

    let formikInitialarray =
      contactDetailsOfEntity.contactDetails.length !== 0
        ? contactDetailsOfEntity.contactDetails
        : ContactDetailsInitialState.contactDetails;
    let newFormikInitialArray = formikInitialarray.map((element: any) => ({
      ...element,
      domain: basicInfoOfEntity?.domain ? basicInfoOfEntity?.domain : "",
    }));
    return { contactDetails: newFormikInitialArray };
  };
  let errorArray = [];
  !entityId && errorArray.push(StringConstant.pleaseCompleteProgramInfo);

  return (
    <>
      {errorArray.length > 0 && !contactDetails.isLoading ? <ErrorAlert errorArray={errorArray} /> : null}

      {contactDetails.isLoading ? (
        <div className={"loading-container"}>
          <PageLoader />
        </div>
      ) : null}
      <Formik
        initialValues={formikInitialState()}
        validationSchema={ContactDetailsValidationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          isValid,
          dirty,
          setFieldValue,
        }) => {
          return (
            <Form style={{
              height: "100 %",
              overflowY: "auto"
            }} >
              {ContactDetailsInitialState.contactDetails.map(
                (element, index) => (
                  <Grid
                    container
                    className={styles["form-sub-container"]}
                    gap={3}

                    key={index}
                  >
                    <Grid item xs={12}>
                      <p className={styles["entity-form-sub-heading"]}>
                        {index === 0
                          ? StringConstant.primaryContact
                          : StringConstant.escalationContact}
                      </p>
                    </Grid>
                    <Grid item xs={2.5} ml={3}>
                      <input
                        type="hidden"
                        name={`contactDetails[${index}].domain`}
                        value={basicInfoOfEntity?.domain}
                      />
                      <MuiTextField
                        className={styles["entity-form-text-field"]}
                        textfieldlabel={`First Name`}
                        name={`contactDetails[${index}].firstName`}
                        value={values.contactDetails[index].firstName}
                        placeholder={"Enter first name"}
                        onChange={handleChange}
                        onBlur={(e: any) => {
                          handleBlur(e)
                          console.log("handleBlur working in first name")
                        }}
                        required
                        helperText={
                          getIn(touched, `contactDetails.${index}.firstName`)
                            ? getIn(errors, `contactDetails.${index}.firstName`)
                            : ""
                        }
                        error={
                          getIn(touched, `contactDetails.${index}.firstName`) &&
                            getIn(errors, `contactDetails.${index}.firstName`)
                            ? true
                            : false
                        }
                      />
                    </Grid>
                    <Grid item xs={2.5}>
                      <MuiTextField
                        className={styles["entity-form-text-field"]}
                        textfieldlabel={`Last Name`}
                        name={`contactDetails[${index}].lastName`}
                        value={values.contactDetails[index].lastName}
                        placeholder={"Enter last name"}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                        helperText={
                          getIn(touched, `contactDetails.${index}.lastName`)
                            ? getIn(errors, `contactDetails.${index}.lastName`)
                            : ""
                        }
                        error={
                          getIn(touched, `contactDetails.${index}.lastName`) &&
                            getIn(errors, `contactDetails.${index}.lastName`)
                            ? true
                            : false
                        }
                      />
                    </Grid>
                    <Grid item xs={2.5}>
                      <input
                        type="text"
                        name={`contactDetails[${index}].domain`}
                        defaultValue={values.contactDetails[index].domain}
                        style={{ display: "none" }}
                      />
                      <MuiTextField
                        className={styles["entity-form-text-field"]}
                        textfieldlabel={`Work Email`}
                        name={`contactDetails[${index}].workEmail`}
                        value={values.contactDetails[index].workEmail}
                        placeholder={"Enter here"}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                        helperText={
                          getIn(touched, `contactDetails.${index}.workEmail`)
                            ? getIn(errors, `contactDetails.${index}.workEmail`)
                            : ""
                        }
                        error={
                          getIn(touched, `contactDetails.${index}.workEmail`) &&
                            getIn(errors, `contactDetails.${index}.workEmail`)
                            ? true
                            : false
                        }
                      />
                    </Grid>
                    <Grid item xs={2.5}>
                      <MuiTextField
                        className={styles["entity-form-text-field"]}
                        textfieldlabel={"Phone Number"}
                        key={index}
                        name={`contactDetails[${index}].phoneNumber`}
                        value={values.contactDetails[index].phoneNumber}
                        onChange={handlePhoneNumberFieldChange(
                          setFieldValue,
                          values.contactDetails[index].countryCode
                        )}
                        required
                        helperText={
                          getIn(touched, `contactDetails[${index}].phoneNumber`)
                            ? getIn(
                              errors,
                              `contactDetails[${index}].phoneNumber`
                            )
                            : ""
                        }
                        error={Boolean(
                          getIn(
                            touched,
                            `contactDetails[${index}].phoneNumber`
                          ) &&
                            getIn(
                              errors,
                              `contactDetails[${index}].phoneNumber`
                            )
                            ? true
                            : false
                        )}
                        onBlur={handleBlur}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Select
                                variant="standard"
                                disableUnderline
                                value={values.contactDetails[index].countryCode}
                                name={`contactDetails[${index}].countryCode`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                defaultValue={"+91"}
                              >
                                {countryCodeJson.map((element, index) => (
                                  <MenuItem
                                    value={element.dial_code}
                                    key={element.dial_code + index}
                                  >
                                    {element.dial_code}
                                  </MenuItem>
                                ))}
                              </Select>
                            </InputAdornment>
                          ),
                        }}
                        sx={{
                          "& input": {
                            borderLeft: `1px solid ${Boolean(
                              getIn(
                                touched,
                                `contactDetails.${index}.phoneNumber`
                              ) &&
                              getIn(
                                errors,
                                `contactDetails.${index}.phoneNumber`
                              )
                            )
                              ? "#f97973"
                              : "#c4c5ce"
                              }`,
                            paddingLeft: "10px",
                            borderRadius: "0px !important",
                          },
                          "&& .MuiSelect-select.MuiSelect-standard": {
                            paddingTop: "15%",
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                )
              )}
              <FormBottomButtons
                tab={tab}
                loading={basicInfo.isLoading ? true : false}
                handleTabNext={handleTabNext}
                handleTabPrev={handleTabPrev}
                // handleSubmit={handleSubmit}
                isDisabled={!entityId || !isValid || !dirty}
                next={contactDetailsOfEntity.contactDetails?.length === 0 || (isValid && dirty) ? false : true}
              // if "next" is "true" mean it will not make any api call, it simply navigates to next page
              // if "next" is "false" it will make Api calls
              />
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default ContactDetailsForm;
