import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import { FieldArray, Form, Formik, getIn } from "formik";
import React, { useState } from "react";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {
  createAdminInitialState,
  createAdminSchema,
} from "./SuperAdminInitialState";
import MuiTextField from "../../components/ui-elements/mui-text-field/MuiTextField";
import MuiButton from "../../components/ui-elements/mui-button/MuiButton";
import MuiLoadingButton from "../../components/ui-elements/mui-loading-button/MuiLoadingButton";
import { StringConstant } from "../../resources/strings";
import MediaAssets from "../../assets";
import { UsersRoleTypeEnum } from "../../enumerations/UsersRoleTypeEnum";
import { handleNumberFieldChange } from "../../utils/ValidationUtils";
import { useSelector } from 'react-redux';
import { countryCodeJson } from './../../utils/json/countryCode';
import { handlePhoneNumberFieldChange } from './../../utils/ValidationUtils';

const SuperAdminInviteDialog = (props: any) => {
  const { handleClickInviteClose, addInviteSuperAdmin, loggedInUser } = props;

  const { superAdminUserManagement } = useSelector(
    (state: any) => state
  );

  return (
    <Formik
      initialValues={createAdminInitialState}
      validationSchema={createAdminSchema}
      onSubmit={async (values) => {
        let reqBody = values?.createAdmin?.map((value: any) => {
          return {
            firstName: value?.superAdminFirstName,
            lastName: value?.superAdminLastName,
            email: value?.superAdminEmail,
            phoneNumber: value?.phoneNumber?.toString(),
            countryCode: value?.countryCode,
            roleId: Number(UsersRoleTypeEnum.SuperAdmin),
            entityId: loggedInUser?.entityId,
          };
        });

        addInviteSuperAdmin(reqBody);
        handleClickInviteClose();
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        isValid,
        dirty,
        setFieldValue,
      }) => (
        <Form>
          <FieldArray name="createAdmin">
            {({ insert, remove, push }) => (
              <div>
                {values?.createAdmin?.length > 0 &&
                  values?.createAdmin?.map((admin, index) => (
                    <Grid container gap={1.5} mt={1} key={index}>
                      <Grid item xl={2.5} lg={2.5}>
                        <MuiTextField
                          key={index}
                          textfieldlabel={index == 0 && "First Name"}
                          required={index == 0 && true}
                          name={`createAdmin.${index}.superAdminFirstName`}
                          value={values?.createAdmin[index].superAdminFirstName}
                          onChange={handleChange}
                          helperText={
                            getIn(
                              touched,
                              `createAdmin.${index}.superAdminFirstName`
                            )
                              ? getIn(
                                errors,
                                `createAdmin.${index}.superAdminFirstName`
                              )
                              : ""
                          }
                          error={
                            getIn(
                              touched,
                              `createAdmin.${index}.superAdminFirstName`
                            ) &&
                            getIn(
                              errors,
                              `createAdmin.${index}.superAdminFirstName`
                            )
                          }
                          onBlur={handleBlur}
                        />
                      </Grid>
                      <Grid item xl={2.5} lg={2.5}>
                        <MuiTextField
                          key={index}
                          textfieldlabel={index == 0 && "Last Name"}
                          required={index == 0 && true}
                          name={`createAdmin.${index}.superAdminLastName`}
                          value={values?.createAdmin[index].superAdminLastName}
                          onChange={handleChange}
                          helperText={
                            getIn(
                              touched,
                              `createAdmin.${index}.superAdminLastName`
                            )
                              ? getIn(
                                errors,
                                `createAdmin.${index}.superAdminLastName`
                              )
                              : ""
                          }
                          error={
                            getIn(
                              touched,
                              `createAdmin.${index}.superAdminLastName`
                            ) &&
                            getIn(
                              errors,
                              `createAdmin.${index}.superAdminLastName`
                            )
                          }
                          onBlur={handleBlur}
                        />
                      </Grid>
                      <Grid item xl={2.5} lg={2.7}>
                        <MuiTextField
                          textfieldlabel={
                            index == 0 && (
                              <div>
                                Email ID *
                                <span
                                  style={{
                                    fontStyle: "italic",
                                    fontSize: "12px",
                                  }}
                                >
                                  (e.g.name@frientor.io)
                                </span>
                              </div>
                            )
                          }
                          key={index}
                          type="email"
                          name={`createAdmin.${index}.superAdminEmail`}
                          value={values.createAdmin[index].superAdminEmail}
                          onChange={handleChange}
                          helperText={
                            getIn(
                              touched,
                              `createAdmin.${index}.superAdminEmail`
                            )
                              ? getIn(
                                errors,
                                `createAdmin.${index}.superAdminEmail`
                              )
                              : ""
                          }
                          error={
                            getIn(
                              touched,
                              `createAdmin.${index}.superAdminEmail`
                            ) &&
                            getIn(
                              errors,
                              `createAdmin.${index}.superAdminEmail`
                            )
                          }
                          onBlur={handleBlur}
                        />
                      </Grid>
                      <Grid item xl={2.5} lg={2.5}>
                        <MuiTextField
                          textfieldlabel={index == 0 && "Phone Number"}
                          required={index == 0 && true}
                          key={index}
                          name={`createAdmin.${index}.phoneNumber`}
                          value={values.createAdmin[index].phoneNumber}
                          // onChange={handleNumberFieldChange(setFieldValue)}
                          onChange={handlePhoneNumberFieldChange(
                            setFieldValue,
                            values.createAdmin[index].countryCode
                          )}
                          helperText={
                            getIn(touched, `createAdmin.${index}.phoneNumber`)
                              ? getIn(
                                errors,
                                `createAdmin.${index}.phoneNumber`
                              )
                              : ""
                          }
                          error={Boolean(
                            getIn(
                              touched,
                              `createAdmin.${index}.phoneNumber`
                            ) &&
                            getIn(errors, `createAdmin.${index}.phoneNumber`)
                          )}
                          onBlur={handleBlur}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Select
                                  name={`createAdmin.${index}.countryCode`}
                                  variant="standard"
                                  disableUnderline
                                  defaultValue={"+91"}
                                  onBlur={handleBlur}
                                  value={values.createAdmin[index].countryCode}
                                  onChange={(e: any) =>
                                    setFieldValue(
                                      `createAdmin.${index}.countryCode`,
                                      e.target.value
                                    )
                                  }
                                >
                                  {countryCodeJson.map((element: any, index: any) => (
                                    <MenuItem value={element.dial_code} key={index}>
                                      {element.dial_code}
                                    </MenuItem>
                                  ))}
                                </Select>
                                {/* <img src={MediaAssets.ic_divider} /> */}
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xl={1} mt={0.5}>
                        {index > 0 && (
                          <IconButton onClick={() => remove(index)}>
                            <img src={MediaAssets.ic_delete} width={30} />
                          </IconButton>
                        )}
                      </Grid>
                    </Grid>
                  ))}
                <Box mt={2} mb={3}>
                  <MuiButton
                    variant="outlined"
                    disabled={values?.createAdmin?.length < 4 ? false : true}
                    onClick={() =>
                      push({
                        superAdminFirstName: "",
                        superAdminLastName: "",
                        superAdminEmail: "",
                        phoneNumber: "",
                        countryCode: "+91",
                      })
                    }
                  >
                    {StringConstant.inviteAnotherUserButtonText}
                  </MuiButton>
                </Box>
              </div>
            )}
          </FieldArray>
          <hr className={"horizontal-border"} />
          <Stack direction="row" justifyContent={"flex-end"} spacing={2} mt={2}>
            <MuiButton className={"white-bg-button"} onClick={handleClickInviteClose}>
              Cancel
            </MuiButton>
            <MuiLoadingButton className={"loading-button"} loading={superAdminUserManagement?.isLoading} tab={true} disabled={!isValid || !dirty} type="submit">
              Send Invite
            </MuiLoadingButton>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

export default SuperAdminInviteDialog;
