import { createSlice } from "@reduxjs/toolkit";

export interface IThemeQuestion {
  isLoading?: boolean;
  error?: string;
  success?: string;
  data?: any;
  toasterDetails?: any;
  listsProgramThemeQuestion?: any;
  editThemeQuestion: any;
  themeQuestionId: string;
  isProgramThemeQuestionDeleted: boolean;
  eachThemeQuestion?: any;
}

const initialState: IThemeQuestion = {
  isLoading: false,
  error: "",
  success: "",
  data: null,
  toasterDetails: null,
  listsProgramThemeQuestion: [],
  editThemeQuestion: null,
  themeQuestionId: "",
  isProgramThemeQuestionDeleted: false,
  eachThemeQuestion: null,
};

export const ProgramThemeQuestionRedux = createSlice({
  name: "programThemeQuestionRedux",
  initialState,
  reducers: {
    // add new  question
    addProgramThemeQuestionRequest: (state: any, action: any) => {
      const newState = {
        ...state,
        isLoading: true,
      };
      // dispatchEvent({type:"",payload:{}})
      return newState;
    },
    addProgramThemeQuestionSuccess: (state: any, action: any) => {
      const newState = {
        ...state,
        isLoading: false,
        editThemeQuestion: action.payload.response,
        data: action.payload.data,
        toasterDetails: action?.payload,
      };
      return newState;
    },
    addProgramThemeQuestionFailure: (state: any, action: any) => {
      const newState = {
        ...state,
        isLoading: false,
        toasterDetails: action?.payload,
      };
      return newState;
    },

    //  get request
    getProgramThemeQuestionRequest: (state: any, action: any) => {
      const newState = {
        ...state,
        isLoading: true,
      };
      return newState;
    },
    getProgramThemeQuestionSuccess: (state: any, action: any) => {
      const newState = {
        ...state,
        isLoading: false,
        listsProgramThemeQuestion: action?.payload?.response
          ? action?.payload?.response
          : [],
      };
      return newState;
    },
    getProgramThemeQuestionFailure: (state: any, action: any) => {
      const newState = {
        ...state,
        isLoading: false,
        error: action?.payload,
        toasterDetails: action?.payload,
      };
      return newState;
    },

    //delete  question
    deleteProgramThemeQuestionRequest: (state: any, action: any) => {
      const newState = {
        ...state,
        isLoading: true,
      };
      return newState;
    },
    deleteProgramThemeQuestionSuccess: (state: any, action: any) => {
      const newState = {
        ...state,
        isLoading: false,
        isProgramThemeQuestionDeleted: true,
        toasterDetails: action?.payload,
      };
      return newState;
    },
    deleteProgramThemeQuestionFailure: (state: any, action: any) => {
      const newState = {
        ...state,
        isLoading: false,
        error: action?.payload,
        toasterDetails: action?.payload,
      };
      return newState;
    },

    //updated the question
    updateProgramThemeQuestionRequest: (state: any, action: any) => {
      const newState = {
        ...state,
        isLoading: true,
      };
      return newState;
    },
    updateProgramThemeQuestionSuccess: (state: any, action: any) => {
      const newState = {
        ...state,
        isLoading: false,
        // editThemeQuestion:
        toasterDetails: action?.payload,
      };
      return newState;
    },
    updateProgramThemeQuestionFailure: (state: any, action: any) => {
      const newState = {
        ...state,
        isLoading: false,
        error: action?.payload,
        toasterDetails: action?.payload,
      };
      return newState;
    },

    //get Each onboarding question

    getEachThemeQuestionRequest: (state: any, action: any) => {
      const newState = {
        ...state,
        isLoading: true,
      };
      return newState;
    },
    getEachThemeQuestionSuccess: (state: any, action: any) => {
      const newState = {
        ...state,
        isLoading: false,
        eachThemeQuestion: action?.payload?.response,
      };
      return newState;
    },
    getEachThemeQuestionFailure: (state: any, action: any) => {
      const newState = {
        ...state,
        isLoading: false,
        error: action?.payload,
      };
      return newState;
    },

    setProgramThemeState: (state: any, action: any) => {
      const newState = {
        ...state,
        [action.payload.key]: action.payload.value,
      };
      return newState;
    },
    setProgramThemeToasterNull: (state: any) => {
      const newState = {
        ...state,
        toasterDetails: null,
      };
      return newState;
    },
  },
});

const { actions, reducer } = ProgramThemeQuestionRedux;

export const {
  addProgramThemeQuestionRequest,
  addProgramThemeQuestionSuccess,
  addProgramThemeQuestionFailure,

  //on get
  getProgramThemeQuestionRequest,
  getProgramThemeQuestionSuccess,
  getProgramThemeQuestionFailure,

  //on delete
  deleteProgramThemeQuestionRequest,
  deleteProgramThemeQuestionSuccess,
  deleteProgramThemeQuestionFailure,

  //get each programtheme
  getEachThemeQuestionRequest,
  getEachThemeQuestionSuccess,
  getEachThemeQuestionFailure,
  //on update
  updateProgramThemeQuestionRequest,
  updateProgramThemeQuestionSuccess,
  updateProgramThemeQuestionFailure,

  setProgramThemeState,
  setProgramThemeToasterNull,
} = actions;

export default reducer;
