import { SagaIterator } from "@redux-saga/types";
import { call, put } from "redux-saga/effects";
import { HttpResponseTypeEnum } from "../../../enumerations/HttpResponseTypeEnum";
import { TopicCategoryRedux } from "../../../redux/program-theme/topic-category/TopicCategoryRedux";

//getAllquestion
export function* getTopicCategorySaga(api: any, action: any): SagaIterator {
    try {
        const response = yield call(api.getAllTopicCategory, action.payload);
        if (response?.data?.code === HttpResponseTypeEnum.Success) {
            yield put(
                TopicCategoryRedux.actions.getTopicCategorySuccess(
                    response.data
                )
            );
        } else {
            yield put(
                TopicCategoryRedux.actions.getTopicCategoryFailure(
                    response.data
                )
            );
        }
    } catch (error: any) {
        yield put(
            TopicCategoryRedux.actions.getTopicCategoryFailure(error)
        );
    }
}