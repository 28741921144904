import { Box, useTheme } from "@mui/material";

interface ReactNodeProps {
    children: React.ReactNode;
}

const FormFieldContainer = (props: ReactNodeProps) => {
    const theme = useTheme();
    const { children } = props;
    return (
        <Box
            position={"relative"}
            mt={"1.5rem"}
            border={`2px solid ${theme.palette.grey.A200}`}
            borderRadius={"2rem"}
            component={"section"}
            sx={{ height: "calc(100% - 15px)" }}
            {...props}
        >
            {children}
        </Box>
    );
};

export default FormFieldContainer;
