import { Fragment } from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { AppRoutes } from "./routes/AppRoutes";
import ThemeOverrides from "./mui-themes/ThemeOverrides";
import Layout from "./components/layout/Layout";
import { ToastProvider } from "./context/ToastContext";

const App = () => {
  return (
    <Fragment>
      <CssBaseline />
      <ThemeProvider theme={ThemeOverrides}>
        <ToastProvider>
        <AppRoutes />
        </ToastProvider>
        {/* <Layout>
          <div>HI</div>
        </Layout> */}
      </ThemeProvider>
    </Fragment>
  );
};

export default App;
