export enum FileUploadMIMETypeEnum {
  PNG = "1001",
  JPEG = "1002",
  GIF = "1003",
  Document = "1004",
  Audio = "1005",
  Video = "1006",
}

export enum AttachmentTypeEnum {
  ProfilePic = "1001",
  Avatar = "1002",
  Banner = "1003",
  SessionTemplate = "1004",
  Catalogue = "1005",
  Other = "1006",
}
