import { createSlice } from "@reduxjs/toolkit";
import { AnyARecord } from "dns";
import { AttachmentTypeEnum } from "../../../enumerations/FileUploadMIMETypeEnum";

export interface ICreateProgramTheme {
  isLoading?: boolean;
  error?: string;
  success?: string;
  data?: any;
  toasterDetails?: any;
  listsCreateProgramTheme?: any;
  reduxProgramTheme?: any;
  guideLineData?: any
  programThemeId?: any;
  listProgramTheme?: any;
  isProgramThemeBasicInfoUpdate?: boolean;
  isLinkedUpadate?:boolean;
}

const initialState: ICreateProgramTheme = {
  isLoading: false,
  error: "",
  success: "",
  data: null,
  toasterDetails: null,
  reduxProgramTheme: null,
  programThemeId: "",
  listProgramTheme: null,
  guideLineData: null,
  isProgramThemeBasicInfoUpdate: false,
  isLinkedUpadate:false
}

export const CreateProgramThemeRedux = createSlice({
  name: "createProgramThemeRedux",
  initialState,
  reducers: {
    // add new create theme question
    addCreateProgramThemeRequest: (state: any, action: any) => {
      const newState = {
        ...state,
        isLoading: true,
      };

      return newState;
    },
    addCreateProgramThemeSuccess(state: any, action: any) {
      const { programTheme, attachments } = action?.payload?.response;
      const reduxProgramTheme = {
        themeBasicInfo: {
          themeName: programTheme?.response.name,
          themeDescription: programTheme.response.description,
          themeBannerImage: programTheme.response.bannerName,
          themeBannerFileName: programTheme.response.bannerPath,
          // themeBannerImageId: attachments.response[0].id,
        },
      };

      const newState = {
        ...state,
        isLoading: false,
        data: action?.payload?.response,
        toasterDetails: action?.payload,
        reduxProgramTheme: reduxProgramTheme,
        programThemeId: programTheme.response.id,

      };
      return newState;
    },
    addCreateProgramThemeFailure: (state: any, action: any) => {
      const newState = {
        ...state,
        isLoading: false,
        toasterDetails: action?.payload,
      };
      return newState
    },

    // program theme  allget request
    getProgramThemeRequest: (state: any) => {
      const newState = {
        ...state,
        isLoading: true,
      };
      return newState;
    },
    getProgramThemeSuccess: (state: any, action: any) => {
      const newState = {
        ...state,
        isLoading: false,
        listProgramTheme: action?.payload?.response,
      };
      return newState;
    },
    getProgramThemeFailure: (state: any, action: any) => {
      const newState = {
        ...state,
        isLoading: false,
        error: action?.payload,
        toasterDetails: action?.payload,
      };
      return newState;
    },

    // get each theme program theme

    getEachProgramThemeRequest: (state: any, action: any) => {
      const newState = {
        ...state,
        isLoading: true,
      };
      return newState;
    },
    getEachProgramThemeSuccess: (state: any, action: any) => {
      const { attachments, description, name, bannerPath, bannerName, guidelineName, guidelinePath } = action?.payload?.response;
      const bannerAttchment = attachments?.find((banner: any, index: any) => banner?.attachmentType === "1003") // it is for banner
      const guideLineAttachment = attachments?.find((guideLine: any, index: any) => guideLine?.attachmentType === "1004") // it is for guideline
      const guideLineLink = attachments?.find((guideLineLink: any) => guideLineLink?.link?.length > 0)
      const reduxProgramTheme = {
        themeBasicInfo: {
          themeName: name,
          themeDescription: description,
          themeBannerImage: bannerPath,
          themeBannerFileName: bannerName,
          themeBannerImageId: bannerAttchment?.id,
        },
      };


      const ReduxthemeGuidelines = {
        themeGuidelines: {
          guidelineType: guideLineAttachment?.fileName ? 1 : guideLineLink?.link ? 2 : 1,
          guidelineFileValue: guidelinePath,
          guideLineFileName: guidelineName,
          guideLineFileId: guideLineAttachment?.id ? guideLineAttachment?.id : guideLineLink?.id,
          guidelineTextValue: guideLineLink?.link ? guideLineLink?.link : "",
        }
      }
      const newState = {
        ...state,
        data: action?.payload?.response,
        isLoading: false,
        reduxProgramTheme: reduxProgramTheme,
        guideLineData: ReduxthemeGuidelines
      };
      return newState;
    },
    getEachProgramThemeFailure: (state: any, action: any) => {
      const newState = {
        ...state,
        isLoading: false,
        error: action?.payload,
        toasterDetails: action?.payload,
      };
      return newState;
    },
    //delete program theme question
    deleteCreateProgramThemeRequest: (state: any) => {
      const newState = {
        ...state,
        isLoading: true,
      };
      return newState;
    },
    deleteCreateProgramThemeSuccess: (state: any, action: any) => {
      const newState = {
        ...state,
        isLoading: false,
        toasterDetails: action?.payload,
      };
      return newState;
    },
    deleteCreateProgramThemeFailure: (state: any, action: any) => {
      const newState = {
        ...state,
        isLoading: false,
        error: action?.payload,
        toasterDetails: action?.payload,
      };
      return newState;
    },

    //updated the question
    updateCreateProgramThemeRequest: (state: any, action: any) => {
      const newState = {
        ...state,
        isLoading: true,
      };
      return newState;
    },
    updateCreateProgramThemeSuccess: (state: any, action: any) => {
      const newState = {
        ...state,
        isLoading: false,
        toasterDetails: action?.payload,
        isProgramThemeBasicInfoUpdate: true
      };
      return newState;
    },
    updateCreateProgramThemeFailure: (state: any, action: any) => {
      const newState = {
        ...state,
        isLoading: false,
        error: action?.payload,
        toasterDetails: action?.payload,
      };
      return newState;
    },


    //program theme GuideLine,
    addProgramThemeGuidelineRequest: (state: any, action: any) => {
      const newState = {
        ...state,
        isLoading: true,
      };
      // dispatchEvent({type:"",payload:{}})
      return newState;
    },
    addProgramThemeGuidelineSuccess(state: any, action: any) {
      const ReduxthemeGuidelines = {
        themeGuidelines: {
          guidelineType: action.payload.response[0].link ? 2 : 1,
          guidelineFileValue: action.payload.response[0].publicUrl,
          guideLineFileName: action?.payload.response[0].fileName,
          guideLineFileId: action?.payload.response[0].id,
          guidelineTextValue: action?.payload.response[0].link ? action?.payload.response[0].link : "",
        }
      }
      const newState = {
        ...state,
        isLoading: false,
        guideLineData: ReduxthemeGuidelines,
        data: action.payload.response,
        toasterDetails: action?.payload,
        // reduxProgramTheme: reduxProgramTheme,
        // programThemeId: programTheme.response.id
      };
      return newState;
    },
    addProgramThemeGuidelineFailure: (state: any, action: any) => {
      const newState = {
        ...state,
        isLoading: false,
        toasterDetails: action?.payload,
      };
      return newState;
    },

    updateProgramThemeGuidelineRequest: (state: any) => {
      const newState = {
        ...state,
        isLoading: true,
      };
      return newState;
    },
    updateProgramThemeGuidelineSuccess: (state: any, action: any) => {
      const newState = {
        ...state,
        isLoading: false,
        toasterDetails: action?.payload,
        isLinkedUpadate:true
      };
      return newState;
    },
    updateProgramThemeGuidelineFailure: (state: any, action: any) => {
      const newState = {
        ...state,
        isLoading: false,
        error: action?.payload,
        toasterDetails: action?.payload,
      };
      return newState;
    },


    setCreateProgramTheme: (state: any, action: any) => {
      const newState = {
        ...state,
        [action.payload.key]: action.payload.value,
      };
      return newState;
    },
    setCreateProgramThemeNull: (state: any) => {
      const newState = {
        ...state,
        toasterDetails: null,
      };
      return newState;
    },
  },
});

const { actions, reducer } = CreateProgramThemeRedux;

export const {
  addCreateProgramThemeRequest,
  addCreateProgramThemeSuccess,
  addCreateProgramThemeFailure,

  //on get
  getProgramThemeRequest,
  getProgramThemeSuccess,
  getProgramThemeFailure,

  //each getUpadate
  getEachProgramThemeRequest,
  getEachProgramThemeSuccess,
  getEachProgramThemeFailure,

  //on delete
  deleteCreateProgramThemeRequest,
  deleteCreateProgramThemeSuccess,
  deleteCreateProgramThemeFailure,

  //on update
  updateCreateProgramThemeRequest,
  updateCreateProgramThemeSuccess,
  updateCreateProgramThemeFailure,

  //guideline


  addProgramThemeGuidelineRequest,
  addProgramThemeGuidelineSuccess,
  addProgramThemeGuidelineFailure,

  updateProgramThemeGuidelineRequest,
  updateProgramThemeGuidelineSuccess,
  updateProgramThemeGuidelineFailure,

  setCreateProgramTheme,
  setCreateProgramThemeNull,
} = actions;

export default reducer;
