import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { StatsCardStyleWrapper } from './StatsCard.style';
import MuiButton from '../../../components/ui-elements/mui-button/MuiButton';
// import StatsCard  from "./StatsCard.style.ts"

interface StatsCardInterface {
    itemCount?: Number;
    buttonTitle?: string;
    cardName?: string;
    redirectLink?: string;
    handleClick?: () => void;
}


const StatsCard = (props: StatsCardInterface) => {
    const { buttonTitle, itemCount = 0, cardName = "", redirectLink, handleClick } = props

    return (
        <StatsCardStyleWrapper>
            <Typography className={"stat-card-title"} >
                {cardName}
            </Typography>
            <Box>
                    <Typography className={"stat-card-count"} >
                    {(itemCount).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })}
                </Typography>
            </Box>
            <Box mt={1}>
                <MuiButton
                    variant='contained'
                    onClick={handleClick}
                    className="stat-button-font"
                >
                    {buttonTitle}
                </MuiButton>
            </Box>
     
        </StatsCardStyleWrapper>
    );
};

export default StatsCard;