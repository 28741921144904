import { Box, Grid, InputLabel } from "@mui/material";
import { useEffect, useState } from "react";
import { Form, Formik, getIn } from "formik";
import {
  InitialProgramThemeState,
  ProgramThemeValidation,
} from "../ProgramThemeInitialState";
import FileUploadDragDrop from "../../../components/file-upload-drag-drop/FileUploadDragDrop";
import MuiTextField from "../../../components/ui-elements/mui-text-field/MuiTextField";
import { Constants } from "../../../resources/constants";
import { extractFileType } from "../../../utils/FileOperationUtils";
import styles from "./CreateThemeForm.module.css";
import FormBottomButtons from "./../../../components/form-bottom-btns/FormBottomButtons";
import {
  AttachmentTypeEnum,
  FileUploadMIMETypeEnum,
} from "../../../enumerations/FileUploadMIMETypeEnum";
import {
  addCreateProgramThemeRequest,
  getEachProgramThemeRequest,
  setCreateProgramTheme,
  setCreateProgramThemeNull,
  updateCreateProgramThemeRequest,
} from "../../../redux/program-theme/create-program-theme/CreateProgramThemeRedux";
import {
  fileUploadS3Request,
  fileUploadS3UpdateRequest,
  setFileUploadS3Update,
} from "../../../redux/s3-upload/FileUploadS3Redux";

import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { setToaster } from "../../../redux/toaster/ToasterRedux";
import PageLoader from "../../../components/page-loader/PageLoader";
import { height } from '@mui/system';
import { StringConstant } from "../../../resources/strings";
interface IProgramThemesBasicInfo {
  tab?: number;
  handleTabPrev?: (event: any) => void;
  handleTabNext: (event: any) => void;
  handleValidMove?: (validMove: boolean) => void;
  handleClick?: any,
}

const ProgramThemesBasicInfo = (props: IProgramThemesBasicInfo) => {
  const actionDispatch = (dispatch: any) => ({
    addCreateProgramTheme: (data: any) =>
      dispatch(addCreateProgramThemeRequest(data)),
    uploadfileS3: (data: any) => dispatch(fileUploadS3Request(data)),
    updateFileS3: (data: any) => dispatch(fileUploadS3UpdateRequest(data)),
    setFileUpload: (data: any) => dispatch(setFileUploadS3Update(data)),
    getEachProgramTheme: (data: any) =>
      dispatch(getEachProgramThemeRequest(data)),
    setProgramTheme: (data: any) => dispatch(setCreateProgramTheme(data)),
    updateCreateProgramTheme: (data: any) =>
      dispatch(updateCreateProgramThemeRequest(data)),
    // updateOnboardingQuestions: (data: any) => (dispatch(updateOnboardingQuestionRequest(data))),
    setCreateThemeNull: () => dispatch(setCreateProgramThemeNull()),

    setToasterInfo: (data: any) => dispatch(setToaster(data)),
  });
  const {
    addCreateProgramTheme,
    setFileUpload,
    uploadfileS3,
    getEachProgramTheme,
    updateCreateProgramTheme,
    setProgramTheme,
    updateFileS3,
    setCreateThemeNull,
    setToasterInfo
  } = actionDispatch(useDispatch());

  const { createProgramTheme, fileUploader } = useSelector(
    (state: any) => state
  );


  const { tab, handleTabPrev, handleTabNext, handleValidMove } = props;
  const [postMediaData, setPostMediaData] = useState<any[]>([]);
  const [previewFile, setpreviewFile] = useState<boolean | string>(false);
  const [fileName, setFileName] = useState<string>("");
  const [objectURL, setObjectURL] = useState<string>("");
  const [fileError, setFileError] = useState<string | false>(false);
  const [isFormSubmit, setIsFormSubmit] = useState<boolean>(false);
  const [createProgramThemeRequest, setCreateProgramThemeRequest] = useState<any>(null);
  const [updateProgramTheme, setUpdateProgram] = useState<any>(null)
  useEffect(() => {
    if (createProgramTheme?.programThemeId) {
      getEachProgramTheme(createProgramTheme?.programThemeId);
    }
  }, []);

  useEffect(() => {
    if (createProgramTheme?.toasterDetails) {
      setToasterInfo({
        key: "toasterDetails",
        value: { ...createProgramTheme?.toasterDetails, toasterOpen: true },
      });
      setCreateThemeNull()
    }
  }, [createProgramTheme.toasterDetails])

  useEffect(() => {
    if (createProgramTheme?.isProgramThemeBasicInfoUpdate) {
      setToasterInfo({
        key: "toasterDetails",
        value: { ...createProgramTheme?.toasterDetails, toasterOpen: true },
      });
      setCreateThemeNull()
      setProgramTheme({ key: "isProgramThemeBasicInfoUpdate", value: false })
      handleTabNext(tab)
      // setIsFormSubmit(false)
    }

  }, [createProgramTheme?.isProgramThemeBasicInfoUpdate])

  useEffect(() => {
    if (isFormSubmit) {
      setCreateThemeNull()
      createProgramTheme?.reduxProgramTheme
        ? handleTabNext(tab)
        : setIsFormSubmit(false);

    }
  }, [createProgramTheme.reduxProgramTheme]);

  useEffect(() => {
    if (fileUploader?.singleImageUpload) {
      addCreateProgramTheme({
        ...createProgramThemeRequest,
        attachments: [
          {
            attachmentType: AttachmentTypeEnum.Banner,
            contentType:
              fileName?.split(".")[1]?.toUpperCase() ===
                FileUploadMIMETypeEnum?.PNG
                ? FileUploadMIMETypeEnum?.PNG
                : FileUploadMIMETypeEnum?.JPEG,
            publicUrl: fileUploader?.singleImageUpload,
            fileName: fileName,
          },
        ],
      });
      setFileUpload({ key: "singleImageUpload", value: "" });
      setIsFormSubmit(true);

    }
  }, [fileUploader?.singleImageUpload]);

  useEffect(() => {
    if (fileUploader.fileUpadtedInS3) {
      updateCreateProgramTheme(updateProgramTheme)
      setFileUpload({ key: "fileUpadtedInS3", value: false });
      setFileUpload({ key: "toasterDetails", value: null })
      // setProgramTheme({key:"toasterDetails",value:null})
      setIsFormSubmit(true);
    }

  }, [fileUploader.fileUpadtedInS3])

  const handleDrop = (e: any, setFieldValue: any) => {
    e.preventDefault();
    e.stopPropagation();
    const accept = ["jpg", "jpeg", "png"];
    if (
      e.dataTransfer.files[0].size / Constants.one_mega_byte <
      Constants.max_file_size
    ) {
      if (accept.includes(extractFileType(e.dataTransfer.files[0].name))) {
        handleMediaUpload(e.dataTransfer.files[0]);
        setFieldValue(
          "themeBasicInfo.themeBannerImage",
          URL.createObjectURL(e.dataTransfer.files[0])
        );
        // setFieldValue("themeBasicInfo.themeBannerImage", "image uploaded");
        setFileError(false);
      } else {
        setFileError('Please choose "jpg,jpeg,png" file type.');
      }
    } else {
      setPostMediaData([]);
      setpreviewFile(false);
      setFileError("File size exceeds Max Limit.");
    }

  };


  const handleSubmit = async (values:any) => {
    // update 
    if (createProgramTheme?.programThemeId) {
      if (postMediaData?.length) {
        const data: any = {
          name: fileName,
          urlType: "upload",
          binary: "",
          type: "image/png",
          oldFileName: createProgramTheme?.reduxProgramTheme?.themeBasicInfo?.themeBannerFileName,
          attachmentId: createProgramTheme?.reduxProgramTheme?.themeBasicInfo?.themeBannerImageId,
          programThemeId: createProgramTheme?.programThemeId,
          attachmentType: AttachmentTypeEnum.Banner,
          contentType:
            fileName?.split(".")[1]?.toUpperCase() ===
              FileUploadMIMETypeEnum?.PNG
              ? FileUploadMIMETypeEnum?.PNG
              : FileUploadMIMETypeEnum?.JPEG,
        };
        const reader = new FileReader();
        reader.onload = async (e: any) => {
          data.binary = e.target.result;
          const reqBody = {
            programThemeId: createProgramTheme?.programThemeId,
            name: values?.themeBasicInfo?.themeName,
            description: values?.themeBasicInfo?.themeDescription,
            isPublished: false,
          };
          await updateFileS3(data);
          setUpdateProgram(reqBody);
        };
        reader?.readAsArrayBuffer(postMediaData[0]?.data);
      } else {
        updateCreateProgramTheme({
          programThemeId: createProgramTheme?.programThemeId,
          name: values?.themeBasicInfo?.themeName,
          description: values?.themeBasicInfo?.themeDescription,
          isPublished: false,
        })

      }

      // updateCreateProgramTheme(reqBody);
    } else {

      //upload
      const data: any = {
        name: fileName,
        urlType: "upload",
        binary: "",
        type: "image/png",
      };
      const reader = new FileReader();
      reader.onload = async (e: any) => {
        data.binary = e.target.result;
        await uploadfileS3(data);
        const reqBody = {
          name: values?.themeBasicInfo?.themeName,
          description: values?.themeBasicInfo?.themeDescription,
        };
        setCreateProgramThemeRequest(reqBody);
      };
      reader.readAsArrayBuffer(postMediaData[0].data);
    }
  };


  const handleFileChange = (e: any, setFieldValue: any) => {
    e.preventDefault();
    const accept = ["jpg", "jpeg", "png"];
    if (
      e.target.files[0].size / Constants.one_mega_byte <
      Constants.max_file_size
    ) {
      if (accept.includes(extractFileType(e.target.files[0].name))) {
        handleMediaUpload(e.target.files[0]);
        setFieldValue(
          "themeBasicInfo.themeBannerImage",
          URL.createObjectURL(e.target.files[0])
        );
        setFileError(false);
      } else {
        setFileError('Please choose "jpg,jpeg,png" file type.');
      }
    } else {
      // handleClear();
      setPostMediaData([]);
      setpreviewFile(false);
      setFileError(StringConstant.fileisTooLarge);
    }
  };

  const handleDrag = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleMediaUpload = (data: any) => {
    const uploadedMedia: any[] = [...postMediaData];

    uploadedMedia.push({ data });
    let fileURL;
    if (fileURL) {
      URL.revokeObjectURL(fileURL);
    }
    fileURL = URL.createObjectURL(data);
    setObjectURL(fileURL);
    setPostMediaData([...uploadedMedia]);
    setFileName(data.name);
    setpreviewFile(fileURL);
  };

  const handleClear = (e: any, setFieldValue: any) => {
    setPostMediaData([]);
    setpreviewFile(false);
    setFieldValue("themeBasicInfo.themeBannerImage", "");
    setFieldValue("themeBasicInfo.themeBannerFileName", "");

    // if (
    //   createProgramTheme?.reduxProgramTheme?.themeBasicInfo?.themeBannerImage
    // ) {
    //   setProgramTheme({
    //     key: "reduxProgramTheme",
    //     value: {
    //       themeBasicInfo: {
    //         ...createProgramTheme?.reduxProgramTheme?.themeBasicInfo,
    //         themeBannerImage: "",
    //       },
    //     },
    //   });
    // }
  };
  return (
    <Formik
      initialValues={
        createProgramTheme?.reduxProgramTheme || InitialProgramThemeState
      }
      validationSchema={ProgramThemeValidation}
      onSubmit={(values:any) => handleSubmit(values)}
      enableReinitialize={true}
    >
      {({
        values,
        setFieldValue,
        handleChange,
        handleBlur,
        errors,
        touched,
        isValid,
        dirty,
      }) => {

        return (
          <Form style={{ height: "100%" }}>
            {/* <div style={{fontSize: "12px"}}>Testtt{isValid}- {dirty}- {JSON.stringify(createProgramTheme)}</div> */}
            {createProgramTheme.isLoading ? <PageLoader /> : <Box className={styles["program-theme-inner-container"]}>
              <Grid container pr={4}>
                <Grid item xs={8} mt={2} ml={3}>
                  <MuiTextField
                    textfieldlabel={"Theme name"}
                    name="themeBasicInfo.themeName"
                    placeholder="Enter here"
                    value={values.themeBasicInfo.themeName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      getIn(touched, "themeBasicInfo.themeName") &&
                      getIn(errors, `themeBasicInfo.themeName`)
                    }
                    helperText={
                      getIn(touched, `themeBasicInfo.themeName`)
                        ? getIn(errors, `themeBasicInfo.themeName`)
                        : ""
                    }
                  />
                </Grid>
                <Grid item xs={12} mt={2} ml={3}>
                  <MuiTextField
                    textfieldlabel={"Theme description"}
                    name="themeBasicInfo.themeDescription"
                    placeholder="Enter here"
                    value={values?.themeBasicInfo?.themeDescription}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      getIn(touched, "themeBasicInfo.themeDescription") &&
                      getIn(errors, `themeBasicInfo.themeDescription`)
                    }
                    helperText={
                      getIn(touched, `themeBasicInfo.themeDescription`)
                        ? getIn(errors, `themeBasicInfo.themeDescription`)
                        : ""
                    }
                    minRows={3}
                    maxRows={3}
                    multiline
                  />
                </Grid>
                <Grid item xs={12} mt={2}>
                  <InputLabel sx={{ marginLeft: 3 }} required>
                    Upload theme banner
                  </InputLabel>
                  <FileUploadDragDrop
                    onDrop={(e: any) => {
                      handleDrop(e, setFieldValue);
                    }}
                    name={
                      fileName
                        ? fileName
                        : values?.themeBasicInfo?.themeBannerFileName
                    }
                    onChange={(e) => {
                      handleFileChange(e, setFieldValue);
                    }}
                    previewFile={
                      previewFile
                        ? previewFile
                        : values?.themeBasicInfo?.themeBannerImage
                    }
                    onDeleteImage={(e) => {
                      handleClear(e, setFieldValue);
                    }}
                    isImage={true}
                    handleDrag={handleDrag}
                    downloadUrl={objectURL}
                    error={fileError}
                  />
                </Grid>
              </Grid>
            </Box>}
            {/* <FormBottomButtons
              tab={tab}
              handleTabNext={handleTabNext}
              handleTabPrev={handleTabPrev}
              // handleClick={handleSubmit}
              isDisabled={!isValid || !dirty}
              loading={fileUploader?.isLoading || createProgramTheme?.isLoading}
            /> */}
            <FormBottomButtons
              tab={tab}
              loading={fileUploader?.isLoading || createProgramTheme?.isLoading}
              handleTabNext={handleTabNext}
              handleTabPrev={handleTabPrev}
              // handleSubmit={handleSubmit}
              isDisabled={!isValid || !dirty}
              next={!createProgramTheme?.programThemeId || (isValid && dirty) ? false : true}
            // if "next" is "true" mean it will not make any api call, it simply navigates to next page
            // if "next" is "false" it will make Api calls
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default ProgramThemesBasicInfo;
