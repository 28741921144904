import ApiServices from "../services/ApiServices";
import { takeEvery, takeLatest } from "redux-saga/effects";

import { LoginRedux } from "../redux/login/LoginRedux";
import { OnboardingQuestionRedux } from "../redux/onboarding-question/OnboardingQuestionRedux";
import { LoginSaga, updateMyProfile, getUserDetails, getProfileInfo } from "./login/LoginSaga";
import { SuperAdminUserManagementRedux } from "../redux/super-admin-user-management-redux/SuperAdminUserManagementRedux";
import {
  addInviteSuperAdmin,
  getAllUsersByRoleId,
} from "./super-admin-user-management-saga/SuperAdminUserManagementSaga";
import { basicInfoRedux } from "../redux/entity-management/BasicInfoRedux";
import { basicInfoSaga } from "./entity-management/AddBasicInfoSaga";
import { entityTypeRedux } from "../redux/entity-management/EntityTypesRedux";
import { entityTypeSaga } from "./entity-management/FetchEntityTypeSaga";
import contactDetailsSaga from "./entity-management/AddContactDetailsSaga";
import addProgramAdminSaga from "./entity-management/AddProgramAdminSaga";
import { contactDetailsRedux } from "../redux/entity-management/ContactDetailsRedux";
import { programAdminRedux } from "../redux/entity-management/ProgramAdminRedux";
import fetchOnboardedEntitiesSaga from "./entity-management/FetchOnBoardedEntitiesSaga";
import { onBoardedEntitiesRedux } from "../redux/entity-management/OnBoardedEntitiesRedux";
import deleteProgramAdminSaga from "./entity-management/DeleteProgramAdminSaga";
import { programThemesRedux } from "../redux/program-themes/ProgramThemesRedux";
import { fetchProgramThemesSaga } from "./program-themes/FetchProgramThemesSaga";
import { associatedThemeEntitiesRedux } from "../redux/entity-management/AssociatedThemeEntitiesRedux";
import { fetchAssociatedProgramThemesEntitiesSaga } from "./entity-management/FetchAssociatedThemeEntitiesSaga";
import { associateProgramThemesEntitiesSaga } from "./entity-management/AssociateThemesAndEntitiesSaga";
import editProgramAdminSaga from "./entity-management/EditProgramAdminSaga";
import patchOnboardedEntitiesSaga from "./entity-management/PatchEntityOnboardedSaga";
import { updateBasicInfoSaga } from "./entity-management/UpdateBasicInfoSaga";
import updateContactDetailsSaga from "./entity-management/UpdateContactDetailsSaga";
import fetchContactDetailsSaga from "./entity-management/FetchContactDetailsSaga";
import fetchProgramAdminSaga from "./entity-management/FetchProgramAdminsSaga";
import { addOnboardingQuestionSaga, deleteOnboardingQuestionSaga, getEachOnboardingQuestionSaga, getOnboardingQuestionSaga, updateOnboardingQuestionSaga } from "./onboarding-question/OnboardingQuestionSaga";
import { CreateProgramThemeRedux } from "../redux/program-theme/create-program-theme/CreateProgramThemeRedux";
import { addCreateProgramThemeSaga, getEachProgramThemeSaga, getProgramThemeSaga, updateCreateProgramThemeSaga, updateProgramThemeGuidelineSaga } from "./program-theme/create-program-theme/CreateProgramThemeSaga";
import { FileUploadS3Redux } from "../redux/s3-upload/FileUploadS3Redux";
import { deleteAttachmentById, fileUpdateS3Saga, fileUploadS3Saga, multiFileUpdateSaga, multipleFileUploadS3Saga, profileFileUpdateS3Saga } from "./s3-upload/FileUploadS3Saga";
import { ProgramThemeQuestionRedux } from "../redux/program-theme/programtheme-question/ProgramThemQuestionRedux";
import { addThemeProgramQuestionSaga, deleteThemeProgramQuestionSaga, getEachProgramThemeQuestionSaga, getThemeProgramQuestionSaga, updateThemeProgramQuestionSaga } from "./program-theme/programtheme-question/ProgramThemeQuestionSaga";
import { addProgramThemeGuidelineSaga } from "./program-theme/create-program-theme/CreateProgramThemeSaga";
import { TopicCategoryRedux } from "../redux/program-theme/topic-category/TopicCategoryRedux";
import { getTopicCategorySaga } from "./program-theme/topic-category/TopicCategorySaga";
import { SubmitProgramThemeRedux } from "../redux/program-theme/submit-program-theme/SubmitProgramThemeRedux";
import { submitProgramThemeSaga } from "./program-theme/submit-program-theme/SubmitProgramThemeSaga";
import { AttachmentRedux } from "../redux/attachment/AttachmentRedux";
import { addAttachmentSaga, deleteAttachmentSaga, updateAttachmentSaga } from "./attachment/AttachmentSaga";

const api = ApiServices.create();

export default function* root() {
  yield takeEvery(LoginRedux.actions.loginRequest, LoginSaga, api);
  yield takeEvery(
    OnboardingQuestionRedux.actions.addOnboardingQuestionRequest,
    addOnboardingQuestionSaga,
    api
  );
  yield takeEvery(
    LoginRedux.actions.updateMyProfileRequest,
    updateMyProfile,
    api
  );
  yield takeEvery(
    LoginRedux.actions.getUserDetailsByEmailRequest,
    getUserDetails,
    api
  );
  yield takeEvery(
    LoginRedux.actions.getProfileInfoRequest,
    getProfileInfo,
    api
  );
  yield takeEvery(
    SuperAdminUserManagementRedux.actions.getAllUsersByRoleIdRequest,
    getAllUsersByRoleId,
    api
  );
  yield takeEvery(
    SuperAdminUserManagementRedux.actions.addInviteSuperAdminRequest,
    addInviteSuperAdmin,
    api
  );
  yield takeEvery(
    basicInfoRedux.actions.addBasicDetailsRequest,
    basicInfoSaga,
    api
  );
  yield takeEvery(
    basicInfoRedux.actions.updateBasicDetailsRequest,
    updateBasicInfoSaga,
    api
  );
  yield takeEvery(
    entityTypeRedux.actions.fetchEntityTypeRequest,
    entityTypeSaga,
    api
  );
  yield takeLatest(
    contactDetailsRedux.actions.addContactDetailsRequest,
    contactDetailsSaga,
    api
  );
  yield takeLatest(
    contactDetailsRedux.actions.updateContactDetailsRequest,
    updateContactDetailsSaga,
    api
  );
  yield takeLatest(
    contactDetailsRedux.actions.fetchContactDetailsRequest,
    fetchContactDetailsSaga,
    api
  );
  yield takeLatest(
    programAdminRedux.actions.addProgramAdminRequest,
    addProgramAdminSaga,
    api
  );
  yield takeLatest(
    programAdminRedux.actions.fetchProgramAdminRequest,
    fetchProgramAdminSaga,
    api
  );
  yield takeLatest(
    programAdminRedux.actions.editProgramAdminRequest,
    editProgramAdminSaga,
    api
  );
  yield takeLatest(
    programAdminRedux.actions.deleteProgramAdminRequest,
    deleteProgramAdminSaga,
    api
  );
  yield takeLatest(
    onBoardedEntitiesRedux.actions.addOnBoardedEntitiesRequest,
    fetchOnboardedEntitiesSaga,
    api
  );
  yield takeLatest(
    onBoardedEntitiesRedux.actions.makeEntityAsOnboardedRequest,
    patchOnboardedEntitiesSaga,
    api
  );
  yield takeLatest(
    programThemesRedux.actions.fetchprogramThemesRequest,
    fetchProgramThemesSaga,
    api
  );
  yield takeLatest(
    associatedThemeEntitiesRedux.actions.fetchAssociatedThemeEntitiesRequest,
    fetchAssociatedProgramThemesEntitiesSaga,
    api
  );
  yield takeLatest(
    associatedThemeEntitiesRedux.actions.associateThemeEntitiesRequest,
    associateProgramThemesEntitiesSaga,
    api
  );

  // File Upload

  // yield takeEvery(LoginRedux.actions.updateMyProfileRequest, updateMyProfile, api);
  // yield takeEvery(SuperAdminUserManagementRedux.actions.getAllUsersByRoleIdRequest, getAllUsersByRoleId, api);
  // yield takeEvery(SuperAdminUserManagementRedux.actions.addInviteSuperAdminRequest, addInviteSuperAdmin, api);
  yield takeEvery(OnboardingQuestionRedux.actions.getOnboardingQuestionRequest, getOnboardingQuestionSaga,api)
  yield takeEvery(OnboardingQuestionRedux.actions.deleteOnboardingQuestionRequest, deleteOnboardingQuestionSaga, api)
  yield takeEvery(OnboardingQuestionRedux.actions.updateOnboardingQuestionRequest,updateOnboardingQuestionSaga, api)
  yield takeEvery(OnboardingQuestionRedux.actions.getEachOnboardingQuestionRequest, getEachOnboardingQuestionSaga,api)


  // program themes
  yield takeEvery(CreateProgramThemeRedux.actions.addCreateProgramThemeRequest, addCreateProgramThemeSaga,api)
  yield takeEvery(CreateProgramThemeRedux.actions.updateCreateProgramThemeRequest,updateCreateProgramThemeSaga,api)
  yield takeEvery(ProgramThemeQuestionRedux.actions.addProgramThemeQuestionRequest, addThemeProgramQuestionSaga,api)
  yield takeEvery(ProgramThemeQuestionRedux.actions.getProgramThemeQuestionRequest,getThemeProgramQuestionSaga,api)
  yield takeEvery(ProgramThemeQuestionRedux.actions.deleteProgramThemeQuestionRequest,deleteThemeProgramQuestionSaga,api)
  yield takeEvery(CreateProgramThemeRedux.actions.getProgramThemeRequest, getProgramThemeSaga,api)
  yield takeEvery(CreateProgramThemeRedux.actions.getEachProgramThemeRequest, getEachProgramThemeSaga,api)
  yield takeEvery(ProgramThemeQuestionRedux.actions.updateProgramThemeQuestionRequest,updateThemeProgramQuestionSaga,api)
  yield takeEvery(ProgramThemeQuestionRedux.actions.getEachThemeQuestionRequest, getEachProgramThemeQuestionSaga, api)


  yield takeEvery(SubmitProgramThemeRedux.actions.submitProgramThemeRequest,submitProgramThemeSaga,api)

  //program theme guideline
  yield takeEvery(CreateProgramThemeRedux.actions.addProgramThemeGuidelineRequest, addProgramThemeGuidelineSaga, api)
  yield takeEvery(CreateProgramThemeRedux.actions.updateProgramThemeGuidelineRequest, updateProgramThemeGuidelineSaga,api)

  //get topic category
 yield takeEvery (TopicCategoryRedux.actions.getTopicCategoryRequest,getTopicCategorySaga,api)


 //attchments
 yield takeEvery(AttachmentRedux.actions.addAttachmentRequest,addAttachmentSaga,api)
 yield takeEvery(AttachmentRedux.actions.deleteAttachmentRequest,deleteAttachmentSaga,api)
  yield takeEvery(AttachmentRedux.actions.updateAttachmentRequest, updateAttachmentSaga, api)



  //s3upload loginRequest
  yield takeEvery(FileUploadS3Redux.actions.fileUploadS3Request, fileUploadS3Saga,api)
  yield takeEvery(FileUploadS3Redux.actions.multiFileUploadRequest,multipleFileUploadS3Saga,api)
  yield takeEvery(FileUploadS3Redux.actions.fileUploadS3UpdateRequest,fileUpdateS3Saga, api)
  yield takeEvery(FileUploadS3Redux.actions.multiFileUpdateRequest,multiFileUpdateSaga,api)
  yield takeEvery(FileUploadS3Redux.actions.deleteAttachmentByIdRequest,deleteAttachmentById,api)
  yield takeEvery(FileUploadS3Redux.actions.profileFileUpdateS3Request,profileFileUpdateS3Saga, api)

} 
