type columns =
  | {
      id: string;
      label: string;
      minWidth: number;
      align: string;
    }[]
  | [];

export const EntityThemeColumns: columns = [
  { id: "theme_name", label: "Theme Name", minWidth: 130, align: "left" },
  {
    id: "start date",
    label: "Start Date",
    minWidth: 100,
    align: "left",
  },
  {
    id: "end_date",
    label: "End Date",
    minWidth: 150,
    align: "left",
  },
  {
    id: "",
    label: "",
    minWidth: 130,
    align: "left",
  },
];


