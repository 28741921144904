import { SagaIterator } from "redux-saga";
import { call, put } from "redux-saga/effects";
import { HttpResponseTypeEnum } from "../../enumerations/HttpResponseTypeEnum";
import { entityTypeRedux } from "../../redux/entity-management/EntityTypesRedux";

export function* entityTypeSaga(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.fetchEntityTypes, action.data);
    if (response.data.code === HttpResponseTypeEnum.Success) {
      yield put(entityTypeRedux.actions.fetchEntityTypeSuccess(response.data));
    } else {
      yield put(entityTypeRedux.actions.fetchEntityTypeFailure(response.data));
    }
  } catch (error) {
    yield put(entityTypeRedux.actions.fetchEntityTypeFailure(error));
  }
}
