import { SagaIterator } from "redux-saga";
import { call, put } from "redux-saga/effects";
import { HttpResponseTypeEnum } from "../../enumerations/HttpResponseTypeEnum";
import { programAdminRedux } from "../../redux/entity-management/ProgramAdminRedux";

export default function* fetchProgramAdminSaga(
  api: any,
  action: any
): SagaIterator {
  try {
    const response = yield call(api.fetchProgramAdmins, action.payload);
    if (response.data.code === HttpResponseTypeEnum.Success) {
      yield put(
        programAdminRedux.actions.addProgramAdminSuccess({
          data: response.data,
          fetched: true,
        })
      );
    } else {
      yield put(
        programAdminRedux.actions.addProgramAdminFailure(response.data)
      );
    }
  } catch (e) {
    yield put(programAdminRedux.actions.addProgramAdminFailure(e));
  }
}
