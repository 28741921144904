import { ToWords } from "to-words";
import * as yup from "yup";
import {
  ActionTypeEnumTextResponse,
  ResponseTypeEnum,
} from "../../enumerations/ResponseTypeEnum";
import { UsersRoleTypeEnum } from "../../enumerations/UsersRoleTypeEnum";
import { StringConstant } from "../../resources/strings";

export interface IOnboardingQuestionProps {
  mentorQuestion?: string;
  menteeQuestion?: string;
  description?: string;
  responseType?: string; //response option enum
  responseFormat?: string; //response option enum
  questionType?: string;
  responseOptionImage?:
  | {
    fileType: any;
    name?: any;
    oldFileName?: any;
    imageUrl?: any;
    id?: string;
    caption?: string;
  }[]
  | any;
  options?: {
    id?: string;
    optionName?: string;
  }[];
  characterLimitForResponse?: number | string;
  topicCategory?:
  | {
    topicCategoryId: string;
    categoryName: string;
    options: {
      id?: string;
      optionName?: string;
    }[];
    deletedOptionId?: string[];
  }[]
  | any;

  questionVisibleTo?: string; //response options enum
  isVisibleForMentor?: boolean;
  isVisibleForMentee?: boolean;
  isIdentityQuestion?: boolean;
  isTopicBased?: boolean;
  isImpactful?: boolean;
  isQuestionMandatory?: boolean;
  isAnswerMandatory?: boolean;
  isCustomisable?: boolean;
  maximumResponseSelection?: string | number;
  isMustMatchQuestion?: boolean;
}

export const initialBoardingQuestionState: IOnboardingQuestionProps = {
  mentorQuestion: "",
  menteeQuestion: "",
  responseType: "", //response option enum
  description: "hh",
  responseFormat: "", //response option enum
  questionType: "1001", // for only onboarding question
  responseOptionImage: [
    {
      fileType: "",
      name: "",
      imageUrl: "",
      id: "",
      caption: "",
    },
    {
      fileType: "",
      name: "",
      imageUrl: "",
      id: "",
      caption: "",
    },
  ],
  options: [],
  topicCategory: [
    {
      topicCategoryId: "",
      categoryName: "",
      options: [],
      deletedOptionId: [],
    },
  ],
  characterLimitForResponse: 3,

  questionVisibleTo: UsersRoleTypeEnum.Mentor, //response options enum
  isQuestionMandatory: true,
  isImpactful: true,
  isTopicBased: true,
  isAnswerMandatory: true,
  isCustomisable: true,
  isIdentityQuestion: true,
  maximumResponseSelection: 1,
  isVisibleForMentor: true,
  isVisibleForMentee: false,
  isMustMatchQuestion: false
};

export const onBoardingQuestionsScheme = yup.object().shape({
  questionVisibleTo: yup.string().required(StringConstant.mandatory),
  mentorQuestion: yup.string().when("questionVisibleTo", {
    is: (questionVisibleTo: any) =>
      questionVisibleTo == UsersRoleTypeEnum.Mentor ||
      questionVisibleTo == UsersRoleTypeEnum.MentorAndMentee,
    then: yup.string().required(StringConstant.mandatory),
  }),
  menteeQuestion: yup.string().when("questionVisibleTo", {
    is: (questionVisibleTo: any) =>
      questionVisibleTo == UsersRoleTypeEnum.Mentee ||
      questionVisibleTo == UsersRoleTypeEnum.MentorAndMentee,
    then: yup.string().required(StringConstant.mandatory),
  }),
  responseType: yup.string().required(StringConstant.mandatory),
  responseFormat: yup.string().required("selected Reponse format"),

  // validing based on selected response
  maximumResponseSelection: yup.number().when("responseFormat", {
    is: "1002",
    then: yup
      .number()
      .moreThan(0, "Maximum response selection should be more then one"),
  }),
  options: yup.array().when(["responseFormat", "responseType"], {
    is: (responseFormat: any, responseType: any) =>
      (responseFormat == ActionTypeEnumTextResponse.SingleChoice &&
        responseType == ResponseTypeEnum.Text) ||
      (responseFormat == ActionTypeEnumTextResponse.MultipleChoice &&
        responseType == ResponseTypeEnum.Text),
    then: yup
      .array()
      .of(
        yup.object().shape({
          optionName: yup.string().required("Please Enter value"),
        })
      ).min(2, `Minimum two response options are mandatory`)
      // .when("maximumResponseSelection", (value: any, schema: any) => {
      //   if (value ? value : 1) {
      //     const toWords = new ToWords();
      //     let words = toWords.convert(value ? value : 1);

      //     return schema.min(
      //       value,
      //       `Minimum ${words} response options are mandatory`
      //     );
      //   }
      // })
      .required("Please Enter options")
      .nullable(),
  }),

  responseOptionImage: yup.array().when(["responseFormat", "responseType"], {
    is: (responseFormat: any, responseType: any) =>
      (responseFormat == ActionTypeEnumTextResponse.SingleChoice &&
        responseType == ResponseTypeEnum.Image) ||
      (responseFormat === ActionTypeEnumTextResponse.MultipleChoice &&
        responseType == ResponseTypeEnum.Image),
    then: yup
      .array()
      .of(
        yup.object().shape({
          imageUrl: yup.string().required("Please Enter value"),
        })
      ).when("maximumResponseSelection", (value: any, schema: any) => {
        const toWords = new ToWords();
        let words = toWords.convert(value ? value : 1);
        return schema.min(value, `Minimum ${words} response options are mandatory`);
      }),
  }),
  characterLimitForResponse: yup.number().when("responseFormat", {
    is: "1003",
    then: yup.number().moreThan(2, "Character Limit must be greater than 3"),
  }),

  topicCategory: yup.array().when("responseFormat", {
    is: "1005",
    then: yup.array(
      yup.object().shape({
        categoryName: yup.string().required("Please Enter Category"),
        options: yup
          .array()
          .min(1, "Minimum one response is mandatory")
          .required("Provide at least one option")
          .nullable(),
      })
    ),
  }),
});
