export const Constants = {
  developmentBaseUrl: "",
  qaBaseUrl: "",
  preProductionBaseUrl: "",
  productionBaseUrl: "",

  loginGoogleConsoleUrl:process.env.REACT_APP_LOGIN_GOOGLE_CONSOLE_URL,
  // loginGoogleConsoleUrl: " http://localhost:3000/user-management/api/authenticate/0",
  authorizationToken: "AuthorizationToken",
  loggedInUser: "LoggedInUser",

  dateFormatMMDDYY: "MM-DD-YY",
  dateFormatDDMMMYY: "DD MMM YYYY",
  dateFormatYYYYMMDD: "YYYY-MM-DD",
  dateFormatDDmmYYYY: "DD/MM/YYYY",
  timeFormathhmmssA: "hh:mm:ss A",
  dateAndTimeFormatDDMMYYYYhhmmss: "DD/MM/YYYY hh:mm:ss A",
};
