import {
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import MediaAssets from "../../assets";
import { navBarImage, SideBarData } from "./SideBarMenu";
import styles from "./Sidemenu.module.css";

const Sidemenu = () => {
  const [selectedmenu, setSelectedMenu] = useState("");
  let location: any = useLocation();
  useEffect(() => {
    setSelectedMenu(location.pathname === "/onboarding-question" ? "/onboarding-question-list" : location.pathname);
  }, [location]);

  return (
    <div className={styles["sidemenu-dimension"]}>
      <div className={styles["sidemenu-conatiner-logo"]}>
        <div>
          <img src={MediaAssets.ic_frientor_super_logo} />
        </div>
      </div>

      <div className={styles["sidemenu-nav-list"]}>
        <List>
          {SideBarData.map((sideNavItem: any, index: any) => (
            <NavLink
              className={styles["nav-style"]}
              to={`${sideNavItem?.path}`}
              key={index}
            >
              <ListItemButton
                key={index}
                selected={
                  selectedmenu?.includes(sideNavItem?.path) ? true : false
                }
              >
                <ListItemIcon key={index}>
                  <img
                    src={navBarImage(sideNavItem.id)}
                    className={styles["navbar-img"]}
                  />
                </ListItemIcon>
                <ListItemText primary={`${sideNavItem?.title}`} />
              </ListItemButton>
            </NavLink>
          ))}
        </List>
      </div>
    </div>
  );
};

export default Sidemenu;
