import {
  Box,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  MenuList,
  Radio,
  Select,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";

import { OnboardingQuestionStyleWrapper } from "../OnboardingQuestion.style";
import {
  initialBoardingQuestionState,
  IOnboardingQuestionProps,
  onBoardingQuestionsScheme,
} from "../OnboardingQuestionStaticData";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import RadioButtonGroupComponent from "../../../components/mui-group-radio-button/MuiGroupRadioButton";
import { FieldArray, Form, Formik, getIn } from "formik";

import FormFieldContainer from "../../../components/form-field-container/FormFieldContainer";
import ImageUploader from "../../../components/image-uploader/ImageUploader";
import ImageUploadButton from "../../../components/image-upload-button/ImageUploadButton";
import { MuiChipsInput } from "mui-chips-input";
import { StringConstant } from "../../../resources/strings";
import MuiLoadingButton from "../../../components/ui-elements/mui-loading-button/MuiLoadingButton";
import MuiTextField from "../../../components/ui-elements/mui-text-field/MuiTextField";
import PageHeading from "../../../components/page-heading/PageHeading";
import {
  ActionTypeEnumUtilsTextResponse,
  ActionTypeEnumUtilsImageResponse,
  ActionTypeEnumTextResponse,
  ResponseTypeEnum,
} from "../../../enumerations/ResponseTypeEnum";
import MediaAssets from "../../../assets";
import MuiButton from "../../../components/ui-elements/mui-button/MuiButton";
import MuiRadioButton from "../../../components/ui-elements/mui-radio-button/MuiRadioButton";
import OnboardingListOfQuestion from "../onboarding-question-list/OnboardingQuestionList";
import {
  addOnboardingQuestionRequest,
  deleteOnboardingQuestionRequest,
  getEachOnboardingQuestionRequest,
  setOnboardingState,
  setOnboardingToasterNull,
  updateOnboardingQuestionRequest,
} from "../../../redux/onboarding-question/OnboardingQuestionRedux";
import { useDispatch, useSelector } from "react-redux";
import { setToaster } from "../../../redux/toaster/ToasterRedux";
import { handleNumberFieldChange, handleRestrictLength } from "../../../utils/ValidationUtils";
import { useLocation, useNavigate } from "react-router-dom";
import { RouteConfigurations } from "../../../routes/RouteConfigurations";
import {
  multiFileUpdateRequest,
  multiFileUploadRequest,
  setFileUploadS3Update,
} from "../../../redux/s3-upload/FileUploadS3Redux";
import {
  AttachmentTypeEnum,
  FileUploadMIMETypeEnum,
} from "../../../enumerations/FileUploadMIMETypeEnum";
import {
  addAttachmentRequest,
  deleteAttachmentRequest,
  setAttachmentState,
  updateAttachmentRequest,
} from "../../../redux/attachment/AttachmentRedux";
import { QuestionEditResponse } from "../../../utils/QuestionEditResponse";
import ConfirmationDialog from "../../../components/confirmation-dialog/ConfirmationDialog";
import { UsersRoleTypeEnum } from "../../../enumerations/UsersRoleTypeEnum";
import PageLoader from "../../../components/page-loader/PageLoader";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { ScrollToError } from "../../../components/scroll-error/ScrollError";


const OnboardingQuestion = () => {
  const actionDispatch = (dispatch: any) => ({
    addOnboardingQuestions: (data: any) =>
      dispatch(addOnboardingQuestionRequest(data)),
    updateOnboardingQuestions: (data: any) =>
      dispatch(updateOnboardingQuestionRequest(data)),
    setOnboardingNull: () => dispatch(setOnboardingToasterNull()),
    multiFileUpload: (data: any) => dispatch(multiFileUploadRequest(data)),
    setToasterInfo: (data: any) => dispatch(setToaster(data)),
    setFileUpload: (data: any) => dispatch(setFileUploadS3Update(data)),
    multiFileUpdate: (data: any) => dispatch(multiFileUpdateRequest(data)),
    addAttachment: (data: any) => dispatch(addAttachmentRequest(data)),
    setAttachment: (data: any) => dispatch(setAttachmentState(data)),
    deleteAttachment: (data: any) => dispatch(deleteAttachmentRequest(data)),
    deleteOnboardingQuestion: (data: any) =>
      dispatch(deleteOnboardingQuestionRequest(data)),
    setOnboardingQuestion: (data: any) => dispatch(setOnboardingState(data)),
    updateCaption: (data: any) => dispatch(updateAttachmentRequest(data)),
    getEachOnboardQuestion: (data: any) => dispatch(getEachOnboardingQuestionRequest(data)),
  });

  const {
    addOnboardingQuestions,
    setOnboardingNull,
    setToasterInfo,
    updateOnboardingQuestions,
    multiFileUpload,
    setFileUpload,
    multiFileUpdate,
    addAttachment,
    setAttachment,
    deleteAttachment,
    setOnboardingQuestion,
    deleteOnboardingQuestion,
    updateCaption,
    getEachOnboardQuestion
  } = actionDispatch(useDispatch());

  const { onboardingQuestion, fileUploader, attachment } = useSelector(
    (state: any) => state
  );
  const navigate = useNavigate();
  const location = useLocation();
  const [actionDropDown, setActionDropDown] = useState<any>([]);
  const [captionUpdate, setCaptionUpdate] = useState<boolean>(false)
  const [responseFormat, setResponseFormat] = useState<any>();
  const [responseType, setResponseType] = useState<string>("");
  const [isUpdateAttachment, setUpdateAttachment] = useState(false);
  const [editQuestionBody, setEditQuestionBody] = useState<any>(null);
  const [imageDeleteIds, setImageDeleteIds] = useState<any>([]);
  const [onBoardRequestBody, setOnBoardRequestBody] = useState<any>(null);
  const [isNewImageUploaded, setNewImageUploaded] = useState<boolean>(false);
  const [deleteCategoryID, setDeleteCategoryID] = useState<any>([])
  const [imageResponseDelete, setImageResponseDelete] = useState<any>("")
  const [deletedOption, setDeleteOption] = useState<any>([])
  const [shouldOpenConfirmatonDialog, setShouldOpenConfirmatonDialog] =
    useState(false);
  const [shouldOpenConfirmOnImg, setShouldOpenConfirmOnImg] = useState<boolean>(false)
  const [questionId, setQuestionId] = useState("")
  const [isImageUpdate, setImageUpdate] = useState<boolean>(false);
  const [hasImageDeleted, setHasImageDeleted] = useState<boolean>(false)
  const actionText =
    ActionTypeEnumUtilsTextResponse.getActionTypeEnumsTextResponse();
  const actionImage =
    ActionTypeEnumUtilsImageResponse.getActionTypeEnumsImageResponse();
  useEffect(() => {
    if (onboardingQuestion?.toasterDetails) {

      setToasterInfo({
        key: "toasterDetails",
        value: { ...onboardingQuestion?.toasterDetails, toasterOpen: true },
      });
      setOnboardingNull();
      if (onboardingQuestion?.toasterDetails?.code === 200) {
        navigate(RouteConfigurations.onBoardingQuestionList);
      }
    }
    return () => {
      setOnboardingNull();
    };
  }, [onboardingQuestion?.toasterDetails]);

  useEffect(() => {
    getEachOnboardQuestion(location?.state?.id)
    if (location?.state) {
      if (location?.state?.responseType === ResponseTypeEnum.Text) {
        setActionDropDown(actionText);
      } else {
        setActionDropDown(actionImage);
      }
    }
    return () => {
      setOnboardingNull();
      setOnboardingQuestion({ key: "eachOnboardingQuestion", value: null })
    }
  }, []);

  ///NEW Onboarding question
  useEffect(() => {
    // added new question after adding new attachment not in edit mode
    if (fileUploader?.multiAttachment && !location?.state) {
      addOnboardingQuestions({
        questions: [
          {
            ...onBoardRequestBody,
            options: onBoardRequestBody?.options.map((item: any) => item?.optionName),
            attachments: [...fileUploader?.multiAttachment],
          },
        ],
      });
      setFileUpload({ key: "multiAttachment", value: null });
    }
  }, [fileUploader?.multiAttachment]);

  // updated onboarding question
  useEffect(() => {
    if (fileUploader?.multiAttachment && location?.state) {
      addAttachment({
        questionId: location?.state.id,
        attachments: [...fileUploader?.multiAttachment],
      });
      setFileUpload({ key: "multiAttachment", value: null });
    }
  }, [fileUploader?.multiAttachment]);


  useEffect(() => {
    //both upload and editted
    if (isImageUpdate && isNewImageUploaded) {
      if (
        location?.state &&
        fileUploader?.editedAttachment &&
        attachment?.isAttachmentSuccessful
      ) {
        const updateRequestBody = QuestionEditResponse(editQuestionBody, deletedOption, deleteCategoryID)
        updateOnboardingQuestions({
          ...editQuestionBody,
          questionId: location.state.id,
          mentorQuestion: editQuestionBody?.mentorQuestion,
          menteeQuestion: editQuestionBody?.menteeQuestion,
          options: updateRequestBody?.options,
          topicCategory: updateRequestBody?.topicCategory

        });
        setUpdateAttachment(false);
        setFileUpload({ key: "editedAttachment", value: null });
        setAttachment({ key: "isAttachmentSuccessful", value: false });

        setFileUpload({ key: "editedAttachment", value: null });
        setAttachment({ key: "isAttachmentSuccessful", value: false });
      }
    }
    // ONLY UPLOAD
    else if (!isImageUpdate && (isNewImageUploaded || captionUpdate)) {
      console.log("new image uploaded", attachment?.isAttachmentSuccessful)
      if (location?.state && attachment?.isAttachmentSuccessful) {
        const updateRequestBody = QuestionEditResponse(editQuestionBody, deletedOption, deleteCategoryID)
        updateOnboardingQuestions({
          ...editQuestionBody,
          questionId: location.state.id,
          mentorQuestion: editQuestionBody?.mentorQuestion,
          menteeQuestion: editQuestionBody?.menteeQuestion,
          options: updateRequestBody?.options,
          topicCategory: updateRequestBody?.topicCategory

        });
        setAttachment({ key: "isAttachmentSuccessful", value: false });
        setAttachment({ key: "isAttachmentUpdateSuccess", value: false });
        setCaptionUpdate(false)
      }
    }
    // ONLY UPDATE
    else if (isImageUpdate && !isNewImageUploaded) {
      if (location?.state && fileUploader?.editedAttachment) {
        const updateRequestBody = QuestionEditResponse(editQuestionBody, deletedOption, deleteCategoryID)
        updateOnboardingQuestions({
          ...editQuestionBody,
          questionId: location.state.id,
          mentorQuestion: editQuestionBody?.mentorQuestion,
          menteeQuestion: editQuestionBody?.menteeQuestion,
          options: updateRequestBody?.options,
          topicCategory: updateRequestBody?.topicCategory

        });
        setFileUpload({ key: "editedAttachment", value: null });
      }
    }
  }, [
    isUpdateAttachment, fileUploader?.editedAttachment, attachment?.isAttachmentSuccessful,
  ]);

  //it is for switching for image type question to text
  useEffect(() => {
    if (responseType === ResponseTypeEnum.Text && attachment?.isAttachmentDeleteSuccessful) {
      const updateRequestBody = QuestionEditResponse(editQuestionBody, deletedOption, deleteCategoryID)
      updateOnboardingQuestions({
        ...editQuestionBody,
        questionId: location.state.id,
        mentorQuestion: editQuestionBody?.mentorQuestion,
        menteeQuestion: editQuestionBody?.menteeQuestion,
        options: updateRequestBody?.options,
        topicCategory: updateRequestBody?.topicCategory

      });
      setAttachment({ key: "isAttachmentDeleteSuccessful", value: false });
    }

  }, [attachment?.isAttachmentDeleteSuccessful])

  useEffect(() => {
    if (onboardingQuestion?.isOnboradingQuestionDeleted) {
      setShouldOpenConfirmatonDialog(false)
      setOnboardingQuestion({ key: "isOnboradingQuestionDeleted", value: false })

    }
  }, [onboardingQuestion?.isOnboradingQuestionDeleted])

  //deletedd image response
  useEffect(() => {
    if (!(responseType === ResponseTypeEnum.Text) &&
      attachment?.isAttachmentDeleteSuccessful) {

      setShouldOpenConfirmOnImg(false)
      setHasImageDeleted(true)


      // const updateRequestBody = QuestionEditResponse(editQuestionBody, deletedOption, deleteCategoryID)
      // updateOnboardingQuestions({
      //   ...editQuestionBody,
      //   questionId: location.state.id,
      //   mentorQuestion: editQuestionBody?.mentorQuestion,
      //   menteeQuestion: editQuestionBody?.menteeQuestion,
      //   options: updateRequestBody?.options,
      //   topicCategory: updateRequestBody?.topicCategory

      // });   
      getEachOnboardQuestion(location?.state?.id)
      setAttachment({ key: "isAttachmentDeleteSuccessful", value: false });
    }

  }, [attachment?.isAttachmentDeleteSuccessful])

  // only image caption updated
  useEffect(() => {
    if (attachment?.isAttachmentUpdateSuccess && !captionUpdate) {
      const updateRequestBody = QuestionEditResponse(editQuestionBody, deletedOption, deleteCategoryID)
      updateOnboardingQuestions({
        ...editQuestionBody,
        questionId: location.state.id,
        mentorQuestion: editQuestionBody?.mentorQuestion,
        menteeQuestion: editQuestionBody?.menteeQuestion,
        options: updateRequestBody?.options,
        topicCategory: updateRequestBody?.topicCategory

      });
      setAttachment({ key: "isAttachmentUpdateSuccess", value: false });
    }

  }, [attachment?.isAttachmentUpdateSuccess])




  const deleteImageResponse = (setFieldValue: any, index: any) => {

    // console.log("minajiigi")
    // if (hasImageDeleted) {

    setFieldValue(
      `responseOptionImage.${index}.name`,
      ""
    );
    setFieldValue(
      `responseOptionImage.${index}.fileType`,
      ""
    );
    setFieldValue(
      `responseOptionImage.${index}.imageUrl`,
      ""
    );
    setFieldValue(
      `responseOptionImage.${index}.oldFileName`,
      ""
    );
    setFieldValue(
      `responseOptionImage.${index}.id`,
      ""
    );
    setFieldValue(
      `responseOptionImage.${index}.caption`,
      ""
    );


  }

  const editingOnBoardingQuestion = () => {
    const isAnswerMandatory: any = onboardingQuestion?.eachOnboardingQuestion?.isAnswerMandatory
    const isCustomisable: any = onboardingQuestion?.eachOnboardingQuestion?.isCustomisable
    const isIdentityQuestion: any = onboardingQuestion?.eachOnboardingQuestion?.isIdentityQuestion
    const isImpactful: any = onboardingQuestion?.eachOnboardingQuestion?.isImpactful
    const isQuestionMandatory: any = onboardingQuestion?.eachOnboardingQuestion?.isQuestionMandatory
    const isTopicBased: any = onboardingQuestion?.eachOnboardingQuestion?.isTopicBased
    const isVisibleForMentee: any = onboardingQuestion?.eachOnboardingQuestion?.isVisibleForMentee
    const isVisibleForMentor: any = onboardingQuestion?.eachOnboardingQuestion?.isVisibleForMentor
    const menteeQuestion: any = onboardingQuestion?.eachOnboardingQuestion?.menteeQuestion
    const mentorQuestion: any = onboardingQuestion?.eachOnboardingQuestion?.mentorQuestion
    const questionType: any = onboardingQuestion?.eachOnboardingQuestion?.questionType
    const responseFormat: any = onboardingQuestion?.eachOnboardingQuestion?.responseFormat
    const responseType: any = onboardingQuestion?.eachOnboardingQuestion?.responseType
    const responseOptions: any = onboardingQuestion?.eachOnboardingQuestion?.responseOptions
    const characterLimitForResponse: any = onboardingQuestion?.eachOnboardingQuestion?.characterLimit
    const attachments: any = onboardingQuestion?.eachOnboardingQuestion?.attachments
    const maximumResponseSelection: any = onboardingQuestion?.eachOnboardingQuestion?.maximumResponseSelection

    const optionsArray =
      responseOptions?.length > 0 &&
      responseOptions?.map((resOption: any) => ({ id: resOption?.id, optionName: resOption?.optionName }));
    const topicRespones = onboardingQuestion?.eachOnboardingQuestion?.topicCategories?.map(
      (item: any) => ({
        categoryName: item?.topic,
        topicCategoryId: item?.id,
        options: item?.responseOptions?.map((resOption: any) => ({ id: resOption?.id, optionName: resOption?.optionName })),
        deletedOptionId: []
      })
    );



    const attachmentImage = attachments?.map((img: any, index: any) => ({
      fileType: img?.contentType,
      name: img?.fileName,
      oldFileName: img?.fileName,
      imageUrl: img.publicUrl,
      id: img?.id,
      caption: img?.caption,
    }));

    return {
      mentorQuestion: mentorQuestion,
      menteeQuestion: menteeQuestion,
      responseType: responseType, //response option enum
      description: "jj",
      responseFormat: responseFormat, //response option enum
      questionType: questionType, // for only onboarding question
      responseOptionImage: attachmentImage?.length ? attachmentImage : [
        {
          fileType: "",
          name: "",
          imageUrl: "",
          id: "",
          caption: "",
        },
        {
          fileType: "",
          name: "",
          imageUrl: "",
          id: "",
          caption: "",
        },
      ],
      options: optionsArray || [],
      topicCategory: topicRespones?.length ? topicRespones : [
        {
          topicCategoryId: '',
          categoryName: "",
          options: [],
          deletedOptionId: []
        },
      ],
      characterLimitForResponse: characterLimitForResponse?.length ? characterLimitForResponse : 2,
      questionVisibleTo:
        isVisibleForMentee && isVisibleForMentor
          ? UsersRoleTypeEnum.MentorAndMentee
          : isVisibleForMentor
            ? UsersRoleTypeEnum.Mentor
            : UsersRoleTypeEnum.Mentee, //response options enum
      isQuestionMandatory: isQuestionMandatory,
      isImpactful: isImpactful,
      isTopicBased: isTopicBased,
      isAnswerMandatory: isAnswerMandatory,
      isCustomisable: isCustomisable,
      isIdentityQuestion: isIdentityQuestion,
      maximumResponseSelection: maximumResponseSelection ? maximumResponseSelection : 1,
      isVisibleForMentor: isVisibleForMentor,
      isVisibleForMentee: isVisibleForMentee,
      isMustMatchQuestion: false,
      createdBy: location.state.createdBy,
    };
  };

  const handleResponseType = (event: any) => {
    if (event === ResponseTypeEnum.Text) {
      setResponseType(ResponseTypeEnum.Text);
      setActionDropDown(actionText);
    } else {
      setResponseType(ResponseTypeEnum.Image);
      setActionDropDown(actionImage);
    }
  };
  const handleResponseFormat = (resFormat: any) => {
    setResponseFormat(resFormat);
  };
  const handleSumbitOnBoardQuestion = async (values: any) => {
    // editting the question
    if (location?.state?.id) {
      setEditQuestionBody(values);

      // if it has any delete
      // if (imageDeleteIds.length > 0) {
      //   deleteAttachment(imageDeleteIds);
      // }

      if (values?.responseType !== ResponseTypeEnum.Image) {
        // text response
        const imageIDs = values?.responseOptionImage.map(
          (item: any) => item.id
        );
        if (imageIDs[0]?.length && imageIDs[1]?.length) {
          deleteAttachment(imageIDs);
        } else {
          const updateRequestBody = QuestionEditResponse(values, deletedOption, deleteCategoryID)
          updateOnboardingQuestions({
            ...values,
            questionId: location.state.id,
            mentorQuestion: values?.mentorQuestion,
            menteeQuestion: values?.menteeQuestion,
            options: updateRequestBody?.options,
            topicCategory: updateRequestBody?.topicCategory

          });
        }
      } else if (values?.responseType == ResponseTypeEnum.Image) {
        // image response
        const editedImages = values?.responseOptionImage?.filter(
          // filtering editted image
          (item: any, index: any) => {
            return item.imageUrl.includes("blob");
          }
        );
        const newImageUpload = editedImages?.filter((newUploadImage: any) => {
          // getting new added image if id is empty is new image
          return newUploadImage.id === "";
        });
        /// new image upload while editing
        if (newImageUpload?.length > 0) {
          const changeCaption = values?.responseOptionImage?.filter((caption: any, index: any) => {
            return caption?.caption !== onboardingQuestion?.eachOnboardingQuestion?.attachments[index]?.caption
          }
          )
          const removeId = changeCaption?.filter((newImage: any, index: any) =>
            newImage?.id?.length !== 0
          )
          if (removeId?.length) {
            const attachmentReq = removeId?.map((item: any, index: any) => {
              return {
                attachmentId: item?.id,
                attachmentType: AttachmentTypeEnum.Other,
                publicUrl: item?.imageUrl,
                fileName: item?.name,
                caption: item?.caption,
                contentType: item?.fileType
              }
            })
            // setEditting caption while adding new image
            updateCaption(attachmentReq)
            setCaptionUpdate(true)

          }

          setNewImageUploaded(true)
          const multipleImageToGet = [];
          for (const image of newImageUpload) {
            const reader = new FileReader();
            let data: any = {
              urlType: "upload",
              name: image.name,
              binary: "",
              type: image.fileType,
              caption: image?.caption
            };
            let blob = await fetch(image.imageUrl).then((r) => r.blob());
            reader.onload = (e: any) => {
              data.binary = e.target.result;
            };
            reader.readAsArrayBuffer(blob);
            multipleImageToGet.push(data);
          }
          multiFileUpload(multipleImageToGet);
        }
        const updateImage = editedImages?.filter((updateImage: any) => {
          return updateImage?.id !== "";
        });
        if (updateImage?.length > 0) {
          setImageUpdate(true)
          const multipleImageToGet = [];
          for (const image of updateImage) {
            const reader = new FileReader();
            let data: any = {
              questionId: location?.state?.id,
              urlType: "upload",
              name: image.name,
              binary: "",
              type: image?.fileType,
              caption: image?.caption,
              oldFileName: image?.oldFileName,
              attachmentId: image?.id,
              contentType:
                image?.name.split(".")[1].toUpperCase() ===
                  FileUploadMIMETypeEnum?.PNG
                  ? FileUploadMIMETypeEnum?.PNG
                  : FileUploadMIMETypeEnum?.JPEG,
              attachmentType: AttachmentTypeEnum.Other,
            };
            let blob = await fetch(image.imageUrl).then((r) => r.blob());
            reader.onload = (e: any) => {
              data.binary = e.target.result;
            };
            reader.readAsArrayBuffer(blob);
            multipleImageToGet.push(data);
          }
          multiFileUpdate(multipleImageToGet);
          setUpdateAttachment(true);
        }
        if (newImageUpload?.length == 0 && updateImage?.length === 0) {
          QuestionEditResponse(values, deletedOption, deleteCategoryID)
          const changeCaption = values?.responseOptionImage.filter((caption: any, index: any) =>
            caption.caption !== onboardingQuestion?.eachOnboardingQuestion?.attachments[index]?.caption
          )
          if (changeCaption?.length > 0) {
            const attachmentReq = changeCaption?.map((item: any, index: any) => {
              return {
                attachmentId: item?.id,
                attachmentType: AttachmentTypeEnum.Other,
                publicUrl: item?.imageUrl,
                fileName: item?.name,
                caption: item?.caption,
                contentType: item?.fileType
              }
            })
            updateCaption(attachmentReq)
          } else {
            const updateRequestBody = QuestionEditResponse(values, deletedOption, deleteCategoryID)
            updateOnboardingQuestions({
              ...values,
              questionId: location.state.id,
              options: updateRequestBody?.options,
              topicCategory: updateRequestBody?.topicCategory
            });
          }
        }
      }
    } else {
      // new question adding only attachement api call 
      const multipleImageToGet = [];
      if (values?.responseType == ResponseTypeEnum.Image) {
        for (const image of values.responseOptionImage) {
          const reader = new FileReader();
          let data: any = {
            urlType: "upload",
            name: image.name,
            binary: "",
            caption: image.caption,
            type: image.fileType,
          };
          let blob = await fetch(image.imageUrl).then((r) => r.blob());
          reader.onload = (e: any) => {
            data.binary = e.target.result;
          };
          reader.readAsArrayBuffer(blob);
          multipleImageToGet.push(data);
        }
        await multiFileUpload(multipleImageToGet);
        setOnBoardRequestBody(values);
      } else {
        // only adding without attachment
        addOnboardingQuestions({
          questions: [{
            ...values,
            options: values?.options.map((item: any) => item?.optionName),
            topicCategory: values?.topicCategory?.map((item: any) => {
              return {
                categoryName: item?.categoryName,
                options: item?.options.map((optionName: any) => optionName.optionName)
              }
            })
          }],
        });
      }
    }
  };


  const handleConfirmDelete = () => {
    deleteOnboardingQuestion(questionId)
  }

  const handleConfirmImageResDelete = () => {
    deleteAttachment([imageResponseDelete])
  }

  return (
    <>
      <ConfirmationDialog
        isConfirmModelOpen={shouldOpenConfirmatonDialog}
        handleClose={() => setShouldOpenConfirmatonDialog(false)}
        handleConfirm={handleConfirmDelete}
        title={"Are you sure you want to delete this question ?"}
        leftButtonText={"No"}
        rightButtonText={"Yes"}
      />
      <ConfirmationDialog
        isConfirmModelOpen={shouldOpenConfirmOnImg}
        handleClose={() => setShouldOpenConfirmOnImg(false)}
        handleConfirm={handleConfirmImageResDelete}
        title={"Are you sure you want to delete this Image Response ?"}
        leftButtonText={"No"}
        rightButtonText={"Yes"}
      />
      <OnboardingQuestionStyleWrapper>
        {/* {onboardingQuestion.isLoading ? <CircularProgress
          sx={{ position: "absolute", top: "50%", right: "50%" }}
          color="secondary"
        /> : */}
        <Formik
          initialValues={
            location.state
              ? editingOnBoardingQuestion()
              : initialBoardingQuestionState
          }
          validationSchema={onBoardingQuestionsScheme}
          onSubmit={handleSumbitOnBoardQuestion}
          enableReinitialize={true}
        >
          {({
            values,
            setFieldValue,
            handleChange,
            handleBlur,
            errors,
            touched,
            isValid,
            dirty,
          }) => (
            <>
              <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
                <IconButton onClick={() => navigate(-1)}>
                  <ArrowBackIosIcon style={{ color: "#434245" }} fontSize="medium" />
                </IconButton>
                <Typography className="back-to-question-list-label">
                  Back to Onboarding Question List
                </Typography>
              </Box>
              <Grid container mt={1} className={"tab-onboarding-innner-height"}>
                <Grid item xs={11} sx={{ height: "100%" }} >
                  <FormFieldContainer>
                    <Form className="form-container">
                      <Stack
                        direction="row"
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        py={1}
                        px={2}
                      >
                        <Typography
                          className={"question-header"}
                        >
                          Question
                        </Typography>
                        <Stack
                          direction={"row"}
                          spacing={1}
                          alignItems={"center"}

                        >
                          {location?.state && <IconButton
                            onClick={() => {
                              setShouldOpenConfirmatonDialog(true)
                              setQuestionId(location?.state?.id)

                            }}
                          >
                            <img src={MediaAssets.ic_delete} width={30} />
                          </IconButton>}
                          <MuiLoadingButton
                            variant="outlined"
                            type="submit"
                            tab={false}
                            loading={
                              onboardingQuestion.isLoading ||
                              fileUploader.isLoading ||
                              attachment.isLoading
                            }
                          >
                            Save
                          </MuiLoadingButton>
                        </Stack>
                      </Stack>
                      <Stack sx={{ height: "calc(100% - 70px)", overflowY: "auto" }}>
                        <Box>
                          <Grid
                            container
                            direction={"row"}
                            alignItems={"center"}
                            className={"individual-padding"}
                          >
                            <Grid item xl={2} lg={3} >
                              <InputLabel
                                error={
                                  getIn(touched, `questionVisibleTo`) &&
                                  getIn(errors, `questionVisibleTo`)
                                }
                                className="inputs-label-font"
                              >
                                Question visible to
                                <span className={"astrik-color"}>*</span>
                              </InputLabel>
                            </Grid>
                            <Grid item xs={6}>
                              <RadioButtonGroupComponent
                                value={values.questionVisibleTo}
                                name="questionVisibleTo"
                                onChange={(event) => {
                                  if (event?.target?.value === UsersRoleTypeEnum.Mentor) {
                                    setFieldValue("isVisibleForMentor", true);
                                    setFieldValue(
                                      "isVisibleForMentee",
                                      false
                                    );
                                    setFieldValue("menteeQuestion", "");
                                    setFieldValue(
                                      "questionVisibleTo",
                                      event?.target?.value
                                    );
                                  } else if (
                                    event?.target?.value === UsersRoleTypeEnum.Mentee
                                  ) {
                                    setFieldValue("isVisibleForMentee", true);
                                    setFieldValue("mentorQuestion", "");
                                    setFieldValue(
                                      "isVisibleForMentor",
                                      false
                                    );
                                    setFieldValue(
                                      "questionVisibleTo",
                                      event?.target?.value
                                    );
                                  } else {
                                    setFieldValue("isVisibleForMentee", true);
                                    setFieldValue("isVisibleForMentor", true);
                                    setFieldValue(
                                      "questionVisibleTo",
                                      event?.target?.value
                                    );
                                  }
                                }}
                              >
                                <FormControlLabel
                                  value={UsersRoleTypeEnum.Mentor}
                                  control={
                                    <MuiRadioButton
                                      error={
                                        getIn(touched, `questionVisibleTo`) &&
                                        getIn(errors, `questionVisibleTo`)
                                      }
                                    />
                                  }
                                  label={
                                    <Box className="inputs-label-font">
                                      Frientor
                                    </Box>
                                  }
                                  labelPlacement="end"
                                />
                                <FormControlLabel
                                  value={UsersRoleTypeEnum.Mentee}
                                  control={
                                    <MuiRadioButton
                                      error={
                                        getIn(touched, `questionVisibleTo`) &&
                                        getIn(errors, `questionVisibleTo`)
                                      }
                                    />
                                  }
                                  label={
                                    <Box className="inputs-label-font">
                                      Frientee
                                    </Box>
                                  }
                                  labelPlacement="end"
                                />
                                <FormControlLabel
                                  value={UsersRoleTypeEnum.MentorAndMentee}
                                  control={
                                    <MuiRadioButton
                                      error={
                                        getIn(touched, `questionVisibleTo`) &&
                                        getIn(errors, `questionVisibleTo`)
                                      }
                                    />
                                  }
                                  label={
                                    <Box className="inputs-label-font">
                                      Both
                                    </Box>
                                  }
                                  labelPlacement="end"
                                />
                              </RadioButtonGroupComponent>
                            </Grid>
                            <Grid item xs={12}>
                              {" "}
                              {getIn(touched, `questionVisibleTo`) &&
                                getIn(errors, `questionVisibleTo`) && (
                                  <FormHelperText
                                    error={true}
                                    className="helpertext-error"
                                  >
                                    {errors?.questionVisibleTo}
                                  </FormHelperText>
                                )}
                            </Grid>
                          </Grid>
                          {values?.isVisibleForMentor ? <Box my={2} className={"individual-padding"}>
                            {values?.isVisibleForMentor && <MuiTextField
                              textfieldlabel={<span>Frientor’s version <span className={"astrik-color"}>*</span></span>}
                              name="mentorQuestion"
                              placeholder="Enter"
                              // required={true}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values?.mentorQuestion}
                              helperText={
                                getIn(touched, `mentorQuestion`)
                                  ? getIn(errors, `mentorQuestion`)
                                  : ""
                              }
                              error={
                                getIn(touched, `mentorQuestion`) &&
                                getIn(errors, `mentorQuestion`)
                              }
                            />}
                          </Box> : null}
                          {values?.isVisibleForMentee ? <Box my={2} className={"individual-padding"}>
                            {values?.isVisibleForMentee && <MuiTextField
                              textfieldlabel={<span>Frientee's version<span className={"astrik-color"}>*</span></span>}
                              name="menteeQuestion"
                              placeholder="Enter"
                              // required={true}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values?.menteeQuestion}
                              helperText={
                                getIn(touched, `menteeQuestion`)
                                  ? getIn(errors, `menteeQuestion`)
                                  : ""
                              }
                              error={
                                getIn(touched, `menteeQuestion`) &&
                                getIn(errors, `menteeQuestion`)
                              }
                            />}
                          </Box> : null}
                        </Box>
                        <hr className="horizontal-border" />
                        <Grid
                          container
                          direction={"row"}
                          alignItems={"center"}
                          pt={2}
                          pb={2}
                          className={"individual-padding"}
                        >
                          <Grid item xs={2}>
                            <InputLabel className="inputs-label-font" error={getIn(touched, `responseType`) &&
                              getIn(errors, `responseType`)}>
                              Response type  <span className={"astrik-color"}>*</span>
                            </InputLabel>
                          </Grid>
                          <Grid item xs={4}>
                            <FormControl>
                              <RadioButtonGroupComponent
                                value={values?.responseType?.length > 0 && values?.responseType}
                                name={"responseType"}
                                onChange={(event) => {
                                  setFieldValue(
                                    "responseType",
                                    event.target.value
                                  );
                                  handleResponseType(event.target.value);
                                }}
                                onBlur={handleBlur}
                              >
                                <FormControlLabel
                                  value={`${ResponseTypeEnum?.Text}`}
                                  control={
                                    <MuiRadioButton
                                      color="primary"
                                      error={
                                        getIn(touched, `responseType`) &&
                                        getIn(errors, `responseType`)
                                      }
                                    />
                                  }
                                  label={
                                    <Box className="inputs-label-font">Text</Box>
                                  }
                                  labelPlacement="end"
                                />
                                <FormControlLabel
                                  value={`${ResponseTypeEnum.Image}`}
                                  control={
                                    <Radio
                                      sx={{
                                        "& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)":
                                        {
                                          color:
                                            getIn(touched, `responseType`) &&
                                              getIn(errors, `responseType`)
                                              ? "#e0514b"
                                              : "",
                                        },
                                      }}
                                    />
                                  }
                                  label={
                                    <Box className="inputs-label-font">
                                      Image
                                    </Box>
                                  }
                                  labelPlacement="end"
                                />
                              </RadioButtonGroupComponent>
                            </FormControl>
                          </Grid>
                          <Grid xs={12}>
                            {getIn(touched, `responseType`) &&
                              getIn(errors, `responseType`) && (
                                <FormHelperText
                                  error={true}
                                  className="helpertext-error"
                                >
                                  {/* {errors?.responseType && errors?.responseType} */}
                                </FormHelperText>
                              )}
                          </Grid>
                        </Grid>
                        <hr className="horizontal-border" />
                        {values?.responseType?.length ?
                          <Grid container pt={2} pb={2}>
                            {values?.responseType?.length > 0 && (
                              <Grid item xl={4} lg={5} className={"individual-padding"}>
                                <InputLabel
                                  error={
                                    getIn(touched, `responseFormat`) &&
                                    getIn(errors, `responseFormat`)
                                  }
                                >
                                  Select response format
                                  <span className={"astrik-color"}>*</span>
                                </InputLabel>
                                <MuiTextField
                                  select
                                  fullWidth
                                  placeholder="Select"
                                  name={"responseFormat"}
                                  value={values?.responseFormat}
                                  error={
                                    getIn(touched, `responseFormat`) &&
                                    getIn(errors, `responseFormat`)
                                  }
                                  onChange={(event) => {
                                    if (
                                      event.target.value ===
                                      ActionTypeEnumTextResponse.SingleChoice &&
                                      values?.responseType ===
                                      ResponseTypeEnum.Text
                                    ) {
                                      setFieldValue(
                                        "responseFormat",
                                        event.target.value
                                      );
                                      setFieldValue(
                                        "maximumResponseSelection",
                                        1
                                      );

                                      handleResponseFormat(event.target.value);
                                    } else if (
                                      event.target.value ===
                                      ActionTypeEnumTextResponse.SingleChoice &&
                                      values?.responseType ===
                                      ResponseTypeEnum.Image
                                    ) {
                                      setFieldValue(
                                        "responseFormat",
                                        event.target.value
                                      );
                                      handleResponseFormat(event.target.value);
                                      setFieldValue(
                                        "maximumResponseSelection",
                                        2
                                      );
                                    } else {
                                      setFieldValue(
                                        "responseFormat",
                                        event.target.value
                                      );
                                      handleResponseFormat(event.target.value);
                                    }
                                  }}
                                >
                                  {actionDropDown?.map(
                                    (textSelection: any, index: number) => (
                                      <MenuItem value={textSelection.value}>
                                        {textSelection?.label}
                                      </MenuItem>
                                    )
                                  )}
                                </MuiTextField>
                              </Grid>
                            )}
                            <Grid item xs={12} mt={2} className={"individual-padding"}>
                              {(values?.responseFormat ===
                                ActionTypeEnumTextResponse.MultipleChoice ||
                                values?.responseFormat ===
                                ActionTypeEnumTextResponse.SingleChoice) &&
                                values.responseType === ResponseTypeEnum.Text && (
                                  <Box mt={2}>
                                    <InputLabel
                                      error={
                                        getIn(touched, "options") &&
                                        getIn(errors, `options`)
                                      }
                                    >
                                      Response options{" "}
                                      <span className={"astrik-color"}>*</span>
                                      <span
                                        style={{
                                          fontStyle: "italic",
                                          fontSize: "14px",
                                          color: "#807b92",
                                        }}
                                      >
                                        ( Press “Enter” after each response
                                        option)
                                      </span>
                                    </InputLabel>
                                    <MuiChipsInput
                                      name={"options"}
                                      hideClearAll
                                      value={values.options.map((item: any) => item.optionName)}
                                      onAddChip={(chipValue: any, chipIndex: any) => {
                                        setFieldValue("options",
                                          [
                                            ...values.options,
                                            {
                                              id: "",
                                              optionName: chipValue
                                            }
                                          ]
                                        )
                                      }}
                                      onEditChip={
                                        (chipValue: any, chipIndex: any) => {
                                          const newObj = values?.options?.map((item: any, index: any) => {
                                            if (chipIndex == index) {
                                              return {
                                                id: item.id,
                                                optionName: chipValue
                                              };
                                            }
                                            return {
                                              ...item
                                            };
                                          });
                                          setFieldValue("options", newObj);
                                        }
                                      }
                                      onDeleteChip={(chipValue, chipIndex) => {
                                        const deletedID = values?.options?.find((item: any, index: any) => (index === chipIndex))
                                        const deleted = values?.options?.filter((item: any, index: any) => (index !== chipIndex))
                                        if (deletedID?.id?.length > 0) {
                                          setDeleteOption([...deletedOption, deletedID.id])
                                        }
                                        setFieldValue("options", deleted)
                                      }

                                      }
                                      // renderChip={()}
                                      onBlur={handleBlur}
                                      error={
                                        getIn(touched, "options") &&
                                        getIn(errors, `options`)
                                      }
                                      helperText={
                                        getIn(touched, `options`)
                                          ? getIn(errors, `options`)
                                          : ""
                                      }
                                    />
                                  </Box>
                                )}

                              {values?.responseFormat ===
                                ActionTypeEnumTextResponse.TopicQuestion && (
                                  <FieldArray name="topicCategory">
                                    {({ insert, remove, push }) => (
                                      <div>
                                        {values?.topicCategory?.length > 0 &&
                                          values?.topicCategory?.map(
                                            (question: any, index: any) => (
                                              <Grid container spacing={1} mb={1}>
                                                <Grid item xl={3} lg={4}>
                                                  <MuiTextField
                                                    placeholder="Create topic category"
                                                    textfieldlabel={
                                                      index == 0 && <span>Topic Category<span className={"astrik-color"}>*</span></span>
                                                    }
                                                    name={`topicCategory.${index}.categoryName`}
                                                    value={
                                                      values?.topicCategory[index]
                                                        ?.categoryName
                                                    }
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={
                                                      getIn(
                                                        touched,
                                                        `topicCategory.${index}.categoryName`
                                                      ) &&
                                                      getIn(
                                                        errors,
                                                        `topicCategory.${index}.categoryName`
                                                      )
                                                    }
                                                    helperText={
                                                      getIn(
                                                        touched,
                                                        `topicCategory.${index}.categoryName`
                                                      )
                                                        ? getIn(
                                                          errors,
                                                          `topicCategory.${index}.categoryName`
                                                        )
                                                        : ""
                                                    }
                                                  />
                                                </Grid>
                                                <Grid item xs={7}>
                                                  {index == 0 && (
                                                    <InputLabel
                                                      error={
                                                        getIn(
                                                          touched,
                                                          `topicCategory.${index}.options`
                                                        ) &&
                                                        getIn(
                                                          errors,
                                                          `topicCategory.${index}.options`
                                                        )
                                                      }
                                                    >
                                                      Response options{" "}
                                                      <span className={"astrik-color"}>*</span>
                                                      <span
                                                        style={{
                                                          fontStyle: "italic",
                                                          fontSize: "14px",
                                                        }}
                                                      >
                                                        ( Press “Enter” after each
                                                        response option)
                                                      </span>
                                                    </InputLabel>
                                                  )}
                                                  <MuiChipsInput
                                                    name={`topicCategory.${index}.options`}
                                                    hideClearAll
                                                    value={
                                                      values?.topicCategory[index]
                                                        ?.options?.map((item: any) => item?.optionName)
                                                    }
                                                    // onChange={(newValue: any) => {
                                                    //   setFieldValue(
                                                    //     `${`topicCategory.${index}.options`}`,
                                                    //     newValue
                                                    //   );
                                                    // }}
                                                    onAddChip={(chipValue: any, chipIndex: any) => {
                                                      setFieldValue(`${`topicCategory.${index}.options`}`,
                                                        [
                                                          ...values.topicCategory[index].options,
                                                          {
                                                            id: "",
                                                            optionName: chipValue
                                                          }
                                                        ]
                                                      )
                                                    }}
                                                    onEditChip={(chipValue: any, chipIndex: any) => {
                                                      const editObj = values?.topicCategory[index]?.options?.map((item: any, ind: any) => {
                                                        if (ind === chipIndex) {
                                                          return {
                                                            id: item.id,
                                                            optionName: chipValue
                                                          };
                                                        } return {
                                                          ...item
                                                        }

                                                      })
                                                      setFieldValue(`${`topicCategory.${index}.options`}`, editObj)
                                                    }}
                                                    onDeleteChip={(chipValue: any, chipIndex: any) => {
                                                      const deletedID = values?.topicCategory[index]?.options?.find((item: any, index: any) => (index === chipIndex))
                                                      const deleted = values?.topicCategory[index]?.options?.filter((item: any, index: any) => (index !== chipIndex))
                                                      setFieldValue(`${`topicCategory.${index}.options`}`, deleted)
                                                      if (deletedID?.id?.length > 0) {
                                                        console.log(deletedID, "deletedIDdeletedID")
                                                        setFieldValue(`${`topicCategory.${index}.deletedOptionId`}`, [...values?.topicCategory[index].deletedOptionId, deletedID])
                                                      }

                                                    }}
                                                    // renderChip={()}
                                                    onBlur={handleBlur}
                                                    error={
                                                      getIn(
                                                        touched,
                                                        `topicCategory.${index}.options`
                                                      ) &&
                                                      getIn(
                                                        errors,
                                                        `topicCategory.${index}.options`
                                                      )
                                                    }
                                                    helperText={
                                                      getIn(
                                                        touched,
                                                        `topicCategory.${index}.options`
                                                      )
                                                        ? getIn(
                                                          errors,
                                                          `topicCategory.${index}.options`
                                                        )
                                                        : ""
                                                    }
                                                  />
                                                </Grid>
                                                <Grid item xs={1}>
                                                  {index > 0 && (
                                                    <IconButton
                                                      onClick={() => {
                                                        remove(index)
                                                        if (values?.topicCategory[index]?.topicCategoryId?.length > 0) {
                                                          setDeleteCategoryID([...deleteCategoryID, values?.topicCategory[index]?.topicCategoryId])
                                                        }

                                                      }
                                                      }
                                                    >
                                                      <img
                                                        src={MediaAssets.ic_delete}
                                                        width={30}
                                                      />
                                                    </IconButton>
                                                  )}
                                                </Grid>
                                              </Grid>
                                            )
                                          )}
                                        <Box mt={2}>
                                          <MuiButton
                                            variant="outlined"
                                            onClick={() => {
                                              push({
                                                topicCategoryId: "",
                                                categoryName: "",
                                                options: [],
                                              });
                                            }}
                                          >
                                            {" "}
                                            + Add another category
                                          </MuiButton>
                                        </Box>
                                      </div>
                                    )}
                                  </FieldArray>
                                )}
                              {(values?.responseFormat ===
                                ActionTypeEnumTextResponse.MultipleChoice ||
                                values?.responseFormat ===
                                ActionTypeEnumTextResponse.SingleChoice) &&
                                values.responseType == ResponseTypeEnum.Image && (
                                  <div>
                                    <InputLabel
                                      className="inputs-label-font"
                                      required
                                      error={
                                        getIn(touched, `responseOptionImage`) &&
                                        getIn(errors, `responseOptionImage`)
                                      }
                                    >
                                      Response options

                                    </InputLabel>
                                    <FieldArray name="responseOptionImage">
                                      {({ insert, remove, push }) => (
                                        <Grid
                                          container
                                          spacing={1}
                                          alignItems="flex-start"
                                        >
                                          {values?.responseOptionImage?.map(
                                            (item: any, index: any) => (
                                              <Grid item xl={2} lg={3}>
                                                <ImageUploader
                                                  name={`responseOptionImage.${index}.imageUrl`}
                                                  index={index}
                                                  // value={values.responseOptionImage[index]?.imageUrl && values?.responseOptionImage[index]?.imageUrl}
                                                  handleImageUpload={(e) => {
                                                    const imageBlod =
                                                      URL.createObjectURL(
                                                        e.target.files[0]
                                                      );
                                                    setFieldValue(
                                                      `responseOptionImage.${index}.name`,
                                                      e.target.files[0].name
                                                    );
                                                    setFieldValue(
                                                      `responseOptionImage.${index}.fileType`,
                                                      e.target.files[0].type
                                                    );
                                                    setFieldValue(
                                                      `responseOptionImage.${index}.imageUrl`,
                                                      imageBlod
                                                    );
                                                  }}
                                                  captionValue={
                                                    values.responseOptionImage[
                                                      index
                                                    ]?.caption
                                                  }
                                                  captionName={`responseOptionImage.${index}.caption`}
                                                  handleChangeCaption={handleRestrictLength(setFieldValue, 20)}
                                                  previewImage={
                                                    values?.responseOptionImage[
                                                      index
                                                    ]?.imageUrl
                                                      ? values
                                                        ?.responseOptionImage[
                                                        index
                                                      ]?.imageUrl
                                                      : null
                                                  }
                                                  fieldhelpertext={
                                                    getIn(
                                                      touched,
                                                      `responseOptionImage.${index}.imageUrl`
                                                    )
                                                      ? getIn(
                                                        errors,
                                                        `responseOptionImage.${index}.imageUrl`
                                                      )
                                                      : ""
                                                  }
                                                  handleDeleteImageField={() =>
                                                    remove(index)
                                                  }
                                                  onDeleteImage={(e: any) => {

                                                    if (item?.id?.length > 0) {
                                                      setImageResponseDelete(item?.id)
                                                      setShouldOpenConfirmOnImg(true)
                                                    } else {
                                                      deleteImageResponse(setFieldValue, index)
                                                    }


                                                  }}
                                                />
                                              </Grid>
                                            )
                                          )}

                                          <Grid item xl={2} lg={3} mt={4}>
                                            <MuiButton
                                              variant="outlined"
                                              onClick={() =>
                                                push({
                                                  fileType: "",
                                                  name: "",
                                                  imageUrl: "",
                                                  id: "",
                                                  caption: "",
                                                })
                                              }
                                            >
                                              {`+Add more`}
                                            </MuiButton>
                                          </Grid>
                                        </Grid>
                                      )}
                                      {/* <Grid></Grid> */}
                                    </FieldArray>
                                    {values?.responseOptionImage[0]?.imageUrl || values?.responseOptionImage[1]?.imageUrl ? <div className="character-limit-text">
                                      Character limit for each caption is{" "}
                                      <span className={"character-limit-count"}>
                                        20 Characters
                                      </span>
                                    </div> : null}
                                  </div>
                                )}
                              {getIn(touched, `responseOptionImage`) &&
                                getIn(errors, `responseOptionImage`) && (
                                  <InputLabel
                                    className="inputs-label-font"
                                    required
                                    error={
                                      getIn(touched, `responseOptionImage`) &&
                                      getIn(errors, `responseOptionImage`)
                                    }
                                  >
                                    {values.responseOptionImage?.length === 2 &&
                                      errors?.responseOptionImage?.length === 2
                                      ? "Min two response options are mandatory"
                                      : "Upload images in all the options,delete the empty options"}
                                  </InputLabel>
                                )}
                            </Grid>
                          </Grid> : null}
                        <hr className="horizontal-border" />
                        {values?.responseFormat && (
                          <div>
                            {values?.responseFormat ===
                              ActionTypeEnumTextResponse.TextInputWithLimit &&
                              values.responseType === ResponseTypeEnum.Text && (
                                <Grid
                                  container
                                  direction="row"
                                  alignItems={"center"}
                                  p={1}
                                  spacing={1}

                                >
                                  <Grid item lg={4} xl={3}>
                                    <InputLabel
                                      required
                                      error={
                                        getIn(
                                          touched,
                                          `characterLimitForResponse`
                                        ) &&
                                        getIn(
                                          errors,
                                          `characterLimitForResponse`
                                        )
                                      }
                                      className="inputs-label-font"
                                    >
                                      Character limit for responses
                                    </InputLabel>
                                  </Grid>
                                  <Grid xs={1.5} p={1}>
                                    <MuiTextField
                                      name={"characterLimitForResponse"}
                                      onChange={handleNumberFieldChange(
                                        setFieldValue
                                      )}
                                      value={values.characterLimitForResponse}
                                      onBlur={handleBlur}
                                      placeholder={"Enter"}
                                      error={
                                        getIn(
                                          touched,
                                          `characterLimitForResponse`
                                        ) &&
                                        getIn(
                                          errors,
                                          `characterLimitForResponse`
                                        )
                                      }
                                    />
                                  </Grid>
                                  <Grid item xs={12}>
                                    {getIn(
                                      touched,
                                      `characterLimitForResponse`
                                    ) &&
                                      getIn(
                                        errors,
                                        `characterLimitForResponse`
                                      ) && (
                                        <FormHelperText
                                          error={true}
                                          className="helpertext-error"
                                        >
                                          {/* {errors?.characterLimitForResponse} */}
                                        </FormHelperText>
                                      )}
                                  </Grid>
                                </Grid>
                              )}
                            {values?.responseFormat ==
                              ActionTypeEnumTextResponse.MultipleChoice && (
                                <Grid
                                  container
                                  p={2}
                                  direction={"row"}
                                  alignItems={"center"}
                                >
                                  <Grid item xl={3} lg={4}>
                                    <Typography className="inputs-label-font">
                                      Maximum response selection
                                    </Typography>
                                  </Grid>
                                  <Grid xs={2}>
                                    <MuiTextField
                                      name="maximumResponseSelection"
                                      onChange={handleNumberFieldChange(
                                        setFieldValue
                                      )}
                                      onBlur={handleBlur}
                                      placeholder={"Enter Here"}
                                      value={values?.maximumResponseSelection}
                                      helperText={
                                        getIn(touched, `maximumResponseSelection`)
                                          ? getIn(
                                            errors,
                                            `maximumResponseSelection`
                                          )
                                          : ""
                                      }
                                      error={
                                        getIn(
                                          touched,
                                          `maximumResponseSelection`
                                        ) &&
                                        getIn(errors, `maximumResponseSelection`)
                                      }
                                    />
                                  </Grid>
                                </Grid>
                              )}
                            {/* {/ <hr /> /} */}
                            <hr className="horizontal-border" />
                            <Box p={1}>
                              <FormControlLabel
                                control={
                                  <Switch
                                    color="primary"
                                    name={"isQuestionMandatory"}
                                    value={values.isQuestionMandatory}
                                    onChange={(event, checked) =>
                                      setFieldValue(
                                        "isQuestionMandatory",
                                        checked ? true : false
                                      )
                                    }
                                    checked={values.isQuestionMandatory}
                                  />
                                }
                                label={
                                  <Box className="inputs-label-font">
                                    {StringConstant.markThisAsMandatoryQuestion}
                                  </Box>
                                }
                                labelPlacement="start"
                              />
                            </Box>
                            <hr className="horizontal-border" />
                            <Stack
                              p={1}
                              direction={"row"}
                              alignItems={"center"}
                            >
                              <FormControlLabel
                                control={
                                  <Switch
                                    color="primary"
                                    name={"isImpactful"}
                                    value={values.isImpactful}
                                    onChange={(event, checked) =>
                                      setFieldValue(
                                        "isImpactful",
                                        checked ? true : false
                                      )
                                    }
                                    checked={values.isImpactful}
                                  />
                                }
                                label={
                                  <Box className="inputs-label-font">
                                    {StringConstant.markThisAsImpactfulQuestion}
                                  </Box>
                                }
                                labelPlacement="start"
                              />
                              <Typography
                                color="error.main"
                                fontStyle={"italic"}
                              >
                                ( Impactful questions will be used for matching
                                logic)
                              </Typography>
                            </Stack>
                            <hr className="horizontal-border" />
                            <Box p={1}>
                              <FormControlLabel
                                value={values.isAnswerMandatory}
                                control={
                                  <Switch
                                    color="primary"
                                    name={"mustMatchQuestion"}
                                    value={values.isMustMatchQuestion}
                                    onChange={(event, checked) =>
                                      setFieldValue(
                                        "isMustMatchQuestion",
                                        checked ? true : false
                                      )
                                    }
                                    checked={values.isMustMatchQuestion}
                                  />
                                }
                                label={
                                  <Box className="inputs-label-font">
                                    {StringConstant.mustMatchQuestion}
                                  </Box>
                                }
                                labelPlacement="start"
                              />
                            </Box>
                            <hr className="horizontal-border" />
                            <Box p={1}>
                              <FormControlLabel
                                value={values.isAnswerMandatory}
                                control={
                                  <Switch
                                    color="primary"
                                    name={"isAnswerMandatory"}
                                    value={values.isAnswerMandatory}
                                    onChange={(event, checked) =>
                                      setFieldValue(
                                        "isAnswerMandatory",
                                        checked ? true : false
                                      )
                                    }
                                    checked={values.isAnswerMandatory}
                                  />
                                }
                                label={
                                  <Box className="inputs-label-font">
                                    {StringConstant.markResponsesMandatory}
                                  </Box>
                                }
                                labelPlacement="start"
                              />
                            </Box>
                            <hr className="horizontal-border" />
                            <Box p={1}>
                              <FormControlLabel
                                control={
                                  <Switch
                                    color="primary"
                                    name={"isCustomisable"}
                                    value={values.isCustomisable}
                                    onChange={(event, checked) =>
                                      setFieldValue(
                                        "isCustomisable",
                                        checked ? true : false
                                      )
                                    }
                                    checked={values.isCustomisable}
                                  />
                                }
                                label={
                                  <Box className="inputs-label-font">
                                    {
                                      StringConstant.allowProgramAdminToAddMoreResponse
                                    }
                                  </Box>
                                }
                                labelPlacement="start"
                              />
                            </Box>
                          </div>
                        )}
                      </Stack>
                    </Form>
                  </FormFieldContainer>
                </Grid>
              </Grid>
              <ScrollToError />
            </>
          )}
        </Formik>

      </OnboardingQuestionStyleWrapper>
    </>
  );
};

export default OnboardingQuestion;
