import { Dialog, styled } from "@mui/material";

export const ConfirmationDialogWrapper = styled(Dialog)<any>`
  &&& .MuiPaper-root {
    padding: 30px 60px;
    box-shadow: none;
    bottom: 20px;
    border-radius:20px;
  }

  &&& .MuiDialogTitle-root {
    justify-content: center;
    font-size: 2.4rem;
  }

  &&& .MuiDialogContentText-root {
    font-size: 2rem;
  }

  backdrop-filter: blur(3px);
`;
