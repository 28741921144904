import {
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    IconButton,
    styled,
} from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";

interface MuiDialogProps {
    isDialogOpen?: boolean;
    handleClose?: () => void;
    dialogTitle?: any;
    children?: any;
}
export const MuiDialogWrapper = styled(Dialog)(({ theme }) => ({
    "&&& .MuiPaper-root": {
        borderRadius: "20px"

    }
}))

const MuiDialog = (props: MuiDialogProps) => {
    const { isDialogOpen = false, handleClose, children, dialogTitle } = props;
    return (
        <MuiDialogWrapper
            open={isDialogOpen}
            onClose={handleClose}
            maxWidth="xl"
            // fullWidth={true}
        >
            <DialogTitle>
                {dialogTitle}
                <IconButton onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>{children}</DialogContent>
        </MuiDialogWrapper>
    );
};

export default MuiDialog;
