import { useState } from "react";
import { TableRow, TableCell } from "@mui/material";
import { StringConstant } from "../../../resources/strings";
import MediaAssets from "../../../assets";
import styles from "../create-entity/CreateEntity.module.css";
import { useNavigate } from "react-router-dom";
import { RouteConfigurations } from "../../../routes/RouteConfigurations";
import MuiDialog from "../../../components/mui-dialog/MuiDialog";
import EntityTheme from "../entity-themes/EntityTheme";
import dayjs from "dayjs";

interface Props {
  row: any;
  // setIsDialofOpen: (state: boolean) => void;
}
const OnBoardedEntitiesRows = ({ row }: Props) => {
  const [entityId, setEntityId] = useState(() => row.id);
  const [isDialogOpen, setIsDialofOpen] = useState<boolean>(false);

  const navigate = useNavigate();
  const handleDialogClose = () => {
    setIsDialofOpen(false);
  };

  const handleEdit = () => {
    navigate(RouteConfigurations.createEntity, { state: { row } });
  };

  return (
    <TableRow tabIndex={-1} sx={{ "&& .MuiTableCell-root ": { fontSize: "var(--font-180-rem)" } }}>
      <TableCell>{row.entityName}</TableCell>
      <TableCell>{row.entityTypeName}</TableCell>
      <TableCell>{row?.NumberOfProgramAdminsAssociatedToEntity}</TableCell>
      <TableCell>
        {row?.programThemesAssociatedToEntity.length}
        <span
          className={styles["table-cell-link"]}
          onClick={() => setIsDialofOpen(true)}
        >
          {StringConstant.manage}{" "}
          <img src={MediaAssets.ic_right_arrow} alt="" />
        </span>
      </TableCell>
      <TableCell>
        {row.createdBy?.firstName} {row.createdBy?.lastName}
      </TableCell>
      <TableCell>{dayjs(row?.createdAt).format("DD-MM-YYYY")}</TableCell>
      <TableCell>
        {row.isEntityOnboardingCompleted
          ? StringConstant.onboarded
          : StringConstant.pending}
      </TableCell>
      <TableCell>
        <img
          src={MediaAssets.ic_edit_icon}
          width="30px"
          height="30px"
          style={{ cursor: "pointer" }}
          onClick={handleEdit}
        />
      </TableCell>

      <MuiDialog
        dialogTitle={`${row.entityName.charAt(0).toUpperCase() + row.entityName.slice(1)
          } - Manage Program Themes`}
        isDialogOpen={isDialogOpen}
        handleClose={handleDialogClose}
        children={<EntityTheme entityId={row.id} />}
      />
    </TableRow>
  );
};

export default OnBoardedEntitiesRows;
