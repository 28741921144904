type column =
  | {
    id: string;
    label: string;
    minWidth: number;
    align: string;
  }[]
  | [];

export const columns: column = [
  { id: "entity_name", label: "Entity Name", minWidth: 150, align: "left" },
  { id: "entity_type", label: "Entity Type", minWidth: 150, align: "left" },
  {
    id: "program_admins",
    label: "Program Admins",
    minWidth: 150,
    align: "left",
  },
  {
    id: "program_theme",
    label: "Program Themes",
    minWidth: 150,
    align: "left",
  },
  {
    id: "created_by",
    label: "Created By",
    minWidth: 150,
    align: "left",
  },
  {
    id: "created_on",
    label: "Created on",
    minWidth: 150,
    align: "left",
  },
  {
    id: "status",
    label: "Status",
    minWidth: 150,
    align: "left",
  },
  {
    id: "",
    label: "",
    minWidth: 70,
    align: "left",
  },
];
