import React, { useEffect } from "react";
import { Box, Grid } from "@mui/material";
import styles from "./CreateThemeForm.module.css";
import { StringConstant } from "../../../resources/strings";
import { getTopicCategoryRequest } from "../../../redux/program-theme/topic-category/TopicCategoryRedux";
import { useDispatch, useSelector } from "react-redux";
import FormBottomButtons from "../../../components/form-bottom-btns/FormBottomButtons";
import { useNavigate } from "react-router";
import { setSubmitProgramThemeNull, submitProgramThemeRequest } from "../../../redux/program-theme/submit-program-theme/SubmitProgramThemeRedux";
import { setToaster } from "../../../redux/toaster/ToasterRedux";

interface IThemePreview {
  tab?: number;
  handleTabPrev?: (event: any) => void;
  handleTabNext: (event: any) => void;
  handleValidMove?: (validMove: boolean) => void;
}

const ThemePreview = (props: IThemePreview) => {
  const { tab, handleTabPrev, handleTabNext, handleValidMove } = props;
  const actionDispatch = (dispatch: any) => ({
    getTopicCategory: (data: any) => dispatch(getTopicCategoryRequest(data)),
    submitProgram:(data:any)=>dispatch(submitProgramThemeRequest(data)),
    setSumbitNull:()=>dispatch(setSubmitProgramThemeNull()),
    setToasterInfo: (data: any) => dispatch(setToaster(data)),
  });

  const { getTopicCategory,submitProgram,setSumbitNull,setToasterInfo } = actionDispatch(useDispatch());
  const navigate=useNavigate()

  const { createProgramTheme, topicCategroy,submitProgramTheme,allTopicCategory,programThemeQuestion } = useSelector(
    (state: any) => state
  );

  useEffect(() => {
    getTopicCategory(createProgramTheme?.programThemeId);
  }, []);

  useEffect(()=>{
    if(submitProgramTheme?.toasterDetails){

      setToasterInfo({
        key: "toasterDetails",
        value: { ...submitProgramTheme?.toasterDetails, toasterOpen: true },
      });
      navigate("/program-themes")
      setSumbitNull()
    }
  },[submitProgramTheme?.toasterDetails])

  const handleSubmit=()=>{
   const reqBody= {
     programThemeId: createProgramTheme?.programThemeId,
      isPublished: true,
      name:createProgramTheme?.reduxProgramTheme?.themeBasicInfo?.themeName,
      description:createProgramTheme?.reduxProgramTheme?.themeBasicInfo?.themeDescription,
    }

    submitProgram(reqBody)
  }

  return (
    <Box pl={3} mt={2} className={styles["program-theme-inner-container"]}>
      <p className={styles["form-heading"]}>
        {StringConstant.themepreviewHeading}
      </p>
      <Grid container mt={5}>
        <Grid item lg={12}>
          <p className={styles["form-sub-heading"]}>
            {StringConstant.topicInthisTheme}
          </p>
          <p className={styles["form-description"]}>
            {StringConstant.fetchedFromTheme}
          </p>
          {/* <div>{JSON.stringify(topicCategroy)}</div> */}
          <div className={styles["question-container"]}>
            {topicCategroy?.allTopicCategory?.topicsInThisTheme?.map(
              (element: any, index: any) => (
                <p className={styles["questions-created"]} key={element}>
                  {element}
                </p>
              )
            )}
          </div>
        </Grid>
        {/* <Grid item lg={6}>
          <p className={styles["form-sub-heading"]}>
            {StringConstant?.TopicfromOnboardingQuestions}
          </p>
          <p className={styles["form-description"]}>
            {StringConstant?.fetchedFromOnboardQuestions}
          </p>
          <div className={styles["question-container"]}>
            {topicCategroy?.allTopicCategory?.topicsFromOnboardingQuestions.map(
              (element: any, index: any) => (
                <span className={styles["questions-created"]} key={index}>
                  {element?.topic}
                </span>
              )
            )}
          </div>
        </Grid> */}
      </Grid>
      {/* <div>11{JSON.stringify(createProgramTheme?.reduxProgramTheme)}</div>
      <div>--{JSON.stringify(programThemeQuestion?.listsProgramThemeQuestion?.length)}</div>
      <div>--{JSON.stringify(createProgramTheme?.guideLineData)}</div>
      <div>--{JSON.stringify(createProgramTheme?.data?.isPublished)}</div> */}
      <FormBottomButtons
        tab={tab}
        pageId={1}
        isDisabled={!(createProgramTheme?.reduxProgramTheme && programThemeQuestion?.listsProgramThemeQuestion?.length >0 &&
           createProgramTheme?.guideLineData)  || createProgramTheme?.data?.isPublished}
        handleTabNext={handleTabNext}
        handleTabPrev={handleTabPrev}
        handleClick={handleSubmit}
        loading={createProgramTheme?.isLoading}
        next={false}
      />
    </Box>
  );
};

export default ThemePreview;
