import { SagaIterator } from "redux-saga";
import { call, put } from "redux-saga/effects";
import { HttpResponseTypeEnum } from "../../enumerations/HttpResponseTypeEnum";
import { onBoardedEntitiesRedux } from "../../redux/entity-management/OnBoardedEntitiesRedux";

export default function* fetchOnboardedEntitiesSaga(
  api: any,
  action: any
): SagaIterator {
  try {
    const response = yield call(api.fetchOnboardedEntities, action.payload);
    if (response.data.code === HttpResponseTypeEnum.Success) {
      yield put(
        onBoardedEntitiesRedux.actions.addOnBoardedEntitiesSuccess(
          response.data
        )
      );
    } else {
      yield put(
        onBoardedEntitiesRedux.actions.addOnBoardedEntitiesFailure(
          response.data
        )
      );
    }
  } catch (e) {
    yield put(onBoardedEntitiesRedux.actions.addOnBoardedEntitiesFailure(e));
  }
}
