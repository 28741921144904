import { IconButton, Typography, Box } from "@mui/material";
import { Stack } from "@mui/material";
import React from "react";
import { PageHeadingWrapper } from "./PageHeading.style";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import MuiButton from "../ui-elements/mui-button/MuiButton";
import { Link } from "react-router-dom";

interface PageheaderProps {
  pageHeading?: string;
  pageHeaderSubtitle?: string;
  isPageHeaderNavIcon?: boolean;
  headingSuffix?: string;
  isInviteButton?: boolean;
  buttonText?: string;
  btnroute?: string;
  handleClick?: () => void;
  buttonDisabled?:any;
}

const PageHeading = (props: PageheaderProps) => {
  const {
    pageHeading,
    pageHeaderSubtitle,
    isPageHeaderNavIcon,
    headingSuffix,
    isInviteButton,
    buttonText,
    btnroute,
    handleClick,
    buttonDisabled
  } = props;
  return (
    <PageHeadingWrapper>
      <Stack direction="row" alignItems={"flex-start"}>
        {isPageHeaderNavIcon && (
          <IconButton>
            <KeyboardArrowLeftIcon />
          </IconButton>
        )}
        <Box>
          <Typography className="page-header">
            {pageHeading}{" "}
            {headingSuffix !== "" && (
              <span className="suffix-user-conut">
                {headingSuffix ? `( ${headingSuffix} )` : ""}
              </span>
            )}
          </Typography>
          {pageHeaderSubtitle?.length && (
            <Typography className="page-header-subtitle">{pageHeaderSubtitle}</Typography> 
          )}
        </Box>
      </Stack>

      {isInviteButton && (
        <Box>
          <MuiButton onClick={handleClick} variant={"contained"} disabled={buttonDisabled} className="header-page-button">
            {buttonText}
          </MuiButton>
        </Box>
      )}
    </PageHeadingWrapper>
  );
};

export default PageHeading;
