import { Box, styled } from "@mui/material";

export const OnboardingQuestionListsStyleWrapper = styled(Box)(({ theme }) => ({
  border: `solid 1px ${theme.palette.grey[600]}`,
  borderRadius: 13,

  ".question-list-header": {
    fontSize: "1.8rem",
    color: `${theme.palette.primary.main}`,
    fontWeight: 600
  },

  ".question-version-style": {
    color: "#807b92",
    fontSize: "1.8rem",
    fontFamily: "Urbanist-Regular"
  },

  ".question-response-style": {
    color: `${theme.palette.grey.A400}`,
    fontSize: "1.8rem",
    fontFamily: "Urbanist-Regular"
  },
  ".question-list-icon": {
    width: "35px"
  },
  ".question-version-res": {
    fontFamily: "Urbanist-SemiBold",
    fontSize: "1.8rem",
    color: "#615b76",
    weight: 600
  },

  [theme.breakpoints.down("xl")]: {
    ".question-list-header": {
      fontSize: "1.2rem",
    },

    ".question-version-style": {
      fontSize: "1.2rem",
      fontFamily: "Urbanist-Regular"
    },
    ".question-response-style": {
      color: `${theme.palette.grey.A400}`,
      fontSize: "1.2rem",
      fontFamily: "Urbanist-Regular"
    },
    ".question-list-icon": {
      width: "21px"
    },
    ".question-version-res": {
      fontFamily: "Urbanist-SemiBold",
      fontSize: "1.2rem",
      color: "#615b76",
    },
  },

}));
