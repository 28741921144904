import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RouteConfigurations } from "../../routes/RouteConfigurations";
import MediaAssets from "../../assets";
import Notifications from "../../pages/notifications/Notifications";
import styles from "./Header.module.css";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ConfirmationDialog from "../confirmation-dialog/ConfirmationDialog";
import {
  getItemFromLocalStorage,
  removeItemFromLocalStorage,
} from "../../utils/LocalStorageUtils";
import { Constants } from "../../constants/Constants";
import {
  getProfileInfoRequest,
  getUserDetailsByEmailRequest,
  setLoginState,
} from "../../redux/login/LoginRedux";
import { useDispatch, useSelector } from "react-redux";
import { ClickAwayListener, Popper } from '@mui/material';
import { Paper } from '@mui/material';

const Header = () => {
  const navigate = useNavigate();
  const loggedInUser = getItemFromLocalStorage(Constants.loggedInUser);

  const actionDispatch = (dispatch: any) => {
    return {
      loginStateSet: (data: any) => dispatch(setLoginState(data)),
      getUserDetails: (email: any) =>
        dispatch(getUserDetailsByEmailRequest(email)),
      getProfileInfo: () => dispatch(getProfileInfoRequest()),
    };
  };

  const { loginStateSet, getUserDetails, getProfileInfo } = actionDispatch(useDispatch());

  const [isLogoutDialogOpen, setIsLogoutDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [isOpenNotification, setIsOpenNotification] = useState(false);
  const open = Boolean(anchorEl);

  const loginState = useSelector((state: any) => state.login);

  useEffect(() => {
    getProfileInfo()
  }, []);

  useEffect(() => {
    if (loginState?.isSuccess) {
      getProfileInfo()
      loginStateSet({ key: "isSuccess", value: false });
    }

  }, [loginState?.isSuccess]);


  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);

  };


  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigateToMyProfile = () => {
    navigate(RouteConfigurations.myProfile);
    handleClose()
    
  };

  const handleCloseLogoutDialog = () => {
    setIsLogoutDialogOpen(false);
  };

  const handleOpenLogoutDialog = () => {
    setIsLogoutDialogOpen(true);
    handleClose()
  };


  const onConfirmLogout = () => {
    removeItemFromLocalStorage(Constants.loggedInUser);
    removeItemFromLocalStorage(Constants.authorizationToken);
    setIsLogoutDialogOpen(false);
    loginStateSet({ key: "isLoggedIn", value: false });
    navigate(RouteConfigurations.login);
  };

  const onOpenNotification = () => {
    setIsOpenNotification(true);
  };

  const onCloseOnNotification = () => {
    setIsOpenNotification(false);
  };

  return (
    <header className={styles["header"]}>
      <ul>
        <li onClick={onOpenNotification}>
          <a>
            <img src={MediaAssets.ic_notifications} />
          </a>
          <span>Notifications</span>
        </li>
        <li onClick={handleClick}>
          <a>
            <img
              src={
                loginState?.profileDetails?.userProfilePic?.length > 0
                  ? loginState?.profileDetails?.userProfilePic[0]?.filePath
                  : MediaAssets.ic_profile
              }
            />
          </a>
          <span>Profile</span>
        </li>
      </ul>

      <Notifications
        onCloseOnNotification={onCloseOnNotification}
        openNotification={isOpenNotification}
      />
      <Menu
        id="long-menu"
        sx={{
          " && .MuiPaper-root": {
            borderRadius: " 0px  0px 12px 12px",
            width: "260px",
            marginLeft: "-19px",
            boxShadow: "1px 4px 8px 0 rgba(196, 196, 196, 0.36)"
          }
        }}
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        disableAutoFocusItem={false}
      >
        <MenuItem className={styles["header-menulist"]} onClick={navigateToMyProfile}>My Profile</MenuItem>
        <MenuItem className={styles["header-menulist"]} onClick={handleOpenLogoutDialog}>Logout</MenuItem>
      </Menu>


      <ConfirmationDialog
        isConfirmModelOpen={isLogoutDialogOpen}
        title={"Log Out"}
        contentText={"Are you sure you want to log out?"}
        leftButtonText={"No"}
        rightButtonText={"Yes"}
        handleClose={handleCloseLogoutDialog}
        handleConfirm={onConfirmLogout}
      />
      {/* <Notifications /> */}
    </header>
  );
};

export default Header;
