import { Grid, Paper, Stack } from "@mui/material";
import MediaAssets from "../../assets";
import styles from "./Login.module.css";
import { StringConstant } from "../../resources/strings";
import MuiButton from "../../components/ui-elements/mui-button/MuiButton";

export const Login = () => {
  const handleGoogleLogin = () => {
    window.open(`${process.env.REACT_APP_LOGIN_GOOGLE_CONSOLE_URL}?applicationType=0&prefixUrl`, "_self");
  };

  return (
    <div className={styles["login-container"]}>
      <Paper className={styles["login-paper-component"]}>
        <Grid container>
          <Grid item xs={4} className={styles["login-page-left-split"]}>
            <img
              src={MediaAssets.ic_frientor_logo_with_label}
              className={styles["logo"]}
              data-testid="login-frientor-logo"
            ></img>
            <div className={styles["super-admin-text"]}>
              {StringConstant.text_super_admin}
            </div>
            <Stack direction={"row"} spacing={0.5}>
              <div className={styles["login-with-your-frientor-label"]}>
                {StringConstant.text_login_with_your}
              </div>
              <div className={styles["frientor-mail-id-label"]}>
                {StringConstant.text_frientor_io}
              </div>
              <div className={styles["login-with-your-frientor-label"]}>
                {StringConstant.text_account}
              </div>
            </Stack>
            <MuiButton
              className={styles["sign-up-button"]}
              startIcon={< img src={MediaAssets.ic_google_logo} width="30" />}
              onClick={handleGoogleLogin}
            >
              {StringConstant.text_sign_in_with_google}
            </MuiButton>
          </Grid>
          <Grid item xs={8}>
            <img
              src={MediaAssets.ic_login_banner}
              className={styles["login-background-image"]}
              data-testid="login-background-image"
            ></img>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};
