import { LoadingButton, LoadingButtonProps } from "@mui/lab";
import { Box, styled } from "@mui/material";

const LoadingButtonWrapper = styled(Box)<any>(
  ({ theme, tab }: any) => ({

    " &&&& .MuiLoadingButton-root": {
      color: tab ? theme.palette.common.white : theme.palette.secondary.dark,
      fontFamily: "Urbanist-SemiBold",
      fontWeight: "600",
      height: "50px",
    },
    "&&&& .MuiLoadingButton-root.Mui-disabled": {
      color: theme.palette.common.white,
      backgroundImage: "none",
      border: "none",
      backgroundColor: theme.palette.grey[400]
    },
    "&&&& .MuiLoadingButton-root:hover": {
      border: "none",
      backgroundColor: theme.palette.primary.light
    },

    [theme.breakpoints.down("xl")]: {
      " &&&& .MuiLoadingButton-root": {
        height: "40px"
      },
    }
  })
);

interface ILoadingButton extends LoadingButtonProps {
  tab?: boolean,
}

export default function MuiLoadingButton(props: ILoadingButton) {
  const { children, tab } = props;
  return (
    <LoadingButtonWrapper tab={tab}>
      <LoadingButton {...props}>{children}</LoadingButton>
    </LoadingButtonWrapper>
  );
}
