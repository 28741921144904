import { Box, styled } from "@mui/material";

export const PageHeadingWrapper = styled(Box)(({ theme }) => ({
  marginTop: "8px",
  marginBottom: "15px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "97%",
  "& .MuiIconButton-root": {
    padding: "2px",
    marginTop: "4px",
  },
  ".suffix-user-conut": {
    fontWeight: 400,
    color: "var(--medium-gray-color)",
  },
  ".page-header": {
    fontFamily: "Urbanist-SemiBold",
    fontSize: "2.2rem",
    fontWeight: "600",
  },

  ".header-page-button": {
    fontSize: "1.8rem",
    height: "50px"
  },
  ".page-header-subtitle": {
    color: "#9793a5",
    fontSize: "16px",
    fontFamilyt:"Urbanist-Regular"
  },
  [theme.breakpoints.down("xl")]: {
    marginTop: "8px",
    ".page-header": {
      fontFamily: "Urbanist-SemiBold",
      fontSize: "1.6rem",

    },
    ".page-header-subtitle": {
      fontSize: "13px"
    },

    ".header-page-button": {
      fontSize: "1.3rem",
      height: "40px"
    },
  }


}));
