import { createSlice } from "@reduxjs/toolkit";
import { AnyARecord } from "dns";
import { AttachmentTypeEnum } from "../../../enumerations/FileUploadMIMETypeEnum";

export interface ICreateProgramTheme {
    isLoading?: boolean,
    error?: string,
    success:string,
    programThemeId:string,
    toasterDetails?: any;

}

const initialState: ICreateProgramTheme = {
  isLoading: false,
  error: "",
  success: "",
  programThemeId: "",
  toasterDetails: null
};

export const SubmitProgramThemeRedux = createSlice({
  name: "createProgramThemeRedux",
  initialState,
  reducers: {

    //updated the question
    submitProgramThemeRequest: (state: any, action: any) => {

      const newState = {
        ...state,
        isLoading: true,
      };
      return newState;
    },
    submitProgramThemeSuccess: (state: any, action: any) => {
      const newState = {
        ...state,
        isLoading: false,
        toasterDetails: action?.payload,
      };
      return newState;
    },
    submitProgramThemeFailure: (state: any, action: any) => {
      const newState = {
        ...state,
        isLoading: false,
        error: action?.payload,
        toasterDetails: action?.payload,
      };
      return newState;
    },
    setSubmitProgramTheme: (state: any, action: any) => {
      const newState = {
        ...state,
        [action.payload.key]: action.payload.value,
      };
      return newState;
    },
    setSubmitProgramThemeNull: (state: any) => {
      const newState = {
        ...state,
        toasterDetails: null,
      };
      return newState;
    },
  },
});

const { actions, reducer } = SubmitProgramThemeRedux;

export const {
  
  //on update
  submitProgramThemeRequest,
  submitProgramThemeSuccess,
  submitProgramThemeFailure,

  setSubmitProgramTheme,
  setSubmitProgramThemeNull,
} = actions;

export default reducer;
