import { Dashboard } from "@mui/icons-material";
import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import PageHeader from "../../components/page-heading/PageHeading";
import { adminDashBoardStaticData } from "./stats-card/dashboardStatData";
import StatsCard from "./stats-card/StatsCard";
import { useNavigate } from "react-router-dom";
import { RouteConfigurations } from "../../routes/RouteConfigurations";

import { setProgramThemeState } from "../../redux/program-theme/programtheme-question/ProgramThemQuestionRedux";
import { getProgramThemeRequest, setCreateProgramTheme } from "../../redux/program-theme/create-program-theme/CreateProgramThemeRedux";
import { useDispatch, useSelector } from 'react-redux';
import { getOnboardingQuestionRequest } from "../../redux/onboarding-question/OnboardingQuestionRedux";
import { getAllUsersByRoleIdRequest } from "../../redux/super-admin-user-management-redux/SuperAdminUserManagementRedux";
import { getItemFromLocalStorage } from "../../utils/LocalStorageUtils";
import { Constants } from "../../constants/Constants";
import { UsersRoleTypeEnum } from "../../enumerations/UsersRoleTypeEnum";
import { onBoardedEntitiesRedux } from "../../redux/entity-management/OnBoardedEntitiesRedux";
import { addOnBoardedEntitiesRequest } from "../../redux/entity-management/OnBoardedEntitiesRedux";

const SuperAdminDashBoard = () => {
  const { superAdmin, createProgramThemes, entityOnboard, onBoarding } =
    adminDashBoardStaticData;
  const navigate = useNavigate();
  const actionDispatch = (dispatch: any) => ({
    getProgramThemes: () => dispatch(getProgramThemeRequest()),
    setThemeQuestion: (data: any) => dispatch(setProgramThemeState(data)),
    setProgramThemeId: (data: any) => dispatch(setCreateProgramTheme(data)),
    setReduxProgramTheme: (data: any) => dispatch(setCreateProgramTheme(data)),
    getAllOnboardingQuestion: () => dispatch(getOnboardingQuestionRequest()),
    getAllUsersByRoleId: (roleId: any) =>
      dispatch(getAllUsersByRoleIdRequest(roleId)),
    getEntities: (data: any) => dispatch(addOnBoardedEntitiesRequest(data))
    // updateOnboardingQuestions: (data: any) => (dispatch(updateOnboardingQuestionRequest(data))),
    // setOnboardingNull: () => dispatch(setOnboardingToasterNull()),

    // setToasterInfo: (data: any) => dispatch(setToaster(data)),
  });
  const { getProgramThemes, setProgramThemeId, setReduxProgramTheme, setThemeQuestion, getAllOnboardingQuestion, getAllUsersByRoleId, getEntities } =
    actionDispatch(useDispatch());

  const { onboardingQuestion, superAdminUserManagement, createProgramTheme, onBoardedEntities } = useSelector((state: any) => state)
  const { listsOnboardingQuestion } = onboardingQuestion
  const { listProgramTheme } = createProgramTheme
  const superAdminuserManagementState = superAdminUserManagement

  const handleClickInviteSuperAdmin = () => {
    navigate(`${RouteConfigurations.adminUserManagement}`, { state: { isOpenDialog: true } });
  };

  const setThemeReduxEmpty = () => {
    setReduxProgramTheme({ key: "reduxProgramTheme", value: null })
    setProgramThemeId({ key: "programThemeId", value: "" })
    setReduxProgramTheme({ key: "guideLineData", value: null })
    setThemeQuestion({ key: "listsProgramThemeQuestion", value: null })
    setReduxProgramTheme({ key: "data", value: null })
  }

  const navigateToProgramThemes = () => {
    setThemeReduxEmpty()
    navigate(RouteConfigurations.createTheme);
  };

  const navigateToEntityManagement = () => {
    navigate(RouteConfigurations.createEntity);
  };

  const navigateToOnboardQuestionsCreation = () => {
    navigate(RouteConfigurations.onboardingQuestion);
  };
  const loggedInUser = getItemFromLocalStorage(Constants.loggedInUser);

  useEffect(() => {
    let params = `${loggedInUser?.entityId}?roleIds=${UsersRoleTypeEnum.SuperAdmin}`;
    getProgramThemes()
    getAllUsersByRoleId(params);
    getAllOnboardingQuestion()
    getEntities({
      size: 5,
      index: 1,
    })


  }, [])

  return (
    <div>
      <PageHeader pageHeading={"Dashboard"} />
      <Grid container gap={2} mt={2} >
        <Grid item xl={2.5} lg={2.5}>
          <StatsCard
            itemCount={superAdminuserManagementState?.allUsersData[0]?.mappedUsers?.length > 0 ? superAdminuserManagementState?.allUsersData[0]?.mappedUsers?.length : 0}
            buttonTitle={superAdmin?.buttonTitle}
            cardName={superAdmin?.useName}
            handleClick={handleClickInviteSuperAdmin}
          />
        </Grid>
        {/* <Grid item xl={2.7} lg={2.9}>
          <StatsCard
            itemCount={listsOnboardingQuestion?.length > 0 ? listsOnboardingQuestion?.length : 0}
            buttonTitle={onBoarding?.buttonTitle}
            cardName={onBoarding?.useName}
            handleClick={navigateToOnboardQuestionsCreation}
          />
        </Grid> */}
        <Grid item xl={2.5} lg={2.5}>
          <StatsCard
            itemCount={listProgramTheme?.length > 0 ? listProgramTheme?.length : 0}
            buttonTitle={createProgramThemes?.buttonTitle}
            cardName={createProgramThemes?.useName}
            handleClick={navigateToProgramThemes}
          />
        </Grid>
        <Grid item xl={2.5} lg={2.5}>
          <StatsCard
            itemCount={onBoardedEntities?.data?.totalCount}
            buttonTitle={entityOnboard?.buttonTitle}
            cardName={entityOnboard?.useName}
            handleClick={navigateToEntityManagement}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default SuperAdminDashBoard