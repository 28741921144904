import {
  Container,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ConfirmationDialog from "../../components/confirmation-dialog/ConfirmationDialog";
import MuiDialog from "../../components/mui-dialog/MuiDialog";
import PageHeader from "../../components/page-heading/PageHeading";
import { TableHeadWrapper } from "../../components/table-head/TableHead.style";
import {
  addInviteSuperAdminRequest,
  getAllUsersByRoleIdRequest,
  setSuperAdminUserManagement,
} from "../../redux/super-admin-user-management-redux/SuperAdminUserManagementRedux";
import { StringConstant } from "../../resources/strings";
import { columns } from "./SuperAdminInitialState";
import SuperAdminInviteDialog from "./SuperAdminInviteDialog";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Box } from "@mui/material";
import { Constants } from "../../constants/Constants";
import { getItemFromLocalStorage } from "../../utils/LocalStorageUtils";
import { UsersRoleTypeEnum } from "../../enumerations/UsersRoleTypeEnum";
import { TableBodyWrapper } from "./../../components/table-body/TableBodyWrapper.style";
import { useLocation } from "react-router-dom";
import styles from "../program-themes/Styles.module.css";
import { MuiTablePagination } from "../../components/mui-pagination/MuiTablePagination";
import MuiButton from "../../components/ui-elements/mui-button/MuiButton";

const SuperAdminUserManagement = () => {
  const actionDispatch = (dispatch: any) => ({
    getAllUsersByRoleId: () =>
      dispatch(getAllUsersByRoleIdRequest()),
    addInviteSuperAdmin: (data: any) =>
      dispatch(addInviteSuperAdminRequest(data)),
    setSuperAdminUserManagementState: (data: any) =>
      dispatch(setSuperAdminUserManagement(data)),
  });

  const {
    getAllUsersByRoleId,
    addInviteSuperAdmin,
    setSuperAdminUserManagementState,
  } = actionDispatch(useDispatch());

  const superAdminuserManagementState = useSelector(
    (state: any) => state.superAdminUserManagement
  );

  const [isCreateSuperAdmin, setIsCreateSuperAdmin] = useState<boolean>(false);
  const [superAdminList, setSuperAdminList] = useState([]);
  const [isInvitaionSuccess, setIsInvitaionSuccess] = useState(false);
  const [isInvitaionFailed, setIsInvitaionFailed] = useState(false);

  // Pagination
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const location = useLocation();

  const loggedInUser = getItemFromLocalStorage(Constants.loggedInUser);

  useEffect(() => {
    getAllUsersByRoleId();
    if (location?.state?.isOpenDialog) {
      setIsCreateSuperAdmin(true);
    }
  }, []);

  useEffect(() => {
    if (superAdminuserManagementState?.allUsersData?.length > 0) {
      let superAdminList = superAdminuserManagementState?.allUsersData?.find(
        (item: any) => item?.roleId == UsersRoleTypeEnum.SuperAdmin
      )?.mappedUsers;
      setSuperAdminList(superAdminList);
    }
  }, [superAdminuserManagementState?.allUsersData]);

  useEffect(() => {
    if (superAdminuserManagementState?.isSuccess) {
      setIsInvitaionSuccess(true);
      getAllUsersByRoleId();
      setSuperAdminUserManagementState({ key: "isSuccess", value: false });
    }

    if (superAdminuserManagementState?.isError) {
      setIsInvitaionFailed(true);
      getAllUsersByRoleId();
      setSuperAdminUserManagementState({ key: "isError", value: false });
    }
  }, [
    superAdminuserManagementState?.isSuccess,
    superAdminuserManagementState?.isError,
  ]);

  // const apiSucessToaster = useContext(ToasterContext)
  const handleClickNewAdmin = () => {
    setIsCreateSuperAdmin(true);
  };
  const handleClickInviteClose = () => {
    setIsCreateSuperAdmin(false);
  };

  const onCloseOfSuccessDialog = () => {
    setIsInvitaionSuccess(false);
    setIsInvitaionFailed(false);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <PageHeader
        pageHeading={"Super Admin Management"}
        isInviteButton={true}
        buttonText={"Invite Super Admin"}
        handleClick={handleClickNewAdmin}
        headingSuffix={`${superAdminList.length} Users`}
      />
      <TableContainer className="tab-innner-content-height">
        <Table stickyHeader aria-label="sticky table">
          <TableHeadWrapper>
            <TableRow>
              {columns?.map((column) => (
                <TableCell
                  key={`${column.id}_${column.label}`}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHeadWrapper>
          <TableBodyWrapper>
            {superAdminList
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((superAdmin: any, index: any) => {
                return (
                  <TableRow tabIndex={-1} key={`${superAdmin.id}_${index}`}>
                    <TableCell>{superAdmin?.firstName}</TableCell>
                    <TableCell>{superAdmin?.lastName}</TableCell>
                    <TableCell>{superAdmin?.email}</TableCell>
                    <TableCell>
                      {superAdmin?.countryCode} {superAdmin?.phoneNumber}
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                );
              })}
          </TableBodyWrapper>
        </Table>
      </TableContainer>
      <hr className="horizontal-border" />
      <MuiTablePagination
        rowsPerPageOptions={[5, 10, 25, 30, 35, 40, 45, 50]}
        component="div"
        count={superAdminList?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={"Per Page"}
        labelDisplayedRows={function defaultLabelDisplayedRows({ count }: any) {
          return (
            <div style={{ display: "flex", gap: "1rem" }}>
              <div className={styles["current-page"]}>{page + 1}</div>
              <div className={styles["total-page-count"]}>
                {" "}
                of {Math.ceil(count / rowsPerPage)}
              </div>
            </div>
          );
        }}
      />
      <MuiDialog
        isDialogOpen={isCreateSuperAdmin}
        handleClose={handleClickInviteClose}
        dialogTitle={StringConstant.inviteHeader}
      >
        <SuperAdminInviteDialog
          handleClickInviteClose={handleClickInviteClose}
          addInviteSuperAdmin={addInviteSuperAdmin}
          loggedInUser={loggedInUser}
        />
      </MuiDialog>
      <MuiDialog
        isDialogOpen={isInvitaionSuccess}
        handleClose={onCloseOfSuccessDialog}
        dialogTitle={<div> </div>}
      >
        <>
          <div
            style={{
              fontSize: "2rem",
              fontFamily: "Urbanist-Bold",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              color: "black",
              width: "500px",
              marginBottom: "40px",
            }}
          >
            {superAdminuserManagementState?.inviteAdminSuccessArray?.map(
              (element: any, elementIndex: any) => {
                return (
                  <div>
                    {element?.isSuccess ? (
                      <div
                        style={{
                          alignItems: "center",
                          flexDirection: "row",
                          display: "flex",
                        }}
                      >
                        <CheckCircleIcon
                          style={{ color: "#27bc48", marginRight: "20px" }}
                        />
                        <div> {element?.value?.message}</div>
                      </div>
                    ) : (
                      <div
                        style={{
                          alignItems: "center",
                          flexDirection: "row",
                          display: "flex",
                        }}
                      >
                        <ErrorOutlineIcon
                          style={{ color: "red", marginRight: "20px" }}
                        />
                        <div> {element?.value}</div>
                      </div>
                    )}
                  </div>
                );
              }
            )}
            {/* <span>{StringConstant.invitationSentSuccessfully}</span> */}
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <MuiButton variant="contained" onClick={onCloseOfSuccessDialog}>
              {StringConstant.ok}
            </MuiButton>
          </div>
        </>
      </MuiDialog>
      <MuiDialog
        isDialogOpen={isInvitaionFailed}
        handleClose={onCloseOfSuccessDialog}
        dialogTitle={<div> </div>}
      >
        <>
          <div
            style={{
              fontSize: "2rem",
              fontFamily: "Urbanist-Bold",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              color: "black",
              width: "500px",
              marginBottom: "40px",
            }}
          >
            {superAdminuserManagementState?.inviteAdminErrorArray?.map(
              (item: any) => {
                return (
                  <div
                    style={{
                      alignItems: "center",
                      flexDirection: "row",
                      display: "flex",
                    }}
                  >
                    {
                      <ErrorOutlineIcon
                        style={{ color: "red", marginRight: "10px" }}
                      />
                    }
                    <div> {item?.value}</div>
                  </div>
                );
              }
            )}
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <MuiButton variant="contained" onClick={onCloseOfSuccessDialog}>
              {StringConstant.ok}
            </MuiButton>
          </div>
        </>
      </MuiDialog>
    </>
  );
};

export default SuperAdminUserManagement;
