import Grid from "@mui/material/Grid";
import styles from "./Notifications.module.css";

const NotificationItem = () => {
  return (
    <div className={styles["notification-item"]}>
      <Grid container spacing={1}>
        <Grid item md={3} lg={3} xl={3} className={styles["center-align"]}>
          <img
            src="https://media.istockphoto.com/id/1382384282/photo/bangalore-or-bengaluru.jpg?s=612x612&w=0&k=20&c=6pxwL3JxNV2B_NZSLMZLhrSLqAbyCPlGuSZYKImpjKQ="
            alt=""
            className={styles["notification-image"]}
          />
        </Grid>
        <Grid item md={6} lg={6} xl={6}>
          <span className={styles["notification-content"]}>
            Arun has accepted your invitation as new Program Admin
          </span>
        </Grid>
        <Grid item md={3} lg={3} xl={3} className={styles["center-align"]}>
          <span className={styles["notification-date"]}>13/12/2022</span>
        </Grid>
      </Grid>
    </div>
  );
};

export default NotificationItem;
