import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React from "react";
import { StringConstant } from "../../resources/strings";
import MuiButton from "../ui-elements/mui-button/MuiButton";
import { ConfirmationDialogWrapper } from "./ConfirmationDialog.style";

interface ConfirmationProps {
  isConfirmModelOpen?: boolean;
  handleClose?: () => void;
  handleConfirm?: () => void;
  title?: string;
  contentText?: string;
  leftButtonText?: string;
  rightButtonText?: string;
}

const ConfirmationDialog = (props: ConfirmationProps) => {
  const {
    isConfirmModelOpen,
    handleClose,
    handleConfirm,
    title,
    contentText,
    leftButtonText,
    rightButtonText,
  } = props;

  return (
    <ConfirmationDialogWrapper
      open={isConfirmModelOpen}
      onClose={handleClose}
      maxWidth="lg"
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{contentText}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <MuiButton className={"white-bg-button"} onClick={handleClose}>{leftButtonText}</MuiButton>
        <MuiButton variant="contained" onClick={handleConfirm}>
          {rightButtonText}
        </MuiButton>
      </DialogActions>
    </ConfirmationDialogWrapper>
  );
};

export default ConfirmationDialog;
