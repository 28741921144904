import { Grid, useTheme } from "@mui/material";
import React from "react";
import styles from "./SecondaryFormField.module.css"

interface ReactNodeProps {
  children: React.ReactNode;
}

const SecondaryFormFieldContainer = (props: ReactNodeProps) => {
  const theme = useTheme();
  return (
    <Grid
      container
       className={styles["secondary-form-container"]}
      {...props}
    >
      {props.children}
    </Grid>
  );
};

export default SecondaryFormFieldContainer;
