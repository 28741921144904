import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { StringConstant } from "../../resources/strings";
import * as Yup from "yup";
import {
  handleNumberFieldChange,
  handlePhoneNumberFieldChange,
  isEmailValid,
} from "../../utils/ValidationUtils";
import { Box, InputAdornment, Popper, Select, Stack } from "@mui/material";
import { Form, Formik, getIn } from "formik";
import {
  getItemFromLocalStorage,
  setItemToLocalStorage,
} from "../../utils/LocalStorageUtils";
import { Constants } from "../../constants/Constants";
import MuiButton from "../../components/ui-elements/mui-button/MuiButton";
import MuiLoadingButton from "../../components/ui-elements/mui-loading-button/MuiLoadingButton";
import { useNavigate } from "react-router-dom";
import { RouteConfigurations } from "../../routes/RouteConfigurations";
import {
  getProfileInfoRequest,
  getUserDetailsByEmailRequest,
  setLoginState,
  updateMyProfileRequest,
} from "../../redux/login/LoginRedux";
import { useDispatch, useSelector } from "react-redux";
import MuiDialog from "../../components/mui-dialog/MuiDialog";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import MuiTextField from "../../components/ui-elements/mui-text-field/MuiTextField";
import PageHeading from "../../components/page-heading/PageHeading";
import styles from "./MyProfile.module.css";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  deleteAttachmentByIdRequest,
  fileUploadS3Request,
  profileFileUpdateS3Request,
  setFileUploadS3Update,
} from "../../redux/s3-upload/FileUploadS3Redux";
import MediaAssets from "../../assets";
import { countryCodeJson } from "../../utils/json/countryCode";
import { FileUploadMIMETypeEnum } from "../../enumerations/FileUploadMIMETypeEnum";
import { checkPhoneNumberWithCountrycode } from "../../utils/ValidationUtils";

const MyProfile = () => {
  const loggedInUser = getItemFromLocalStorage(Constants.loggedInUser);
  interface IMyProfileInitialState {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string | number;
    countryCode: string | number;
    profilePic: string;
    fileName: any;
  }

  const myProfileSchema = Yup.object().shape({
    firstName: Yup.string().required(StringConstant.pleaseEnterFirstName),
    lastName: Yup.string().required(StringConstant.pleaseEnterLastName),
    email: Yup.string()
      .email()
      .required(StringConstant.pleaseEnterEmail)
      .matches(isEmailValid(), StringConstant.pleaseEnterFrientorEmail),
    countryCode: Yup.string().required(),
    phoneNumber: Yup.string()
      .required(StringConstant.pleaseEnterPhoneNumber)
      .test({
        name: "check country code",
        exclusive: false,
        params: {},
        message: StringConstant.invalidPhoneNumerforCountryCode,
        test: function (value) {
          let length = value ? value.length : 0;
          return checkPhoneNumberWithCountrycode(
            this.parent.countryCode,
            length
          );
        },
      }),
  });

  const actionDispatch = (dispatch: any) => ({
    updateMyProfile: (reqBody: any) =>
      dispatch(updateMyProfileRequest(reqBody)),
    loginStateSet: (data: any) => dispatch(setLoginState(data)),
    uploadfileS3: (data: any) => dispatch(fileUploadS3Request(data)),
    getUserDetails: (email: any) =>
      dispatch(getUserDetailsByEmailRequest(email)),
    getProfileInfo: () => dispatch(getProfileInfoRequest()),
    setFileUpload: (data: any) => dispatch(setFileUploadS3Update(data)),
    deleteAttachmentById: (data: any) =>
      dispatch(deleteAttachmentByIdRequest(data)),
    profileFileUpdateS3: (data: any) =>
      dispatch(profileFileUpdateS3Request(data)),
  });

  const {
    updateMyProfile,
    loginStateSet,
    uploadfileS3,
    getUserDetails,
    getProfileInfo,
    setFileUpload,
    deleteAttachmentById,
    profileFileUpdateS3,
  } = actionDispatch(useDispatch());

  const { login, fileUploader } = useSelector((state: any) => state);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [hasProfileUpdated, setHasProfileUpdated] = useState(false);
  const [profilePic, setProfilePic] = useState<any>(null);
  const [profilePicName, setProfilePicName] = useState("");
  const [profileReqBody, setProfileReqBody] = useState({});
  const [removedProfilePic, setRemovedProfilePic] = useState(false);

  const open = Boolean(anchorEl);

  const myProfileInitialState: IMyProfileInitialState = {
    firstName: login?.profileDetails?.firstName
      ? login?.profileDetails?.firstName
      : loggedInUser?.firstName,
    lastName: login?.profileDetails?.lastName
      ? login?.profileDetails?.lastName
      : loggedInUser?.lastName,
    email: login?.profileDetails?.email
      ? login?.profileDetails?.email
      : loggedInUser?.email,
    phoneNumber: login?.profileDetails?.phoneNumber
      ? login?.profileDetails?.phoneNumber
      : loggedInUser?.phoneNumber,
    countryCode: login?.profileDetails?.countryCode
      ? login?.profileDetails?.countryCode
      : loggedInUser?.countryCode,
    profilePic:
      login?.profileDetails?.userProfilePic?.length > 0
        ? login?.profileDetails?.userProfilePic[0]?.filePath
        : MediaAssets.ic_blank_profile_picture,
    fileName:
      login?.profileDetails?.userProfilePic?.length > 0
        ? login?.profileDetails?.userProfilePic[0]?.fileName
        : "",
  };
  useEffect(() => {
    getProfileInfo();
  }, []);

  useEffect(() => {
    getProfileInfo();
  }, [hasProfileUpdated]);

  useEffect(() => {
    if (fileUploader?.singleImageUpload) {
      setProfilePic(fileUploader?.singleImageUpload);
      updateMyProfile({
        ...profileReqBody,
        profilePicUrl: fileUploader?.singleImageUpload,
      });
      setFileUpload({ key: "singleImageUpload", value: "" });
    }
  }, [fileUploader?.singleImageUpload]);

  useEffect(() => {
    if (fileUploader?.updatedProfileUrl) {
      setProfilePic(fileUploader?.updatedProfileUrl);
      updateMyProfile({
        ...profileReqBody,
        profilePicUrl: fileUploader?.updatedProfileUrl,
      });
      setFileUpload({ key: "updatedProfileUrl", value: "" });
    }
  }, [fileUploader?.updatedProfileUrl]);

  useEffect(() => {
    if (fileUploader?.isDeleted) {
      updateMyProfile(profileReqBody);
      setFileUpload({ key: "isDeleted", value: false });
    }
  }, [fileUploader?.isDeleted]);

  useEffect(() => {
    if (login?.isSuccess) {
      loginStateSet({ key: "isSuccess", value: false });
      setHasProfileUpdated(true);
    }
  }, [login?.isSuccess]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleReplaceImage = (e: any, setFieldValue: any) => {
    let uploadedImage = e.target.files[0];

    setProfilePic(uploadedImage);
    setFieldValue("profilePic", URL.createObjectURL(uploadedImage));
    setProfilePicName(e.target.files[0].name + Date.now());

    setRemovedProfilePic(false);
  };

  const handleRemoveImage = (setFieldValue: any) => {
    setFieldValue("profilePic", MediaAssets.ic_blank_profile_picture);
    setProfilePic("");
    setRemovedProfilePic(true);
    setProfilePicName("");
  };

  const navigate = useNavigate();

  const onCancelInMyProfile = () => {
    navigate(RouteConfigurations.dashboard);
  };

  const onCloseOfSuccessDialog = () => {
    setHasProfileUpdated(false);
  };

  const handleSubmit = (values: any) => {
    //1.  Update values other than image
    if (login?.profileDetails?.userProfilePic?.length == 0 && !profilePicName) {
      let reqBody = {
        id: login?.profileDetails?.id,
        firstName: values?.firstName,
        lastName: values?.lastName,
        email: values?.email,
        countryCode: values?.countryCode,
        phoneNumber: values?.phoneNumber?.toString(),
        profilePicUrl: "",
        contentType: "",
        fileName: "",
      };

      updateMyProfile(reqBody);
    }

    //2. First time image upload
    else if (
      profilePicName &&
      login?.profileDetails?.userProfilePic?.length == 0
    ) {
      let reqBody = {
        id: login?.profileDetails?.id,
        firstName: values?.firstName,
        lastName: values?.lastName,
        email: values?.email,
        countryCode: values?.countryCode,
        phoneNumber: values?.phoneNumber?.toString(),
        profilePicUrl: profilePic ? profilePic : "",
        contentType:
          profilePicName?.split(".")[1]?.toUpperCase() ===
          FileUploadMIMETypeEnum?.PNG
            ? FileUploadMIMETypeEnum?.PNG
            : FileUploadMIMETypeEnum?.JPEG,
        fileName: profilePicName,
      };

      setProfileReqBody(reqBody);

      const data: any = {
        name: profilePicName,
        urlType: "upload",
        binary: "",
        type: "image/png",
      };

      const reader = new FileReader();
      reader.onload = async (e: any) => {
        data.binary = e.target.result;
      };
      reader?.readAsArrayBuffer(profilePic);
      uploadfileS3(data);
    }
    //3. URL already exist and comes to UPDATE
    else if (
      login?.profileDetails?.userProfilePic?.length > 0 &&
      profilePicName?.length > 0
    ) {
      
      let reqBody = {
        id: login?.profileDetails?.id,
        firstName: values?.firstName,
        lastName: values?.lastName,
        email: values?.email,
        countryCode: values?.countryCode,
        phoneNumber: values?.phoneNumber?.toString(),
        profilePicUrl: profilePic ? profilePic : "",
        contentType:
          profilePicName?.split(".")[1]?.toUpperCase() ===
          FileUploadMIMETypeEnum?.PNG
            ? FileUploadMIMETypeEnum?.PNG
            : FileUploadMIMETypeEnum?.JPEG,
        fileName: profilePicName,
      };

     
      const data: any = {
        name: profilePicName,
        urlType: "upload",
        binary: "",
        type: "image/png",
        oldProfilePicName:
          login?.profileDetails?.userProfilePic?.length > 0
            ? login?.profileDetails?.userProfilePic[0]?.fileName
            : "",
      };

      if (profilePicName !== login?.profileDetails?.userProfilePic[0]?.fileName){
        const reader = new FileReader();
        reader.onload = async (e: any) => {
          data.binary = e.target.result;
        };
        reader?.readAsArrayBuffer(profilePic);
        profileFileUpdateS3(data);
        setProfileReqBody(reqBody);
      }else{
        updateMyProfile(reqBody)
      }

    }
    //4. Removes profile Pic URL and saves i.e. DELETE ProfilePic
    else if (removedProfilePic && !profilePicName) {
      let idTobeDeleted: any = [login?.profileDetails?.userProfilePic[0]?.id];
      deleteAttachmentById(idTobeDeleted);

      let reqBody = {
        id: login?.profileDetails?.id,
        firstName: values?.firstName,
        lastName: values?.lastName,
        email: values?.email,
        countryCode: values?.countryCode,
        phoneNumber: values?.phoneNumber?.toString(),
        profilePicUrl: "",
        contentType: "",
        fileName: "",
      };

      setProfileReqBody(reqBody);
    } else {
      let reqBody = {
        id: login?.profileDetails?.id,
        firstName: values?.firstName,
        lastName: values?.lastName,
        email: values?.email,
        countryCode: values?.countryCode,
        phoneNumber: values?.phoneNumber?.toString(),
        profilePicUrl: profilePic,
        contentType:
          profilePicName?.split(".")[1]?.toUpperCase() ===
          FileUploadMIMETypeEnum?.PNG
            ? FileUploadMIMETypeEnum?.PNG
            : FileUploadMIMETypeEnum?.JPEG,
        fileName: profilePicName,
      };
      updateMyProfile({
        ...reqBody,
        profilePicUrl: fileUploader?.updatedProfileUrl,
      });
    }
  };

  return (
    <div>
      <PageHeading pageHeading="My Profile" />

      <Formik
        initialValues={myProfileInitialState}
        validationSchema={myProfileSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({
          values,
          setFieldValue,
          handleChange,
          handleBlur,
          errors,
          touched,
          isValid,
          dirty,
        }) => (
          <Form>
            <Grid
              container
              direction="row"
              // justifyContent="center"
              // alignItems="center"
              spacing={2}
              sx={{ marginTop: "30px" }}
            >
              <Grid
                item
                md={2.4}
                lg={2.4}
                xl={2.4}
                className={styles["center-align"]}
              >
                <div className={styles["profile-pic-holder"]}>
                  <img
                    src={values?.profilePic}
                    alt="Profile Pic"
                    className={styles["profile-pic"]}
                  />

                  <div
                    className={styles["profile-pic-actions"]}
                    onClick={handleClick}
                  >
                    <MoreHorizIcon htmlColor="#ffffff" />
                  </div>
                  {/* <div style={{ borderRadius: "20px" }}> */}
                  <Menu
                    id="long-menu"
                    sx={{
                      " && .MuiPaper-root": {
                        borderRadius: "12px",
                      },
                    }}
                    MenuListProps={{
                      "aria-labelledby": "long-button",
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    <MenuItem>
                      <div>
                        <label htmlFor="replace">
                          <input
                            id="replace"
                            accept={".jpg,.png,.jpeg,"}
                            type="file"
                            name="profilePic"
                            onChange={(e: any) =>
                              handleReplaceImage(e, setFieldValue)
                            }
                            style={{ display: "none" }}
                          />
                          Replace
                        </label>
                      </div>
                    </MenuItem>
                    <MenuItem onClick={() => handleRemoveImage(setFieldValue)}>
                      Remove
                    </MenuItem>
                  </Menu>
                  {/* </div> */}
                </div>
              </Grid>
              <Grid item md={2.4} lg={2.4} xl={2.4} mt={"30px"}>
                <MuiTextField
                  textfieldlabel={"First Name*"}
                  fullWidth
                  inputProps={{ maxLength: 100 }}
                  name="firstName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.firstName}
                  helperText={
                    getIn(touched, `firstName`)
                      ? getIn(errors, `firstName`)
                      : ""
                  }
                  error={
                    getIn(touched, `firstName`) && getIn(errors, `firstName`)
                  }
                />
              </Grid>

              <Grid item md={2.4} lg={2.4} xl={2.4} mt={"30px"}>
                <MuiTextField
                  textfieldlabel={"Last Name*"}
                  fullWidth
                  inputProps={{ maxLength: 100 }}
                  name="lastName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.lastName}
                  helperText={
                    getIn(touched, `lastName`) ? getIn(errors, `lastName`) : ""
                  }
                  error={
                    getIn(touched, `lastName`) && getIn(errors, `lastName`)
                  }
                />
              </Grid>

              <Grid item md={2.4} lg={2.4} xl={2.4} mt={"30px"}>
                <MuiTextField
                  textfieldlabel={
                    <div style={{ color: "#e3e3e3" }}>{"Email ID*"}</div>
                  }
                  fullWidth
                  disabled
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.email}
                  helperText={
                    getIn(touched, `email`) ? getIn(errors, `email`) : ""
                  }
                  error={getIn(touched, `email`) && getIn(errors, `email`)}
                />
              </Grid>

              <Grid item md={2.4} lg={2.4} xl={2.4} mt={"30px"}>
                <MuiTextField
                  textfieldlabel="Phone Number*"
                  name="phoneNumber"
                  // onChange={handleNumberFieldChange(setFieldValue)}
                  onChange={handlePhoneNumberFieldChange(
                    setFieldValue,
                    values?.countryCode
                  )}
                  onBlur={handleBlur}
                  value={values?.phoneNumber}
                  helperText={
                    getIn(touched, `phoneNumber`)
                      ? getIn(errors, `phoneNumber`)
                      : ""
                  }
                  error={
                    getIn(touched, `phoneNumber`) &&
                    getIn(errors, `phoneNumber`)
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Select
                          name={`countryCode`}
                          variant="standard"
                          disableUnderline
                          defaultValue={"+91"}
                          value={values?.countryCode}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          {countryCodeJson?.map((element, index) => (
                            <MenuItem
                              value={element.dial_code}
                              key={element.dial_code + index}
                            >
                              {element.dial_code}
                            </MenuItem>
                          ))}
                        </Select>
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    "& input": {
                      borderLeft: `1px solid ${
                        Boolean(
                          getIn(touched, `phoneNumber`) &&
                            getIn(errors, `phoneNumber`)
                        )
                          ? "#f97973"
                          : "#c4c5ce"
                      }`,
                      paddingLeft: "10px",
                      borderRadius: "0px !important",
                    },

                    "&& .MuiSelect-select.MuiSelect-standard": {
                      paddingTop: "15%",
                    },
                  }}
                />
              </Grid>
            </Grid>
            <Stack
              direction="row"
              justifyContent={"flex-end"}
              spacing={2}
              mt={2}
            >
              <MuiButton
                className={"white-bg-button"}
                onClick={onCancelInMyProfile}
              >
                Cancel
              </MuiButton>
              <MuiLoadingButton
                // disabled={!isValid || !dirty}
                tab={true}
                type="submit"
                loading={login?.isLoading || fileUploader?.isLoading}
              >
                Save
              </MuiLoadingButton>
            </Stack>
          </Form>
        )}
      </Formik>
      <MuiDialog
        isDialogOpen={hasProfileUpdated}
        handleClose={onCloseOfSuccessDialog}
        dialogTitle={<div> </div>}
      >
        <div
          style={{
            fontSize: "2rem",
            fontFamily: "Urbanist-Bold",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            color: "black",
            width: "500px",
            marginBottom: "40px",
          }}
        >
          <CheckCircleIcon style={{ color: "#27bc48", marginRight: "20px" }} />
          <span>{StringConstant.text_profile_updated_successfully}</span>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <MuiButton variant="contained" onClick={onCloseOfSuccessDialog}>
            {StringConstant.ok}
          </MuiButton>
        </div>
      </MuiDialog>
    </div>
  );
};

export default MyProfile;
