export const RouteConfigurations = {
  login: "/login",
  loginSuccess: "/login/success/:email",
  dashboard: "/dashboard",
  adminUserManagement: "/admin-user-management",
  programThemes: "/program-themes",
  entityManagement: "/entity-management",
  onboardingQuestion: "/onboarding-question",
  onBoardingQuestionList: "/onboarding-question-list",
  myProfile: "/my-profile",
  createEntity: "/entity-management/create",
  createTheme: "/program-themes/create",
  themeQuestionsList: "theme-questions-list",
  themeQuestionCreation: "create-theme-questions",
};
