import { Alert } from "@mui/material";

interface Props {
  setOpenAlert: any;
  alertText: any;
}
const AlertCard = ({ setOpenAlert, alertText }: Props) => {
  return (
    <Alert
      variant="outlined"
      severity="error"
      icon={false}
      sx={{
        position: "absolute",
        top: "1%",
        left: "40%",
        fontFamily: "Urbanist-Bold",
        backgroundColor: "var(--light-red-color)",
        borderRadius: "13px",
        border: "none",
        width: "30%",
        color: "var(--teritary-red-color)",
        "& .MuiAlert-message": {
          fontSize: "var(--font-200-rem)",
        },
      }}
      onClose={() => setOpenAlert(false)}
    >
      {alertText}
    </Alert>
  );
};

export default AlertCard;
