import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setLoginState } from "../../redux/login/LoginRedux";
import { Constants } from "../../constants/Constants";
import {
  setItemToLocalStorage,
} from "../../utils/LocalStorageUtils";
import { useNavigate, useParams } from "react-router-dom";
import { RouteConfigurations } from "../../routes/RouteConfigurations";
import PageLoader from "../../components/page-loader/PageLoader";
import styles from './Login.module.css'

const actionDispatch = (dispatch: any) => {
  return {
    setLoginState: (data: any) => dispatch(setLoginState(data)),
  };
};

const LoginSuccess = () => {
  const { setLoginState } = actionDispatch(useDispatch());
  const navigate = useNavigate();

  const [isSuccess, setIsSuccess] = useState(false)

  const param = useParams();
  useEffect(() => {
    if (param) {
      fetch(
        `${process.env.REACT_APP_USER_MGNT_URL}user-management/api/user?email=${param.email}`
      )
        .then((response) => response.json())
        .then((data) => {
          setItemToLocalStorage(Constants.loggedInUser, data[1][0]);
          setItemToLocalStorage(Constants.authorizationToken, data[1][0]?.loginToken);
          navigate(RouteConfigurations.dashboard);
          setLoginState({ key: "isLoggedIn", value: true });
        });
      setIsSuccess(true)
    }
  }, []);

  // useEffect(() => {
  //   if (isSuccess) {
  //     console.log("ddddddddd")
  //     navigate(RouteConfigurations.dashboard);
  //     setLoginState({ key: "isLoggedIn", value: true });
  //     setIsSuccess(false)
  //   }
  // }, [getItemFromLocalStorage(Constants.loggedInUser)]);

  return <div className={styles["login-success-container"]}><PageLoader /></div>;
};

export default LoginSuccess;
