import { SagaIterator } from "@redux-saga/types";
import { call, put } from "redux-saga/effects";
import { HttpResponseTypeEnum } from "../../enumerations/HttpResponseTypeEnum";
import { basicInfoRedux } from "../../redux/entity-management/BasicInfoRedux";

export function* basicInfoSaga(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.createEntity, action.payload);

    if (response.data.code === HttpResponseTypeEnum.Success) {
      yield put(basicInfoRedux.actions.addBasicDetailsSuccess(response?.data));
    } else {
      yield put(basicInfoRedux.actions.addBasicDetailsFailure(response.data));
    }
  } catch (error) {
    yield put(basicInfoRedux.actions.addBasicDetailsFailure(error));
  }
}
