import React, { Fragment, useState } from "react";
import PageHeader from "../../../components/page-heading/PageHeading";
import { Grid } from "@mui/material";
import { StringConstant } from "../../../resources/strings";
import { Constants } from "../../../resources/constants";
import SideBar from "../../../components/secondary-sidemenu/SecondarySidemenu";
import BasicInfoForm from "../create-entity-forms/BasicInfoForm";
import ContactDetailsForm from "../create-entity-forms/ContactDetailsForm";

import AddProgramAdminForm from "../create-entity-forms/AddProgramAdminForm";
import ManageEntittyThemes from "../create-entity-forms/ManageEntityThemes";
import CreateContainerWrapper from "../../../components/secondary-form-field-container/SecondaryFormFieldContainer";
import FormFieldWrapper from "../../../components/secondary-form-field-inner-container/SecondaryFormFieldInnerContainer";

const sideBarArray = [
  { id: 1, name: "Basic info" },
  { id: 2, name: "Contact Details" },
  { id: 3, name: "Add Program Admins" },
  { id: 4, name: "Selected Program Themes" },
];

const CreateEntity = () => {
  const [tab, setTab] = useState<number>(
    Constants.number_min_create_entity_tab
  );

  const handleTabSideBar = (event: React.MouseEvent<HTMLElement>) => {
    let tabValue = parseInt(
      (event.currentTarget as HTMLElement).dataset?.id || "1"
    );

    setTab(tabValue);
  };

  const handleTabPrev = (value: number) => {
    value > Constants.number_min_create_entity_tab &&
      setTab((value) => value - 1);
  };

  const handleTabNext = (value: number) => {
    value < Constants.number_max_create_entity_tab &&
      setTab((value) => value + 1);
  };

  const props = {
    tab,
    handleTabNext,
    handleTabPrev,
  };

  return (
    <Fragment>
      <div>
        <PageHeader
          pageHeading={StringConstant.onBoardEntityCreate}
          pageHeaderSubtitle={StringConstant.letsGetStarted}
        />
      </div>
      <CreateContainerWrapper>
        <Grid item lg={2} xl={2.3}>
          <SideBar
            tab={tab}
            handleTab={handleTabSideBar}
            sidebarData={sideBarArray}
          />
        </Grid>
        <FormFieldWrapper>
          {tab === 1 && <BasicInfoForm {...props} />}
          {tab === 2 && <ContactDetailsForm {...props} />}
          {tab === 3 && <AddProgramAdminForm {...props} />}
          {tab === 4 && <ManageEntittyThemes {...props} />}
        </FormFieldWrapper>
      </CreateContainerWrapper>
    </Fragment>
  );
};

export default CreateEntity;
