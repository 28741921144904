import { SagaIterator } from "redux-saga";
import { call, put } from "redux-saga/effects";
import { HttpResponseTypeEnum } from "../../../enumerations/HttpResponseTypeEnum";
import { SubmitProgramThemeRedux } from "../../../redux/program-theme/submit-program-theme/SubmitProgramThemeRedux";

export function* submitProgramThemeSaga(api: any, action: any): SagaIterator {
    try {
        const response = yield call(api.submitProgramTheme, action.payload);

        if (response.data.code === HttpResponseTypeEnum.Success) {
            yield put(
                SubmitProgramThemeRedux.actions.submitProgramThemeSuccess(
                    response.data
                )
            );
        } else {
            yield put(
                SubmitProgramThemeRedux.actions.submitProgramThemeFailure(
                    response.data
                )
            );
        }
    } catch (error: any) {
        yield put(
            SubmitProgramThemeRedux.actions.submitProgramThemeFailure(error)
        );
    }
}