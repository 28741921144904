import { configureStore } from "@reduxjs/toolkit";
import { combineReducers, applyMiddleware } from "redux";
import login from "./login/LoginRedux";
import onboardingQuestion from "./onboarding-question/OnboardingQuestionRedux";
import superAdminUserManagement from "./super-admin-user-management-redux/SuperAdminUserManagementRedux";
import createProgramTheme from "./program-theme/create-program-theme/CreateProgramThemeRedux"
import programThemeQuestion from "./program-theme/programtheme-question/ProgramThemQuestionRedux"
// import programThemeGuideline from "./program-theme/program-theme-guidelines/ProgramThemeGuidelinesRedux"

import topicCategroy from "./program-theme/topic-category/TopicCategoryRedux"
import fileUploader from "./s3-upload/FileUploadS3Redux"
import submitProgramTheme from "./program-theme/submit-program-theme/SubmitProgramThemeRedux"
import attachment from "./attachment/AttachmentRedux"
import createSagaMiddleware from "redux-saga";
import toaster from "./toaster/ToasterRedux";
import rootSaga from "../sagas";
import basicInfo from "./entity-management/BasicInfoRedux";
import entityTypes from "./entity-management/EntityTypesRedux";
import programAdmin from "./entity-management/ProgramAdminRedux";
import contactDetails from "./entity-management/ContactDetailsRedux";
import { composeWithDevTools } from "@redux-devtools/extension";
import onBoardedEntities from "./entity-management/OnBoardedEntitiesRedux";
import programThemes from "./program-themes/ProgramThemesRedux";
import associatedThemeEntities from "./entity-management/AssociatedThemeEntitiesRedux";

export default () => {
  const middlewares: any = [];
  const enhancers: any = [];

  const middleware: any = createSagaMiddleware();
  middlewares.push(middleware);
  enhancers.push(composeWithDevTools(applyMiddleware(...middlewares)));

  const sagaMiddleware = () =>
    // getDefaultMiddleware({ thunk: false }),
    middlewares;

  const reducer = combineReducers({
    login,
    superAdminUserManagement,
    onboardingQuestion,
    toaster,
    basicInfo,
    entityTypes,
    programAdmin,
    contactDetails,
    onBoardedEntities,
    associatedThemeEntities,
    programThemes,
    fileUploader,
    createProgramTheme,
    programThemeQuestion,
    attachment,
    topicCategroy,
    submitProgramTheme,
    
  });

  const store = configureStore({
    reducer,
    middleware: sagaMiddleware,
  });
  middleware.run(rootSaga);

  return {
    store,
    reducer,
  };
};
