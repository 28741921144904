import { SagaIterator } from "@redux-saga/types";
import { call, put } from "redux-saga/effects";
import { HttpResponseTypeEnum } from "../../enumerations/HttpResponseTypeEnum";
import { LoginRedux } from "../../redux/login/LoginRedux";

export function* LoginSaga(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.login, action.data);
    if (response.data.code === HttpResponseTypeEnum.Success) {
      yield put(LoginRedux.actions.loginSuccess(response.data));
    } else {
      yield put(LoginRedux.actions.loginFailure(response.data));
    }
  } catch (error: any) {
    yield put(LoginRedux.actions.loginFailure(error));
  }
}

export function* updateMyProfile(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.updateMyProfile, action.payload);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(LoginRedux.actions.updateMyProfileSuccess(response.data));
    } else {
      yield put(LoginRedux.actions.updateMyProfileFailure(response.data));
    }
  } catch (error: any) {
    yield put(LoginRedux.actions.updateMyProfileFailure(error));
  }
}

export function* getUserDetails(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.getUserDetails, action.payload);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(LoginRedux.actions.getUserDetailsByEmailSuccess(response.data));
    } else {
      yield put(LoginRedux.actions.getUserDetailsByEmailFailure(response.data));
    }
  } catch (error: any) {
    yield put(LoginRedux.actions.getUserDetailsByEmailFailure(error));
  }
}

export function* getProfileInfo(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.getProfileInfo, action.payload);
    if (response.status === HttpResponseTypeEnum.Success) {
      yield put(LoginRedux.actions.getProfileInfoSuccess(response.data));
    } else {
      yield put(LoginRedux.actions.getProfileInfoFailure(response.data));
    }
  } catch (error: any) {
    yield put(LoginRedux.actions.getProfileInfoFailure(error));
  }
}
