
// for text or image type
export enum ResponseTypeEnum {
  Text = "1001",
  Image = "1002",
}

export class ActionTypeEnumResponseType {
  public static getResponseType(type: string):any{
    switch(type){
      case ResponseTypeEnum.Text:
        return "Text"
      case  ResponseTypeEnum.Image:
        return "Image"
    }
  }
}

/// for text response
export const enum ActionTypeEnumTextResponse {
  SingleChoice = "1001",
  MultipleChoice = "1002",
  TextInputWithLimit = "1003",
  TextInputWithoutLimit = "1004",
  TopicQuestion = "1005",
}

export class ActionTypeEnumUtilsTextResponse {
  public static getEnumText(type: string): any {
    switch (type) {
      case ActionTypeEnumTextResponse.SingleChoice:
        return "Single choice (radio button)";
      case ActionTypeEnumTextResponse.MultipleChoice:
        return "Multiple choice (checkbox)";
      case ActionTypeEnumTextResponse.TextInputWithLimit:
        return "Text Input (With Character limit)";
      case ActionTypeEnumTextResponse.TextInputWithoutLimit:
        return "Text Input (Without Character limit)";
      case ActionTypeEnumTextResponse.TopicQuestion:
        return "Topic Question";
    }
  }

  public static getActionTypeEnumsTextResponse(): {
    label: string;
    value: ActionTypeEnumTextResponse;
  }[] {
    const options: { label: string; value: ActionTypeEnumTextResponse }[] = [
      {
        label: this.getEnumText(ActionTypeEnumTextResponse.SingleChoice),
        value: ActionTypeEnumTextResponse.SingleChoice,
      },
      {
        label: this.getEnumText(ActionTypeEnumTextResponse.MultipleChoice),
        value: ActionTypeEnumTextResponse.MultipleChoice,
      },
      {
        label: this.getEnumText(ActionTypeEnumTextResponse.TextInputWithLimit),
        value: ActionTypeEnumTextResponse.TextInputWithLimit,
      },
      {
        label: this.getEnumText(
          ActionTypeEnumTextResponse.TextInputWithoutLimit
        ),
        value: ActionTypeEnumTextResponse.TextInputWithoutLimit,
      },
      {
        label: this.getEnumText(ActionTypeEnumTextResponse.TopicQuestion),
        value: ActionTypeEnumTextResponse.TopicQuestion,
      },
    ];
    return options;
  }
}

/////image response

export const enum ActionTypeEnumImageResponse {
  SingleChoice = "1001",
  MultipleChoice = "1002",
}

export class ActionTypeEnumUtilsImageResponse {
  public static getEnumImage(type: string): any {
    switch (type) {
      case ActionTypeEnumImageResponse.SingleChoice:
        return "Single choice (radio button)";
      case ActionTypeEnumImageResponse.MultipleChoice:
        return "Multiple choice (checkbox)";
    }
  }

  public static getActionTypeEnumsImageResponse(): {
    label: string;
    value: ActionTypeEnumImageResponse;
  }[] {
    const options: { label: string; value: ActionTypeEnumImageResponse }[] = [
      {
        label: this.getEnumImage(ActionTypeEnumImageResponse.SingleChoice),
        value: ActionTypeEnumImageResponse.SingleChoice,
      },
      {
        label: this.getEnumImage(ActionTypeEnumImageResponse.MultipleChoice),
        value: ActionTypeEnumImageResponse.MultipleChoice,
      },
    ];
    return options;
  }
}
