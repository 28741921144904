import { Box, BoxProps, styled } from "@mui/material";

export const StatsCardStyleWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  width: "100%",
  padding: "20px 0px  15px 15px",
  backgroundColor: `${theme.palette.secondary.light}`,
  borderRadius: "0px 50px 20px 20px",

  ".stat-card-title": {
    fontSize: "16px",
    fontWeight: "bold",
    textTransform: "capitalize",
    color: `${theme.palette.secondary.main}`
  },
  ".stat-card-count": {
    fontSize: "50px",
    fontWeight: "bold",
    textTransform: "capitalize",
    color: `${theme.palette.secondary.main}`
  },
  "&& .stat-button-font": {
    fontSize: "1.6rem",
    height: "45px"
  },


  [theme.breakpoints.down("xl")]: {
    width: "100%",
    padding: "20px 0px  15px 15px",
    "&& .stat-card-title": {
      fontSize: "12px",
    },
    "&& .stat-card-count": {
      fontSize: "30px",
    },
    "&&&& .stat-button-font": {
      fontSize: "1.2rem",
      height: "35px"
    }
  },



}));
