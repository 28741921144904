import { Grid, useTheme } from "@mui/material";
import React from "react";
import styles from './SecondaryFormFieldInnerContainer.module.css'

interface ReactNodeProps {
  children: React.ReactNode;
}

const SecondaryFormFieldInnerContainer = (props: ReactNodeProps) => {
  const theme = useTheme();
  return (
    <Grid
      item
      lg={10}
      xl={9.7}
      sx={{
        display: "flex",
        flexDirection: "column",
        // justifyContent: "space-between",
        padding: "0px 0px",
        height:'calc(100% - 85px)',
      }}
    >
      {props.children}
    </Grid>
  );
};

export default SecondaryFormFieldInnerContainer;
