import { createSlice } from "@reduxjs/toolkit";

export interface IOnboardingQuestion {
  isLoading?: boolean;
  error?: string;
  success?: string;
  data?: any;
  toasterDetails?: any;
  listsOnboardingQuestion?: any;
  isOnboradingQuestionDeleted?: boolean;
  eachOnboardingQuestion?: any;
}

const initialState: IOnboardingQuestion = {
  isLoading: false,
  error: "",
  success: "",
  data: null,
  toasterDetails: null,
  listsOnboardingQuestion: null,
  isOnboradingQuestionDeleted: false,
  eachOnboardingQuestion: null

};

export const OnboardingQuestionRedux = createSlice({
  name: "onboardingQuestionRedux",
  initialState,
  reducers: {

    // add new onboarding question
    addOnboardingQuestionRequest: (state: any, action: any) => {
      const newState = {
        ...state,
        isLoading: true,
      };
      // dispatchEvent({type:"",payload:{}})
      return newState;
    },
    addOnboardingQuestionSuccess: (state: any, action: any) => {
      const newState = {
        ...state,
        isLoading: false,
        data: action.payload.data,
        toasterDetails: action?.payload,
      };
      return newState;
    },
    addOnboardingQuestionFailure: (state: any, action: any) => {
      const newState = {
        ...state,
        isLoading: false,
        toasterDetails: action?.payload,
      };
      return newState;
    },

    // onboarding get request
    getOnboardingQuestionRequest: (state: any) => {
      const newState = {
        ...state,
        isLoading: true,
      };
      return newState;
    },
    getOnboardingQuestionSuccess: (state: any, action: any) => {
      const newState = {
        ...state,
        isLoading: false,
        listsOnboardingQuestion: action?.payload?.response,
      };
      return newState;
    },
    getOnboardingQuestionFailure: (state: any, action: any) => {
      const newState = {
        ...state,
        isLoading: false,
        error: action?.payload,
      };
      return newState;
    },


    //delete onBoarding question 
    deleteOnboardingQuestionRequest: (state: any) => {
      const newState = {
        ...state,
        isLoading: true,
      };
      return newState;
    },
    deleteOnboardingQuestionSuccess: (state: any, action: any) => {
      const newState = {
        ...state,
        isLoading: false,
        toasterDetails: action?.payload,
        isOnboradingQuestionDeleted: true,
      };
      return newState;
    },
    deleteOnboardingQuestionFailure: (state: any, action: any) => {
      const newState = {
        ...state,
        isLoading: false,
        error: action?.payload,
        toasterDetails: action?.payload,
      };
      return newState;
    },

    //updated the question 
    updateOnboardingQuestionRequest: (state: any) => {
      const newState = {
        ...state,
        isLoading: true,
      };
      return newState;
    },
    updateOnboardingQuestionSuccess: (state: any, action: any) => {
      const newState = {
        ...state,
        isLoading: false,
        toasterDetails: action?.payload,
      };
      return newState;
    },
    updateOnboardingQuestionFailure: (state: any, action: any) => {
      const newState = {
        ...state,
        isLoading: false,
        error: action?.payload,
        toasterDetails: action?.payload,
      };
      return newState;
    },

    //get Each onboarding question

    getEachOnboardingQuestionRequest: (state: any, action: any) => {
      console.log(action, "each onborading request")
      const newState = {
        ...state,
        isLoading: true,
      };
      return newState;
    },
    getEachOnboardingQuestionSuccess: (state: any, action: any) => {
      console.log(action, "import")
      const newState = {
        ...state,
        isLoading: false,
        eachOnboardingQuestion: action?.payload?.response,
      };
      return newState;
    },
    getEachOnboardingQuestionFailure: (state: any, action: any) => {
      const newState = {
        ...state,
        isLoading: false,
        error: action?.payload,
      };
      return newState;
    },




    setOnboardingState: (state: any, action: any) => {
      const newState = {
        ...state,
        [action.payload.key]: action.payload.value,
      };
      return newState;
    },
    setOnboardingToasterNull: (state: any) => {
      const newState = {
        ...state,
        toasterDetails: null,
      };
      return newState;
    },
  },
});

const { actions, reducer } = OnboardingQuestionRedux;

export const {
  addOnboardingQuestionRequest,
  addOnboardingQuestionSuccess,
  addOnboardingQuestionFailure,

  //on get
  getOnboardingQuestionRequest,
  getOnboardingQuestionSuccess,
  getOnboardingQuestionFailure,

  // on get Each 
  getEachOnboardingQuestionRequest,
  getEachOnboardingQuestionSuccess,
  getEachOnboardingQuestionFailure,

  //on delete
  deleteOnboardingQuestionRequest,
  deleteOnboardingQuestionSuccess,
  deleteOnboardingQuestionFailure,

  //on update
  updateOnboardingQuestionRequest,
  updateOnboardingQuestionSuccess,
  updateOnboardingQuestionFailure,

  setOnboardingState,
  setOnboardingToasterNull,
} = actions;

export default reducer;
