import { Box, styled, TableHead } from "@mui/material";

export const TableHeadWrapper = styled(TableHead)(({ theme }) => ({
  marginTop: "20px",
  "&&&&& .MuiTableRow-root": {
    height: "0px",
   
  },
  "&&& .MuiTableCell-root": {
    padding: "10px 15px",
    fontSize:"1.8rem"
  },
}));
