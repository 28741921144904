import { createSlice } from "@reduxjs/toolkit";


export interface ITopicCategory{
    isLoading?:boolean,
    error?:string,
    success?:string,
    allTopicCategory?:any,
    toasterDetails?:any
}

const initialState:ITopicCategory ={
    isLoading:false,
    error:"",
    success:"",
    allTopicCategory:null,
    toasterDetails:null
}


export const TopicCategoryRedux = createSlice({
    name:"topicCategoryRedux",
    initialState,
    reducers:{
       //  get request
       getTopicCategoryRequest: (state: any,action:any) => {
        const newState = {
            ...state,
            isLoading: true,
        };
        return newState;
    },
    getTopicCategorySuccess: (state: any, action: any) => {
        const newState = {
            ...state,
            isLoading: false,
            allTopicCategory: action?.payload?.response,
        };
        return newState;
    },
    getTopicCategoryFailure: (state: any, action: any) => {
    
        const newState = {
            ...state,
            isLoading: false,
            error: action?.payload,
            toasterDetails: action?.payload,
        };
        return newState;
    },
    }
})

export const { actions, reducer } = TopicCategoryRedux;

export const {
    getTopicCategoryRequest,
    getTopicCategorySuccess,
    getTopicCategoryFailure
}= actions
export default reducer;