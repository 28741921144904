import FrientorTheme from "./FrientorTheme";
import type { } from "@mui/lab/themeAugmentation";
import { PeopleTwoTone } from "@mui/icons-material";
import { tableCellClasses } from "@mui/material";

const { palette, shape, breakpoints } = FrientorTheme;

const ThemeOverrides = {
  ...FrientorTheme,
  components: {
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: palette.grey[800],
          padding: "1rem 0.2rem",
          fontSize: "1.4rem",
          fontWeight: 600,
          borderRadius: 8,
          height: "40px",
          [breakpoints.down("xl")]: {
            height: "30px",
          },

        },
        label: {
          color: palette.secondary.contrastText,
          width: "120px",
          fontSize: "1.6rem",
          [breakpoints.down("xl")]: {
            fontSize: "1.2rem",
          },
        },
        avatar: {
          width: "40px",
          height: "40px",
          marginLeft: "-3px",
          marginRight: "2px",
          borderRadius: "4px"
        },
      },
    },
    MuiAppBar: {
      ...FrientorTheme.components?.MuiAppBar,
      styleOverrides: {
        root: {
          background: palette.common.white,
          padding: "0.5rem 0",
        },
      },
    },
    MuiBreadcrumbs: {
      ...FrientorTheme.components?.MuiBreadcrumbs,
      styleOverrides: {
        root: {
          padding: "0.5rem 2rem",
        },
      },
    },
    MuiButton: {
      ...FrientorTheme.components?.MuiButton,
      styleOverrides: {
        root: {
          fontFamily: "Urbanist-Bold",
          fontSize: "1.6rem",
          textTransform: "capitalize",
          borderRadius: "15px",
          [breakpoints.down("xl")]: {
            fontSize: "1.4rem",
          },

          ":hover": {
            border: "none",
          },
        },
        // outlinedInherit: {
        //   color: palette.grey.A700,
        //   borderColor: palette.grey[700],
        // },
        outlined: {
          color: palette.secondary.dark,
          backgroundColor: palette.primary.light,
          border: "none",
        },
        contained: {
          color: palette.common.white,
          backgroundImage: palette.grey.A100,
          border: "none",
        },
      },
    },

    MuiLoadingButton: {
      ...FrientorTheme.components?.MuiLoadingButton,
      styleOverrides: {
        root: {
          fontFamily: "Urbanist-Regular",
          textTransform: "capitalize",
          color: palette.common.white,
          fontSize: "1.6rem",
          height: "40px",
          [breakpoints.down("xl")]: {
            fontSize: "1.2rem",
            height: "35px",
          },

        },
      },
    },
    MuiTextField: {
      ...FrientorTheme.components?.MuiTextField,
      styleOverrides: {
        root: {
          borderRadius: shape.borderRadius,
        },
      },
    },
    MuiInputBase: {
      ...FrientorTheme.components?.MuiInputBase,
      styleOverrides: {
        root: {
          color: palette.primary.main,
          borderRadius: shape.borderRadius,
          backgroundColor: "#fbfbfb",
        },
        input: {
          color: palette.common.black,
          fontFamily: "Urbanist-Regular",
          fontWeight: 500,
        },
        disabled: {
          color: palette.grey[300],
        },
        error: {
          backgroundColor: "#fbfbfb",
        },
      },
    },
    MuiCheckbox: {
      ...FrientorTheme.components?.MuiCheckbox,
      styleOverrides: {
        root: {
          color: palette.common.black,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          margin: "0.2rem 0 0",
          lineHeight: 1,
          color: palette.info.main,
        },
      },
    },
    MuiSelect: {
      ...FrientorTheme.components?.MuiSelect,
      styleOverrides: {
        select: {
          borderRadius: shape.borderRadius,
          fontFamily: "Urbanist-Regular",
          color: palette.common.black,
          [breakpoints.down("xl")]: {
            paddingTop: "12px",
            fontSize: "1.3rem",
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginRight: "2rem",
          marginLeft: "0.5rem",
          fontWeight: 500,
          color: palette.grey[900],
        },
      },
    },
    MuiAvatar: {
      ...FrientorTheme.components?.MuiAvatar,
      styleOverrides: {
        img: {
          objectFit: "contain",
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          // backgroundColor: palette.background.paper,
          backgroundColor: palette.common.black,
          border: `1px solid ${palette.grey[400]}`,
          maxWidth: 500,
        },
        arrow: {
          "&::before": {
            border: `1px solid ${palette.grey[400]}`,
            // backgroundColor: palette.background.paper,
            backgroundColor: palette.common.black,
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
      },
    },
    MuiAlert: {
      ...FrientorTheme.components?.MuiAlert,
      styleOverrides: {
        root: {
          // backgroundColor: "white",
          // borderRadius:4
          color: "white",
        },
        // standard: {
        //   padding: 0,
        // },
        // standardError: {
        //   color: palette.error.main,
        // },
        // standardInfo: {
        //   color: palette.primary.light,
        // },
        // filled: {
        //   color: palette.common.white,
        // },
        filledSuccess: {
          backgroundColor: "green",
        },
        filledError: {
          backgroundColor: "red",
        },
      },
    },
    MuiContainer: {
      ...FrientorTheme.components?.MuiContainer,
      styleOverrides: {
        root: {
          padding: "0 1rem",
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 35,
        },
      },
    },
    MuiListItemButton: {
      ...FrientorTheme?.components?.MuiListItemButton,
      styleOverrides: {
        root: {
          height: 62,
          gap: 10,
          [breakpoints.down("xl")]: {
            height: 45,
            gap: 6,
            margin: "5px 0px ",
          },
          margin: "10px 0px ",
          borderRadius: "13.2px",
          color: palette.primary.contrastText,
          "&& .MuiListItemText-primary": {
            [breakpoints.down("xl")]: {
              fontSize: "1.2rem",
            },
            fontSize: "1.8rem",
            fontFamily: "Urbanist-Bold",
            fontWeight: 600,
          },
          "&.Mui-selected, &.Mui-selected:hover": {
            backgroundColor: palette.common.white,
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: "1.6rem",
          fontFamily: "Urbanist-Medium",
          fontWeight: 500,
          color: "#807b92",
          [breakpoints.down("xl")]: {
            fontSize: "1.3rem",
          },
          error: {
            color: `${palette.error.main}`,
          },
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          columnGap: "3rem",
          justifyContent: "center",
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        },
      },
    },
    MuiRadio: {
      styleOverride: {
        root: {
          border: "0.5px solid  grey",
        },
        colorPrimary: {
          color: "#8867ff",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontWeight: 500,
          fontFamily: "Urbanist-Medium",
          color: palette.grey[500],
          borderBottom: ` solid 8px ${palette.common.white}`,
          fontSize: "1.8rem",
        },
        head: {
          backgroundColor: palette.common.white,
          color: palette.grey[600],
          border: "none",
          fontSize: "var(--font-160-rem) !important",
          fontWeight: 600,

          fontFamily: "Urbanist-SemiBold",
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          borderRadius: shape.borderRadius,
          backgroundColor: "#faf9ff",
          height: "89px",
          // borderBottom: `8px solid ${palette.common.black}`,
          [breakpoints.down("xl")]: {
            height: "65px",
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h1: {
          [breakpoints.up("xl")]: {
            fontSize: "5.5rem",
            fontFamily: "Urbanist-Regular",
            lineHeight: 1,
          },
          [breakpoints.down("xl")]: {
            fontSize: "5rem",
            fontFamily: "Urbanist-Regular",
            lineHeight: 1,
          },
          [breakpoints.down("lg")]: {
            fontSize: "4.5rem",
            fontFamily: "Urbanist-Regular",
            lineHeight: 1,
          },
          [breakpoints.down("md")]: {
            fontSize: "4rem",
            fontFamily: "Urbanist-Regular",
            lineHeight: 1,
          },
        },
        h4: {
          [breakpoints.up("xl")]: {
            fontSize: "3rem",
            fontFamily: "Urbanist-Regular",
          },
          [breakpoints.down("xl")]: {
            fontSize: "3rem",
            fontFamily: "Urbanist-Regular",
          },
          [breakpoints.down("lg")]: {
            fontSize: "3rem",
            fontFamily: "Urbanist-Regular",
          },
          [breakpoints.down("md")]: {
            fontSize: "3rem",
            fontFamily: "Urbanist-Regular",
          },
        },
        h5: {
          fontSize: "1rem",
          fontFamily: "Urbanist-Regular",
        },
        h6: {
          [breakpoints.up("xl")]: {
            fontSize: "2.2rem",
            fontWeight: 600,
            color: palette.secondary.main,
            fontFamily: "Urbanist-Regular",
            lineHeight: 2,
          },
          [breakpoints.down("xl")]: {
            fontSize: "2.2rem",
            fontWeight: 600,
            color: palette.secondary.main,
            fontFamily: "Urbanist-Regular",
            lineHeight: 2,
          },
          [breakpoints.down("lg")]: {
            fontSize: "1.8rem",
            fontWeight: 600,
            color: palette.secondary.main,
            fontFamily: "Urbanist-Regular",
            lineHeight: 2,
          },
          [breakpoints.down("md")]: {
            fontSize: "1.8rem",
            fontWeight: 600,
            color: palette.secondary.main,
            fontFamily: "Urbanist-Regular",
            lineHeight: 2,
          },
        },
        body1: {
          fontSize: "1.8rem",
          fontFamily: "Urbanist-Regular",
        },
        body2: {
          fontSize: "1rem",
          fontFamily: "Urbanist-Regular",
        },
        subtitle1: {
          fontSize: "1.8rem",
          fontFamily: "Urbanist-Regular",
          color: palette.grey[900],
        },
        caption: {
          [breakpoints.up("xl")]: {
            fontSize: "0.8rem",
            fontFamily: "Urbanist-Regular",
          },
          [breakpoints.down("xl")]: {
            fontSize: "0.8rem",
            fontFamily: "Urbanist-Regular",
          },
        },
      },
    },
    MuiMenuList: {
      styleOverrides: {
        width: "201px",
        height: "54px",
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          background: palette.common.white,
          boraderRadius: 0,
          "&.Mui-selected": {
            backgroundColor: palette.primary.dark,
            color: palette.common.white,
            background: palette.grey.A700,
            [breakpoints.down("xl")]: {
              fontSize: "1.3rem",
            },
          },
          ":hover": {
            color: "#363636",
            fontFamily: "Urbanist-Bold",
            backgroundColor: "#e4e1ec"
          }
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
          fontWeight: "500",
          padding: "20px 16px",
          alignItems: "flex-start",
          fontSize: "1.1rem",
          "&& .MuiTab-root MuiTab-textColorPrimary": {
            fontSize: "14px",
          },
          "&.Mui-selected": {
            color: palette.grey[400],
            fontWeight: "600",
          },
        },
      },
    },

    MuiSwitch: {
      styleOverrides: {
        root: {
          height: "29px",
        },
        switchBase: {
          // Controls default (unchecked) color for the thumb
          color: "#e1e3ec",
          top: "-1px",
        },
        colorPrimary: {
          "&.Mui-checked": {
            // Controls checked color for the thumb
            color: "#8867ff",
          },
        },
        track: {
          // Controls default (unchecked) color for the track
          opacity: 0.8,
          backgroundColor: "#e1e3ec",
          width: "30px",
          height: "7px",
          ".Mui-checked.Mui-checked + &": {
            // Controls checked color for the track
            opacity: 0.7,
            backgroundColor: "#e1e3ec",
          },
        },
        thumb: {
          width: 15,
          height: 15,
        },
      },
    },
  },
};

export default ThemeOverrides;
