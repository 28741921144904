import { ActionTypeEnumTextResponse, ResponseTypeEnum } from "../enumerations/ResponseTypeEnum"
export const QuestionEditResponse = (data: any, deletedIds: any, deleteCategoryID: any) => {
    console.log("QuestionEditResponse0", data);
    // for option
    const newResponse = data?.options?.filter((item: any) => item.id.length === 0)
    const modifiedResponse = data?.options?.filter((item: any) => item?.id?.length > 0)
    console.log("QuestionEditResponse01", data.topicCategory);
    // for topic option
    ;
    // 
    const topicNewResponse = data?.topicCategory?.filter((item: any) => item?.topicCategoryId?.length == 0);
    const topicModifiedResponse = data?.topicCategory?.filter((item: any) => (item?.topicCategoryId + "").length > 0);
    console.log("QuestionEditResponse31", topicModifiedResponse);

    // topic option filter
    const topicModificationOption = topicModifiedResponse?.map((item: any) => {
        const newOption = item?.options?.filter((item: any) => item.id.length === 0)
        const modificationOption = item?.options?.filter((item: any) => item.id.length > 0)
        return {
            topicCategoryId: item?.topicCategoryId,
            categoryName: item?.categoryName,
            responseOptions: [
                {
                    newResponseOptions: newOption?.map((item: any) => item.optionName)
                },
                {
                    deleteResponseOptionIds: item?.deletedOptionId?.map((item: any) => item.id)

                }, {
                    modifiedResponseOption: modificationOption
                }
            ]
        }
    })

    if ((data?.responseType === ResponseTypeEnum.Text) && (data?.responseFormat === ActionTypeEnumTextResponse?.TopicQuestion)) {
        const deleteAll = data?.options?.map((item: any) => item?.id)
        const options = [
            {
                newResponseOptions: []
            },
            {
                deleteResponseOptionIds: deletedIds?.length > 0 ? deletedIds : data?.options?.map((item: any) => item?.id)
            },
            {
                modifiedResponseOption: []
            }
        ]
        const topicCategory = [
            {
                newTopicCategory: topicNewResponse?.map((item: any) => {
                    return {
                        categoryName: item?.categoryName,
                        options: item?.options?.map((option: any) => option?.optionName)
                    }
                })
            },
            {
                deleteTopicCategoryIds: deleteCategoryID,
            },
            {
                modifiedTopicCategory: topicModificationOption
            }
        ]


        return { topicCategory, options }
    } else if ((data?.responseType === ResponseTypeEnum.Text) && (data?.responseFormat === ActionTypeEnumTextResponse.MultipleChoice || data?.responseFormat === ActionTypeEnumTextResponse.SingleChoice)) {
        const deletedCategoryId = data?.topicCategory?.map((item: any) => (item?.topicCategoryId))
        const options = [
            {
                newResponseOptions: newResponse?.map((option: any) => option.optionName)
            },
            {
                deleteResponseOptionIds: deletedIds?.length > 0 ? deletedIds : []
            },
            {
                modifiedResponseOption: modifiedResponse
            }

        ]
        const topicCategory = [
            {
                newTopicCategory: [],
            },
            {
                deleteTopicCategoryIds: deletedCategoryId?.length > 0 ? deletedCategoryId[0] == "" ? [] : deletedCategoryId : [],
            },
            {
                modifiedTopicCategory: []
            }
        ]

        return { topicCategory, options }
    } else {
        const deletedCategoryId = data?.topicCategory?.map((item: any) => (item?.topicCategoryId))
        const deleteAll = data?.options?.map((item: any) => item?.id)
        const options = [
            {
                newResponseOptions: []

            },
            {
                deleteResponseOptionIds: deleteAll?.length > 0 ? deleteAll : []
            },
            {
                modifiedResponseOption: []
            }

        ]
        const topicCategory = [
            {
                newTopicCategory: [],
            },
            {
                deleteTopicCategoryIds: deletedCategoryId?.length > 0 ? deletedCategoryId[0] == "" ? [] : deletedCategoryId : []
            },
            {
                modifiedTopicCategory: []
            }
        ]

        return { topicCategory, options }

    }

}