import ic_profile from "./images/ic_profile.png";
import ic_notifications from "./images/ic_notifications.png";
import ic_frientor_logo from "./images/ic_frientor_logo.png";
import ic_login_banner from "./images/ic_login_banner.png";
import ic_frientor_logo_with_label from "./images/ic_frientor_logo_with_label.png";
import ic_admin_user from "./images/ic_admin_user.png";
import ic_dashboard from "./images/ic_dashboard.png";
import ic_entity from "./images/ic_entity.png";
import ic_program_theme from "./images/ic_program_theme.png";
import ic_onboarding_question from "./images/ic_onboarding_question.png";
import ic_frientor_super_logo from "./images/ic_frientor_super_logo.png";
import ic_delete from "./images/ic_delete.png";
import ic_upload from "./images/ic_upload.png";
import ic_delete_white from "./images/ic_delete_white.png";
import ic_replace from "./images/ic_replace.png";
import ic_replace_white from "./images/ic_replace_white.png";
import ic_download from "./images/ic_download.png";
import ic_doc from "./images/ic_doc.png";
import ic_edit from "./images/ic_edit.png"
import ic_down_arrow from "./images/ic_down_arrow.png";
import ic_calendar from "./images/ic_calendar.png";
import ic_edit_icon from "./images/ic_edit_icon.png";
import ic_cross from "./images/ic_cross.png";
import ic_uploader_error from "./images/ic_uploader_error.png";
import ic_right_arrow from "./images/ic_right_arrow.png";
import ic_pagination_drop_down_arrow from "./images/ic_pagination_drop_down_arrow_2x.png";
import ic_blank_profile_picture from "./images/ic_blank_profile_picture.png"
import ic_google_logo from "./images/ic_google_logo.png"

const MediaAssets = {
  ic_notifications,
  ic_profile,
  ic_frientor_logo,
  ic_login_banner,
  ic_frientor_logo_with_label,
  ic_admin_user,
  ic_dashboard,
  ic_entity,
  ic_program_theme,
  ic_onboarding_question,
  ic_frientor_super_logo,
  ic_delete,
  ic_upload,
  ic_delete_white,
  ic_replace,
  ic_replace_white,
  ic_download,
  ic_doc,
  ic_edit,
  ic_down_arrow,
  ic_calendar,
  ic_edit_icon,
  ic_cross,
  ic_uploader_error,
  ic_right_arrow,
  ic_pagination_drop_down_arrow,
  ic_blank_profile_picture,
  ic_google_logo
};

export default MediaAssets;
