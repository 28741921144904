import { createSlice } from "@reduxjs/toolkit";

interface IBasicInfo {
  isLoading?: boolean;
  error?: string;
  success?: string;
  data?: any;
  toasterDetails?: any;
}

const initialState: IBasicInfo = {
  isLoading: false,
  error: "",
  success: "",
  data: null,
  toasterDetails: null,
};

export const basicInfoRedux = createSlice({
  name: "basicInfo",
  initialState,
  reducers: {
    addBasicDetailsRequest: (state, action) => {
      const newState = {
        ...state,
        isLoading: true,
      };
      return newState;
    },
    updateBasicDetailsRequest: (state, action) => {
      const newState = {
        ...state,
        isLoading: true,
      };
      return newState;
    },
    addBasicDetailsSuccess: (state, action) => {
      const newState = {
        isLoading: false,
        error: "",
        success: "",
        data: action?.payload?.response,
        toasterDetails: action?.payload,
      };
      return newState;
    },
    addBasicDetailsFailure: (state, action) => {
      const newState = {
        ...state,
        isLoading: false,
        error: action?.payload.response,
        toasterDetails: action.payload?.message?.["Validation Error"]
          ? {
              code: 400,
              message: Object.values(
                action.payload?.message?.["Validation Error"][0]
              )[0],
            }
          : action?.payload,
      };
      return newState;
    },
    makeToasterNull(state) {
      return {
        ...state,
        toasterDetails: null,
      };
    },
    reset(state) {
      return {
        isLoading: false,
        error: "",
        success: "",
        data: null,
        toasterDetails: null,
      };
    },
  },
});

const { reducer, actions } = basicInfoRedux;
export const {
  addBasicDetailsRequest,
  updateBasicDetailsRequest,
  addBasicDetailsSuccess,
  addBasicDetailsFailure,
  makeToasterNull,
  reset,
} = actions;
export default reducer;
