import { SagaIterator } from "@redux-saga/types";
import { call, put } from "redux-saga/effects";
import { HttpResponseTypeEnum } from "../../enumerations/HttpResponseTypeEnum";
import { AttachmentRedux } from "../../redux/attachment/AttachmentRedux";


export function* addAttachmentSaga(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.addAttachmentQuestion, action.payload);
    if (response.data.code === HttpResponseTypeEnum.Success) {
      yield put(
        AttachmentRedux.actions.addAttachmentSuccess(
          response.data
        )
      );
    } else {
      yield put(
        AttachmentRedux.actions.addAttachmentFailure(
          response.data
        )
      );
    }
  } catch (error: any) {
    yield put(
      AttachmentRedux.actions.addAttachmentFailure(error)
    );
  }
}

export function* deleteAttachmentSaga(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.multiDeleteAttachment, action.payload);
    if (response.data.code === HttpResponseTypeEnum.Success) {
      yield put(
        AttachmentRedux.actions.deleteAttachmentSuccess(
          response.data
        )
      );
    } else {
      yield put(
        AttachmentRedux.actions.deleteAttachmentFailure(
          response.data
        )
      );
    }
  } catch (error: any) {
    yield put(
      AttachmentRedux.actions.deleteAttachmentFailure(error)
    );
  }
}

export function* updateAttachmentSaga(api: any, action: any): SagaIterator {
  try {
    const response = yield call(api.updateAttchment, action.payload);
    if (response.data.code === HttpResponseTypeEnum.Success) {
      yield put(
        AttachmentRedux.actions.updateAttachmentSuccess(
          response.data
        )
      );
    } else {
      yield put(
        AttachmentRedux.actions.updateAttachmentFailure(
          response.data
        )
      );
    }
  } catch (error: any) {
    yield put(
      AttachmentRedux.actions.updateAttachmentFailure(error)
    );
  }
}

