import { CircularProgress } from "@mui/material";
import React from "react";
import styles from "./PageLoader.module.css";

const PageLoader: React.FC = () => {
  return (
    <div className={styles["loader-container"]}>
      <CircularProgress
        color="secondary"
      />
    </div>
  );
};


export default PageLoader;
