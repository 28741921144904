import { createTheme, responsiveFontSizes } from "@mui/material";
import type { } from "@mui/lab/themeAugmentation";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

let FrientorTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 690,
      md: 890,
      lg: 1200,
      xl: 1560,
    },
  },
  palette: {
    common: {
      black: "#000000",
      white: "#ffffff",
    },
    primary: {
      main: "#4174E5",
      light: "#efeaff", //button back ground color for outline
      dark: "#6776ff", //onBoarding question question title
      contrastText: "#413863", // side menu nav text color
    },
    secondary: {
      dark: "#8867ff", // for button text outlined
      main: "#2e2a3b", // stat count text color
      light: "#f6f5fa", // for stat background color
      contrastText: "#736c52",
    },
    text: {
      primary: "#6e6b8f",
      secondary: "#9b9aa8",
    },
    grey: {
      50: "#5A5D62",
      100: "#f7f6f8",
      200: "#efeeee",
      300: "#c4c4ce",
      400: "#707070",
      500: "#434245", //table cell color
      600: "#9793a5", //table header color
      700: "#7a7b7f", //page headerSubtitle
      800: "#f8edbf", // chip color
      900: "#7b7d92", // all input title color
      A100: "linear-gradient(to bottom, #c8bcf2, #8d7ad1 93%)", // button contained color",
      A200: "#e8e8e8", // border color
      A400: "#424245", //table cell
      A700: "#1d2639", // page header font color
    },
    info: {
      main: "#4a39ff",
      light: "#c0bcef",
    },
    success: {
      main: "#3cc012",
    },
    error: {
      main: "#e0514b",
      dark: "#ff3939",
    },
    background: {
      paper: "#fff",
      default: "transparent",
    },
    action: {
      disabled: "#d3d3d9",
    },
  },
  typography: {
    htmlFontSize: 10,
    fontFamily: "Urbanist-Regular",
    // fontSize: 10,
    h5: {
      fontFamily: "Urbanist-Regular",
    },
    h6: {
      fontFamily: "Urbanist-Regular",
    },
    body1: {
      fontFamily: "Urbanist-Regular",
    },
    body2: {
      fontFamily: "Urbanist-Regular",
    },
  },
  shape: {
    borderRadius: 4,
  },
  components: {
    MuiButton: {
      defaultProps: {
        size: "large",
        disableRipple: true,
        disableFocusRipple: true,
        disableElevation: true,
        disableTouchRipple: true,
        variant: "outlined",
      },
    },
    MuiTypography: {
      defaultProps: {
        color: "#000",
      },
    },
    MuiButtonGroup: {
      defaultProps: {
        disableRipple: true,
        disableElevation: true,
        disableFocusRipple: true,
      },
    },
    MuiLoadingButton: {
      defaultProps: {
        disableTouchRipple: true,
        disableElevation: true,
        loadingPosition: "start",
        disableFocusRipple: true,
        variant: "contained",
      },
    },
    MuiIconButton: {
      defaultProps: {
        disableRipple: true,
        disableFocusRipple: true,
      },
    },
    MuiListItem: {
      defaultProps: {
        disableGutters: true,
      },
    },

    MuiListItemButton: {
      defaultProps: {
        disableTouchRipple: true,
        disableGutters: false,
      },
    },
    MuiAlert: {
      defaultProps: {
        variant: "standard",
      },
    },
    MuiCheckbox: {
      defaultProps: {
        color: "default",
      },
    },
    MuiTextField: {
      defaultProps: {
        fullWidth: true,
        variant: "outlined",
        margin: "none",
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: "outlined",
        margin: "none",
        size: "medium",
        IconComponent: ExpandMoreIcon,
        MenuProps: {
          PaperProps: { sx: { maxHeight: 250 } },
          MenuListProps: { sx: { ".MuiMenuItem-root": { minHeight: 35 } } },
        },
      },
    },
    MuiAutocomplete: {
      defaultProps: {
        size: "small",
      },
    },
    MuiIcon: {
      defaultProps: {
        fontSize: "small",
      },
    },
    MuiLink: {
      defaultProps: {
        color: "inherit",
        underline: "none",
      },
    },
    MuiStack: {
      defaultProps: {
        direction: "row",
        alignItems: "center",
        justifyContent: "space-between",
      },
    },
    MuiDrawer: {
      defaultProps: {
        color: "inherit",
      },
    },
    MuiAppBar: {
      defaultProps: {
        color: "inherit",
        position: "fixed",
      },
    },
    MuiFormControl: {
      defaultProps: {
        fullWidth: true,
      },
    },
    MuiAvatar: {
      defaultProps: {
        variant: "square",
        sizes: "small",
        alt: "icons",
      },
    },
    MuiContainer: {
      defaultProps: {
        maxWidth: false,
        fixed: false,
        disableGutters: true,
      },
    },
    MuiInputAdornment: {
      defaultProps: {
        position: "start",
      },
    },
  },
});

export default FrientorTheme = responsiveFontSizes(FrientorTheme);
