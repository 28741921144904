import { createSlice } from "@reduxjs/toolkit";

interface IContactDetails {
  isLoading?: boolean;
  error?: string;
  success?: string;
  data?: any;
  toasterDetails?: any;
}

const initialState: IContactDetails = {
  isLoading: false,
  error: "",
  success: "",
  data: null,
  toasterDetails: null,
};

export const contactDetailsRedux = createSlice({
  name: "contactDetails",
  initialState,
  reducers: {
    addContactDetailsRequest(state, action) {
      return { ...state, isLoading: true };
    },
    updateContactDetailsRequest(state, action) {
      return { ...state, isLoading: true };
    },
    addContactDetailsSuccess(state, action) {
      return {
        ...state,
        data: action?.payload?.data?.response,
        isLoading: false,
        toasterDetails: action?.payload?.fetched // while editing we are updating this state by GET API call, for that time , this logic makes toaster value null
          ? null // because toaster should visible after update or create it... and it is same for Failure state update
          : action?.payload?.data,
      };
    },
    addContactDetailsFailure(state, action) {
      return {
        ...state,
        isLoading: false,
        error: action?.payload?.data?.response,
        toasterDetails: action?.payload?.fetched ? null : action?.payload?.data,
      };
    },
    fetchContactDetailsRequest(state, action) {
      return { ...state, toasterDetails: null, isLoading: true };
    },
    makeToasterNull(state) {
      return {
        ...state,
        toasterDetails: null,
      };
    },
    reset(state) {
      return {
        ...state,
        toasterDetails: null,
        data: null,
        error: "",
        success: "",
      };
    },
  },
});

export const { actions, reducer } = contactDetailsRedux;
export const {
  addContactDetailsRequest,
  updateContactDetailsRequest,
  addContactDetailsSuccess,
  addContactDetailsFailure,
  fetchContactDetailsRequest,
  makeToasterNull,
  reset
} = actions;

export default reducer;
