import { Box, Typography, Stack, Grid, Tooltip } from "@mui/material";
import { styled } from "@mui/material";
import styles from "./FileUploadDragDrop.module.css";
import MediaAssets from "../../assets";
import { StringConstant } from "../../resources/strings";
import AlertHelperText from "../ui-elements/mui-alert-helper-text/AlertHelperText";

interface IFileUploadDragDrop {
  fieldhelpertext?: any;
  onDrop?: (event: any) => void;
  onChange?: (event: any) => void;
  previewFile?: any;
  name?: string;
  value?: string;
  onDeleteImage?: (event?: any) => void;
  onBlur?: (event: any) => void;
  disabled?: boolean;
  acceptFileType?: string;
  isImage?: boolean;
  onClickOnDownload?: any;
  handleDrag?: (e: any) => void;
  downloadUrl?: string;
  error:string | false;
}

export const DragAndDropStyle = styled(Box)({
  border: "1px dashed #9795af",
  borderRadius: "4px",
  width: "450px",
  height: "250px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
  cursor: "pointer",
  marginLeft: "2.75rem",
  "& .innerButton": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
});

const FileUploadDragDrop = (props: IFileUploadDragDrop) => {
  return (
    <div
      data-testid="media-upload-drag-drop"
      className={`${props.disabled ? styles["drag-and-drop"] : styles[""]}`}
    >
      {props?.previewFile ? (
        <Box className={styles["image-dropdown-section"]}>
          {props?.isImage ? (
            <img
              className={styles["preview-image-section"]}
              src={`${props?.previewFile}`}
              alt={props.name}
            />
          ) : (
            <div className={styles["preview-doc-section"]}>
              <img
                className={styles["doc-icon"]}
                src={MediaAssets.ic_doc}
              ></img>
            </div>
          )}
          <Grid className={styles["perview-file-bottom-panel"]}>
            <Tooltip title={props?.name}>
              <Typography className={styles["perview-file-name"]}>
                {props?.name}
              </Typography>
            </Tooltip>
            <Stack
              spacing={2}
              direction={"row"}
              justifyContent={"space-evenly"}
              alignItems={"center"}
              className={styles["replace-button-wrapper"]}
            >
              {props?.isImage ? null : (
                <a download href={props.downloadUrl}>
                  <img
                    src={MediaAssets.ic_download}
                    className={styles["download-replace-delete-icon"]}
                  ></img>
                </a>
              )}
              <div>
                {" "}
                {/* upload image section */}
                <label
                  className={styles["display-flex-space-btw"]}
                  htmlFor={`${props.name}`}
                >
                  <input
                    accept={props?.isImage ? ".jpg,.png,.jpeg," : ".doc,.docx"}
                    id={`${props.name}`}
                    name={props.name}
                    type="file"
                    value={props.value}
                    onBlur={props.onBlur}
                    className={styles["input-display-none"]}
                    onChange={props.onChange}
                    readOnly={props.disabled}
                  />
                  <img
                    className={styles["download-replace-delete-icon"]}
                    src={MediaAssets.ic_replace}
                  ></img>
                </label>
              </div>

              <div onClick={props.onDeleteImage}>
                <img
                  className={styles["download-replace-delete-icon"]}
                  src={MediaAssets.ic_delete}
                ></img>
              </div>
            </Stack>
          </Grid>
        </Box>
      ) : (
        <DragAndDropStyle>
          <Box component="label" htmlFor={`${props.name}`}>
            <input
              data-testid="media-input-field"
              accept={props?.isImage ? ".jpg,.png,.jpeg," : ".doc,.docx"}
              id={`${props.name}`}
              name={props.name}
              type="file"
              value={props.value}
              onBlur={props.onBlur}
              className={styles["image-upload-input"]}
              onChange={props.onChange}
              onDrop={props.onDrop}
              onDragEnter={props.handleDrag}
              onDragLeave={props.handleDrag}
              onDragOver={props.handleDrag}
            />
            <Box className="innerButton">
              <img
                className={styles["download-replace-delete-icon"]}
                src={MediaAssets.ic_upload}
              />
              <Box className={styles["drag-and-drop"]}>
                {StringConstant.text_drag_and_drop}
                {props?.isImage
                  ? StringConstant.text_image
                  : StringConstant.text_document}
                {StringConstant.text_here}
              </Box>
              <Box color="#9b9aa8" fontSize="16px" margin={"0 0 5px 0"}>
                {StringConstant.text_or}
              </Box>
              <Box
                className={`${
                  props.disabled
                    ? styles["browse-files-link-disabled"]
                    : styles["browse-files-link"]
                }`}
              >
                {StringConstant.text_browse}
              </Box>

              <Box className={styles["max-file-size"]}>
                {StringConstant.text_max_file_size}
                <span className={styles["font-family-as-urbanist-bold"]}>
                  {StringConstant.text_ten_MB}
                </span>
                {StringConstant.text_file_type}
                <span className={styles["font-family-as-urbanist-bold"]}>
                  {/* {StringConstant.text_word} */}
                  {props?.isImage
                    ? StringConstant.text_MIME_type
                    : StringConstant.text_word}
                </span>
              </Box>
              {props.fieldhelpertext ? (
                <AlertHelperText color="error" severity="error">
                  {props.fieldhelpertext || ""}
                </AlertHelperText>
              ) : null}
            </Box>
          </Box>
        </DragAndDropStyle>
      )}
      {props.error && <span className={styles["input-file-error-message"]}>{props.error}</span>}
    </div>
  );
};
export default FileUploadDragDrop;
