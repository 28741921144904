import Drawer from "@mui/material/Drawer";
import NotificationItem from "./NotificationItem";
import styles from "./Notifications.module.css";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { IconButton } from "@mui/material";

const Notifications = (props:any) => {
  return (
    <Drawer
      anchor={"right"}
      open={props?.openNotification}
      PaperProps={{
        sx: {
          width: "30%",
          marginTop: "0px",
          padding: "30px",
          borderTopLeftRadius: "20px",
        },
      }}
      onClose={props?.onCloseOnNotification}
    >
      <div className={styles["heading-holder"]}>
        <h1>Notifications</h1>
        <IconButton onClick={props?.onCloseOnNotification}>
          <CloseRoundedIcon fontSize="large" />
        </IconButton>
      </div>

      <div className={styles["notifications-item-holder"]}>
        <NotificationItem />
      </div>
    </Drawer>
  );
};

export default Notifications;
