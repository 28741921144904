import React from "react";
import DashboardIcon from "@mui/icons-material/Dashboard";
import MediaAssets from "../../assets";

export const SideBarData: any = [
  {
    id: 1,
    title: "Dashboard",
    path: "/dashboard",
    icon: <DashboardIcon />,
  },
  {
    id: 2,
    title: "Super Admin Management",
    path: "/admin-user-management",
    icon: <DashboardIcon />,
  },
  // {
  //   id: 3,
  //   title: "Onboarding Questions",
  //   path: "/onboarding-question-list",
  //   icon: <DashboardIcon />,
  // },
  {
    id: 4,
    title: "Program Themes",
    path: "/program-themes",
    icon: <DashboardIcon />,
  },
  {
    id: 5,
    title: "Entity Management",
    path: "/entity-management",
    icon: <DashboardIcon />,
  },
];

export const navBarImage = (key: any) => {
  switch (key) {
    case 1:
      return `${MediaAssets.ic_dashboard}`;
      break;
    case 2:
      return `${MediaAssets.ic_admin_user}`;
      break;
    case 3:
      return `${MediaAssets.ic_onboarding_question}`;
      break;
    case 4:
      return `${MediaAssets.ic_program_theme}`;
      break;
    case 5:
      return `${MediaAssets.ic_entity}`;
      break;
    default:
      break;
  }
};
